/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {useIntl} from 'react-intl';
import {useAuth} from '../core/Auth';
import {login} from '../../../shared/services/auth-service';
import {getCurrentUser, shortAccessToken} from "../../../shared/services/user-service";
import clsx from "clsx";
import {Link, useLocation} from "react-router-dom";
import {NotificationMessage} from "../../../shared/helper/Notification";
import {postRequestCode} from "../../../shared/services/access-token-service";

const initialValues = {
    username: '',
    password: '',
}

const departmentsForShortAccessToken = ['Odsjek APU 1', 'Odsjek APU 3'];
const weLevelsForShortAccessToken = ['WE5', 'WE6'];

export function Login() {
    const intl = useIntl();
    const {saveAuth} = useAuth();

    const myParam = encodeURI(useLocation().search);
    const request_code = new URLSearchParams(myParam).get('request_code');

    const [loading, setLoading] = useState(false);

    const loginSchema = Yup.object().shape({
        username: Yup.string()
            .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED_USERNAME_FIELD'})),
        password: Yup.string()
            .required(intl.formatMessage({id: 'AUTH.VALIDATION.REQUIRED_PASSWORD_FIELD'})),
    })

    function afterLogin() {
        if (request_code) {
            postRequestCode(request_code).then((requestId: any) => {
                    window.location.href = `/request/${requestId}/overview`;
                }
            )
        } else {
            window.location.href = '/dashboard';
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values) => {
            setLoading(true);

            try {
                login(values.username, values.password).then((response) => {
                    const {data: auth} = response;

                    localStorage.setItem('access_token', auth.access_token);
                    localStorage.setItem('refresh_token', auth.refresh_token);

                    getCurrentUser('expand=profile.image_url').then((response: any) => {
                        if (response) {
                            localStorage.setItem('current_user', JSON.stringify(response.data as string));
                            saveAuth(auth);

                            if (departmentsForShortAccessToken.includes(response.data?.job_position?.department?.name) && weLevelsForShortAccessToken.includes(response.data?.job_position?.we_level)) {
                                shortAccessToken(auth.access_token).then(() => {
                                    afterLogin();
                                });
                            } else {
                                afterLogin();
                            }
                        }
                    });
                }, () => {
                    saveAuth(undefined);
                    setLoading(false);
                    NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.LOGIN_FAILED'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.WRONG_PASSWORD_OR_USERNAME'}), 'danger');

                    formik.values.password = '';
                    formik.setTouched({username: undefined, password: undefined}, true);
                    formik.setErrors({password: ' ', username: ' '});
                });
            } catch (error) {
                saveAuth(undefined);
                setLoading(false);
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.LOGIN_FAILED'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.WRONG_PASSWORD_OR_USERNAME'}), 'danger');

                formik.values.password = '';
                await formik.setTouched({username: true, password: true}, true);
                await formik.setErrors({password: ' ', username: ' '});
            }
        },
    })

    return (
        <>
            <div className='text-center mb-5'>
                <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.HEADER'})}</h1>
                <div className='text-gray-400 fw-bold fs-4'>
                    {intl.formatMessage({id: 'AUTH.HEADER.DESCRIPTION'})}
                </div>
            </div>
            <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
            >
                <div className='fv-row mb-10'>
                    <label
                        className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({id: 'AUTH.INPUT.USERNAME'})}</label>
                    <input
                        placeholder={intl.formatMessage({id: 'AUTH.INPUT.USERNAME'})}
                        {...formik.getFieldProps('username')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid border border-danger': formik.touched.username && formik.errors.username},
                            {
                                'is-valid border border-success': formik.touched.username && !formik.errors.username,
                            },
                        )}
                        type='username'
                        name='username'
                        autoComplete='off'
                        tabIndex={1}
                    />
                    {formik.touched.username && formik.errors.username && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.username}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-10'>
                    <div className='d-flex justify-content-between mt-n5'>
                        <div className='d-flex flex-stack mb-2'>
                            <label
                                className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}</label>
                            <Link
                                to='/forgot-password'
                                className='link-primary fs-6 fw-bolder'
                                style={{marginLeft: '8px'}}
                            >
                                {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD'})}
                            </Link>
                        </div>
                    </div>
                    <input
                        type='password'
                        placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid border border-danger': formik.touched.password && formik.errors.password,
                            },
                            {
                                'is-valid border border-success': formik.touched.password && !formik.errors.password,
                            }
                        )}
                        tabIndex={2}
                    />
                    {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.password}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='text-center'>
                    <button
                        type='submit'
                        className='btn btn-primary fw-bolder w-100 mb-5'
                        disabled={formik.isSubmitting || !formik.isValid || loading}
                        tabIndex={3}
                    >
                        {!loading &&
                            <span className='indicator-label'>{intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}</span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                            {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                        )}
                    </button>
                </div>
            </form>
        </>
    )
}
