/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom';
import {Request} from '../../../shared/models/_models';
import {getRequestActionNotes} from '../../../shared/services/request-service';
import {SimpleLoading} from "../../../modules/apps/shared/list/components/loading/SimpleLoading";

type Props = {
    request: Request
}
const StatusChanges: FC<Props> = ({request}) => {
    const intl = useIntl()
    const requestId = useParams().id;
    const [isLoading, setIsLoading] = useState(false)
    const [activities, setActivities] = useState([] as any)

    const asyncFetch = async () => {
        setActivities(await getRequestActionNotes(requestId))
        setIsLoading(false)
    }

    useEffect(() => {
        setIsLoading(true)
        asyncFetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request])

    function isLastElement(array: any, element: any): boolean {
        let latestActivity = array[0];

        for (const obj of array) {
            if (obj['completed_at'] > latestActivity['completed_at']) {
                latestActivity = obj;
            }
        }

        return latestActivity === element;
    }

    const optionsLists = ((item: any) => {
        const isLastActivity = isLastElement(activities?.data, item);

        switch (item?.action_name) {
            case intl.formatMessage({id: 'REQUEST.ACTIONS.REQUEST.APPROVAL'}):
                return intl.formatMessage({id: 'REQUEST.ACTIONS.APPROVAL.DISPLAY'})
            case intl.formatMessage({id: 'REQUEST.ACTIONS.APPROVE'}):
                if (request?.request_type?.name?.includes('Obrazac') && isLastActivity && request?.status === 'finished') {
                    return intl.formatMessage({id: 'REQUEST.ACTIVITY.APPROVE_EVALUATION.DISPLAY'});
                }
                return intl.formatMessage({id: 'REQUEST.ACTIVITY.APPROVE.DISPLAY'})
            case intl.formatMessage({id: 'REQUEST.ACTIONS.CANCEL'}):
                return intl.formatMessage({id: 'REQUEST.ACTIVITY.CANCEL.DISPLAY'})
            case intl.formatMessage({id: 'REQUEST.ACTIONS.UPLOAD'}):
                return intl.formatMessage({id: 'REQUEST.ACTIVITY.UPLOAD.DISPLAY'})
            case intl.formatMessage({id: 'REQUEST.ACTIONS.DENY'}):
                return intl.formatMessage({id: 'REQUEST.ACTIVITY.DENY.DISPLAY'})
            case intl.formatMessage({id: 'REQUEST.ACTIONS.PRINT'}):
                return intl.formatMessage({id: 'REQUEST.DROPDOWN.STATUS.PRINTED'})
            default:
                intl.formatMessage({id: 'REQUEST.STATUS.PRINT'})
        }
    })

    return (
        <div
            className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9 mt-10 mb-xl-0 mb-10'>
            <div className='card-body'>
                {isLoading ? <div className='border-bottom pb-8 mb-7'><SimpleLoading/></div> :
                    <h3 className='fw-bolder m-0 mb-7 d-flex justify-content-center justify-content-sm-start justify-content-md-start justify-content-lg-start fs-5 text-uppercase pb-8 border-bottom'>{intl.formatMessage({id: 'REQUEST.STATUS.STATE.ACTIVITIES'})}</h3>}
                {activities?.data?.map((item: any, i: any) =>
                    <div key={i} className='timeline-label'>
                        <div className='timeline-item me-3 pt-2 pb-3 w-100'>
                            <div className='h-50px'>
                                <span
                                    className='fs-8 fw-bolder text-gray-700'> {moment.unix(item?.completed_at || 0).format("DD.MM.YY")}</span>
                                <span
                                    className='fs-8 fw-bolder text-gray-700 justify-content-end d-flex'> {moment.unix(item?.completed_at || 0).format("H:mm")}</span>
                            </div>
                            <div className='d-inline ps-3' style={{width: '90%'}}>
                                <div className='pe-1 text-dark fs-7 fw-bold ps-4'>
                                    {item?.full_name}
                                </div>
                                <div className='fw-mormal timeline-content text-muted ps-4'>
                                    <span
                                        className='fs-7 fw-bolder text-gray-700'>{intl.formatMessage({id: 'REQUEST.ACTIVITY.PRINT.DISPLAY'})}:&nbsp;&nbsp;</span>
                                    <span className='pe-1 text-dark fs-7 fw-bold'>{optionsLists(item)}</span>
                                </div>
                                {item?.note && <div className='fw-mormal timeline-content text-muted ps-4 text-justify'>
                                    <span
                                        className='fs-7 fw-bolder text-gray-700'>{intl.formatMessage({id: 'REQUEST.STATUS.STATE.COMMENT'})}:&nbsp;&nbsp;</span>
                                    <span className='fs-7 text-gray-700'>{item?.note}</span>
                                </div>}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export {StatusChanges}
