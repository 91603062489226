import {ID, Response} from '../../../_metronic/helpers'
import {
    FamilyMember,
    FamilyMembersQueryResponse,
    User,
    UsersQueryResponse
} from '../models/_models'
import {exportFile, get, post, put, remove} from './api-client'
import {AxiosResponse} from 'axios'

const ENDPOINT = `/user`;

const getUsers = (query: string): Promise<UsersQueryResponse> => {
    return get(ENDPOINT, query).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID, query: string): Promise<User | undefined> => {
    return get(`${ENDPOINT}/${id}`, query)
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}

const getUserForActivation = (activationCode: string | undefined, query: string): Promise<Response<User> | User> => {
    return get(`${ENDPOINT}/user-for-activation/${activationCode}`, query)
        .then((response: AxiosResponse<Response<User>>) => response.data)
}

const activateUser = (id: ID, password: any): Promise<User | undefined> => {
    return put(`${ENDPOINT}/activate-user/${id}`, password)
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}

const sendActivationMail = (id: ID, query: string): Promise<boolean | undefined> => {
    return get(`${ENDPOINT}/send-activation-mail/${id}`, query)
        .then((response: AxiosResponse<boolean>) => response.data);
}

const sendResetPasswordMail = (email: string, query: string): Promise<boolean | undefined> => {
    return get(`${ENDPOINT}/send-reset-password-mail/${email}`, query)
        .then((response: AxiosResponse<boolean>) => response.data);
}

const getUserForResetPassword = (resetPasswordToken: string | undefined, query: string): Promise<Response<User> | User> => {
    return get(`${ENDPOINT}/user-for-reset-password/${resetPasswordToken}`, query)
        .then((response: AxiosResponse<Response<User>>) => response.data)
}

const resetPasswordUser = (id: ID, newPassword: any): Promise<User | undefined> => {
    return put(`${ENDPOINT}/reset-password-user/${id}`, newPassword)
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}

const getFamilyMembers = (userId: ID): Promise<FamilyMembersQueryResponse> => {
    return get(`${ENDPOINT}/${userId}/get-family-members`, '')
        .then((response: AxiosResponse<FamilyMembersQueryResponse>) => response.data)
}

const getFamilyMember = (id: ID): Promise<FamilyMember | undefined> => {
    return get(`${ENDPOINT}/get-family-member/${id}`, '')
        .then((response: AxiosResponse<Response<FamilyMember>>) => response.data)
        .then((response: Response<FamilyMember>) => response.data)
}

const createFamilyMember = (familyMember: any): Promise<FamilyMember | undefined> => {
    return post(`${ENDPOINT}/create-family-member`, familyMember)
        .then((response: AxiosResponse<Response<FamilyMember>>) => response.data)
        .then((response: Response<FamilyMember>) => response.data)
}

const deleteFamilyMember = (familyMemberID: ID): Promise<void> => {
    return remove(`${ENDPOINT}/delete-family-member/${familyMemberID}`).then(() => {
    })
}

const updateFamilyMember = (familyMember: FamilyMember): Promise<FamilyMember | undefined> => {
    return put(`${ENDPOINT}/update-family-member/${familyMember.id}`, familyMember)
        .then((response: AxiosResponse<Response<FamilyMember>>) => response.data)
        .then((response: Response<FamilyMember>) => response.data)
}

const createUser = (user: any): Promise<User | undefined> => {
    return post(ENDPOINT, user)
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | any | undefined> => {
    return put(`${ENDPOINT}/${user.id}`, user)
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}

const updateUserImage = (user_id: any, image_id: any): Promise<User | any | undefined> => {
    return put(`${ENDPOINT}/update-profile-image/${user_id}`, {
        "profile": {
            "image_id": image_id
        }
    })
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}

const newPassword = (password: any): Promise<User | undefined> => {
    return put(`${ENDPOINT}/${password.id}`, password)
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}

const updateUserPersonalData = (user: User): Promise<User | undefined> => {
    const bodyPersonalDetails = {
        "first_name": user?.first_name,
        "last_name": user?.last_name,
        "profile": {
            "parent_name": user.profile?.parent_name,
            "address": user.profile?.address,
            "postal_number": user.profile?.postal_number,
            "municipality": user.profile?.municipality,
            "city": user.profile?.city,
            "date_of_birth": user?.profile?.date_of_birth,
            "place_of_birth": user.profile?.place_of_birth,
            "jmbg": user.profile?.jmbg,
            "gender": user.profile?.gender,
            "id_number": user.profile?.id_number,
            "phone_number": user.profile?.phone_number,
            "driving_licence": user.profile?.driving_licence,
            "education": user.profile?.education,
            "profession": user.profile?.profession,
            "foreign_languages": user.profile?.foreign_languages,
        }
    }
    return put(`${ENDPOINT}/${user.id}`, bodyPersonalDetails)
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}

const updateUserBusinessData = (user: User): Promise<User | undefined> => {
    const bodyBusinessDetails = {
        "job_position_id": user?.job_position_id,
        "secondary_job_position": user?.secondary_job_position,
        "profile": {
            "code": user.profile?.code,
            "business_number": user.profile?.business_number,
            "date_of_employment": user.profile?.date_of_employment,
            "end_of_employment_date": user.profile?.end_of_employment_date,
            "shift": user.profile?.shift,
            "shift_from": user.profile?.shift_from,
            "shift_to": user.profile?.shift_to,
            "contract_type": user.profile?.contract_type,
            "end_of_contract": user?.profile?.end_of_contract,
            "is_on_probation": user?.profile?.is_on_probation,
            "probation_to": user?.profile?.probation_to,
            "gross_salary": user?.profile?.gross_salary,
            "note": user.profile?.note,
            "reason_for_termination": user.profile?.reason_for_termination,
            "working_years": user.profile?.working_years,
            "working_years_in_company": user.profile?.working_years_in_company,
            "experience_years": user.profile?.experience_years,
            "experience_months": user.profile?.experience_months,
            "experience_days": user.profile?.experience_days,
            "apu": user.profile?.apu
        }
    }
    return put(`${ENDPOINT}/${user.id}`, bodyBusinessDetails)
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}

const updateUserFamilyData = (user: User): Promise<User | undefined> => {
    const bodyFamilyDetails = {
        "family_members": user?.family_members,
        "profile": {
            "is_married": user.profile?.is_married,
            "spouse_first_name": user.profile?.spouse_first_name,
            "spouse_last_name": user.profile?.spouse_last_name,
            "spouse_phone_number": user.profile?.spouse_phone_number
        }
    }
    return put(`${ENDPOINT}/${user.id}/update-family-data`, bodyFamilyDetails)
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
    return remove(`${ENDPOINT}/${userId}`).then(() => {
    })
}

const getCurrentUser = (query: string): Promise<User | undefined> => {
    return get(`${ENDPOINT}/me`, query)
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response as unknown as User)
}

const exportUsersToExcel = () => {
    return exportFile(`${ENDPOINT}/export-users`).then((response) => response)
}

const exportFamilyDetailsToExcel = () => {
    return exportFile(`${ENDPOINT}/export-family-details`).then((response) => response)
}

const exportVacationDetailsToExcel = () => {
    return exportFile(`${ENDPOINT}/export-vacation-details`).then((response) => response)
}

const getRequestContractStatistics = (year: string): Promise<any> => {
    return get(`${ENDPOINT}/request-contract-statistics/${year}`, '')
        .then((response: AxiosResponse<any>) => response.data)
}

const shortAccessToken = (accessToken: string): Promise<User | any | undefined> => {
    return put(`${ENDPOINT}/short-access-token/${accessToken}`, '')
        .then((response: AxiosResponse<Response<User>>) => response.data)
        .then((response: Response<User>) => response.data)
}

export {
    getUsers,
    deleteUser,
    getUserById,
    getFamilyMembers,
    getFamilyMember,
    deleteFamilyMember,
    updateFamilyMember,
    createUser,
    createFamilyMember,
    updateUser,
    updateUserPersonalData,
    updateUserBusinessData,
    updateUserFamilyData,
    getCurrentUser,
    sendActivationMail,
    getUserForActivation,
    activateUser,
    sendResetPasswordMail,
    getUserForResetPassword,
    resetPasswordUser,
    newPassword,
    exportUsersToExcel,
    exportFamilyDetailsToExcel,
    exportVacationDetailsToExcel,
    getRequestContractStatistics,
    updateUserImage,
    shortAccessToken
}
