import {ListViewProvider, useListView} from '../../modules/apps/shared/list/core/ListViewProvider'
import {QueryRequestProvider} from '../../modules/apps/shared/list/core/QueryRequestProvider'
import {QueryResponseProviderDepartments} from '../../modules/apps/department-management/core/QueryResponseProviderDepartments'
import {DepartmentsTable} from './DepartmentsTable'
import {KTCard} from '../../../_metronic/helpers'
import React from 'react'
import {DepartmentsListHeader} from '../../modules/apps/department-management/components/header/DepartmentsListHeader'
import {DepartmentCreateModal} from '../../modules/apps/department-management/department-create-modal/DepartmentCreateModal'
import {DeleteDepartmentModal} from "../../shared/components/DeleteDepartmentModal";

const DepartmentsList = () => {
    const {itemIdForUpdate} = useListView()
    const {itemIdForDelete} = useListView()
    return (
        <>
            <KTCard>
                <DepartmentsListHeader/>
                <DepartmentsTable/>
            </KTCard>
            {itemIdForUpdate !== undefined && <DepartmentCreateModal/>}
            {itemIdForDelete !== undefined && <DeleteDepartmentModal id={itemIdForDelete}/>}
        </>
    )
}

const DepartmentsListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProviderDepartments>
            <ListViewProvider>
                <DepartmentsList/>
            </ListViewProvider>
        </QueryResponseProviderDepartments>
    </QueryRequestProvider>
)

export {DepartmentsListWrapper}
