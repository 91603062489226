/* eslint-disable react-hooks/exhaustive-deps */
import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../../shared/list/core/ListViewProvider'
import {DepartmentCreateModalForm} from './DepartmentCreateModalForm'
import {getDepartmentById, getDepartments} from '../../../../shared/services/department-service'
import React, {useEffect, useState} from "react";

type Props = {
    isLoadingModal: boolean,
    setIsLoadingModal: React.Dispatch<React.SetStateAction<boolean>>,
}

const DepartmentCreateModalFormWrapper: React.FC<Props> = ({isLoadingModal, setIsLoadingModal}) => {
    const {itemIdForUpdate, setItemIdForUpdate} = useListView()
    const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
    const [isDepartmentLoading, setIsDepartmentLoading] = useState(isLoadingModal)

    const {
        isLoading,
        data: department,
        error,
    } = useQuery(
        `${QUERIES.DEPARTMENTS_LIST}-department-${itemIdForUpdate}`,
        () => {
            return getDepartmentById(itemIdForUpdate);
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {
                setItemIdForUpdate(undefined)
                console.error(err)
            },
        }
    );

    const {
        data: departments,
    } = useQuery(
        `departments`,
        () => {
            return getDepartments('list=true')
        },
        {
            cacheTime: 0,
            enabled: true,
            onError: (err) => {
                console.error(err)
            },
        }
    );

    useEffect(() => {
        setIsLoadingModal(isDepartmentLoading);
    });

    if (!itemIdForUpdate) {
        return (
            <DepartmentCreateModalForm isDepartmentLoading={isDepartmentLoading} department={{id: undefined}}
                                       departments={departments?.data} setIsDepartmentLoading={setIsDepartmentLoading}/>
        )
    }

    if (!isLoading && !error && department) {
        return <DepartmentCreateModalForm isDepartmentLoading={isDepartmentLoading} department={department}
                                          departments={departments?.data}
                                          setIsDepartmentLoading={setIsDepartmentLoading}/>
    }

    return null
}

export {DepartmentCreateModalFormWrapper}
