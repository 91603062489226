import React, {Dispatch, SetStateAction} from 'react'
import {Contract} from "../../../../shared/models/_models";
import moment from "moment";
import {useIntl} from "react-intl";
import clsx from "clsx";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import { KTSVG } from '../../../../../_metronic/helpers';
import { ContractHelper } from '../../../../shared/helper/ContractHelper';

type props = {
    contract: Contract
    setOpenContractModal: Dispatch<SetStateAction<boolean>>;
    setUserContractInfo: Dispatch<SetStateAction<Contract>>;
}

const ContractElement: React.FC<props> = ({contract}) => {
    const navigate = useNavigate();
    const intl = useIntl();
    const contract_undefined = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE.UNDEFINED'});
    const contract_defined = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE.DEFINED'});

    const imageCssClass = clsx('svg-icon-2x', {
        // eslint-disable-next-line
        ['svg-icon-danger']: contract.status === intl.formatMessage({id: 'CONTRACT.TABLE.STATUS.EXPIRE'}),
        // eslint-disable-next-line
        ['svg-icon-success']: contract.status === intl.formatMessage({id: 'CONTRACT.TABLE.STATUS.ACTIVE'}),
    })

    const goToContract = () => {
        navigate(`/contract/${{contract}.contract?.id}/overview`)
    }

    return (
        <tr onClick={() => goToContract()} className={'notification-row bg-gray-200 bg-hover-light-primary'}>
                <td className='ps-5'>
                    <div className='position-relative'>
                        {contract?.signed_contract_file_url && <span className='position-absolute bottom-0 start-50 translate-middle'>
                            <KTSVG
                                path='/media/icons/duotune/communication/com008.svg'
                                className={'svg-icon-primary svg-icon-1'}
                            />
                        </span>}
                        <div className='symbol symbol-100px'>
                            <OverlayTrigger placement="top" overlay={<Tooltip>{ContractHelper(contract)}</Tooltip>}>
                                <span>
                                    <KTSVG
                                        path='/media/icons/duotune/abstract/abs009.svg'
                                        className={imageCssClass}
                                    />
                                </span>
                            </OverlayTrigger>
                        </div>
                    </div>
                </td>
                <td className='min-w-100px text-center align-middle fs-6'>{contract?.code || '-'}</td>
                <td className='min-w-100px text-center align-middle fs-6'>{contract?.type === 'undefined' ? contract_undefined : contract_defined}</td>
                <td className='min-w-150px text-center align-middle fs-6'>{moment(contract?.start_date).format('DD.MM.YYYY') || 'N/A'}</td>
                <td className='min-w-150px text-center align-middle pe-6 fs-6'>{contract?.end_date ? moment(contract?.end_date).format('DD.MM.YYYY') : '-'}</td>
        </tr>
    )
}

export {ContractElement}
