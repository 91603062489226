import clsx from 'clsx';
import moment from 'moment';
import React, {FC} from 'react'
import {OverlayTrigger, Tooltip, TooltipProps} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {KTSVG} from '../../../../../_metronic/helpers';
import {RequestStatusHelper} from '../../../../shared/helper/RequestStatusHelper';
import {Request} from "../../../../shared/models/_models";

type Props = {
    request: Request
}
const RequestStatusCell: FC<Props> = ({request}) => {
    const intl = useIntl();
    const currentUser = JSON.parse(localStorage.getItem('current_user') || '');
    const restrictionAccess = request?.status === intl.formatMessage({id: 'REQUEST.STATUS.NEW'}) && (currentUser?.id === request?.created_by);
    const url = (restrictionAccess) ? `/request/${request.id}/edit` : `/request/${request.id}/overview`;
    
    var dateString = moment.unix(request?.created_at || 0).format("DD.MM.YYYY");

    const dangerTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
        <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.REQUEST.LATE'})}</Tooltip>
    );

    const imageCssClass = clsx('svg-icon-2x', {
        // eslint-disable-next-line
        ['svg-icon-warning']: request.status === intl.formatMessage({id: 'REQUEST.STATUS.NEW'}),
        // eslint-disable-next-line
        ['svg-icon-danger']: request.status === intl.formatMessage({id: 'REQUEST.STATUS.REJECT'}),
        // eslint-disable-next-line
        ['svg-icon-primary']: [intl.formatMessage({id: 'REQUEST.STATUS.SUCCESS'}), intl.formatMessage({id: 'REQUEST.STATUS.PROCESSING'}), intl.formatMessage({id: 'REQUEST.STATUS.PRINT'})].includes(request?.status || ''),
        // eslint-disable-next-line
        ['svg-icon-success']: request.status === intl.formatMessage({id: 'REQUEST.STATUS.FINISHED'}),
    })

    return (
        <div className='d-flex align-items-center'>
            <div className='position-relative'>
                {request?.signed_approval_file_id && <span className='position-absolute bottom-0 start-50 translate-middle'>
                    <KTSVG
                        path='/media/icons/duotune/communication/com008.svg'
                        className={'svg-icon-primary svg-icon-1'}
                    />
                </span>}
                <div className='symbol symbol-50px me-5'>
                    <OverlayTrigger placement="top" overlay={<Tooltip>{RequestStatusHelper(request)}</Tooltip>}>
                        <span>

                            <KTSVG
                                path='/media/icons/duotune/abstract/abs009.svg'
                                className={imageCssClass}
                            />
                        </span>
                    </OverlayTrigger>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center'>
                <Link to={url} className='text-dark text-hover-primary fs-6 fw-bold text-gray-800'>
                    {request.request_type?.name}
                </Link>
                <span className='text-muted fw-semibold d-flex align-items-center'>
                    {(request?.is_late && request?.status !== 'finished' && request?.status !== 'rejected') ? (<> <span
                        className='text-danger fw-semibold d-flex align-items-center fs-6'>{dateString}</span>
                        <OverlayTrigger placement="bottom" overlay={dangerTooltip}>
                        <span>
                        <KTSVG
                            path='/media/icons/duotune/general/gen013.svg'
                            className='svg-icon svg-icon-2 text-danger blink ms-1'
                        />
                        </span>
                        </OverlayTrigger></>) : <span className='fs-6'>{dateString}</span>}
                </span>
            </div>
        </div>
    )
}

export {RequestStatusCell}
