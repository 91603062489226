import {KTSVG} from '../../../../../../_metronic/helpers'
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";

const ContractsListToolbar = () => {
    const intl = useIntl()
    return (
        <div data-kt-user-table-toolbar='base'>
            <Link type='button' className='btn btn-primary mb-1' to={'create'}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                {intl.formatMessage({id: 'CONTRACT.ADD_CONTRACT'})}
            </Link>

        </div>
    )
}

export {ContractsListToolbar}
