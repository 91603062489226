/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
    createResponseContext,
    initialQueryResponse,
    initialQueryState, Links,
    PaginationState,
    QUERIES,
    stringifyRequestQuery,
    WithChildren,
} from '../../../../../../_metronic/helpers'
import {getUsers} from '../../../../../shared/services/user-service'
import {User} from '../../../../../shared/models/user'
import {useQueryRequest} from '../../../shared/list/core/QueryRequestProvider'

let pagination: { _meta: any; data?: User[] | undefined; payload?: { message?: string | undefined; errors?: { [key: string]: string[] } | undefined; pagination?: PaginationState | undefined } | undefined; _links?: Links };

const QueryResponseContext = createResponseContext<User>(initialQueryResponse)
const QueryResponseProviderUsers: FC<WithChildren> = ({children}) => {
    const {state} = useQueryRequest()
    const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
    const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

    useEffect(() => {
        if (query !== updatedQuery) {
            setQuery(updatedQuery)
        }
    }, [updatedQuery])

    const {
        isFetching,
        refetch,
        data: response,
    } = useQuery(
        `${QUERIES.USERS_LIST}-${query}`,
        () => {
            return getUsers('expand=profile,contracts&schema_sort=true' + (query ? '&' + query : ''))
        },
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    )

    return (
        <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
            {children}
        </QueryResponseContext.Provider>
    )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
    const {response} = useQueryResponse()
    if (!response) {
        return []
    }
    pagination = response;
    return response?.data || []
}
const useQueryResponsePagination = () => {
    const defaultPaginationState: PaginationState = {
        links: [
            {
                label: '<',
                active: false,
                url: 'http://api.veritas.local/v1/user?page=' + (pagination?._meta?.currentPage - 1),
                page: pagination?._meta?.currentPage - 1,
            },
            {
                label: (pagination?._meta?.currentPage - 1) ? (pagination?._meta?.currentPage - 1) : '0',
                active: false,
                url: 'http://api.veritas.local/v1/user?page=' + (pagination?._meta?.currentPage - 1),
                page: pagination?._meta?.currentPage - 1,
            },
            {
                label: (pagination?._meta?.currentPage) ? (pagination?._meta?.currentPage) : '1',
                active: true,
                url: 'http://api.veritas.local/v1/user?page=' + pagination?._meta?.currentPage,
                page: pagination?._meta?.currentPage,
            },
            {
                label: (pagination?._meta?.currentPage + 1) ? (pagination?._meta?.currentPage + 1) : '2',
                active: false,
                url: 'http://api.veritas.local/v1/user?page=' + (pagination?._meta?.currentPage + 1),
                page: pagination?._meta?.currentPage + 1,
            },
            {
                label: '>',
                active: false,
                url: 'http://api.veritas.local/v1/user?page=' + (pagination?._meta?.currentPage + 1),
                page: pagination?._meta?.currentPage + 1,
            },
        ],
        ...initialQueryState,
    }

    const {response} = useQueryResponse()

    if (!response || !response.payload || !response.payload.pagination) {
        return {
            items_per_page: response?._meta?.perPage,
            links: response?._meta?.totalCount !== 0 ? defaultPaginationState.links : [],
            page: response?._meta?.currentPage,
            total_pages: response?._meta?.totalCount
        }
    }

    return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
    const {isLoading} = useQueryResponse()
    return isLoading
}

export {
    QueryResponseProviderUsers,
    useQueryResponse,
    useQueryResponseData,
    useQueryResponsePagination,
    useQueryResponseLoading,
}
