import clsx from 'clsx';
import moment from 'moment';
import {FC} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import { KTSVG } from '../../../../_metronic/helpers';
import { RequestStatusHelper } from '../../../shared/helper/RequestStatusHelper';
import {Request} from "../../../shared/models/request";


type Props = {
    request: Request
}

const RequestInfoField: FC<Props> = ({request}) => {
    const intl = useIntl();    

    const imageCssClass = clsx('svg-icon-2x', {
        // eslint-disable-next-line
        ['svg-icon-warning']: request.status === intl.formatMessage({id: 'REQUEST.STATUS.NEW'}),
        // eslint-disable-next-line
        ['svg-icon-danger']: request.status === intl.formatMessage({id: 'REQUEST.STATUS.REJECT'}),
        // eslint-disable-next-line
        ['svg-icon-primary']: [intl.formatMessage({id: 'REQUEST.STATUS.SUCCESS'}), intl.formatMessage({id: 'REQUEST.STATUS.PROCESSING'}), intl.formatMessage({id: 'REQUEST.STATUS.PRINT'})].includes(request?.status || ''),
        // eslint-disable-next-line
        ['svg-icon-success']: request.status === intl.formatMessage({id: 'REQUEST.STATUS.FINISHED'}),
    })

    var dateString = moment.unix(request?.created_at || 0).format("DD.MM.YYYY");

    return (
           <>
           <div className='symbol symbol-100px me-5'>
                <OverlayTrigger placement="top" overlay={<Tooltip>{RequestStatusHelper(request)}</Tooltip>}>
                <span>
                    <KTSVG
                        path='/media/icons/duotune/abstract/abs009.svg'
                        className={imageCssClass}
                    />
                </span>
                </OverlayTrigger>
            </div>
            <div className='flex-grow-1'>
                <span className='text-gray-800 fw-bold fs-6 d-block'>
                    {request?.request_type?.name}
                </span>
                <span className='text-gray-800 fw-semibold'>
                    {request?.user?.first_name + ' ' + request?.user?.last_name}
                </span>
            </div>
            <span className='text-gray-800 fw-semibold fs-6'>
                {dateString}
            </span>
        </>
    )
}

export {RequestInfoField}
