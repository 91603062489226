import React, {FC, useEffect} from 'react';
import {OverlayTrigger, Tooltip, TooltipProps} from "react-bootstrap";
import {useIntl} from "react-intl";
import {KTSVG} from "../../../../../_metronic/helpers";
import {useListView} from "../../shared/list/core/ListViewProvider";
import {MenuComponent} from "../../../../../_metronic/assets/ts/components";
import {Link} from 'react-router-dom';
import {Request} from '../../../../shared/models/_models'

type Props = {
    request: Request
}

const RequestActionsCell: FC<Props> = ({request}) => {
    const intl = useIntl();
    const currentUser = JSON.parse(localStorage.getItem('current_user') || '')
    const userPermissionHr = !!(currentUser?.roles?.filter((item: any) => (item === 'hr' || item === 'admin' || item === 'hr1' || item === 'hr2'))?.length > 0);
    const restrictionAccess = (request?.status === intl.formatMessage({id: 'REQUEST.STATUS.NEW'})) && (currentUser?.id === request?.created_by)
    const url = (restrictionAccess || userPermissionHr) ? `/request/${request?.id}/edit` : `/request/${request?.id}/overview`

    const {setItemIdForDelete} = useListView();

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openDeleteModal = () => {
        setItemIdForDelete(request.id)
    }


    const editTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
        <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.EDIT'})}</Tooltip>
    );

    const deleteTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
        <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.DELETE'})}</Tooltip>
    );

    return (
        <>
        {(restrictionAccess || userPermissionHr) ?
           <div className='d-flex justify-content-end'>
            <OverlayTrigger placement="top" overlay={editTooltip}>
                <Link to={url}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                </Link>
            </OverlayTrigger>
            {userPermissionHr && <OverlayTrigger placement="top" overlay={deleteTooltip}>
                <button
                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                    onClick={openDeleteModal}
                >
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3'/>
                </button>
            </OverlayTrigger>}
            </div> : <></>
        }
       </>
    )
}

export {RequestActionsCell}
