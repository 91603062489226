import React, {FC, useEffect} from 'react'
import {OverlayTrigger, Tooltip, TooltipProps} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {ID, KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../../shared/list/core/ListViewProvider'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import { RoutesHelper } from '../../../../shared/helper/RoutesHelper'

type Props = {
  id: ID
}

const JobPositionActionsCell: FC<Props> = ({id}) => {
  const intl = useIntl()
  const {setItemIdForDelete} = useListView()
  const {setItemIdForUpdate} = useListView()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const openDeleteModal = () => {
    setItemIdForDelete(id)
  }

  const editTooltip = (
    props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>
  ) => <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.EDIT'})}</Tooltip>

  const deleteTooltip = (
    props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>
  ) => <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.DELETE'})}</Tooltip>

  return (
    <>
      {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) && (
        <>
          <OverlayTrigger placement='top' overlay={editTooltip}>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={openEditModal}
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </button>
          </OverlayTrigger>
          <OverlayTrigger placement='top' overlay={deleteTooltip}>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
              onClick={openDeleteModal}
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </OverlayTrigger>
        </>
      )}
    </>
  )
}

export {JobPositionActionsCell}
