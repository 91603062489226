import {FC} from 'react'
import {Department} from '../../../../../shared/models/department'
import {Link} from 'react-router-dom'
import {useIntl} from "react-intl";

type Props = {
    department: Department
}

const DepartmentInfoCell: FC<Props> = ({department}) => {
    const intl = useIntl()
    if(department.name === intl.formatMessage({id: 'DEPARTMENT.HUMAN.RESOURCES.DEPARTMENT'})) {
        return (
            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column'>
                    <Link to={`/department/${department.id}/overview`}
                          className='text-gray-800 text-hover-primary mb-1'>
                        HR
                    </Link>
                    <span>{department?.parent?.name}</span>
                </div>
            </div>
        )
    }
    else{
        return (
            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column'>
                    <Link to={`/department/${department.id}/overview`}
                          className='text-gray-800 text-hover-primary mb-1'>
                        {department.name}
                    </Link>
                    <span>{department?.parent?.name}</span>
                </div>
            </div>
        )
    }
}

export {DepartmentInfoCell}
