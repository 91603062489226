import React, {useState} from 'react';
import {KTSVG} from '../../../../_metronic/helpers';
import {Dropdown} from "react-bootstrap";
import Select from "react-select";
import {useIntl} from "react-intl";
import {listMonths, listNotificationTypes} from "../../../shared/SelectLists";
import {NotificationMessage} from "../../../shared/helper/Notification";

type props = {
    setFilterParamsQuery: any;
    setLoading: any;
    setIsFiltered: any;
}

const NotificationListFilter: React.FC<props> = ({setFilterParamsQuery, setLoading, setIsFiltered}) => {
    const intl = useIntl();
    const [selectedMonth, setSelectedMonth] = useState({} as any);
    const [selectedYear, setSelectedYear] = useState('' as any);
    const [selectedType, setSelectedType] = useState({} as any);

    const [buttonColor, setButtonColor] = useState('primary');
    const [buttonColorActive, setButtonColorActive] = useState('btn-active-light-primary');

    function resetFilterForm() {
        setSelectedMonth({});
        setSelectedYear('');
        setSelectedType({});

        setButtonColor('primary');
        setButtonColorActive('btn-active-light-primary');

        setIsFiltered(true);
        setLoading(true);
        setFilterParamsQuery(undefined);

        NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FILTER_RESET_SUCCESSFULLY'}), '', 'success')
    }

    function filterNotifications() {
        let filterString = '';

        if (selectedType.value === undefined && selectedMonth.value === undefined && selectedYear === '') {
            NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.MUST_CHOOSE_FILTER_VALUE'}), '', 'warning')
        } else {
            setLoading(true);
            setButtonColor('success');
            setButtonColorActive('btn-active-light-success');

            if (selectedType.value !== undefined) {
                filterString += '&is_read=' + selectedType.value;
            }
            if (selectedMonth.value !== undefined) {
                filterString += '&month=' + selectedMonth.value;
            }
            if (selectedYear) {
                filterString += '&year=' + selectedYear;
            }

            setIsFiltered(true);
            setFilterParamsQuery(filterString)
        }
    }

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant={buttonColor} className={buttonColorActive}>
                    <KTSVG
                        path='/media/icons/duotune/general/gen031.svg'
                        className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    {intl.formatMessage({id: 'NOTIFICATIONS.FILTER'})}
                </Dropdown.Toggle>
                <Dropdown.Menu className='px-4 py-4menu menu-sub menu-sub-dropdown w-250px w-sm-300px' align="start"
                               id="dropdown-menu-align-end">
                    <div className='px-2 py-5'>
                        <div
                            className='fs-5 text-dark fw-bolder'>{intl.formatMessage({id: 'NOTIFICATIONS.FILTER.OPTIONS'})}</div>
                    </div>
                    <div className='separator border-gray-200'></div>
                    <div className='px-2 py-5' data-kt-user-table-filter='form'>
                        <div className='mb-5'>
                            <label
                                className='form-label fs-6 fw-bold'>{intl.formatMessage({id: 'NOTIFICATIONS.FILTER.MONTH'})}:</label>
                            <Select
                                name='month'
                                placeholder={intl.formatMessage({id: 'NOTIFICATIONS.FILTER.CHOOSE_MONTH'})}
                                options={listMonths}
                                value={{
                                    value: selectedMonth ? selectedMonth.id : '',
                                    label: selectedMonth?.label ? selectedMonth.label : intl.formatMessage({id: 'NOTIFICATIONS.FILTER.CHOOSE_MONTH'})
                                }}
                                onChange={(newValue: any) => setSelectedMonth(newValue)}
                                isSearchable={false}
                                classNamePrefix='select'
                                className='mb-3 mb-lg-0'
                            />
                        </div>
                        <div className='mb-5'>
                            <label
                                className='form-label fs-6 fw-bold'>{intl.formatMessage({id: 'NOTIFICATIONS.FILTER.YEAR'})}:</label>
                            <input
                                placeholder={intl.formatMessage({id: 'NOTIFICATIONS.FILTER.INPUT_YEAR'})}
                                type='text'
                                name='year'
                                className='form-control form-control-solid mb-3 mb-lg-0'
                                autoComplete='off'
                                onChange={(e) => setSelectedYear(e.target.value)}
                                value={selectedYear}
                            />
                        </div>
                        <div className='separator border-gray-200 my-5'></div>
                        <div className='mb-5'>
                            <label
                                className='form-label fs-6 fw-bold'>{intl.formatMessage({id: 'NOTIFICATIONS.FILTER.TYPE'})}:</label>
                            <Select
                                name='type'
                                placeholder={intl.formatMessage({id: 'NOTIFICATIONS.FILTER.CHOOSE_TYPE'})}
                                options={listNotificationTypes}
                                value={{
                                    value: selectedType ? selectedType.id : '',
                                    label: selectedType?.label ? selectedType.label : intl.formatMessage({id: 'NOTIFICATIONS.FILTER.CHOOSE_TYPE'})
                                }}
                                onChange={(newValue: any) => setSelectedType(newValue)}
                                isSearchable={false}
                                classNamePrefix='select'
                                className='mb-3 mb-lg-0'
                            />
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button
                                type='button'
                                className='btn btn-sm btn-light btn-active-light-danger fw-bold me-2 px-6'
                                onClick={resetFilterForm}
                            >
                                {intl.formatMessage({id: 'NOTIFICATIONS.FILTER.RESET'})}
                            </button>
                            <button
                                type='button'
                                className='btn btn-sm btn-primary fw-bold px-6'
                                onClick={filterNotifications}
                            >
                                {intl.formatMessage({id: 'NOTIFICATIONS.FILTER.CONFIRM'})}
                            </button>
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export {NotificationListFilter}
