import {Contract} from "../models/_models"
import moment from "moment";

export class ContractStatusHelper {
    static calculate(contract: Contract) {
        var currentDate = moment(new Date());
        var contractEndDate = moment(contract?.end_date);
        var leftDays = Math.ceil(contractEndDate.diff(currentDate, 'days', true));

        if (leftDays > 45) {
            if(contract?.status === 'expired')
            {
                return {days: leftDays, status: 'danger'}
            }
            else {
                return {days: leftDays, status: 'success'}
            }
        } else if (leftDays <= 45 && leftDays > 0) {
            if (contract?.status === 'expired') {
                return {days: leftDays, status: 'danger'}
            } else {
                return {days: leftDays, status: 'warning'}
            }
        } else {
            return {days: leftDays, status: 'danger'}
        }
    }
}
