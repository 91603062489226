import React, {useState} from 'react'
import {useFormik} from 'formik'
import {Link, useNavigate} from 'react-router-dom'
import {updateUser} from '../../../shared/services/user-service'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {ChildrensListWrapper} from './children/ChildrensList'
import {User} from '../../../shared/models/user'
import {NotificationMessage} from '../../../shared/helper/Notification'
import {SimpleLoading} from '../../../modules/apps/shared/list/components/loading/SimpleLoading'

type Props = {
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
  asyncFetch?: Function
}

const EditFamilyDetails: React.FC<Props> = ({user, setUser, asyncFetch}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: JSON.parse(JSON.stringify(user).replace(/:null/gi, ':""')),
    onSubmit: async (values) => {
      setLoading(true)

      updateUser(values).then((response) => {
        if (response) {
          setUser(values)
          NotificationMessage(
            intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FAMILY_SUCCESSFULLY_UPDATED!'}),
            '',
            'success'
          )
          navigate(`/user/${user?.id}/details/family/view/`)
        } else {
          NotificationMessage(
            intl.formatMessage({id: 'MESSAGE_NOTIFICATION.UPDATE_FAILED!'}),
            intl.formatMessage({
              id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_UPDATING_FAMILY_INFORMATION!_PLEASE_TRY_AGAIN.',
            }),
            'danger'
          )
          setLoading(false)
        }
      })
    },
  })

  return (
    <div className='card mb-5 mb-xl-10 card-xl-stretch'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'ACCOUNT.FAMILY_DETAILS'})}</h3>
        </div>
      </div>
      {!loading ? (
        <div id='kt_account_family_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top'>
              <div className='row'>
                <div className='col-md-2 col-sm-6 mb-2'>
                  <label className='col-form-label pt-0 fw-bold fs-6 mb-3'>
                    {intl.formatMessage({id: 'ACCOUNT.FAMILY_DETAILS.MARITAL_STATUS.MARRIED'})}
                  </label>
                  <div className='d-flex align-items-center form-check form-check-solid form-switch'>
                    <input
                      className='form-check-input cursor-pointer form-check-solid form-switch w-45px h-30px'
                      type='checkbox'
                      checked={formik.values.profile?.is_married === 1}
                      name='profile.is_married'
                      onChange={(e) => {
                        formik.setFieldValue(
                          'profile.is_married',
                          formik.values.profile?.is_married === 1 ? 0 : 1
                        )

                        if (!e.target.checked) {
                          formik.setFieldValue('profile.spouse_first_name', null)
                          formik.setFieldValue('profile.spouse_last_name', null)
                          formik.setFieldValue('profile.spouse_phone_number', null)
                        }
                      }}
                    />
                  </div>
                </div>
                {formik.values.profile?.is_married === 1 ? (
                  <div className='col-sm-12 col-md-3 mb-2 mb-sm-0'>
                    <label className='col-form-label pt-0 fw-bold fs-6'>
                      {intl.formatMessage({id: 'ACCOUNT.FAMILY_DETAILS.SPOUSE.FIRST_NAME'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'ACCOUNT.FAMILY_DETAILS.SPOUSE.FIRST_NAME',
                      })}
                      {...formik.getFieldProps('profile.spouse_first_name')}
                      type='text'
                      name='profile.spouse_first_name'
                      className={clsx('form-control form-control-solid')}
                      autoComplete='off'
                    />
                  </div>
                ) : (
                  ''
                )}
                {formik.values.profile?.is_married === 1 ? (
                  <div className='col-sm-12 col-md-4 mb-2 mb-sm-0'>
                    <label className='col-form-label pt-0 fw-bold fs-6 '>
                      {intl.formatMessage({id: 'ACCOUNT.FAMILY_DETAILS.SPOUSE.LAST_NAME'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'ACCOUNT.FAMILY_DETAILS.SPOUSE.LAST_NAME',
                      })}
                      {...formik.getFieldProps('profile.spouse_last_name')}
                      type='text'
                      name='profile.spouse_last_name'
                      className={clsx('form-control form-control-solid')}
                      autoComplete='off'
                    />
                  </div>
                ) : (
                  ''
                )}
                {formik.values.profile?.is_married === 1 ? (
                  <div className='col-sm-12 col-md-3'>
                    <label className='col-form-label pt-0 fw-bold fs-6'>
                      {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.PHONE_NUMBER'})}
                    </label>
                    <input
                      placeholder={intl.formatMessage({
                        id: 'ACCOUNT.PERSONAL_DETAILS.PHONE_NUMBER',
                      })}
                      {...formik.getFieldProps('profile.spouse_phone_number')}
                      type='text'
                      name='profile.spouse_phone_number'
                      className={clsx('form-control form-control-solid')}
                      autoComplete='off'
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            {formik.dirty && (
              <div className='card-footer d-flex justify-content-end pb-0'>
                <Link to={`/user/${{user}.user?.id}/details/family/view`}>
                  <button type='reset' className='btn btn-light me-3' disabled={loading}>
                    {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                  </button>
                </Link>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && intl.formatMessage({id: 'BUTTON.SAVE_CHANGES'})}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            )}
          </form>
          <div>
            <ChildrensListWrapper asyncFetch={asyncFetch}></ChildrensListWrapper>
          </div>
        </div>
      ) : (
        <div className='position-absolute top-50 start-50 translate-middle'>
          <SimpleLoading />
        </div>
      )}
    </div>
  )
}
export {EditFamilyDetails}
