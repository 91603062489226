/* eslint-disable react-hooks/exhaustive-deps */
import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../../shared/list/core/ListViewProvider'
import {getDepartments} from '../../../../shared/services/department-service'
import React, {useEffect, useState} from "react";
import {JobPositionCreateModalForm} from "./JobPositionCreateModalForm";
import {getJobPositionById} from "../../../../shared/services/job-position-service";

type Props = {
    isLoadingModal: boolean,
    setIsLoadingModal: React.Dispatch<React.SetStateAction<boolean>>,
}

const JobPositionCreateModalFormWrapper: React.FC<Props> = ({isLoadingModal, setIsLoadingModal}) => {
    const {itemIdForUpdate, setItemIdForUpdate} = useListView()
    const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
    const [isJobPositionLoading, setIsJobPositionLoading] = useState(isLoadingModal)

    const {
        isLoading,
        data: jobPosition,
        error,
    } = useQuery(
        `${QUERIES.JOB_POSITIONS_LIST}-job-position-${itemIdForUpdate}`,
        () => {
            return getJobPositionById(itemIdForUpdate)
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {
                setItemIdForUpdate(undefined)
                console.error(err)
            },
        }
    )
    const {
        data: departments,
    } = useQuery(
        `departments`,
        () => {
            return getDepartments('list=true')
        },
        {
            cacheTime: 0,
            enabled: true,
            onError: (err) => {
                console.error(err)
            },
        }
    )
    useEffect(() => {
        setIsLoadingModal(isJobPositionLoading);
    })

    if (!itemIdForUpdate) {
        return (
            <JobPositionCreateModalForm isJobPositionLoading={isJobPositionLoading} jobPosition={{id: undefined}} departments={departments?.data}  setIsJobPositionLoading={setIsJobPositionLoading}/>
        )
    }

    if (!isLoading && !error && jobPosition) {
        return <JobPositionCreateModalForm isJobPositionLoading={isJobPositionLoading} jobPosition={jobPosition} departments={departments?.data} setIsJobPositionLoading={setIsJobPositionLoading}/>
    }

    return null
}

export {JobPositionCreateModalFormWrapper}
