import {Store} from "react-notifications-component";

const NotificationMessage = (titleText: string, messageText: string, notificationType: any) => {
    return (
        Store.addNotification({
            title: titleText,
            message: messageText,
            type: notificationType,
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2500
            }
        })
    )
}

export {NotificationMessage}




