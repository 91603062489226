import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTCardBody} from '../../../../_metronic/helpers'
import {ListLoading} from '../../../modules/apps/shared/list/components/loading/ListLoading'
import {getRequestTypes} from '../../../shared/services/request-service'
import {SettingsItem} from './SettingsItem'

const RequestSettings: React.FC = () => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const [requestTypes, setRequestTypes] = React.useState<any[]>([])

  useEffect(() => {
    setIsLoading(true)
    asyncFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const asyncFetch = async () => {
    await getRequestTypes('').then((response: any) => {
      setRequestTypes(response?.data)
      setIsLoading(false)
    })
  }

  return (
    <KTCardBody className='px-8'>
      <div className='card-header row border-0 pt-4'>
        <div className='d-flex align-items-center'>
          <h1 className='d-flex align-items-sm-start align-items-center flex-column fs-2qx'>
            {intl.formatMessage({id: 'REQUEST.SETTINGS.TITLE'})}
          </h1>
        </div>
      </div>
      <div className='table-responsive'>
        <table
          id='kt_table_requests'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th className='align-middle'>{intl.formatMessage({id: 'REQUEST.SETTINGS.NAME'})}</th>
              <th>
                <div className='d-flex justify-content-center'>
                  {intl.formatMessage({id: 'REQUEST.SETTINGS.MAX_WE_LEVEL'})}
                </div>
              </th>
              <th>
                <div className='d-flex justify-content-center'>
                  {intl.formatMessage({id: 'REQUEST.SETTINGS.MIN_WE_LEVEL'})}
                </div>
              </th>
              <th className='align-middle'>
                <div className='d-flex justify-content-center'>
                  {intl.formatMessage({id: 'REQUEST.SETTINGS.TECHNICAL'})}
                </div>
              </th>
              <th className='align-middle'>
                <div className='d-flex justify-content-center'>
                  {intl.formatMessage({id: 'REQUEST.SETTINGS.COMMERCIAL'})}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {requestTypes && requestTypes.length > 0 ? (
              requestTypes.map((requestType) => (
                <SettingsItem key={requestType?.id} item={requestType} setLoading={setIsLoading} />
              ))
            ) : (
              <tr>
                <td colSpan={4}>
                  <div className='d-flex flex-column text-center align-content-center'>
                    {intl.formatMessage({id: 'REQUEST.SETTINGS.NOT_ACTIVE'})}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export default RequestSettings
