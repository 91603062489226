import clsx from 'clsx'
import {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'

import { FamilyMember} from "../../../../shared/models/family_member";

type Props = {
    className?: string
    title?: string
    tableProps: PropsWithChildren<HeaderProps<FamilyMember>>
}
const ChildrenCustomHeader: FC<Props> = ({className, title, tableProps}) => {
    return (
        <th
            {...tableProps.column.getHeaderProps()}
            className={clsx(
                className,
            )}
        >
            {title}
        </th>
    )
}

export {ChildrenCustomHeader}
