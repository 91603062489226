import {FC} from 'react';
import {Link} from 'react-router-dom';
import {toAbsoluteUrl} from '../../../../_metronic/helpers';
import {useIntl} from "react-intl";

const Error500: FC = () => {
    const intl = useIntl();

    return (
        <div className='d-flex flex-column flex-root'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-15'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/images/logo_hr_impulse.svg')}
                            className='w-200px mb-5'
                        />
                    </div>
                    <div className='pt-lg-5 mb-10'>
                        <h1 className='fw-bolder fs-2qx text-gray-800'>{intl.formatMessage({id: 'ERROR500.HEADER'})}</h1>
                        <div className='fw-bold fs-3 text-muted mb-10'>
                            {intl.formatMessage({id: 'ERROR500.INFO'})}
                            <br/>
                            {intl.formatMessage({id: 'ERROR500.ADDITIONAL_INFO'})}
                        </div>
                        <div className='text-center'>
                            <Link to='/' className='btn btn-lg btn-primary btn-hover-scale btn-active-light-primary fw-bolder'>
                                {intl.formatMessage({id: 'ERROR500.BUTTON'})}
                            </Link>
                        </div>
                    </div>
                    <div
                        className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px'
                        style={{
                            backgroundImage: `url('${toAbsoluteUrl('/media/images/500_image.png')}')`,
                        }}
                    ></div>
                </div>
            </div>
        </div>
    )
}

export {Error500}
