import React, {FC, useState} from 'react'
import {useIntl} from "react-intl";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone, {IDropzoneProps, IInputProps} from 'react-dropzone-uploader'
import {KTCardBody} from '../../../_metronic/helpers';
import {uploadFile} from '../../shared/services/file-service';
import {useMutation} from 'react-query';
import {uploadSignedContract} from '../../shared/services/contract-service';
import {NotificationMessage} from '../../shared/helper/Notification';
import {Contract} from '../../shared/models/_models';

type Props = {
    setOpenModal: any
    action: any
    contract: Contract
}

const ContractModalForm: FC<Props> = ({setOpenModal, action, contract}) => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false);
    const [uploadFileLoading, setUploadFileLoading] = useState(false)
    const [dropzoneValidation, setDropzoneValidation] = useState({
        minHeight: 200,
        justifyContent: 'center',
        borderColor: "#3699FF",
        borderWidth: 2,
        borderRadius: 7,
        borderStyle: 'dashed',
        overflow: 'hidden'
    })
    const [dropzoneValidationMessage, setDropzoneValidationMessage] = useState(false)
    const [arrayOfFileIdsForUpload, setArrayOfFileIdsForUpload] = React.useState<any[]>([]);

    const updateContractFormData = () => {
        if (action?.name === intl.formatMessage({id: 'CONTRACT.ACTIONS.UPLOAD.DISPLAY'}) && arrayOfFileIdsForUpload.length !== 0) {
            setLoading(true);
            setUploadFileLoading(true)
            arrayOfFileIdsForUpload && uploadSolutionFiles.mutateAsync(arrayOfFileIdsForUpload[0]);
        } else if (action?.name === intl.formatMessage({id: 'CONTRACT.ACTIONS.UPLOAD.DISPLAY'}) && arrayOfFileIdsForUpload.length === 0) {
            setDropzoneValidationMessage(true)
            setDropzoneValidation({
                minHeight: 200,
                justifyContent: 'center',
                borderColor: "var(--kt-text-danger)",
                borderWidth: 2,
                borderRadius: 7,
                borderStyle: 'dashed',
                overflow: 'hidden'
            })
        }
    }

    const closeModal = () => {
        setOpenModal(false)
    }

    const uploadSolutionFiles = useMutation((fileId: any) => uploadSignedContract(
        {
            ...contract,
            signed_contract_file_id: fileId
        }), {
        onSuccess: () => {
            try {
                setUploadFileLoading(false)
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.ACTION_SUCCESSFUL'}), '', 'success')
                closeModal()
            } catch (ex) {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.SEND_FAILED'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SENDING_ACTION_FOR_EXECUTING!_PLEASE_TRY_AGAIN.'}), 'danger')
            } finally {
                closeModal()
            }
        }
    })

    const handleChangeStatus: IDropzoneProps['onChangeStatus'] = (files, status) => {
        if (status === intl.formatMessage({id: 'CONTRACT.ACTIONS.DONE'})) {
            setDropzoneValidationMessage(false)
            setDropzoneValidation({
                minHeight: 200,
                justifyContent: 'center',
                borderColor: "#3699FF",
                borderWidth: 2,
                borderRadius: 7,
                borderStyle: 'dashed',
                overflow: 'hidden'
            })
            setUploadFileLoading(true)
            uploadFile(files.file)
                .then(result => {
                    const fileId = result?.data[0]?.id
                    setArrayOfFileIdsForUpload(arrayOfFileIdsForUpload => [...arrayOfFileIdsForUpload, fileId])
                    setUploadFileLoading(false)
                })
        } else if (status === intl.formatMessage({id: 'CONTRACT.ACTIONS.REMOVED'})) {
            setArrayOfFileIdsForUpload([]);
        }
    }

    const getUploadParams = (meta: any) => {
        const url = 'https://httpbin.org/post'
        return {url, meta: {fileUrl: `${url}/${encodeURIComponent(meta.name)}`}}
    }

    const Input = ({accept, onFiles, files, getFilesFromEvent}: IInputProps) => {
        const text = intl.formatMessage({id: 'CONTRACT.DROPZONE.ADD_FILE'})
        const fileSize = files.length

        return (
            fileSize < 1 &&
            <label className='bg-primary mx-2 my-10 text-light justify-content-center rounded-2 fw-semibold p-5 cursor-pointer'>
                {text}
                <input
                    style={{display: 'none'}}
                    type="file"
                    accept={accept}
                    onChange={async e => {
                        setUploadFileLoading(true);
                        const chosenFiles = await getFilesFromEvent(e);
                        onFiles(chosenFiles as File[]);
                    }}
                />
            </label>
        )
    }

    return (
        <>
            <KTCardBody>
                {action?.name === intl.formatMessage({id: 'CONTRACT.ACTIONS.UPLOAD.DISPLAY'}) &&
                    <div className="row px-6 d-flex mb-8">
                        <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus}
                            InputComponent={Input}
                            styles={{dropzone: dropzoneValidation}}
                        />
                        {dropzoneValidationMessage && <ul className="error-detail bs-callout bs-callout-info">
                            <li className="text-danger">{intl.formatMessage({id: 'REQUIRED_FIELD'})}</li>
                        </ul>}
                    </div>}
                <div className="row px-3">
                    <div className='card-footer d-flex flex-wrap justify-content-end pt-6'>
                        <button
                            onClick={() => closeModal()}
                            type="button"
                            className='btn btn-light me-3 mb-2'
                            data-kt-users-modal-action='cancel'
                            disabled={uploadFileLoading || loading}
                        >
                            {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                        </button>
                        <button type='submit' className='btn btn-success mb-2' disabled={loading || uploadFileLoading}
                                onClick={() => updateContractFormData()}
                        >
                            {!loading && !uploadFileLoading && intl.formatMessage({id: 'CONTRACT.MODAL.SUBMIT'})}
                            {(loading || uploadFileLoading) && (
                                <span className='indicator-progress text-white fw-semibold' style={{display: 'block'}}>
                                {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                                    <span className='spinner-border text-white spinner-border-sm align-middle ms-2'></span>
                            </span>
                            )}
                        </button>
                    </div>
                </div>
            </KTCardBody>
        </>
    )
}

export {ContractModalForm}
