import React, {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import 'react-datepicker/dist/react-datepicker.css'
import {Request} from '../../shared/models/_models'
import {postRequestActions} from '../../shared/services/request-service'
import Form from 'react-jsonschema-form'
import {JSONSchema6} from 'json-schema'
import {KTCardBody, toAbsoluteUrl} from '../../../_metronic/helpers'
import {ConfirmationModal} from './ConfirmationModal'
import './jsonSchemaAccessories/form.css'
import {ObjectFieldTemplate} from './jsonSchemaAccessories/ObjectFieldTemplate'
import {CustomDateTimeWidget} from './jsonSchemaAccessories/CustomDateTimeWidget'
import {uiDefaultSchema} from './jsonSchemaAccessories/uiDefaultSchema'
import {jsonDefaultSchema} from './jsonSchemaAccessories/jsonDefaultSchema'
import {CustomDateWidget} from './jsonSchemaAccessories/CustomDateWidget'
import {RequestFormSchemaHeader} from './jsonSchemaAccessories/RequestFormSchemaHeader'
import {CustomTimeWidget} from './jsonSchemaAccessories/CustomTimeWidget'
import {NotificationMessage} from '../../shared/helper/Notification'
import {CustomSelectWidget} from './jsonSchemaAccessories/CustomSelectWidget'
import Dropzone, {IDropzoneProps, IInputProps} from 'react-dropzone-uploader'
import {deleteAdditionalRequstFiles, uploadFiles} from '../../shared/services/file-service'

type Props = {
  request: Request
  setIsDeliting: any
  actions: any
  vacations: any
}

declare global {
  interface Window {
    isApprovedButton: boolean
    validationFormData: object
  }
}

const RequestFormSchema: FC<Props> = ({request, setIsDeliting, actions, vacations}) => {
  const [openModal, setOpenModal] = useState(false)
  const [formData, setFormData] = useState(
    JSON.stringify(request?.form_data) === '[]' ? {'': ''} : request?.form_data
  )
  const [loading, setLoading] = useState(false)
  const [liveValidation, setliveValidation] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [arrayOfFileForUpload, setArrayOfFileForUpload] = React.useState<any[]>([])
  const [dropzoneValidation, setDropzoneValidation] = useState({
    minHeight: 200,
    justifyContent: 'center',
    borderColor: '#3699FF',
    borderWidth: 2,
    borderRadius: 7,
    borderStyle: 'dashed',
    overflow: 'hidden',
  })
  const attachmentFiles = request?.additional_files && request?.additional_files
  const [isEnable, setIsEnable] = useState({})
  const intl = useIntl()
  const [arrayOfIdsForUpload, setArrayOfIdsForUpload] = React.useState<any[]>([])
  const current_user = JSON.parse(localStorage.getItem('current_user') || '')
  const [attachment, setAttachment] = useState(false)
  const navigate = useNavigate()
  const schema: JSONSchema6 = request?.request_type?.json_schema
    ? JSON.parse(request?.request_type?.json_schema)
    : jsonDefaultSchema
  const uiSchema: JSONSchema6 = request?.request_type?.ui_schema
    ? JSON.parse(request?.request_type?.ui_schema)
    : uiDefaultSchema
  const currentUser = JSON.parse(localStorage.getItem('current_user') || '')
  const restrictionAccess =
    currentUser?.id !== request?.created_by &&
    !!!(currentUser?.roles?.filter((item: any) => (item === 'hr' || item === 'admin' || item === 'hr1' || item === 'hr2'))?.length > 0)
  const vacationInfo =
    request?.request_type?.name === intl.formatMessage({id: 'REQUEST.VACATION.CHANGE.NAME.DETAIL'}) ||
      request?.request_type?.name === intl.formatMessage({id: 'REQUEST.VACATION.NAME.DETAIL'}) ||
      request?.request_type?.name === intl.formatMessage({id: 'REQUEST.VACATION.CHANGE.NAME.DETAIL.WE5'}) ||
      request?.request_type?.name === intl.formatMessage({id: 'REQUEST.VACATION.NAME.DETAIL.WE5'})

  const changeJsonSchema = (event: any) => {
    window.validationFormData = event.formData

    if ([6, 7].includes(Number(request?.request_type_id))) {
      const subjects = Object.keys(event.formData.subject).filter((item) => item !== 'reason_for')
      const formDataSubjects = event.formData.subject
      const newFormDataObject = {reason_for: formDataSubjects['reason_for'] as string} as any
      newFormDataObject[subjects[0] as string] = formDataSubjects[subjects[0]] as string
      event.formData.subject = newFormDataObject
    }

    var attachment = document.documentElement.innerHTML.includes(
      `${intl.formatMessage({id: 'REQUEST.DROPZONE.NAME'})}`
    )
    setAttachment(attachment)
    if (attachment) {
      document.querySelector('.dzu-dropzone')?.classList.add('d-flex')
      document.querySelector('.dzu-dropzone')?.classList.remove('d-none')
    } else {
      document.querySelector('.dzu-dropzone')?.classList.add('d-none')
    }
  }

  useEffect(
    () => {
      if (restrictionAccess) {
        navigate(`/request/${request?.id}/overview`)
      }
    },
    // eslint-disable-next-line
    [restrictionAccess]
  )

  useEffect(
    () => {
      let attachment = document.documentElement.innerHTML.includes(
        `${intl.formatMessage({id: 'REQUEST.DROPZONE.NAME'})}`
      )
      setAttachment(attachment)
      if (attachment) {
        document.querySelector('.dzu-dropzone')?.classList.add('d-flex')
        document.querySelector('.dzu-dropzone')?.classList.remove('d-none')
      } else {
        document.querySelector('.dzu-dropzone')?.classList.add('d-none')
      }
      ;(JSON.stringify(isEnable) !== '{}' || JSON.stringify(request?.form_data) !== '[]') &&
        document.querySelector('.reason_for')?.classList.add('pe-none')
    },
    // eslint-disable-next-line
    [isEnable]
  )

  const deleteAditionalFile = async (item: any) => {
    await deleteAdditionalRequstFiles(request?.id, item?.id)
    setIsDeliting(item)
  }

  const widgets = {
    DateTimeWidget: CustomDateTimeWidget,
    DateWidget: CustomDateWidget,
    TimeWidget: CustomTimeWidget,
    SelectWidget: CustomSelectWidget,
  }

  const handleChangeStatus: IDropzoneProps['onChangeStatus'] = (files, status) => {
    if (status === intl.formatMessage({id: 'REQUEST.ACTIONS.DONE'})) {
      setDropzoneValidation({
        minHeight: 200,
        justifyContent: 'center',
        borderColor: '#3699FF',
        borderWidth: 2,
        borderRadius: 7,
        borderStyle: 'dashed',
        overflow: 'hidden',
      })
      setArrayOfFileForUpload((arrayOfFileForUpload) => [...arrayOfFileForUpload, files.file])
      setIsUploading(false)
    } else if (status === intl.formatMessage({id: 'REQUEST.ACTIONS.REMOVED'})) {
      setArrayOfFileForUpload(arrayOfFileForUpload.filter((item) => item !== files.file))
      setIsUploading(false)
    }
  }

  const getUploadParams = (meta: any) => {
    setIsUploading(true)
    const url = 'https://httpbin.org/post'
    return {url, meta: {fileUrl: `${url}/${encodeURIComponent(meta.name)}`}}
  }

  const Input = ({accept, onFiles, files, getFilesFromEvent}: IInputProps) => {
    const text =
      files.length > 0
        ? intl.formatMessage({id: 'REQUEST.DROPZONE.ADD_MORE_FILES'})
        : intl.formatMessage({id: 'REQUEST.DROPZONE.ADD_FILE'})

    return (
      <label className='bg-primary mx-2 my-10 text-light fw-semibold justify-content-center rounded-2 p-5 cursor-pointer'>
        {text}
        <input
          style={{display: 'none'}}
          type='file'
          accept={accept}
          multiple
          onChange={async (e) => {
            const chosenFiles = await getFilesFromEvent(e)
            onFiles(chosenFiles as File[])
          }}
        />
      </label>
    )
  }

  const updateRequestFormData = (formData: any) => {
    if (window.isApprovedButton) {
      setFormData(formData.formData)
      setOpenModal(true)
      window.isApprovedButton = false
    } else {
      setFormData(formData.formData)
      setLoading(true)
      if (arrayOfFileForUpload.length > 0 && attachment) {
        let fileIds = [] as number[]
        uploadFiles(arrayOfFileForUpload).then((result) => {
          for (var i = 0; i < result?.data.length; i++) {
            fileIds.push(result?.data[i]?.id)
          }
          fileIds.length > 0 &&
            postRequestActions({
              ...request,
              form_data: formData.formData,
              additional_file_ids_for_upload: fileIds,
              action_id: actions?.data[0].id,
            }).then((response) => {
              setIsUploading(false)
              setLoading(false)
              setIsEnable(formData.formData)
            })
          setArrayOfIdsForUpload(fileIds)
        })
        try {
          NotificationMessage(
            intl.formatMessage({id: 'MESSAGE_NOTIFICATION.REQUEST_SAVED'}),
            intl.formatMessage({id: 'MESSAGE_NOTIFICATION.REQUEST_SAVED.INFO'}),
            'success'
          )
        } catch (ex) {
          NotificationMessage(
            intl.formatMessage({id: 'MESSAGE_NOTIFICATION.SAVE_FAILED!'}),
            intl.formatMessage({
              id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SAVING_DATA!_PLEASE_TRY_AGAIN.',
            }),
            'danger'
          )
        } finally {
        }
      } else {
        postRequestActions({
          ...request,
          form_data: formData.formData,
          additional_file_ids_for_upload: [],
          action_id: actions?.data[0].id,
        })
        setIsUploading(false)
        setLoading(false)
        try {
          NotificationMessage(
            intl.formatMessage({id: 'MESSAGE_NOTIFICATION.REQUEST_SAVED'}),
            intl.formatMessage({id: 'MESSAGE_NOTIFICATION.REQUEST_SAVED.INFO'}),
            'success'
          )
        } catch (ex) {
          NotificationMessage(
            intl.formatMessage({id: 'MESSAGE_NOTIFICATION.SAVE_FAILED!'}),
            intl.formatMessage({
              id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SAVING_DATA!_PLEASE_TRY_AGAIN.',
            }),
            'danger'
          )
        } finally {
        }
      }
    }
  }

  function transformErrors(errors: any[]) {
    setliveValidation(true)
    return errors.map((error) => {
      error.message = intl.formatMessage({id: 'REQUIRED_FIELD'})
      return error
    })
  }

  return (
    <KTCardBody>
      <div className='card'>
        <div className='card-body p-9'>
          <h3 className='fw-bolder m-0 mb-7 text-start fs-5 text-uppercase pb-10 border-bottom'>
            {request?.request_type?.name}
          </h3>
          <RequestFormSchemaHeader request={request} />
          {vacationInfo && (
            <div className='m-0 mb-7 pb-10 border-bottom'>
              <span className='fs-5 fw-bolder text-gray-800'>
                {intl.formatMessage({id: 'REQUEST.VACATION.NAME'})}:
              </span>
              <div className='d-grid d-md-flex'>
                {vacations?.data?.slice(0, 2).map((vacation: any) => {
                  const totalDaysOfVacation =
                    vacation?.first_part + vacation?.second_part > 0
                      ? vacation?.first_part + vacation?.second_part + vacation?.previously_used
                      : 0
                  return (
                    <div className='w-100' key={vacation?.id}>
                      <span className='pe-2'>
                        - Broj preostalih dana godisnjeg odmora za godinu {vacation?.year}:
                      </span>
                      <span className='text-gray-800 fw-bolder'>
                        {totalDaysOfVacation - vacation?.used >= 0
                          ? totalDaysOfVacation - vacation?.used
                          : '0'}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          {schema && (
            <Form
              id='json-schema-form'
              autocomplete='off'
              noHtml5Validate
              schema={schema}
              uiSchema={uiSchema}
              ObjectFieldTemplate={ObjectFieldTemplate}
              formData={
                JSON.stringify(request?.form_data) === '[]' ? window.validationFormData : formData
              }
              widgets={widgets}
              liveValidate={liveValidation}
              transformErrors={transformErrors}
              onChange={(e) => changeJsonSchema(e)}
              onSubmit={(formData) => {
                updateRequestFormData(formData)
              }}
            >
              {attachmentFiles && (
                <div className='d-flex w-100 pt-6'>
                  <div className='ps-8'>
                    {attachmentFiles?.map((item: any) => (
                      <div key={item.id} className='d-flex align-items-center'>
                        <img
                          alt=''
                          className='w-30px me-1'
                          src={toAbsoluteUrl('/media/icons/duotune/files/fil004.svg')}
                        />
                        <a href={item.url} className='d-flex py-2'>
                          {item.original_name}
                        </a>
                        {current_user?.id === request?.created_by && (
                          <span
                            onClick={() => deleteAditionalFile(item)}
                            className='ps-2 cursor-pointer text-hover-danger'
                          >
                            X
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className='d-flex flex-wrap pb-0 mt-5'>
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  InputComponent={Input}
                  styles={{dropzone: dropzoneValidation}}
                />
              </div>
              {actions?.data?.length > 0 && (
                <div className='d-flex flex-wrap justify-content-end mt-5 py-10 pb-0 border-top mt-10'>
                  <button
                    onClick={() => navigate(`/request`)}
                    type='button'
                    className='btn btn-light me-3 mb-2'
                    data-kt-users-modal-action='cancel'
                    disabled={isUploading || loading}
                  >
                    {intl.formatMessage({id: 'BUTTON.BACKWARD'})}
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary me-md-3 me-lg-3 mb-2 me-sm-3 me-3'
                    disabled={loading || isUploading}
                  >
                    {!loading && !isUploading && intl.formatMessage({id: 'BUTTON.SUBMIT'})}
                    {(loading || isUploading) && (
                      <span
                        className='indicator-progress text-white fw-semibold'
                        style={{display: 'block'}}
                      >
                        {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                        <span className='spinner-border spinner-border-sm text-white align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    type='submit'
                    className='btn btn-success mb-2 me-sm-3 me-md-0 me-lg-0 me-3'
                    disabled={loading || isUploading}
                    onClick={() => (window.isApprovedButton = true)}
                  >
                    {!loading &&
                      !isUploading &&
                        (request?.request_type?.name?.includes('ocjenu') ? intl.formatMessage({id: 'BUTTON.FORWARD_FOR_APPROVAL.GRADING'}) : intl.formatMessage({id: 'BUTTON.FORWARD_FOR_APPROVAL'}))
                    }
                    {(loading || isUploading) && (
                      <span
                        className='indicator-progress text-white fw-semibold'
                        style={{display: 'block'}}
                      >
                        {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                        <span className='spinner-border spinner-border-sm text-white align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              )}
            </Form>
          )}
        </div>
      </div>
      {openModal && (
        <ConfirmationModal
          request={request}
          setOpenModal={setOpenModal}
          actions={actions}
          formData={formData}
          arrayOfFileForUpload={arrayOfFileForUpload}
          arrayOfIdsForUpload={arrayOfIdsForUpload}
        />
      )}
    </KTCardBody>
  )
}

export {RequestFormSchema}
