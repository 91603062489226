import React, {Dispatch, SetStateAction} from 'react';
import {KTSVG} from "../../../../_metronic/helpers";
import {Contract} from "../../../shared/models/contract";
import {useIntl} from "react-intl";

type props = {
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    contractInfo: Contract;
}

const ContractDetailsModal: React.FC<props> = ({setOpenModal, contractInfo}) => {
    const intl = useIntl();

    const definedContract = intl.formatMessage({id: 'CONTRACT.TYPE.DEFINED'});
    const undefinedContract = intl.formatMessage({id: 'CONTRACT.TYPE.UNDEFINED'});

    const contract_active = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.STATUS_TYPE.ACTIVE'});
    const contract_expired = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.STATUS_TYPE.EXPIRED'});

    const technical = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.TECHNICAL'});
    const commercial = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.COMMERCIAL'});
    const global = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.GLOBAL'});

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_delete'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered mw-500px'>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">
                                {intl.formatMessage({id: 'DASHBOARD.MODAL.CONTRACT'})} {contractInfo?.code}
                            </h3>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setOpenModal(false)}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body text-left">
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <label className='col-sm-5 fw-semibold text-muted'>
                                            {intl.formatMessage({id: 'CONTRACT.CODE'})}
                                        </label>
                                        <div className='col-sm-7 mb-5'>
                                    <span className='fs-6 text-hover-primary'>
                                        {contractInfo?.code || '-'}
                                    </span>
                                        </div>
                                        <label className='col-sm-5 fw-semibold text-muted'>
                                            {intl.formatMessage({id: 'CONTRACT.TYPE'})}
                                        </label>
                                        <div className='col-sm-7 mb-5'>
                                    <span className='fs-6 text-hover-primary'>
                                        {contractInfo?.type === 'defined' ? definedContract : contractInfo?.type === 'undefined' ? undefinedContract : '-'}
                                    </span>
                                        </div>
                                        <label className='col-sm-5 fw-semibold text-muted'>
                                            {intl.formatMessage({id: 'CONTRACT.STATUS'})}
                                        </label>
                                        <div className='col-sm-7 mb-5'>
                                    <span className='fs-6 text-hover-primary'>
                                        {contractInfo?.status === 'active' ? contract_active : contract_expired}
                                    </span>
                                        </div>
                                        <label className='col-sm-5 fw-semibold text-muted'>
                                            {intl.formatMessage({id: 'CONTRACT.JOB_POSITION'})}
                                        </label>
                                        <div className='col-sm-7 mb-5'>
                                    <span className='fs-6 text-hover-primary'>
                                        {contractInfo?.job_position?.name || '-'}
                                    </span>
                                        </div>
                                        <label className='col-sm-5 fw-semibold text-muted'>
                                            {intl.formatMessage({id: 'CONTRACT.JOB_POSITION.SECTOR'})}
                                        </label>
                                        <div className='col-sm-7 mb-5'>
                                    <span className='fs-6 text-hover-primary'>
                                        {contractInfo?.job_position?.sector === 'technical' ? technical : contractInfo?.job_position?.sector === 'commercial' ?
                                            commercial : contractInfo?.job_position?.sector === 'global' ? global : '-'
                                        }
                                    </span>
                                        </div>
                                        <label className='col-sm-5 fw-semibold text-muted'>
                                            {intl.formatMessage({id: 'CONTRACT.JOB_POSITION.DEPARTMENT.PARENT'})}
                                        </label>
                                        <div className='col-sm-7 mb-5'>
                                    <span className='fs-6 text-hover-primary'>
                                        {contractInfo?.job_position?.department?.parent ? contractInfo?.job_position?.department?.parent?.name : contractInfo?.job_position?.department ? contractInfo?.job_position?.department?.name : '-'}
                                    </span>
                                        </div>
                                        <label className='col-sm-5 fw-semibold text-muted'>
                                            {intl.formatMessage({id: 'CONTRACT.JOB_POSITION.DEPARTMENT'})}
                                        </label>
                                        <div className='col-sm-7 mb-5'>
                                    <span className='fs-6 text-hover-primary'>
                                        {contractInfo?.job_position?.department?.parent ? contractInfo?.job_position?.department?.name : '-'}
                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {ContractDetailsModal}
