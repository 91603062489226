import React, {useEffect, useState} from 'react';
import {getIn, useFormik} from 'formik';
import {Link, useNavigate, useParams} from 'react-router-dom';
import clsx from 'clsx';
import {useIntl} from 'react-intl';
import 'react-datepicker/dist/react-datepicker.css';
import Flatpickr from "react-flatpickr";
import {Contract, JobPosition, JobPositionsQueryResponse, User} from "../../shared/models/_models";
import {createContract, getContractById, updateContract} from "../../shared/services/contract-service";
import moment from "moment";
import * as Yup from "yup";
import {
    listContractType, listContractStatus,
} from "../../shared/SelectLists";
import {getUserById, getUsers} from "../../shared/services/user-service";
import {FlatpickrDateOptions} from "../../shared/helper/FlatpickrDateOptions";
import {ListLoading} from "../../modules/apps/shared/list/components/loading/ListLoading";
import {SelectField} from "../../shared/helper/Select";
import {NotificationMessage} from "../../shared/helper/Notification";
import {ID} from "../../../_metronic/helpers";
import {getJobPositions} from "../../shared/services/job-position-service";
import {UsersQueryResponse} from "../../shared/models/user";
import {RoutesHelper} from "../../shared/helper/RoutesHelper";

const ContractForm: React.FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const contractId = useParams().id || undefined;
    const userId = useParams().user_id || undefined;

    const currentUser = JSON.parse(localStorage.getItem('current_user') || '');
    const userPermissionHr = !!(currentUser?.job_position?.we_level === "WE1" || currentUser?.job_position?.we_level === "WE2") || !!(currentUser?.roles?.filter((item: any) => (item === 'hr' || item === 'hr1' || item === 'hr2'))?.length > 0);
    const userPermissionAdmin = !!(currentUser?.roles?.filter((item: any) => item === 'admin')?.length > 0) || !!(currentUser?.job_position?.we_level === "WE1" || currentUser?.job_position?.we_level === "WE2");

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({} as User);
    const [users, setUsers] = useState({} as UsersQueryResponse);
    const [loadingUser, setLoadingUser] = useState(false);
    const [contract, setContract] = useState({} as Contract);
    const [contractType, setContractType] = useState(contract?.type);
    const [contractStatus, setContractStatus] = useState(contract?.status);
    const [jobPosition, setJobPosition] = useState(contract?.job_position);
    const [jobPositions, setJobPositions] = useState({} as JobPositionsQueryResponse);

    const contract_undefined = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE.UNDEFINED'});
    const contract_defined = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE.DEFINED'});
    const contract_active = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.STATUS_TYPE.ACTIVE'});
    const contract_expired = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.STATUS_TYPE.EXPIRED'});

    const technical = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.TECHNICAL'});
    const commercial = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.COMMERCIAL'});
    const global = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.GLOBAL'});
    const sector = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.POSITION_SECTOR'});

    const contractSchema = Yup.object().shape({
        user_id: Yup.number()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .nullable(),
        type: Yup.string()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .nullable(),
        status: Yup.string()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .nullable(),
        contract_date: Yup.date()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .nullable(),
        start_date: Yup.date()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .nullable(),
        job_position_id: Yup.number()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .nullable(),
    });

    useEffect(() => {
        getUsers('list=true').then((response) => {
            if (response) {
                setUsers(response);
            }
        });

        getJobPositions('list=true').then((response) => {
            if (response) {
                setJobPositions(response);
            }
        })
    }, []);

    const usersList = users?.data?.map((item: any) => (
        {
            value: item.id,
            label: item?.first_name + ' ' + item?.last_name + (item?.job_position?.department ? (', ' + item?.job_position?.department?.name) : item?.job_position ? (', ' +
                (item?.job_position?.sector === 'technical' ? ' ' + technical + '' + sector : item?.job_position?.sector === 'commercial' ?
                    ' ' + commercial + '' + sector : item?.job_position?.sector === 'global' ? ' ' + global + '' + sector : '')) : '')
        }
    ));

    const jobPositionsList = jobPositions?.data?.filter((job_position: any) => job_position.we_level ?
        job_position?.we_level >= (RoutesHelper(['admin', 'hr']) ? 'WE1' : RoutesHelper(['hr1']) ? 'WE4' : 'WE5') : '').map((item: any) => (
        {
            value: item.id,
            label: item?.name + ',' + item.department?.name + ',' + item.department?.parent?.name + ',' +
                (item.sector === 'technical' ? ' ' + technical + '' + sector : item.sector === 'commercial' ?
                    ' ' + commercial + '' + sector : item.sector === 'global' ? ' ' + global + '' + sector : ''),
            job_position: item
        }
    ));

    const formatJobPositionOptionLabel = (data: { value: any, label: any, job_position: any }) => {
        return (
            <div className={'d-flex row'}>
                <label className={'text-gray-800 fs-7 fw-bold'}>{data?.label?.split(',', 1)[0]}</label>
                <label className={'fs-8'}>
                    {data.job_position?.department ? data.job_position?.department?.name : ''}{data.job_position?.department && data.job_position?.department?.parent ? ' - ' + data.job_position?.department?.parent?.name : ''}
                    {(data.job_position?.department || data.job_position?.department?.parent?.name) && data.job_position?.sector ? ' - ' : ''}
                    {data.job_position && (data.job_position?.sector === 'technical' ? ' ' + technical + '' + sector : data.job_position?.sector === 'commercial'
                        ? ' ' + commercial + '' + sector : data.job_position?.sector === 'global' ? ' ' + global + '' + sector : '')}
                    {!data.job_position && (data.job_position?.sector === 'technical' ? technical + '' + sector : data.job_position?.sector === 'commercial'
                        ? commercial + '' + sector : data.job_position?.sector === 'global' ? global + '' + sector : '')}
                </label>
            </div>
        );
    };

    useEffect(() => {
        const asyncFetch = async () => {
            contractId === undefined || setContract(await getContractById(contractId) as Contract);
        }
        asyncFetch();
    }, [contractId]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: contractId ? contract : {
            user_id: userId,
            job_position_id: undefined,
            type: '',
            status: 'active',
            has_competition_ban: 0,
            contract_date: '',
            start_date: '',
            is_full_time: 1
        },
        validationSchema: contractSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setLoading(true);
            setSubmitting(true);
            let newContract: Contract | undefined;
            if (!values.is_on_probation)
                values.probation_to = '';

            if (values.is_full_time)
                values.weekly_hours = 40;

            if (values && jobPositions?.data) {
                values.job_position = jobPosition
            }

            if (!values.has_competition_ban)
                values.has_competition_ban = 0;

            try {
                if (contractId === undefined) {
                    if (user && jobPosition) {
                        values.city = user?.profile?.city;
                        values.address = user?.profile?.address;
                        values.postal_number = user?.profile?.postal_number;
                        values.phone_number = user?.profile?.phone_number;
                        values.employee_category = jobPosition?.category;
                    }

                    await createContract(values).then(res => newContract = res)
                    if (newContract) {
                        NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.CONTRACT_SUCCESSFULLY_CREATED'}), '', 'success')
                        navigate(`/contract/${newContract.id}/overview`);
                    }
                } else {
                    await updateContract(values)
                    NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.CONTRACT_SUCCESSFULLY_UPDATED'}), '', 'success')
                    navigate(`/contract/${contractId}/overview`);
                }
                // navigate(`/contract/${contractId}/overview`);
            } catch (ex) {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.SAVE_FAILED!'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SAVING_DATA!_PLEASE_TRY_AGAIN.'}), 'danger')
            } finally {
                setSubmitting(true)

            }
        },
    });
    useEffect(() => {
        if (userId !== undefined) {
            getUserById(userId, 'expand=profile').then((response) => {
                setUser(response as User);
            })
        }
    }, [userId]);

    useEffect(() => {
        const asyncFetch = async () => {
            formik.values.user_id === undefined || setUser(await getUserById(formik.values.user_id, 'expand=profile,contracts') as User);
        }
        asyncFetch();
    }, [formik.values.user_id])

    // eslint-disable-next-line
    useEffect(() => {
        if (user.id) {
            setLoadingUser(false);
        }
    })

    return <div className='card mb-5 mb-xl-10'>
        <div
            className='card-header border-0'>
            <div className='card-title m-0'>
                {contractId && <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'CONTRACT.HEADER.EDIT'})}</h3>}
                {!contractId && <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'CONTRACT.HEADER.NEW'})}</h3>}
            </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='card-body border-top p-9'>
                    <div className="row">
                        <div>
                            <label className='fw-bold fs-4'>
                                {intl.formatMessage({id: 'CONTRACT.DATA.USER'})}
                            </label>
                        </div>
                        <div className='col-sm-6 mb-3'>
                            <label className='col-form-label fw-bold fs-6 required'>
                                {intl.formatMessage({id: 'CONTRACT.USER'})}
                            </label>
                            <SelectField
                                name={'user_id'}
                                className_condition={getIn(formik.errors, 'user_id') && getIn(formik.touched, 'user_id')}
                                placeholder={intl.formatMessage({id: 'CONTRACT.USER.SELECT'})}
                                value={{
                                    value: user ? user.id : '',
                                    label: user?.first_name ? user?.first_name + ' ' + user?.last_name + (user?.job_position?.department ? (', ' + user?.job_position?.department?.name) : user?.job_position ? (', ' +
                                        (user?.job_position?.sector === 'technical' ? ' ' + technical + '' + sector : user?.job_position?.sector === 'commercial' ?
                                            ' ' + commercial + '' + sector : user?.job_position?.sector === 'global' ? ' ' + global + '' + sector : '')) : '') : 'Izaberite zaposlenog'
                                }}
                                options={usersList}
                                onChange={(
                                    newValue: any,
                                ) => {
                                    if (user?.id) {
                                        user.id = undefined;
                                    }

                                    setLoadingUser(true);
                                    formik.setFieldValue('user_id', newValue?.value);
                                }}
                                isSearchable={true}
                                formatOptionLabel={{}}
                            />
                            {getIn(formik.errors, 'user_id') && getIn(formik.touched, 'user_id') &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'user_id')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='col-sm-6 mb-3'>
                            <label className='col-form-label fw-bold fs-6'>
                                {intl.formatMessage({id: 'CONTRACT.PHONE_NUMBER'})}
                            </label>
                            <input
                                readOnly={true}
                                placeholder={intl.formatMessage({id: 'CONTRACT.PHONE_NUMBER'})}
                                value={user.profile?.phone_number || ''}
                                type='text'
                                name='phone_number'
                                className={'form-control form-control-solid mb-3 mb-lg-0'}
                                autoComplete='off'
                            />
                        </div>
                        <div className='col-lg-3 col-sm-6 col-12 mb-3'>
                            <label className='col-form-label fw-bold fs-6'>
                                {intl.formatMessage({id: 'CONTRACT.ADDRESS'})}
                            </label>
                            <input
                                readOnly={true}
                                placeholder={intl.formatMessage({id: 'CONTRACT.ADDRESS'})}
                                value={user.profile?.address || ''}
                                type='text'
                                name='address'
                                className={'form-control form-control-solid mb-3 mb-lg-0'}
                                autoComplete='off'
                            />
                        </div>
                        <div className='col-lg-3 col-sm-6 col-12 mb-3'>
                            <label className='col-form-label fw-bold fs-6'>
                                {intl.formatMessage({id: 'CONTRACT.POSTAL_NUMBER'})}
                            </label>
                            <input
                                readOnly={true}
                                placeholder={intl.formatMessage({id: 'CONTRACT.POSTAL_NUMBER'})}
                                value={user.profile?.postal_number || ''}
                                type='text'
                                name='postal_number'
                                className={'form-control form-control-solid mb-3 mb-lg-0'}
                                autoComplete='off'
                            />
                        </div>
                        <div className='col-lg-3 col-sm-6 col-12 mb-3'>
                            <label className='col-form-label fw-bold fs-6'>
                                {intl.formatMessage({id: 'CONTRACT.MUNICIPALITY'})}
                            </label>
                            <input
                                readOnly={true}
                                placeholder={intl.formatMessage({id: 'CONTRACT.MUNICIPALITY'})}
                                value={user.profile?.municipality || ''}
                                type='text'
                                name='municipality'
                                className={'form-control form-control-solid mb-3 mb-lg-0'}
                                autoComplete='off'
                            />
                        </div>
                        <div className='col-lg-3 col-sm-6 col-12 mb-3'>
                            <label className='col-form-label fw-bold fs-6'>
                                {intl.formatMessage({id: 'CONTRACT.CITY'})}
                            </label>
                            <input
                                readOnly={true}
                                placeholder={intl.formatMessage({id: 'CONTRACT.CITY'})}
                                value={user.profile?.city || ''}
                                type='text'
                                name='city'
                                className={'form-control form-control-solid mb-3 mb-lg-0'}
                                autoComplete='off'
                            />
                        </div>
                        <div>
                            <label className='fw-bold fs-4 mt-9'>
                                {intl.formatMessage({id: 'CONTRACT.DATA'})}
                            </label>
                        </div>
                        <div className='col-12 col-xxl-4 mb-3'>
                            <label className='col-form-label fw-bold fs-6'>
                                {intl.formatMessage({id: 'CONTRACT.CODE'})}
                            </label>
                            <input
                                placeholder={intl.formatMessage({id: 'CONTRACT.CODE'})}
                                {...formik.getFieldProps('code')}
                                type='text'
                                name='code'
                                className={'form-control form-control-solid mb-3 mb-lg-0'}
                                autoComplete='off'
                            />
                        </div>
                        <div className='col-sm-6 col-xxl-4 mb-3'>
                            <label className='col-form-label fw-bold fs-6 required'>
                                {intl.formatMessage({id: 'CONTRACT.TYPE'})}
                            </label>
                            <SelectField
                                name={'type'}
                                className_condition={formik.touched.type && formik.errors.type}
                                placeholder={intl.formatMessage({id: 'CONTRACT.TYPE.SELECT'})}
                                value={{
                                    value: contractType || contract?.type,
                                    label: contractType ? (contractType === 'defined' ? contract_defined :
                                        contractType === 'undefined' ? contract_undefined : intl.formatMessage({id: 'CONTRACT.TYPE.SELECT'})) : (contract?.type === 'defined' ? contract_defined :
                                        contract?.type === 'undefined' ? contract_undefined : intl.formatMessage({id: 'CONTRACT.TYPE.SELECT'}))
                                }}
                                options={listContractType}
                                onChange={(
                                    newValue: any,
                                ) => {
                                    formik.setFieldValue('type', newValue?.value)
                                    setContractType(newValue.value)
                                }}
                                isSearchable={false}
                                formatOptionLabel={{}}
                            />
                            {formik.touched.type && formik.errors.type &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'type')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='col-sm-6 col-xxl-4 mb-3'>
                            <label className='col-form-label fw-bold fs-6 required'>
                                {intl.formatMessage({id: 'CONTRACT.STATUS'})}
                            </label>
                            <SelectField
                                name={'status'}
                                className_condition={formik.touched.status && formik.errors.status}
                                placeholder={intl.formatMessage({id: 'CONTRACT.STATUS.SELECT'})}
                                value={{
                                    value: contract?.status ? contract?.status : contractStatus ? contractStatus : 'active',
                                    label: contractStatus ? (contractStatus === 'active' ? contract_active :
                                        contractStatus === 'expired' ? contract_expired : intl.formatMessage({id: 'CONTRACT.STATUS.SELECT'})) : contract?.status ? (contract?.status === 'active' ? contract_active :
                                        contract?.status === 'expired' ? contract_expired : intl.formatMessage({id: 'CONTRACT.STATUS.SELECT'})) : contract_active
                                }}
                                options={listContractStatus}
                                onChange={(
                                    newValue: any,
                                ) => {
                                    formik.setFieldValue('status', newValue?.value)
                                    setContractStatus(newValue.value)
                                }}
                                isSearchable={false}
                                formatOptionLabel={{}}
                            />
                            {formik.touched.status && formik.errors.status &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'status')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='col-md-6 mb-3'>
                            <label className='col-form-label fw-bold fs-6 required'>
                                {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.POSITION'})}
                            </label>
                            <SelectField
                                name={'job_position_id'}
                                className_condition={formik.touched.job_position_id && formik.errors.job_position_id}
                                placeholder={intl.formatMessage({id: 'CONTRACT.JOB_POSITION.SELECT'})}
                                value={contract?.job_position_id || jobPosition ? {
                                    value: jobPosition?.id || contract?.job_position_id,
                                    label: jobPosition?.name || contract?.job_position?.name,
                                    job_position: jobPosition || contract?.job_position
                                } : {
                                    value: 1 as ID,
                                    label: intl.formatMessage({id: 'CONTRACT.JOB_POSITION.SELECT'}) as string | undefined,
                                    job_position: {} as JobPosition
                                }}
                                options={jobPositionsList}
                                onChange={(
                                    newValue: any,
                                ) => {
                                    formik.setFieldValue('job_position_id', newValue.value);
                                    setJobPosition(jobPositions?.data?.filter(position => position.id?.toString() === newValue.value.toString())[0])
                                }}
                                isSearchable={true}
                                formatOptionLabel={formatJobPositionOptionLabel}
                            />
                            {getIn(formik.errors, 'job_position_id') && getIn(formik.touched, 'job_position_id') &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'job_position_id')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        {(userPermissionHr || userPermissionAdmin) && <div className='col-6 col-md-3 mb-3'>
                            <label className='col-form-label fw-bold fs-6'>
                                {intl.formatMessage({id: 'CONTRACT.GROSS_SALARY'})}
                            </label>
                            <div className="input-group">
                                <input
                                    placeholder={intl.formatMessage({id: 'CONTRACT.GROSS_SALARY'})}
                                    {...formik.getFieldProps('gross_salary')}
                                    type='text'
                                    name='gross_salary'
                                    className={'form-control form-control-solid'}
                                    autoComplete='off'
                                />
                                <span className="input-group-text d-flex border-0">
                                    {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.GROSS_SALARY.CURRENCY'})}
                                </span>
                            </div>
                        </div>}
                        <div className='col-6 col-md-3 mb-3'>
                            <label className='col-form-label fw-bold fs-6 mb-2'>
                                {intl.formatMessage({id: 'CONTRACT.HAS_COMPETITION_BAN'})}
                            </label>
                            <div className='d-flex align-items-center form-check form-check-solid form-switch'>
                                <input
                                    className='form-check-input form-check-solid form-switch w-45px h-30px'
                                    type='checkbox'
                                    name='has_competition_ban'
                                    checked={formik.values.has_competition_ban === 1}
                                    onChange={() => {
                                        formik.setFieldValue(
                                            'has_competition_ban',
                                            formik.values.has_competition_ban === 1 ? 0 : 1
                                        )
                                    }}
                                />
                            </div>
                        </div>
                        <div className={
                            `mb-3 ${formik.values.is_on_probation === 1 ? "col-sm-4 col-md-2 col-lg-4 col-xxl-2" : "col-sm-12 col-md-6 col-lg-12 col-xxl-6"}`
                        }>
                            <label className='col-form-label fw-bold fs-6 mb-2'>
                                {intl.formatMessage({id: 'CONTRACT.IS_ON_PROBATION'})}
                            </label>
                            <div className='d-flex align-items-center form-check form-check-solid form-switch'>
                                <input
                                    className='form-check-input form-check-solid form-switch w-45px h-30px'
                                    type='checkbox'
                                    name='is_on_probation'
                                    checked={formik.values.is_on_probation === 1}
                                    onChange={() => {
                                        formik.setFieldValue(
                                            'is_on_probation',
                                            formik.values.is_on_probation === 1 ? 0 : 1
                                        )
                                    }}
                                />
                            </div>
                        </div>
                        {formik.values.is_on_probation === 1 ? <div className='col-sm-8 col-md-4 col-lg-8 col-xxl-4'>
                            <label className='col-form-label fw-bold fs-6'>
                                {intl.formatMessage({id: 'CONTRACT.PROBATION_TO'})}
                            </label>
                            <div className='input-group'>
                                <Flatpickr
                                    value={contract?.probation_to ? moment(contract?.probation_to).format('DD.MM.YYYY') : ''}
                                    className={clsx('form-control form-control-solid mb-lg-0')}
                                    placeholder={intl.formatMessage({id: 'CONTRACT.PROBATION_TO'})}
                                    name='probation_to'
                                    options={FlatpickrDateOptions}
                                    onChange={(selectedDates) => {
                                        formik.values.probation_to = moment(selectedDates[0]).format('YYYY-MM-DD');
                                    }}
                                >
                                </Flatpickr>
                                <span className="input-group-text border-0 ">
                                        <i className='fa fa-calendar'></i>
                                    </span>
                            </div>
                        </div> : ''
                        }
                        <div className={
                            `mb-3 ${formik.values.is_full_time === 0 ? "col-sm-4 col-md-3 col-lg-4 col-xxl-2" : "col-sm-12 col-md-6 col-lg-12 col-xxl-6"}`
                        }>
                            <label className='col-form-label fw-bold fs-6 mb-2'>
                                {intl.formatMessage({id: 'CONTRACT.IS_FULL_TIME'})}
                            </label>
                            <div className='d-flex align-items-center form-check form-check-solid form-switch'>
                                <input
                                    className='form-check-input form-check-solid form-switch w-45px h-30px'
                                    type='checkbox'
                                    name='is_full_time'
                                    checked={formik.values.is_full_time === 1}
                                    onChange={() => {
                                        formik.setFieldValue(
                                            'is_full_time',
                                            formik.values.is_full_time === 1 ? 0 : 1
                                        )
                                    }}
                                />
                            </div>
                        </div>
                        {formik.values.is_full_time === 0 ? <div className='col-sm-8 col-md-3 col-lg-8 col-xxl-4'>
                            <label className='col-form-label fw-bold fs-6'>
                                {intl.formatMessage({id: 'CONTRACT.WEEKLY_HOURS'})}
                            </label>
                            <input
                                placeholder={intl.formatMessage({id: 'CONTRACT.WEEKLY_HOURS'})}
                                {...formik.getFieldProps('weekly_hours')}
                                type='text'
                                name='weekly_hours'
                                className={'form-control form-control-solid mb-lg-0'}
                                autoComplete='off'
                            />
                        </div> : ''
                        }
                        <div className={'col-sm-4 mb-3'}>
                            <label className='col-form-label fw-bold fs-6 required'>
                                {intl.formatMessage({id: 'CONTRACT.DATE'})}
                            </label>
                            <div
                                className={['input-group mb-3', formik.touched.contract_date && formik.errors.contract_date ? 'border rounded border-danger' : ''].join(' ')}>
                                <Flatpickr
                                    options={FlatpickrDateOptions}
                                    value={contract?.contract_date ? moment(contract?.contract_date).format('DD.MM.YYYY') : ''}
                                    className={clsx('form-control form-control-solid mb-lg-0')}
                                    placeholder={intl.formatMessage({id: 'CONTRACT.DATE'})}
                                    name='contract_date'
                                    onChange={(selectedDates) => {
                                        formik.values.contract_date = moment(selectedDates[0]).format('YYYY-MM-DD');
                                    }}
                                >
                                </Flatpickr>
                                <span className="input-group-text border-0 ">
                                    <i className='fa fa-calendar'></i>
                                </span>
                            </div>
                            {formik.touched.contract_date && formik.errors.contract_date &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'contract_date')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='col-sm-4 mb-3'>
                            <label className='col-form-label fw-bold fs-6 required'>
                                {intl.formatMessage({id: 'CONTRACT.START_DATE'})}
                            </label>
                            <div
                                className={['input-group mb-3', formik.touched.start_date && formik.errors.start_date ? 'border rounded border-danger' : ''].join(' ')}>
                                <Flatpickr
                                    value={contract?.start_date ? moment(contract?.start_date).format('DD.MM.YYYY') : ''}
                                    className={clsx('form-control form-control-solid mb-lg-0')}
                                    placeholder={intl.formatMessage({id: 'CONTRACT.START_DATE'})}
                                    name='start_date'
                                    options={FlatpickrDateOptions}
                                    onChange={(selectedDates) => {
                                        formik.values.start_date = moment(selectedDates[0]).format('YYYY-MM-DD');
                                    }}
                                >
                                </Flatpickr>
                                <span className="input-group-text border-0 ">
                                    <i className='fa fa-calendar'></i>
                                </span>
                            </div>
                            {formik.touched.start_date && formik.errors.start_date &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'start_date')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='col-sm-4 mb-3'>
                            <label className='col-form-label fw-bold fs-6'>
                                {intl.formatMessage({id: 'CONTRACT.END_DATE'})}
                            </label>
                            <div className='input-group mb-3'>
                                <Flatpickr
                                    value={contract?.end_date ? moment(contract?.end_date).format('DD.MM.YYYY') : ''}
                                    className={clsx('form-control form-control-solid mb-lg-0')}
                                    placeholder={intl.formatMessage({id: 'CONTRACT.END_DATE'})}
                                    name='end_date'
                                    options={FlatpickrDateOptions}
                                    onChange={(selectedDates) => {
                                        formik.values.end_date = moment(selectedDates[0]).format('YYYY-MM-DD');
                                    }}
                                >
                                </Flatpickr>
                                <span className="input-group-text border-0 ">
                                    <i className='fa fa-calendar'></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    <Link to={`../contract`}>
                        <button
                            type='button'
                            className='btn btn-light me-3'
                            disabled={loading}
                        >
                            {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                        </button>
                    </Link>
                    <button type='submit' className='btn btn-primary'
                            disabled={loading}
                    >
                        {!loading && (contractId === undefined ? intl.formatMessage({id: 'BUTTON.CREATE'}) : intl.formatMessage({id: 'BUTTON.SUBMIT'}))}
                        {loading && <span className='indicator-progress' style={{display: 'block'}}>
                                {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>}
                    </button>
                </div>
            </form>
        </div>
        {!formik.values.id && contractId && user && <ListLoading/>}
        {!user.id && contractId && <ListLoading/>}
        {loadingUser && !user.id && <ListLoading/>}
        {userId && !user.id && <ListLoading/>}
    </div>
}

export {ContractForm}
