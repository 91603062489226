import { useIntl } from "react-intl"
import { Contract } from "../models/_models"

const ContractHelper = (contract: Contract)  => {
    const intl = useIntl()
    if(contract?.status  === "active"){
        return intl.formatMessage({id: 'CONTRACT.STATUS.ACTIVE'})
    }
    else
        return intl.formatMessage({id: 'CONTRACT.STATUS.EXPIRED'})

}
export {ContractHelper}
