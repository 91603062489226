import {FC} from 'react'
import {FamilyMember} from "../../../../shared/models/family_member";
import moment from "moment/moment";

type Props = {
    familyMember: FamilyMember
}

const ChildrenDateBirthCell: FC<Props> = ({familyMember}) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <div className='text-gray-800 mb-1'>
                {familyMember?.date_of_birth ? moment(familyMember?.date_of_birth).format('DD.MM.YYYY'):moment().format('DD.MM.YYYY')}
            </div>
        </div>
    </div>
)

export {ChildrenDateBirthCell}
