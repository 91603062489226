/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {KTSVG, toAbsoluteUrl} from '../../../helpers';
import {useIntl} from "react-intl";
import {Notification} from "../../../../app/shared/models/_models";
import moment from "moment";
import NotificationService from "../../../../app/shared/services/notification-service";
import {NotificationAlertContext} from "../../../../app/shared/components/NotificationContext";
import {OverlayTrigger, Tooltip, TooltipProps} from "react-bootstrap";

type props = {
    notifications: Notification[] | undefined;
    numberOfUnreadNotifications: number | undefined;
    showNotifications: any;
    setNotifications: any;
    setReadAll: any;
}

const HeaderNotificationsMenu: React.FC<props> = ({
                                                      notifications,
                                                      numberOfUnreadNotifications,
                                                      showNotifications,
                                                      setNotifications,
                                                      setReadAll
                                                  }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [readLoading, setReadLoading] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('current_user') || '')

    // eslint-disable-next-line
    const [numberOfUnread, setNumberOfUnread] = useContext(NotificationAlertContext);

    const navigateToNotification = (notification: any) => {
        if (notification?.model_name === 'request') {
            window.location.href = `/request/${notification?.model?.id}/overview`;
        } else if (notification?.model_name === 'contract') {
            window.location.href = `/contract/${notification?.model?.id}/overview`;
        }
    }

    function checkIcon(alert: Notification) {
        if (alert.model_name === 'contract') {
            return `/media/icons/duotune/finance/fin006.svg`;
        } else {
            if (alert.type === 'success') {
                return `/media/icons/duotune/general/gen043.svg`;
            } else if (alert.type === 'danger') {
                return `/media/icons/duotune/general/gen042.svg`;
            } else if (alert.type === 'primary') {
                return `/media/icons/duotune/general/gen045.svg`;
            } else {
                return `/media/icons/duotune/general/gen045.svg`;
            }
        }
    }

    const seeAllNotifications = () => {
        navigate('/notification');
    }

    function returnTime(alert: Notification) {
        return moment.unix(alert?.created_at || 0).format("DD.MM.YYYY HH:mm");
    }

    const infoToolTip = (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
        <Tooltip {...props}>{intl.formatMessage({id: 'NOTIFICATIONS.READ_ALL'})}</Tooltip>
    );

    function notificationHandler(alert: any) {
        NotificationService.updateNotification(alert?.id).then(() => {
            NotificationService.getUnreadNotifications().then((response) => {
                if (response) {
                    setNumberOfUnread(response.data.filter((notification: Notification) => !notification.is_read).length);
                    setNotifications(response.data);
                    navigateToNotification(alert);
                }
            });
            navigateToNotification(alert)
        });
    }

    const markAllAsRead = () => {
        setReadLoading(true);
        NotificationService.markAllAsRead(55).then((response) => {
            if (response) {
                setNumberOfUnread(0);
                setReadLoading(false);
                setReadAll(true);
            }
        });
    }

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-500px'
            data-kt-menu='true'
        >
            <div
                className='d-flex bgi-no-repeat bgi-size-cover bgi-position-bottom rounded-top'
                style={{
                    backgroundImage: `url('${toAbsoluteUrl('/media/images/notification_background.jpg')}')`
                }}
            >
                <h3 className='d-flex align-items-center text-white fw-bold px-9 my-6'>
                    <span>{intl.formatMessage({id: 'NOTIFICATIONS'})}</span> <span
                    className='fs-8 opacity-75 ps-3'>{numberOfUnreadNotifications && numberOfUnreadNotifications > 0 ? (numberOfUnreadNotifications + ' ' + intl.formatMessage({id: 'NOTIFICATIONS.UNREAD'})) : ''}</span>
                </h3>
                {currentUser?.job_position && showNotifications && numberOfUnreadNotifications && numberOfUnreadNotifications > 0 && !readLoading ?
                    <OverlayTrigger placement="bottom" overlay={infoToolTip}>
                    <span onClick={markAllAsRead}
                          className='d-flex align-items-center ms-auto my-5 me-8 cursor-pointer'>
                        <KTSVG
                            path='/media/icons/duotune/general/gen037.svg'
                            className={'svg-icon-2x text-white text-hover-gray-100'}
                        />
                    </span>
                    </OverlayTrigger> : ''}
            </div>
            <div className='tab-pane fade show'>
                <div className='scroll-y mh-375px px-4'>
                    {showNotifications && notifications && notifications?.length > 0 ? notifications?.map((alert, index) => {
                        const alertState = alert.message?.split('Komentar ')[0];
                        const alertComment = alert.message?.split('Komentar ')[1];

                        return (
                            <div key={`alert${index}`} className='menu-item my-2'>
                                <a onClick={() => notificationHandler(alert)}
                                   className={clsx('menu-link d-flex flex-stack bg-hover-light-primary rounded', !alert.is_read && 'bg-gray-200')}>
                                    <div className='d-flex align-items-center'>
                                        <div className='symbol symbol-35px me-4'>
                                      <span className={clsx('symbol-label', `bg-light-${alert.type}`)}>
                                        {' '}
                                          <KTSVG
                                              path={checkIcon(alert)}
                                              className={`svg-icon-2 svg-icon-${alert.type}`}
                                          />
                                      </span>
                                        </div>
                                        <div className='mb-0 me-2'>
                                        <span className='fs-6 text-gray-800 fw-bolder'>
                                            {alert?.title}
                                        </span>
                                            <div
                                                className='text-gray-600 fw-bold fs-7'>{alert.model?.user?.first_name + ' ' + alert.model?.user?.last_name}</div>
                                            <div className='text-gray-600 fw-bold fs-7'>{alertState}</div>
                                            {alertComment ? <div
                                                className='text-gray-800 fw-bold fs-7'>{intl.formatMessage({id: 'REQUEST.STATUS.STATE.COMMENT'}) + ' ' + alertComment}</div> : ''}
                                        </div>
                                    </div>
                                    <span className='badge badge-light fs-8'>{returnTime(alert)}</span>
                                </a>
                            </div>
                        )
                    }) : showNotifications && notifications && notifications?.length === 0 ?
                        <div className='d-flex py-5 text-gray-800 flex-column text-center align-content-center'>
                            {intl.formatMessage({id: 'TABLE.NOTIFICATIONS.NOT_FOUND'})}
                        </div> : <div className='py-5 text-gray-800 text-center'>
                            <span className="spinner-border spinner-border-sm me-3" role="status"
                                  aria-hidden="true"></span>
                            {intl.formatMessage({id: 'LOADING'})}
                        </div>}
                </div>
                {showNotifications && <div className='menu-item border-top'>
                    <a
                        onClick={seeAllNotifications}
                        className='btn btn-color-gray-600 btn-active-color-primary menu-link d-flex justify-content-center'
                    >
                        {intl.formatMessage({id: 'NOTIFICATIONS.SEE_ALL'})} <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3'/>
                    </a>
                </div>}
            </div>
        </div>
    )
}

export {HeaderNotificationsMenu}
