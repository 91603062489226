/* eslint-disable */
import {getIn, useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {isNotEmpty, KTSVG} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {NotificationMessage} from '../../../../shared/helper/Notification'
import {createVacation, updateVacation} from '../../../../shared/services/vacation-service'

type props = {
  setModalOpen: any
  vacationData: any
  user: any
  asyncFetch: any
}
const VacationModal: React.FC<props> = ({setModalOpen, vacationData, user, asyncFetch}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  const editVacationSchema = Yup.object().shape({
    year: Yup.string().required(intl.formatMessage({id: 'REQUIRED_FIELD'})),
    previously_used: Yup.string().required(intl.formatMessage({id: 'REQUIRED_FIELD'})),
    first_part: Yup.string().required(intl.formatMessage({id: 'REQUIRED_FIELD'})),
    second_part: Yup.string().required(intl.formatMessage({id: 'REQUIRED_FIELD'})),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      year: vacationData?.year ? vacationData?.year : '',
      previously_used: vacationData?.previously_used ? vacationData?.previously_used : 0,
      first_part: vacationData?.first_part ? vacationData?.first_part : 0,
      second_part: vacationData?.second_part ? vacationData?.second_part : 0,
      used: vacationData?.used ? vacationData?.used : 0,
    },
    validationSchema: editVacationSchema,
    onSubmit: async (values: any, {setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)
      try {
        if (isNotEmpty(vacationData)) {
          await updateVacation(values, vacationData?.id).then(() => {
            NotificationMessage(
              intl.formatMessage({
                id: 'ACCOUNT.VACATION.MESSAGE_NOTIFICATION.SUCCESSFULLY_UPDATED',
              }),
              '',
              'success'
            )
            setModalOpen(false)
            asyncFetch()
          })
        } else {
          values.used = values.previously_used;

          await createVacation(values, user?.id).then(() => {
            NotificationMessage(
              intl.formatMessage({id: 'ACCOUNT.VACATION.MESSAGE_NOTIFICATION.SUCCESSFULLY_ADD'}),
              '',
              'success'
            )
            setModalOpen(false)
            asyncFetch()
          })
        }
      } catch (ex) {
        NotificationMessage(
          intl.formatMessage({id: 'MESSAGE_NOTIFICATION.SAVE_FAILED!'}),
          intl.formatMessage({
            id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SAVING_DATA!_PLEASE_TRY_AGAIN.',
          }),
          'danger'
        )
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-md modal-md'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>
                {intl.formatMessage({id: 'ACCOUNT.VACATION.MODAL.TITLE'})}
              </h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                style={{cursor: 'pointer'}}
                onClick={() => setModalOpen(false)}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y'>
              <div className='card'>
                <div id='kt_account_profile_details' className='collapse show'>
                  <>
                    <form
                      id='kt_modal_add_user_form'
                      className='form'
                      onSubmit={formik.handleSubmit}
                      noValidate
                    >
                      <div className='col-12 mb-7'>
                        <label className='col-form-label fw-bold fs-6 required'>
                          {intl.formatMessage({id: 'ACCOUNT.VACATION.YEAR'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({
                            id: 'ACCOUNT.VACATION.YEAR',
                          })}
                          {...formik.getFieldProps('year')}
                          type='number'
                          name='year'
                          className={
                            formik.errors.year && formik.touched.year
                              ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                              : 'form-control form-control-solid mb-3 mb-lg-0'
                          }
                          autoComplete='off'
                        />
                        {formik.touched.year && formik.errors.year && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{getIn(formik.errors, 'year')}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='col-12 mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>
                          {intl.formatMessage({id: 'ACCOUNT.VACATION.PREVIOUSLY'})}
                        </label>
                        <input
                            placeholder={intl.formatMessage({id: 'ACCOUNT.VACATION.PREVIOUSLY'})}
                            {...formik.getFieldProps('previously_used')}
                            type='number'
                            name='previously_used'
                            className={
                              formik.errors.previously_used && formik.touched.previously_used
                                  ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                                  : 'form-control form-control-solid mb-3 mb-lg-0'
                            }
                            autoComplete='off'
                        />
                        {formik.touched.previously_used && formik.errors.previously_used && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{getIn(formik.errors, 'previously_used')}</span>
                              </div>
                            </div>
                        )}
                      </div>
                      <div className='col-12 mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>
                          {intl.formatMessage({id: 'ACCOUNT.VACATION.DAYS'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'ACCOUNT.VACATION.DAYS'})}
                          {...formik.getFieldProps('first_part')}
                          type='number'
                          name='first_part'
                          className={
                            formik.errors.first_part && formik.touched.first_part
                              ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                              : 'form-control form-control-solid mb-3 mb-lg-0'
                          }
                          autoComplete='off'
                        />
                        {formik.touched.first_part && formik.errors.first_part && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{getIn(formik.errors, 'first_part')}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='col-12 mb-7'>
                        <label className='fw-bold fs-6 mb-2'>
                          {intl.formatMessage({id: 'ACCOUNT.VACATION.ADDITIONA_DAYS'})}
                        </label>
                        <input
                          placeholder={intl.formatMessage({id: 'ACCOUNT.VACATION.ADDITIONA_DAYS'})}
                          {...formik.getFieldProps('second_part')}
                          type='number'
                          name='second_part'
                          className={
                            formik.errors.second_part && formik.touched.second_part
                              ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                              : 'form-control form-control-solid mb-3 mb-lg-0'
                          }
                          autoComplete='off'
                        />
                        {formik.touched.second_part && formik.errors.second_part && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{getIn(formik.errors, 'second_part')}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      {isNotEmpty(vacationData) && (
                        <div className='col-12 mb-7'>
                          <label className='fw-bold fs-6 mb-2'>
                            {intl.formatMessage({id: 'ACCOUNT.VACATION.USED'})}
                          </label>
                          <input
                            placeholder={intl.formatMessage({id: 'ACCOUNT.VACATION.USED'})}
                            {...formik.getFieldProps('used')}
                            type='number'
                            name='used'
                            min='0'
                            max={vacationData?.second_part + vacationData?.first_part}
                            className={
                              formik.errors.used && formik.touched.used
                                ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                                : 'form-control form-control-solid mb-3 mb-lg-0'
                            }
                            autoComplete='off'
                          />
                          {formik.touched.used && formik.errors.used && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{getIn(formik.errors, 'used')}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      <div className='card-footer d-flex flex-wrap justify-content-end pt-6 pe-0 pb-0'>
                        <button
                          onClick={() => setModalOpen(false)}
                          type='button'
                          className='btn btn-light me-3 mb-2'
                          data-kt-users-modal-action='cancel'
                          disabled={loading}
                        >
                          {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                        </button>
                        <button type='submit' className='btn btn-success mb-2'>
                          {!loading && intl.formatMessage({id: 'CONTRACT.MODAL.SUBMIT'})}
                          {loading && (
                            <span
                              className='indicator-progress text-white fw-semibold'
                              style={{display: 'block'}}
                            >
                              {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                              <span className='spinner-border text-white spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {VacationModal}
