import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {UsersListWrapper} from './UsersList'
import React from 'react'

const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Users management',
    path: '/user',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UsersPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='user'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Users list</PageTitle>
              <UsersListWrapper />
            </>
          }
        />
      </Route>
        <Route path='*' element={<Navigate to='/error/404'/>}/>
      {/*<Route index element={<Navigate to={`/user`} />} />*/}
    </Routes>
  )
}

export default UsersPage
