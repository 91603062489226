import {Dispatch, SetStateAction} from 'react'
import * as React from "react";
import {Token} from "prismjs";

export type ID = undefined | null | number | string

export type PaginationState = {
    page: number
    items_per_page: number | undefined
    links?: Array<{ label: string; active: boolean; url: string | null | undefined; page: number | null }>
    total_pages?: number | undefined
}

export type SortState = {
    sort?: string
    order?: 'asc' | 'desc'
}

export type FilterState = {
    filter?: unknown
}

export type FilterSector = {
    sector?: unknown
}

export type FilterStatus = {
    status?: unknown
}

export type FilterActivity = {
    is_active?: unknown
}

export type FilterDepartment = {
    department_id?: unknown
}

export type FilterRisk = {
    is_risk?: unknown
}

export type SearchState = {
    search?: string
}

export type FilterContractType = {
    type?: unknown
}

export type DateFrom = {
    start_date?: unknown
}

export type DateTo = {
    end_date?: unknown
}

export type ApuLevel = {
    apu_value?: unknown
}


export type UserToken = {
    key?: React.Key;
    className?: string;
    token: Token;
    [otherProp: string]: any;
}

export type Response<T> = {
    data?: T
    payload?: {
        message?: string
        errors?: {
            [key: string]: Array<string>
        }
        pagination?: PaginationState
    }
    _meta: PaginationPage,
    _links: Links
}

export type Links = {
    self?: {
        href: string
    }
    first?: {
        href: string
    }
    previous?: {
        href: string
    }
    last?: {
        href: string
    }
    next?: {
        href: string
    }
}

export type PaginationPage = {
    totalCount: number,
    pageCount: number,
    currentPage: number,
    perPage: number
}

export type QueryState = PaginationState & SortState & FilterState & SearchState & FilterStatus & FilterRisk & FilterDepartment & FilterActivity & FilterSector & FilterContractType & DateFrom & DateTo & ApuLevel

export type QueryRequestContextProps = {
    state: QueryState
    updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
    page: 1,
    items_per_page: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
    state: initialQueryState,
    updateState: () => {
    },
}

export type QueryResponseContextProps<T> = {
    response?: Response<Array<T>> | undefined
    refetch: () => void
    isLoading: boolean
    query: string
}

export const initialQueryResponse = {
    refetch: () => {
    }, isLoading: false, query: ''
}

export type ListViewContextProps = {
    selected: Array<ID>
    onSelect: (selectedId: ID) => void
    onSelectAll: () => void
    clearSelected: () => void
    // NULL => (CREATION MODE) | MODAL IS OPENED
    // NUMBER => (EDIT MODE) | MODAL IS OPENED
    // UNDEFINED => MODAL IS CLOSED
    itemIdForUpdate?: ID
    setItemIdForUpdate: Dispatch<SetStateAction<ID>>
    itemIdForDelete?: ID
    setItemIdForDelete: Dispatch<SetStateAction<ID>>
    userIDForFamily?: ID
    setUserIDForFamily: Dispatch<SetStateAction<ID>>
    isAllSelected: boolean
    disabled: boolean
}

export const initialListView: ListViewContextProps = {
    selected: [],
    onSelect: () => {
    },
    onSelectAll: () => {
    },
    clearSelected: () => {
    },
    setItemIdForUpdate: () => {
    },
    setItemIdForDelete: () => {
    },
    setUserIDForFamily: () => {
    },
    isAllSelected: false,
    disabled: false,
}
