import {FC} from 'react'
import {Contract} from "../../../../shared/models/_models";
import {Link} from "react-router-dom";


type Props = {
    contract: Contract
}

const ContractUserCell: FC<Props> = ({contract}) => (
        <div className='d-flex flex-column'>
            <Link to={`/user/${contract.user_id}/details/personal/view`}
                  className='text-gray-800 text-hover-primary mb-1'>
                {contract.user?.first_name} {contract.user?.last_name}
            </Link>
        </div>
)
export {ContractUserCell}
