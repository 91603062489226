import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../../shared/list/core/ListViewProvider'
import {useIntl} from "react-intl";

const UserCreateModalHeader = () => {
    const {setItemIdForUpdate} = useListView()
    const intl = useIntl()
    return (
        <div className='modal-header'>
            <h2 className='fw-bolder'>{intl.formatMessage({id: 'USER.HEADER.NEW'})}</h2>
            <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => setItemIdForUpdate(undefined)}
                style={{cursor: 'pointer'}}
            >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
            </div>
        </div>
    )
}

export {UserCreateModalHeader}
