/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Request} from '../../../shared/models/_models'
import clsx from 'clsx'

type Props = {
  setOpenModal: any
  request: Request
  actions: any
  setRequestAction: any
  setIsDownloading: any
}

const ActionsForMobile: FC<Props> = ({
  setOpenModal,
  actions,
  setRequestAction,
  request,
  setIsDownloading,
}) => {
  const intl = useIntl()

  const setActionData = async (action: any) => {
    setRequestAction(action)
    setOpenModal(true)
  }

  const optionsLists = actions?.map((item: any) => {
    switch (item?.name) {
      case intl.formatMessage({id: 'REQUEST.ACTIONS.APPROVE'}):
        if (item?.full_name === intl.formatMessage({id: 'REQUEST.ACTIONS.DIRECTOR.DISPLAY'})) {
          return {
            id: item?.id,
            name: intl.formatMessage({id: 'REQUEST.ACTIONS.DIRECTOR.DISPLAY'}),
            icon: 'fa-solid fa-forward',
            json_schema: item?.json_schema,
            ui_schema: item?.ui_schema,
          }
        } else if (
          item?.full_name === intl.formatMessage({id: 'REQUEST.ACTIONS.RATE_THE_REQUEST'})
        ) {
          return {
            id: item?.id,
            name: intl.formatMessage({id: 'REQUEST.ACTIONS.RATE_THE_REQUEST.DISPLAY'}),
            icon: 'fa-solid fa-list-check',
            json_schema: item?.json_schema,
            ui_schema: item?.ui_schema,
          }
        } else if (
          item?.full_name === intl.formatMessage({id: 'REQUEST.ACTIONS.INFO_ABOUT_GRADE'})
        ) {
          return {
            id: item?.id,
            name: intl.formatMessage({id: 'REQUEST.ACTIONS.INFO_ABOUT_GRADE.DISPLAY'}),
            icon: 'fa-solid fa-circle-info',
            json_schema: item?.json_schema,
            ui_schema: item?.ui_schema,
          }
        } else if (
          item?.full_name === intl.formatMessage({id: 'REQUEST.ACTIONS.INFO_ABOUT_CONVERSATION'})
        ) {
          return {
            id: item?.id,
            name: intl.formatMessage({id: 'REQUEST.ACTIONS.INFO_ABOUT_CONVERSATION.DISPLAY'}),
            icon: 'fa-solid fa-user-group',
            json_schema: item?.json_schema,
            ui_schema: item?.ui_schema,
          }
        } else {
          return {
            id: item?.id,
            name: intl.formatMessage({id: 'REQUEST.ACTIONS.APPROVE.DISPLAY'}),
            icon: 'fa-solid fa-check',
            json_schema: item?.json_schema,
            ui_schema: item?.ui_schema,
          }
        }
      case intl.formatMessage({id: 'REQUEST.ACTIONS.CANCEL'}):
        return {
          id: item?.id,
          name: intl.formatMessage({id: 'REQUEST.ACTIONS.CANCEL.DISPLAY'}),
          icon: 'fa-solid fa-ban',
        }
      case intl.formatMessage({id: 'REQUEST.ACTIONS.UPLOAD'}):
        return {
          id: item?.id,
          name: intl.formatMessage({id: 'REQUEST.ACTIONS.UPLOAD.DISPLAY'}),
          icon: 'fa-solid fa-upload',
        }
      case intl.formatMessage({id: 'REQUEST.ACTIONS.DENY'}):
        return {
          id: item?.id,
          name: intl.formatMessage({id: 'REQUEST.ACTIONS.DENY.DISPLAY'}),
          icon: 'fa-solid fa-backward',
        }
      default:
        return {
          id: item?.id,
          name: item?.json_schema
            ? intl.formatMessage({id: 'REQUEST.DROPDOWN.STATUS.PRINT'})
            : intl.formatMessage({id: 'REQUEST.DROPDOWN.STATUS.PRINT.ONLY'}),
          icon: 'fa-solid fa-print',
          json_schema: item?.json_schema,
          ui_schema: item?.ui_schema,
        }
    }
  })

  return (
    <>
      <div className='card'>
        <div className='card-body pt-0'>
          {optionsLists?.length > 0 ? (
            optionsLists?.map((action: any) => (
              <button
                key={action?.id}
                className='btn btn-primary w-100 btn-active-light-primary btn-sm m-2'
                onClick={() => setActionData(action)}
              >
                <i className={clsx(action.icon, 'me-1')}></i>
                {action.name}
              </button>
            ))
          ) : (
            <button className='btn btn-outline-primary btn-sm' disabled>
              {intl.formatMessage({id: 'REQUEST.DROPDOWN.STATUS.REJECT.DISPLAY'})}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export {ActionsForMobile}
