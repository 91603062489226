import {FC} from 'react'
import { useIntl } from 'react-intl';

type Props = {
    user: any
}

const UserSectorCell: FC<Props> = ({user}) => {
    const intl = useIntl();

    const technical = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.TECHNICAL'})
    const commercial = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.COMMERCIAL'})
    const global = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.GLOBAL'})

    return (
        <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
                {user?.job_position?.sector === 'technical' ? technical : user?.job_position?.sector === 'commercial' ?
                    commercial : user?.job_position?.sector === 'global' ? global : '-'
                }
            </div>
        </div>
    )
}

export {UserSectorCell}