/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from "react-intl";
import {DetailsHeader} from "../DetailsHeader";
import {User} from "../../../../../shared/models/user";

type Props = {
    className: string
    user?: User
}

const FamilyDetails: React.FC<Props> = ({className, user}) => {
    const intl = useIntl()
    return (
        <>
            <div className={`card ${className}`} id='kt_profile_details_view'>
                <div className='card-header cursor-pointer'>
                    <DetailsHeader user={user}/>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <label
                            className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.FAMILY_DETAILS.MARITAL_STATUS'})}</label>
                        <div className='col-sm-6 mb-5'>
                            {user?.profile?.is_married ?
                                <span
                                    className='fs-6'>{intl.formatMessage({id: 'ACCOUNT.FAMILY_DETAILS.MARITAL_STATUS.MARRIED'})}</span>
                                : <span
                                    className='fs-6'>{intl.formatMessage({id: 'ACCOUNT.FAMILY_DETAILS.MARITAL_STATUS.NOT_MARRIED'})}</span>}
                        </div>
                        {user?.profile?.is_married === 1 ?
                            <>
                                <label
                                    className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.FAMILY_DETAILS.SPOUSE_FULL_NAME'})}</label>
                                <div className='col-sm-6 mb-5'>
                                    <span
                                        className='fs-6 me-2'>{{user}.user?.profile?.spouse_first_name || 'N/A'} {{user}.user?.profile?.spouse_last_name}
                                    </span>
                                </div>
                                <label
                                    className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.FAMILY_DETAILS.SPOUSE_PHONE_NUMBER'})}</label>
                                <div className='col-sm-6 mb-5'>
                                    <span
                                        className='fs-6 me-2'>{{user}.user?.profile?.spouse_phone_number || 'N/A'}
                                    </span>
                                </div>
                            </> : ''}
                        <label
                            className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.FAMILY_DETAILS.CHILDREN_NUMBER'})}</label>
                        <div className='col-sm-6 mb-5'>
                            <span className='fs-6 me-2'>{user?.family_members?.length}</span>
                        </div>
                        {user?.family_members && user?.family_members?.length !== 0 ?
                            <>
                                <label
                                    className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.FAMILY_DETAILS.CHILDREN'})}</label>
                                <div className='col-sm-6 mb-5'>
                                    {{user}.user?.family_members?.map((familyMember) =>
                                        <div className='fs-6 me-2 mb-2'
                                             key={familyMember.id}>{familyMember.first_name} {familyMember.last_name}</div>
                                    )}
                                </div>
                            </> : ''}
                    </div>
                </div>
            </div>
        </>
    )
}

export {FamilyDetails}

