import Flatpickr from "react-flatpickr";
import moment from "moment";
import clsx from 'clsx'
import { RequestFlatpickerDateTimeOptions } from "../../../shared/helper/RequestFlatpickerDateTimeOptions";

const CustomDateTimeWidget = function(props: any) {
    return (
      <div className='input-group'>
        <Flatpickr
          className={clsx('form-control form-control-solid mb-lg-0')}
          disabled={props.disabled}
          value={props?.value}
          options={RequestFlatpickerDateTimeOptions}
          title = {props?.label}
          placeholder = {props?.label}
          onChange={(selectedDates) => {
            props.onChange(moment(selectedDates[0]).format('DD.MM.YYYY HH:mm'))
        }}
        ></Flatpickr>
        <span className="input-group-text border-0 ">
          <i className='fa fa-calendar'></i>
        </span>
      </div>
    )
  };

  export {CustomDateTimeWidget}