import React, {FC, useState} from 'react'
import {useIntl} from "react-intl";
import {useMutation} from 'react-query';
import {postRequestActions} from '../../../shared/services/request-service';
import {NotificationMessage} from '../../../shared/helper/Notification';
import {Request} from "../../../shared/models/_models";
import {CustomTimeWidget} from '../jsonSchemaAccessories/CustomTimeWidget';
import {CustomDateTimeWidget} from '../jsonSchemaAccessories/CustomDateTimeWidget';
import {CustomDateWidget} from '../jsonSchemaAccessories/CustomDateWidget';
import {CustomSelectWidget} from '../jsonSchemaAccessories/CustomSelectWidget';
import {ObjectFieldTemplate} from '../jsonSchemaAccessories/ObjectFieldTemplate';
import 'react-datepicker/dist/react-datepicker.css'
import Form from "react-jsonschema-form";
import {JSONSchema6} from "json-schema";
import './ApprovalJsonSchema.css';
import {KTCardBody} from '../../../../_metronic/helpers';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone, {IDropzoneProps, IInputProps} from 'react-dropzone-uploader'
import {uploadFile} from '../../../shared/services/file-service'
import './requestPreviewCss.css';

type Props = {
    request: Request
    setOpenModal: any
    action: any
    setActionApproved: Function
}

declare global {
    interface Window {
        isApprovedButton: boolean,
        validationFormData: object
    }
}

const RequestModalForm: FC<Props> = ({setOpenModal, action, request, setActionApproved}) => {    
    const intl = useIntl()
    const [comment, setComment] = useState('')
    const [loading, setLoading] = useState(false);
    const [uploadFileLoading, setUploadFileLoading] = useState(false)
    const [liveValidation, setliveValidation] = useState(false)
    const [dropzoneValidation, setDropzoneValidation] = useState({
        minHeight: 200,
        justifyContent: 'center',
        borderColor: "#3699FF",
        borderWidth: 2,
        borderRadius: 7,
        borderStyle: 'dashed',
        overflow: 'hidden'
    })
    const [dropzoneValidationMessage, setDropzoneValidationMessage] = useState(false)
    const [arrayOfFileIdsForUpload, setArrayOfFileIdsForUpload] = React.useState<any[]>([]);
    const schema: JSONSchema6 = action?.json_schema ? JSON.parse(action?.json_schema) : {}
    const uiSchema: JSONSchema6 = action?.ui_schema ? JSON.parse(action?.ui_schema) : {}    

    const widgets = {
        DateTimeWidget: CustomDateTimeWidget,
        DateWidget: CustomDateWidget,
        TimeWidget: CustomTimeWidget,
        SelectWidget: CustomSelectWidget
    };

    const updateRequestFormData = (formData: any) => {
        if (action?.name === intl.formatMessage({id: 'REQUEST.ACTIONS.UPLOAD.DISPLAY'}) && arrayOfFileIdsForUpload.length !== 0) {
            setLoading(true);
            setUploadFileLoading(true)
            arrayOfFileIdsForUpload && uploadSolutionFiles.mutateAsync(arrayOfFileIdsForUpload[0]);
        } else if (action?.name === intl.formatMessage({id: 'REQUEST.ACTIONS.UPLOAD.DISPLAY'}) && arrayOfFileIdsForUpload.length === 0) {
            setDropzoneValidationMessage(true)
            setDropzoneValidation({
                minHeight: 200,
                justifyContent: 'center',
                borderColor: "var(--kt-text-danger)",
                borderWidth: 2,
                borderRadius: 7,
                borderStyle: 'dashed',
                overflow: 'hidden'
            })
        } else if (action?.name === intl.formatMessage({id: 'REQUEST.DROPDOWN.STATUS.PRINT'}) || action?.name === intl.formatMessage({id: 'REQUEST.DROPDOWN.STATUS.PRINT.ONLY'})) {
            setLoading(true);
            postRequestActions({...request,
                action_id: action?.id,
                request_note: comment,
                approval_form_data: formData?.formData})
            .then((response:any) => {
                window.open(`${response?.data?.initial_approval_file_url}`, `_blank`)
                setActionApproved()
                closeModal()
                }
            )
        } else {
            setLoading(true)
            sendActionData?.mutateAsync(formData)
        }
    }

    function transformErrors(errors: any[]) {
        setliveValidation(true)
        return errors.map(error => {
            error.message = intl.formatMessage({id: 'REQUIRED_FIELD'})
            return error;
        });
    }

    const closeModal = () => {
        setOpenModal(false)
    }

    // eslint-disable-next-line
    const sendActionData = useMutation((formData: any) => postRequestActions(
        {
            ...request,
            action_id: action?.id,
            request_note: comment,
            approval_form_data: formData?.formData
        }), {
        onSuccess: () => {
            try {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.ACTION_SUCCESSFUL'}), '', 'success')
                setActionApproved()
                closeModal()
            } catch (ex) {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.SEND_FAILED'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SENDING_ACTION_FOR_EXECUTING!_PLEASE_TRY_AGAIN.'}), 'danger')
            } finally {
                closeModal()
            }
        }
    })

    const uploadSolutionFiles = useMutation((fileId: any) => postRequestActions(
        {
            ...request,
            signed_approval_file_id: fileId,
            request_note: comment,
            action_id: action?.id
        }), {
        onSuccess: () => {
            try {
                setUploadFileLoading(false)
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.ACTION_SUCCESSFUL'}), '', 'success')
                setActionApproved()
                closeModal()
            } catch (ex) {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.SEND_FAILED'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SENDING_ACTION_FOR_EXECUTING!_PLEASE_TRY_AGAIN.'}), 'danger')
            } finally {
                closeModal()
            }
        }
    })

    const handleChangeStatus: IDropzoneProps['onChangeStatus'] = (files, status) => {
        if (status === intl.formatMessage({id: 'REQUEST.ACTIONS.DONE'})) {
            setDropzoneValidationMessage(false)
            setDropzoneValidation({
                minHeight: 200,
                justifyContent: 'center',
                borderColor: "#3699FF",
                borderWidth: 2,
                borderRadius: 7,
                borderStyle: 'dashed',
                overflow: 'hidden'
            })
            setUploadFileLoading(true)
            uploadFile(files.file)
                .then(result => {
                    const fileId = result?.data[0]?.id
                    setArrayOfFileIdsForUpload(arrayOfFileIdsForUpload => [...arrayOfFileIdsForUpload, fileId])
                    setUploadFileLoading(false)
                })
        } else if (status === intl.formatMessage({id: 'REQUEST.ACTIONS.REMOVED'})) {
            setArrayOfFileIdsForUpload([]);
        }
    }

    const getUploadParams = (meta: any) => {
        setUploadFileLoading(true)
        const url = 'https://httpbin.org/post'
        return {url, meta: {fileUrl: `${url}/${encodeURIComponent(meta.name)}`}}
    }

    const Input = ({accept, onFiles, files, getFilesFromEvent}: IInputProps) => {
        const text = intl.formatMessage({id: 'REQUEST.DROPZONE.ADD_FILE'})
        const fileSize = files.length

        return (
            fileSize < 1 &&
            <label className='bg-primary mx-2 my-10 text-light fw-semibold justify-content-center rounded-2 p-5 cursor-pointer'>
                {text}
                <input
                    style={{display: 'none'}}
                    type="file"
                    accept={accept}
                    onChange={async e => {
                        setUploadFileLoading(true);
                        const chosenFiles = await getFilesFromEvent(e);
                        onFiles(chosenFiles as File[]);
                    }}
                />
            </label>
        )
    }

    return (
        <>
            <KTCardBody>
                {schema && <Form
                    id="approval-json-schema-form"
                    autocomplete="off"
                    noHtml5Validate
                    schema={schema}
                    uiSchema={uiSchema}
                    ObjectFieldTemplate={ObjectFieldTemplate}
                    formData={JSON.stringify(window.validationFormData) === '{}' ? request?.approval_form_data : window.validationFormData}
                    widgets={widgets}
                    liveValidate={liveValidation}
                    transformErrors={transformErrors}
                    onChange={(e) => window.validationFormData = e.formData}
                    onSubmit={(formData) => {
                        updateRequestFormData(formData)
                    }}
                >
                    {action?.name === intl.formatMessage({id: 'REQUEST.ACTIONS.UPLOAD.DISPLAY'}) &&
                        <div className="row px-6 d-flex mb-8">
                            <Dropzone
                                getUploadParams={getUploadParams}
                                onChangeStatus={handleChangeStatus}
                                InputComponent={Input}
                                styles={{dropzone: dropzoneValidation}}
                            />
                            {dropzoneValidationMessage && <ul className="error-detail bs-callout bs-callout-info">
                                <li className="text-danger">{intl.formatMessage({id: 'REQUIRED_FIELD'})}</li>
                            </ul>}
                        </div>}
                    <div className="row px-3">
                        <div className='mb-3'>
                            <label className='col-form-label fw-bold fs-6 fw-bolder'>
                                {intl.formatMessage({id: 'REQUEST.MODAL.ADD_COMMENT'})}
                            </label>
                            <textarea placeholder={intl.formatMessage({id: 'REQUEST.MODAL.ADD_COMMENT'})}
                                      className="form-control" id="exampleFormControlTextarea1"
                                      autoComplete='off'
                                      onChange={(event) => setComment(event.target.value)}
                                      rows={6}
                            />
                        </div>
                        <div className='card-footer d-flex flex-wrap justify-content-end pt-6'>
                            <button
                                onClick={() => closeModal()}
                                type="button"
                                className='btn btn-light me-3 mb-2'
                                data-kt-users-modal-action='cancel'
                                disabled={uploadFileLoading || loading}
                            >
                                {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                            </button>
                            <button type='submit' className='btn btn-success mb-2'
                                    disabled={loading || uploadFileLoading}
                            >
                                {!loading && !uploadFileLoading && intl.formatMessage({id: 'REQUEST.MODAL.SUBMIT'})}
                                {(loading || uploadFileLoading) && (
                                    <span className='indicator-progress text-white fw-semibold' style={{display: 'block'}}>
                                            {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                                        <span className='spinner-border spinner-border-sm text-white align-middle ms-2'></span>
                                        </span>
                                )}
                            </button>
                        </div>
                    </div>
                </Form>}
            </KTCardBody>
        </>
    )
}

export {RequestModalForm}
