import {useListView} from '../../../shared/list/core/ListViewProvider';
import {ListSearchComponent} from '../../../shared/list/components/header/ListSearchComponent';
import {useIntl} from "react-intl";
import {JobPositionFilter} from './JobPositionFilter';
import {KTSVG} from "../../../../../../_metronic/helpers";
import { RoutesHelper } from '../../../../../shared/helper/RoutesHelper';

const JobPositionsListHeader = () => {
    const intl = useIntl();
    const {setItemIdForUpdate} = useListView();
    const openAddDepartmentModal = () => {
        setItemIdForUpdate(null)
    }


    return (
        <>
            <div className='card-header row border-0 pt-6'>
                <h1 className='d-flex align-items-md-start align-items-center justify-content-center flex-column fs-2qx'>{intl.formatMessage({id: 'JOB_POSITIONS'})}</h1>
            </div>
            <div className='card-header border-0 pt-0 pt-md-4'>
                <ListSearchComponent/>
                <div
                    className='card-toolbar d-flex justify-content-sm-center justify-content-md-center justify-content-center justify-content-lg-end col-sm-12 col-12 col-md-12 col-lg-6'>
                    <JobPositionFilter/>
                    {(RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2'])) && 
                        <button type='button' className='btn btn-primary btn-active-light-primary mb-1 px-4'
                                onClick={openAddDepartmentModal}>
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                            {intl.formatMessage({id: 'JOB_POSITION.ADD_JOB_POSITION'})}
                        </button>
                    }
                </div>
            </div>
        </>
    )
}

export {JobPositionsListHeader}
