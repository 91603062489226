import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
    createResponseContext,
    initialQueryResponse,
    QUERIES,
    stringifyRequestQuery,
    WithChildren,
} from '../../../../../_metronic/helpers'

import {FamilyMember} from '../../../../shared/models/family_member'
import {useQueryRequest} from '../../shared/list/core/QueryRequestProvider'
import {getFamilyMembers} from "../../../../shared/services/user-service";
import {useParams} from "react-router-dom";


const QueryResponseContext = createResponseContext<FamilyMember>(initialQueryResponse)
const QueryResponseProviderFamilyMember: FC<WithChildren> = ({children}) => {
    const {state} = useQueryRequest()
    const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
    const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
    const userId = useParams().id
    useEffect(() => {
            if (query !== updatedQuery) {
                setQuery(updatedQuery)
            }
        },
        // eslint-disable-next-line
        [updatedQuery])

    const {
        isFetching,
        refetch,
        data: response,
    } = useQuery(
        `${QUERIES.USERS_LIST}-${query}`,
        () => {

            return getFamilyMembers(userId)
        },
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    )

    return (
        <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
            {children}
        </QueryResponseContext.Provider>
    )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
    const {response} = useQueryResponse()
    if (!response) {
        return []
    }
    return response || []
}


const useQueryResponseLoading = (): boolean => {
    const {isLoading} = useQueryResponse()
    return isLoading
}

export {
    QueryResponseProviderFamilyMember,
    useQueryResponse,
    useQueryResponseData,
    useQueryResponseLoading,
}
