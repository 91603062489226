import {Options} from "flatpickr/dist/types/options";

const FlatpickrDateOptions: Partial<Options> = {
    dateFormat: "d.m.Y",
    enableTime: false,
    locale: "sr",
    allowInput: false
}

export {FlatpickrDateOptions}
