import React, {useEffect, useState} from 'react';
import {NavLink, useParams} from "react-router-dom";
import {getDepartments} from "../../../shared/services/department-service";
import {DepartmentUsersTable} from "../overview/tables/DepartmentUsersTable";
import {useIntl} from "react-intl";

type props = {
    departmentId: any
}

const DepartmentChildList: React.FC<props> = ({departmentId}) => {
    const intl = useIntl();
    const childDepartmentId = useParams().child_id;
    const [selectedChildDepartmentId, setSelectedChildDepartmentId] = useState(childDepartmentId);
    const [childDepartments, setChildDepartments] = useState([] as any);
    let activeClassName = 'nav-link text-active-primary me-6 hover active';

    useEffect(() => {
            getDepartments('parent_id=' + departmentId).then((response) => {
                if (response) {
                    setChildDepartments(response);
                }
            })
        },
        // eslint-disable-next-line
        []);

    return (
        <>
            {childDepartments?.data?.length > 0 && <div className='card mt-8'>
                <div className='card-body'>
                    <div className='d-flex overflow-auto'>
                        <ul className='nav nav-stretch nav-line-tabs border-transparent fs-5 fw-bolder flex-nowrap d-block d-lg-flex d-md-flex d-sm-flex'>
                            {childDepartments?.data?.map((child: any) => (
                                <li className='nav-item' key={child?.id}
                                    onClick={() => setSelectedChildDepartmentId(child?.id)}>
                                    <NavLink
                                        className={({isActive}) =>
                                            isActive ? activeClassName : 'nav-link text-active-primary me-6 ms-0 hover'
                                        }
                                        to={'/department/' + departmentId + '/overview/child/' + child?.id}
                                    >
                                        {child?.name}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='mt-10'>
                        {selectedChildDepartmentId ? <div className='card-body p-0'>
                            <div className='row'>
                                <DepartmentUsersTable departmentId={selectedChildDepartmentId}/>
                            </div>
                        </div> : <div
                            className='text-dark fs-6'>{intl.formatMessage({id: 'DEPARTMENT.CHILD_DEPARTMENT.SELECT'})}</div>}
                    </div>
                </div>
            </div>}
        </>
    )
}

export default DepartmentChildList;
