import React, {useEffect, useState} from 'react';
import {getContracts} from "../../../shared/services/contract-service";
import {useIntl} from "react-intl";
import moment from "moment";
import clsx from "clsx";
import {ContractDetailsModal} from "../modals/ContractDetailsModal";
import {Contract} from "../../../shared/models/contract";
import {logoutCurrentUser} from "../../../modules/auth";
import {SimpleLoading} from "../../../modules/apps/shared/list/components/loading/SimpleLoading";
import Pagination from "react-js-pagination";
import {ScrollTopComponent} from "../../../../_metronic/assets/ts/components";

const ContractInfoDashboard: React.FC = () => {
    const intl = useIntl();
    const [contractInfo, setContractInfo] = useState({} as Contract);
    const [pagination, setPagination] = useState({} as any);
    const [openModal, setOpenModal] = useState(false);
    const [contracts, setContracts] = useState([] as Contract[] | undefined);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);

    let current_user = JSON.parse(localStorage.getItem('current_user') || logoutCurrentUser() as unknown as string);

    useEffect(() => {
            if (current_user.job_position_id) {
                getContracts('user_id=' + current_user.id + '&page=' + page + '&is_deleted=0').then(response => {
                    setContracts(response?.data);
                    setPagination((response._meta));
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        },
        // eslint-disable-next-line
        [page]);

    function handlePageChange(pageNumber: number) {
        if (pagination?.currentPage !== pageNumber) {
            setLoading(true);
            setPage(pageNumber);
            ScrollTopComponent.goTop();
        }
    }

    return (
        <>
            <div className='card card-xxl-stretch-50 mb-5 mb-xl-8'>
                <div className='card-header border-bottom border-2'>
                    <h3 className='card-title fw-bold text-dark'>
                        {intl.formatMessage({id: 'ACCOUNT.HEADER.MY_CONTRACTS'})}
                    </h3>
                </div>
                <div className='card-body pt-2'>
                    {!loading ? (contracts && contracts?.length > 0 ? contracts?.map((contract) =>
                        <div className='row mt-2 bg-hover-light-primary rounded py-2 px-1' key={contract?.id}
                             onClick={() => {
                                 setContractInfo(contract);
                                 setOpenModal(true);
                             }}>
                            <div className='col-4 d-flex align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <span
                                        className={clsx(
                                            'bullet bullet-vertical h-40px me-3',
                                            {'bg-danger': contract?.status === 'expired'},
                                            {
                                                'bg-success': contract?.status === 'active',
                                            },
                                        )}
                                    ></span>
                                    <div className='flex-grow-1'>
                                        <span className='text-gray-800 fw-bold fs-6'
                                        >
                                            {contract?.code}
                                        </span>
                                        <span className='text-muted fw-semibold d-block'>
                                            {moment(contract?.contract_date).format('DD.MM.YYYY')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4 d-flex align-items-center justify-content-center'>
                                <span className='text-gray-800 fw-bold fs-6'>
                                    {contract?.type === 'undefined' && intl.formatMessage({id: 'CONTRACT.TYPE.UNDEFINED'})}
                                    {contract?.type === 'defined' && intl.formatMessage({id: 'CONTRACT.TYPE.DEFINED'})}
                                </span>
                            </div>
                            <div className='col-4 d-flex align-items-center justify-content-end'>
                                <span
                                    className={clsx(
                                        'badge fs-7 fw-bold',
                                        {'badge-light-danger': contract?.status === 'expired'},
                                        {
                                            'badge-light-success': contract?.status === 'active',
                                        },
                                    )}
                                >{contract?.status === 'expired' ? 'Istekao' : 'Aktivan'}</span>
                            </div>
                        </div>
                    ) : (
                        <div className='pt-5'>
                        <span className='text-gray-800 fw-bold fs-6'>
                            {intl.formatMessage({id: 'DASHBOARD.CONTRACT.INFO.NOT_FOUND'})}
                        </span>
                        </div>
                    )) : <div className='py-3'><SimpleLoading/></div>}
                    <div className='row mt-4'>
                        <div
                            className='col-12 d-flex align-items-center justify-content-center'>
                            <div className='mb-md-0 mb-2' id='kt_table_users_paginate'>
                                {pagination?.totalCount > 10 && <Pagination
                                    activePage={pagination?.currentPage}
                                    hideFirstLastPages={true}
                                    innerClass='pagination'
                                    activeClass='active'
                                    activeLinkClass='page-link'
                                    itemClass='page-item'
                                    itemClassFirst='page-item'
                                    itemClassPrev='page-item'
                                    itemClassNext='page-item'
                                    itemClassLast='page-item'
                                    linkClass='page-link'
                                    linkClassFirst='page-link bg-light'
                                    linkClassPrev='page-link bg-light'
                                    linkClassNext='page-link bg-light'
                                    linkClassLast='page-link bg-light'
                                    disabledClass='disabled'
                                    itemsCountPerPage={pagination?.perPage}
                                    totalItemsCount={pagination?.totalCount}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange.bind(this)}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
                {openModal && <ContractDetailsModal setOpenModal={setOpenModal} contractInfo={contractInfo}/>}
            </div>
        </>
    )
}

export {ContractInfoDashboard}
