import React, {useMemo} from 'react';
import {useTable, ColumnInstance, Row} from 'react-table';
import {CustomHeaderColumn} from '../../modules/apps/shared/list/columns/CustomHeaderColumn';
import {CustomRow} from '../../modules/apps/shared/list/columns/CustomRow';
import {contractColumns} from '../../modules/apps/shared/list/columns/_columns';
import {Contract} from '../../shared/models/contract';
import {KTCardBody} from '../../../_metronic/helpers';
import {ListLoading} from '../../modules/apps/shared/list/components/loading/ListLoading';
import {useIntl} from 'react-intl';
import {
    useQueryResponseData,
    useQueryResponseLoading,
    useQueryResponsePagination
} from "../../modules/apps/contract-menagement/core/QueryResponseProviderContracts";
import {ListPagination} from "../../shared/components/ListPagination";

const ContractsTable = () => {
    const intl = useIntl();
    const pagination = useQueryResponsePagination();
    const contracts = useQueryResponseData();
    const isLoading = useQueryResponseLoading();
    const data = useMemo(() => contracts, [contracts]);
    const columns = useMemo(() => contractColumns, []);
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    });

    return (
        <KTCardBody className='py-4'>
            <div className='table-responsive'>
                <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<Contract>) => (
                            <CustomHeaderColumn key={column.id} column={column}/>
                        ))}
                    </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row<Contract>, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id: 'TABLE.NOT_FOUND'})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <ListPagination pagination={pagination}/>
            {isLoading && <ListLoading/>}
        </KTCardBody>
    )
}

export {ContractsTable}
