import {ListSearchComponent} from '../../../shared/list/components/header/ListSearchComponent';
import {ContractsListToolbar} from './ContractsListToolbar';
import {useIntl} from "react-intl";
import {ContractsFilter} from './ContractsFilter';
import { RoutesHelper } from '../../../../../shared/helper/RoutesHelper';

const ContractsListHeader = () => {
    const intl = useIntl();

    return (
        <>
            <div className='card-header row border-0 pt-6'>
                <h1 className='d-flex align-items-md-start align-items-center justify-content-center flex-column fs-2qx'>{intl.formatMessage({id: 'CONTRACTS'})}</h1>
            </div>
            <div className='card-header border-0 pt-0 pt-md-4'>
                <ListSearchComponent/>
                <div
                    className='card-toolbar d-flex justify-content-sm-center justify-content-md-center justify-content-center justify-content-lg-end col-sm-12 col-12 col-md-12 col-lg-6'>
                      <ContractsFilter/>
                      {(RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2'])) && 
                        <ContractsListToolbar/>
                      }
                </div>
            </div>
        </>
    )
}

export {ContractsListHeader}
