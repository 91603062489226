import {ID, Response} from '../../../_metronic/helpers'
import {get, post, put, remove} from './api-client'
import {AxiosResponse} from 'axios'
import {Contract, ContractsQueryResponse} from "../models/contract";
import {ActionQueryResponse} from '../models/_models';
import axiosInstance from './axiosInstance';

const ENDPOINT = `/contract`

const getContracts = (query: string): Promise<ContractsQueryResponse> => {
    return get(ENDPOINT, query).then((d: AxiosResponse<ContractsQueryResponse>) => d.data)
}

const getContractById = (id: ID): Promise<Contract | undefined> => {
    return get(`${ENDPOINT}/${id}`, '')
        .then((response: AxiosResponse<Response<Contract>>) => response.data)
        .then((response: Response<Contract>) => response.data)
}

const createContract = (contract: any): Promise<Contract | undefined> => {
    return post(ENDPOINT, contract)
        .then((response: AxiosResponse<Response<Contract>>) => response.data)
        .then((response: Response<Contract>) => response.data)
}

const updateContract = (contract: Contract): Promise<Contract | undefined> => {
    return put(`${ENDPOINT}/${contract.id}`, contract)
        .then((response: AxiosResponse<Response<Contract>>) => response.data)
        .then((response: Response<Contract>) => response.data)
}

const finishProbationWorkContract = (contract: Contract): Promise<Contract | undefined> => {
    return put(`${ENDPOINT}/${contract.id}/finish-probation-work`, contract)
        .then((response: AxiosResponse<Response<Contract>>) => response.data)
        .then((response: Response<Contract>) => response.data)
}

const resolveExpiringContract = (contract: Contract): Promise<Contract | undefined> => {
    return put(`${ENDPOINT}/${contract.id}/resolve-expiring-contract`, contract)
        .then((response: AxiosResponse<Response<Contract>>) => response.data)
        .then((response: Response<Contract>) => response.data)
}

const uploadSignedContract = (contract: Contract): Promise<Contract | undefined> => {
    return post(`${ENDPOINT}/${contract.id}/upload-signed`,
        {
            signed_contract_file_id: contract.signed_contract_file_id
        })
        .then((response: AxiosResponse<Response<Contract>>) => response.data)
        .then((response: Response<Contract>) => response as any)
}

const getPrintedContract = (contract: Contract): Promise<ActionQueryResponse> => {
    return axiosInstance.get(`${ENDPOINT}/${contract?.id}/print`)
        .then((response: AxiosResponse<ActionQueryResponse>) => response.data)
}

const deleteContract = (contractId: ID): Promise<void> => {
    return remove(`${ENDPOINT}/${contractId}`).then(() => {
    })
}

export {
    getContracts,
    deleteContract,
    getContractById,
    createContract,
    updateContract,
    finishProbationWorkContract,
    resolveExpiringContract,
    uploadSignedContract,
    getPrintedContract
}
