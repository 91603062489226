import { FC } from "react";
import { UserProfile } from "../UserProfile";
import {Request} from "../../../shared/models/_models";
import { useIntl } from "react-intl";

type Props = {
    request: Request
}

const RequestFormSchemaHeader: FC<Props> = ({request}) => {
    const intl = useIntl()
    const requestCreatedBy = request?.created_by
    const userId = request?.user_id 

    return (
      <div className='d-flex pb-7 mb-7 w-90 border-bottom flex-wrap'>
        {requestCreatedBy === userId ? 
        <>
        <div className='w-50'>
          <div className='text-start fw-bolder fs-7 text-uppercase gs-0'>{intl.formatMessage({id: 'REQUEST.TABLE.SUBMITTER'})}</div>
          <div className='mt-5'>
            <UserProfile request={request} typeUser = {intl.formatMessage({id: 'REQUEST.TABLE.SUBMITTER'})}/>
          </div>
        </div>
        {(request?.user?.immediate_superiors?.length !== 0) && 
        <div className='w-50'>
          <div className='text-start fw-bolder fs-7 text-uppercase gs-0'>{intl.formatMessage({id: 'REQUEST.TABLE.MANAGER'})}</div>
          <div className='mt-5'>
            <UserProfile request={request} typeUser = {intl.formatMessage({id: 'REQUEST.TABLE.MANAGER'})}/>
          </div>
        </div>}
        </>
        :
        <>
          <div className='w-50'>
            <div className='text-start fw-bolder fs-7 text-uppercase gs-0'>{intl.formatMessage({id: 'REQUEST.TABLE.SUBJECT'})}</div>
            <div className='mt-5'>
              {request?.user && <UserProfile request={request} typeUser = {intl.formatMessage({id: 'REQUEST.TABLE.SUBMITTER'})}/>}
            </div>
          </div>
          <div className='w-50'>
            <div className='text-start fw-bolder fs-7 text-uppercase gs-0'>{intl.formatMessage({id: 'REQUEST.TABLE.SUBMITTER'})}</div>
            <div className='mt-5'>
              {request?.user && <UserProfile request={request} typeUser = {intl.formatMessage({id: 'REQUEST.TABLE.SUBJECT'})}/>}
            </div>
          </div>
        </>}
      </div>
    )
  };

  export {RequestFormSchemaHeader}