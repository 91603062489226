import {ListViewProvider, useListView} from '../../modules/apps/shared/list/core/ListViewProvider';
import {QueryRequestProvider} from '../../modules/apps/shared/list/core/QueryRequestProvider';
import {KTCard} from '../../../_metronic/helpers';
import React from 'react';
import {JobPositionsTable} from "./JobPositionsTable";
import {
    JobPositionsListHeader
} from "../../modules/apps/job-position-management/components/header/JobPositionsListHeader";
import {
    QueryResponseProviderJobPositions
} from "../../modules/apps/job-position-management/core/QueryResponseProviderJobPositions";
import {
    JobPositionCreateModal
} from "../../modules/apps/job-position-management/job-position-create-modal/JobPositionCreateModal";
import {DeleteJobPositionModal} from "../../shared/components/DeleteJobPositionModal";

const JobPositionsList = () => {
    const {itemIdForUpdate} = useListView();
    const {itemIdForDelete} = useListView();
    return (
        <>
            <KTCard>
                <JobPositionsListHeader/>
                <JobPositionsTable/>
            </KTCard>
            {itemIdForUpdate !== undefined && <JobPositionCreateModal/>}
            {itemIdForDelete !== undefined && <DeleteJobPositionModal id={itemIdForDelete}/>}
        </>
    )
}

const JobPositionsListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProviderJobPositions>
            <ListViewProvider>
                <JobPositionsList/>
            </ListViewProvider>
        </QueryResponseProviderJobPositions>
    </QueryRequestProvider>
)

export {JobPositionsListWrapper}
