import {AxiosResponse} from 'axios'
import axiosInstance from './axiosInstance'

const get = (endpoint: string, query: string): Promise<AxiosResponse> => {
    return axiosInstance.get(`${endpoint}?${query}`)
}

const post = (endpoint: string, body: any, query: string = '', config = {}): Promise<AxiosResponse> => {
    return axiosInstance.post(`${endpoint}?${query}`, body, config)
}

const put = (endpoint: string, body: any, query: string = ''): Promise<AxiosResponse> => {
    return axiosInstance.put(`${endpoint}?${query}`, body)
}

const remove = (endpoint: string, query: string = ''): Promise<AxiosResponse> => {
    return axiosInstance.delete(`${endpoint}?${query}`)
}

const exportFile = (endpoint: string): Promise<AxiosResponse> => {
    return axiosInstance.get(`${endpoint}`, {responseType: 'blob'})
}

export {get, post, put, remove, exportFile}
