import {FC, useEffect} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {JSONSchema6} from "json-schema";
import './requestPreviewCss.css';
import { KTCardBody } from '../../../../_metronic/helpers';
import {Request} from "../../../shared/models/_models";
import { jsonDefaultSchema } from '../jsonSchemaAccessories/jsonDefaultSchema';
import { useIntl } from 'react-intl';
import { AprovalSchema } from './AprovalSchema';

type Props = {
    request: Request
    openModal: any
}

const ApprovalRequestPreview: FC<Props> = ({request, openModal}) => {
    const schema: JSONSchema6 = request?.request_type?.approval_json_schema ? JSON.parse(request?.request_type?.approval_json_schema) : jsonDefaultSchema
    const intl = useIntl()    

    useEffect(() => {
        !openModal && Array.from(document.querySelectorAll('input[type=text]')).forEach(function(item: any) {
            var text = document.createElement('label');
            text.setAttribute('class', "textLabel");
            text.innerText = item.value
            item.parentNode.replaceChild(text, item);
         });

         !openModal && Array.from(document.querySelectorAll('.control-label')).forEach(function(item: any) {
            item?.parentElement?.parentElement?.classList.add('ps-2')          
         });
         document.querySelector('.form-group.field.field-null')?.parentElement?.parentElement?.classList.add('d-none')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema])

    return (
         <KTCardBody>
            <div className='card'>
                <div className='card-body pt-0'>
                    <div className='text-start fw-bolder fs-7 text-uppercase gs-0'>{intl.formatMessage({id: 'REQUEST.SOLUTION'})}</div>
                    {request?.previous_action_json_schemas?.map((item: any, index: any) => (  
                        <AprovalSchema key={index} schema={JSON.parse(item)} schemaData={request?.approval_form_data}/>
                    ))}
                </div>
            </div>
        </KTCardBody>
    )
}

export {ApprovalRequestPreview}