/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {ChildrenCreateModalFormWrapper} from './ChildrenCreateModalFormWrapper'
import {KTSVG} from "../../../../../_metronic/helpers";
import {useIntl} from "react-intl";
import {useListView} from "../../shared/list/core/ListViewProvider";
import {ListLoading} from "../../shared/list/components/loading/ListLoading";

type Props = {
    asyncFetch?: any
}

const ChildrenCreateModal: React.FC<Props> = ({asyncFetch}) => {
    const [isLoadingModal, setIsLoadingModal] = useState(true);

    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, []);

    const intl = useIntl();
    const {itemIdForUpdate, setItemIdForUpdate} = useListView();

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='fw-bolder'>{intl.formatMessage({id: 'CHILDREN'})}</h2>
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => setItemIdForUpdate(undefined)}
                                style={{cursor: 'pointer'}}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                            </div>
                        </div>
                        <div className='modal-body'>
                            <ChildrenCreateModalFormWrapper isLoadingModal={isLoadingModal}
                                                            setIsLoadingModal={setIsLoadingModal} asyncFetch={asyncFetch}/>
                        </div>
                    </div>
                </div>
                {isLoadingModal && itemIdForUpdate && <ListLoading/>}
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {ChildrenCreateModal}
