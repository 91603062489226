import React, {useEffect, useState} from 'react'
import {User} from '../../../../shared/models/user'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {AdditionalModalForm} from './AdditionalModalForm'
import {getUserDocumentationByUser} from '../../../../shared/services/file-service'
import {AdditionalDocumentHeader} from '../../../../shared/SelectLists'
import {OverlayTrigger, Tooltip, TooltipProps} from 'react-bootstrap'
import Pagination from 'react-js-pagination'
import {DeleteModalForm} from './DeleteModalForm'
import {SimpleLoading} from '../../../../modules/apps/shared/list/components/loading/SimpleLoading'
import {ScrollTopComponent} from '../../../../../_metronic/assets/ts/components'

type Props = {
  user: User
}

const AdditionalUserDocumentation: React.FC<Props> = ({user}) => {
  const intl = useIntl()
  const [openModal, setOpenModal] = useState(false)
  const [closedWithAction, setClosedWithAction] = useState(false)
  const [userDocuments, setUserDocuments] = useState<any[]>([])
  const [loading, setIsLoading] = useState(false)
  const [deleteModal, openDeleteModal] = useState(false)
  const [deleteFileItem, setDeleteFileItem] = useState()
  const [pagination, setPagination] = useState({} as any)
  const [page, setPage] = useState(1)

  const deleteAttachmentItem = (item: any) => {
    setDeleteFileItem(item)
    openDeleteModal(true)
  }

  const editTooltip = (
    props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>
  ) => <Tooltip {...props}>{intl.formatMessage({id: 'PROFILE.DOCUMENT.DOWNLOAD'})}</Tooltip>

  const deleteTooltip = (
    props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>
  ) => <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.DELETE'})}</Tooltip>

  const asyncFetch = async () => {
    await getUserDocumentationByUser('user_id=' + user?.id + '&page=' + page).then(
      (response: any) => {
        setUserDocuments(response?.data)
        setPagination(response._meta)
        setIsLoading(false)
      }
    )
  }

  function handlePageChange(pageNumber: number) {
    if (pagination?.currentPage !== pageNumber) {
      setIsLoading(true)
      setPage(pageNumber)
      ScrollTopComponent.goTop()
    }
  }

  useEffect(
    () => {
      setIsLoading(true)
      asyncFetch()
      setClosedWithAction(false)
    },
    // eslint-disable-next-line
    [closedWithAction, page]
  )

  return (
    <>
      <div className='card mb-5 mb-xl-10 card-xl-stretch'>
        <div className='card-header border-1 w-100 border-bottom'>
          <div className='card-title m-0 d-flex w-100 d-none d-sm-flex'>
            <h3 className='fw-bolder m-0 w-50'>
              {intl.formatMessage({id: 'ACCOUNT.ADDITIONAL_DOCUMENTATION'})}
            </h3>
            <div className='d-flex w-50 d-flex justify-content-end'>
              <button type='submit' className='btn btn-primary' onClick={() => setOpenModal(true)}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                {intl.formatMessage({id: 'PROFILE.ADD.DOCUMENT'})}
              </button>
            </div>
          </div>
          {!loading ? (
            <div className='card-title m-0 d-flex w-100 d-sm-none'>
              <h3 className='fw-bolder m-0 w-50'>
                {intl.formatMessage({id: 'ACCOUNT.ADDITIONAL_DOCUMENTATION'})}
              </h3>
              <div className='d-flex w-50 d-flex justify-content-end'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  onClick={() => setOpenModal(true)}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                  {intl.formatMessage({id: 'PROFILE.ADD.DOCUMENT'})}
                </button>
              </div>
            </div>
          ) : (
            <div className='d-sm-none pt-5 w-100'>
              <SimpleLoading />
            </div>
          )}
        </div>
        {!loading ? (
          <div className='table-responsive p-10 pt-1'>
            {userDocuments?.length > 0 ? (
              <>
                <table
                  id='kt_table_users'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                  style={{tableLayout: 'fixed'}}
                >
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                      {AdditionalDocumentHeader.map((value, key) => (
                        <th key={key}>{value.label}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {userDocuments?.map((value, key) => (
                      <tr key={key}>
                        <td>
                          {/*eslint-disable-next-line*/}
                          <a
                            href={value?.file?.url}
                            className='text-end justify-content-end border-0 fs-7 fw-bold'
                            target='_blank'
                          >
                            {value?.file?.original_name}
                          </a>
                        </td>
                        <td className='ps-5'>{value?.comment ? value?.comment : '-'}</td>
                        <td>
                          <div className='d-flex justify-content-end'>
                            <OverlayTrigger placement='top' overlay={editTooltip}>
                              <button
                                className='ms-2 btn btn-icon btn-bg-light btn-active-color-primary btn-sm px-1'
                                onClick={() => window.open(`${value?.file?.url}`, `_blank`)}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr044.svg'
                                  className='svg-icon-1'
                                />
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger placement='top' overlay={deleteTooltip}>
                              <button
                                className='ms-2 btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                onClick={() => deleteAttachmentItem(value)}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-1'
                                />
                              </button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className='position-absolute bottom-0 start-50 translate-middle w-100'>
                  <div className='col-12 d-flex align-items-center justify-content-center'>
                    <div className='mb-md-0 mb-2' id='kt_table_users_paginate'>
                      {pagination?.totalCount > 10 && (
                        <Pagination
                          activePage={pagination?.currentPage}
                          hideFirstLastPages={true}
                          innerClass='pagination'
                          activeClass='active'
                          activeLinkClass='page-link'
                          itemClass='page-item'
                          itemClassFirst='page-item'
                          itemClassPrev='page-item'
                          itemClassNext='page-item'
                          itemClassLast='page-item'
                          linkClass='page-link'
                          linkClassFirst='page-link bg-light'
                          linkClassPrev='page-link bg-light'
                          linkClassNext='page-link bg-light'
                          linkClassLast='page-link bg-light'
                          disabledClass='disabled'
                          itemsCountPerPage={pagination?.perPage}
                          totalItemsCount={pagination?.totalCount}
                          pageRangeDisplayed={5}
                          onChange={handlePageChange.bind(this)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='pt-5 d-flex justify-content-center'>
                <span className='text-gray-800 fs-6'>
                  {intl.formatMessage({id: 'TABLE.NOT_FOUND'})}
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className='d-none d-sm-flex position-absolute top-50 start-50 translate-middle'>
            <SimpleLoading />
          </div>
        )}
      </div>
      {deleteModal && (
        <DeleteModalForm
          item={deleteFileItem}
          openDeleteModal={openDeleteModal}
          asyncFetch={asyncFetch}
          setClosedWithAction={setClosedWithAction}
        />
      )}
      {openModal && (
        <AdditionalModalForm
          setOpenModal={setOpenModal}
          user={user}
          asyncFetch={asyncFetch}
          setClosedWithAction={setClosedWithAction}
        />
      )}
    </>
  )
}

export {AdditionalUserDocumentation}
