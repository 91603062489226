import clsx from 'clsx'
import {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'
import {Department} from '../../../../../shared/models/department'

type Props = {
    className?: string
    title?: string
    tableProps: PropsWithChildren<HeaderProps<Department>>
}
const DepartmentCustomHeader: FC<Props> = ({className, title, tableProps}) => {
/*    const id = tableProps.column.id
    const {state, updateState} = useQueryRequest()

    const isSelectedForSorting = useMemo(() => {
        return state.sort && state.sort === id
    }, [state, id])*/

 /*   const sortColumn = () => {
        // avoid sorting for these columns
        if (id === 'actions' || id === 'selection') {
            return
        }

        if (!isSelectedForSorting) {
            // enable sort asc
            updateState({sort: id, ...initialQueryState})
            return
        }

        if (isSelectedForSorting) {
            if (false) {
                // enable sort desc
                updateState({sort: id, ...initialQueryState})
                return
            }

            // disable sort
            updateState({sort: undefined, ...initialQueryState})
        }
    }*/

    return (
        <th
            {...tableProps.column.getHeaderProps()}
            className={clsx(
                className,
               /* isSelectedForSorting && `table-sort-`*/
            )}
            /*style={{cursor: 'pointer'}}*/
            /*onClick={sortColumn}*/
        >
            {title}
        </th>
    )
}

export {DepartmentCustomHeader}
