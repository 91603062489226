import {FC} from 'react'
import {Request} from '../../../../shared/models/_models'
import {Link} from 'react-router-dom'
import {AccessHelper} from '../../../../shared/helper/AccessHelper'

type Props = {
  request: Request
}
const RequestUserCell: FC<Props> = ({request}) => {
  const checkUserSupervisor = (item: any) => {
    if (request?.user && AccessHelper(request?.user)) {
      return (
        <Link
          to={`/user/${request.user_id}/details/business/view`}
          className='text-dark text-hover-primary fs-6 fw-bold text-gray-800'
        >
          {request?.user?.first_name} {request?.user?.last_name}
        </Link>
      )
    } else {
      return (
        <div className='text-dark fs-6 fw-bold text-gray-800'>
          {request?.user?.first_name} {request?.user?.last_name}
        </div>
      )
    }
  }

  return (
    <div className='d-flex flex-column justify-content-center'>
      {checkUserSupervisor(request?.user)}
      <span className='text-muted fw-semibold text-sm-left fs-6'>
        {request?.user?.job_position?.name}
      </span>
      {request.user_id !== request.created_by && (
        <div>
          <span className='text-gray-500 fs-6 pe-1'>Kreiran od strane:</span>
          <span className='text-gray-700 fs-6 pe-1'>{request?.created_by_user}</span>
        </div>
      )}
    </div>
  )
}
export {RequestUserCell}
