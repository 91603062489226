import {get, post, put, remove} from './api-client'
import {AxiosResponse} from 'axios'

const ENDPOINT = `/vacation`

const getVacations = (query: string): Promise<any> => {
  return get(`${ENDPOINT}`, query).then((response: AxiosResponse<any>) => response.data)
}

const updateVacation = (vacation: any, vacation_id: any): Promise<any | undefined> => {
  return put(`${ENDPOINT}/${vacation_id}`, vacation).then(
    (response: AxiosResponse<any>) => response.data
  )
}

const createVacation = (vacation: any, user_id: any): Promise<any | undefined> => {
  return post(`${ENDPOINT}/${user_id}`, vacation).then(
    (response: AxiosResponse<any>) => response.data
  )
}

const deleteVacation = (vacation_id: any): Promise<any | undefined> => {
  return remove(`${ENDPOINT}/${vacation_id}`).then(() => {})
}

export {getVacations, updateVacation, createVacation, deleteVacation}
