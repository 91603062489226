import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Department} from "../../../shared/models/department";
import {DepartmentHeader} from "./DepartmentHeader";
import {getDepartmentById} from "../../../shared/services/department-service";
import {DepartmentList} from "./DepartmentList";
import DepartmentChildList from "./DepartmentChildList";

const DepartmentPage: React.FC = () => {
    const departmentId = useParams().id;
    const [department, setDepartment] = useState({} as Department);

    useEffect(() => {
            getDepartmentById(departmentId).then((response) => {
                if (response) {
                    setDepartment(response);
                }
            })
        },
        // eslint-disable-next-line
        [departmentId]);

    return (
        <>
            <div className='card' id='kt_department_details_view'>
                <DepartmentHeader department={department}/>
                <DepartmentList departmentId={departmentId}/>
            </div>
            {!department?.parent_id ? <DepartmentChildList departmentId={departmentId}/> : ''}
        </>
    )
}

export default DepartmentPage;
