import React, {useState} from 'react';
import * as Yup from 'yup';
import {getIn, useFormik} from 'formik';
import {useListView} from '../../../shared/list/core/ListViewProvider';
import {useQueryResponse} from '../core/QueryResponseProviderUsers';
import {useIntl} from 'react-intl';
import {createUser} from '../../../../../shared/services/user-service';
import {NotificationMessage} from "../../../../../shared/helper/Notification";
import {useNavigate} from 'react-router-dom';
import Select from "react-select";
import {useQuery} from "react-query";
import {getJobPositions} from "../../../../../shared/services/job-position-service";
import {RoutesHelper} from "../../../../../shared/helper/RoutesHelper";

const UserCreateModalForm = () => {
    const intl = useIntl();
    const {setItemIdForUpdate} = useListView();
    const {refetch} = useQueryResponse();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const technical = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.TECHNICAL'})
    const commercial = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.COMMERCIAL'})
    const global = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.GLOBAL'})
    const sector = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.POSITION_SECTOR'})

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdate(undefined)
    }

    const newUserSchema = Yup.object().shape({
        first_name: Yup.string()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'})),
        last_name: Yup.string()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'})),
        email: Yup.string()
            .email(intl.formatMessage({id: 'WRONG_EMAIL'}))
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'})),
        job_position_id: Yup.number()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .nullable(),
    });

    const {data: job_positions} = useQuery(
        `job_positions`,
        () => {
            return getJobPositions('list=true')
        },
        {
            cacheTime: 0,
            enabled: true,
            onError: (err) => {
                console.error(err)
            },
        }
    )

    const jobPositionsList = job_positions?.data
        ?.filter((job_position) =>
            job_position.we_level ? job_position?.we_level >= (RoutesHelper(['admin', 'hr']) ? 'WE1' : RoutesHelper(['hr1']) ? 'WE4' : 'WE5') : '')
        .map((item) => ({
            value: item.id,
            label:
                item?.name +
                ',' +
                item.department?.name +
                ',' +
                item.department?.parent?.name +
                ',' +
                (item.sector === 'technical'
                    ? ' ' + technical + '' + sector
                    : item.sector === 'commercial'
                        ? ' ' + commercial + '' + sector
                        : item.sector === 'global'
                            ? ' ' + global + '' + sector
                            : ''),
            job_position: item,
        }))

    const formatJobPositionOptionLabel = (data: { value: any; label: any; job_position: any }) => {
        return (
            <div className={'d-flex row'}>
                <label className={'text-gray-800 fw-bold'}>{data?.label?.split(',', 1)[0]}</label>
                <label className={'fs-6'}>
                    {data.job_position?.department ? data.job_position?.department?.name : ''}
                    {data.job_position?.department && data.job_position?.department?.parent
                        ? ' - ' + data.job_position?.department?.parent?.name
                        : ''}
                    {(data.job_position?.department || data.job_position?.department?.parent?.name) &&
                    data.job_position?.sector
                        ? ' - '
                        : ''}
                    {data.job_position &&
                        (data.job_position?.sector === 'technical'
                            ? ' ' + technical + '' + sector
                            : data.job_position?.sector === 'commercial'
                                ? ' ' + commercial + '' + sector
                                : data.job_position?.sector === 'global'
                                    ? ' ' + global + '' + sector
                                    : '')}
                    {!data.job_position &&
                        (data.job_position?.sector === 'technical'
                            ? technical + '' + sector
                            : data.job_position?.sector === 'commercial'
                                ? commercial + '' + sector
                                : data.job_position?.sector === 'global'
                                    ? global + '' + sector
                                    : '')}
                </label>
            </div>
        )
    }

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            job_position_id: null
        },
        validationSchema: newUserSchema,
        onSubmit: async (values) => {
            setLoading(true);

            createUser(values).then((response) => {
                if (response) {
                    navigate(`/user/${response?.id}/details/personal/view`)
                    cancel(true);
                    NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.USER_IS_SUCCESSFULLY_ADDED'}), '', 'success');
                } else {
                    setLoading(false);
                    NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.CREATION_FAILED'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHEN_CREATING_A_NEW_EMPLOYEE!_PLEASE_TRY_AGAIN.'}), 'danger');
                }
            })
        },
    })

    return (
        <>
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                <div
                    className='row'
                    id='kt_modal_add_user_scroll'>
                    <div className='col-12 mb-7'>
                        <label
                            className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.FIRST_NAME'})}</label>
                        <input
                            placeholder={intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.FIRST_NAME'})}
                            {...formik.getFieldProps('first_name')}
                            type='text'
                            name='first_name'
                            className={formik.errors.first_name && formik.touched.first_name ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger' : 'form-control form-control-solid mb-3 mb-lg-0'}
                            autoComplete='off'
                        />
                        {formik.touched.first_name && formik.errors.first_name && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{getIn(formik.errors, 'first_name')}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='col-12 mb-7'>
                        <label
                            className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.LAST_NAME'})}</label>
                        <input
                            placeholder={intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.LAST_NAME'})}
                            {...formik.getFieldProps('last_name')}
                            type='text'
                            name='last_name'
                            className={formik.errors.last_name && formik.touched.last_name ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger' : 'form-control form-control-solid mb-3 mb-lg-0'}
                            autoComplete='off'
                        />
                        {formik.touched.last_name && formik.errors.last_name && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{getIn(formik.errors, 'last_name')}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='col-12 mb-7'>
                        <label
                            className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.EMAIL'})}</label>
                        <input
                            placeholder={intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.EMAIL'})}
                            {...formik.getFieldProps('email')}
                            className={formik.errors.email && formik.touched.email ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger' : 'form-control form-control-solid mb-3 mb-lg-0'}
                            type='email'
                            name='email'
                            autoComplete='off'
                        />
                        {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{getIn(formik.errors, 'email')}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='col-12 mb-7'>
                        <label
                            className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.POSITION'})}</label>
                        <Select
                            placeholder={intl.formatMessage({
                                id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.SELECT_POSITION',
                            })}
                            name='job_position_id'
                            className={
                                getIn(formik.errors, 'job_position_id') &&
                                getIn(formik.touched, 'job_position_id')
                                    ? 'mb-3 mb-lg-0 border border-danger rounded'
                                    : 'mb-3 mb-lg-0'
                            }
                            classNamePrefix='select'
                            options={jobPositionsList}
                            noOptionsMessage={() => intl.formatMessage({id: 'NO_DATA'})}
                            formatOptionLabel={formatJobPositionOptionLabel}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    neutral50:
                                        localStorage.getItem('kt_theme_mode_menu') === 'dark'
                                            ? '#495674'
                                            : '#a1a5b7',
                                },
                            })}
                            onChange={(newValue: any) => {
                                formik.setFieldValue('job_position_id', newValue.value)
                            }}
                            menuPortalTarget={document.body}
                        />
                        {getIn(formik.errors, 'job_position_id') &&
                            getIn(formik.touched, 'job_position_id') && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'job_position_id')}</span>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
                <div className='col-12'>
                    <button
                        type='submit'
                        className='btn btn-primary float-end'
                        data-kt-users-modal-action='submit'
                        disabled={loading}
                    >
                        {!loading && intl.formatMessage({id: 'BUTTON.SUBMIT'})}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                    {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                        )}
                    </button>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3 float-end'
                        data-kt-users-modal-action='cancel'
                        disabled={loading}
                    >
                        {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                    </button>
                </div>
            </form>
        </>
    )
}

export {UserCreateModalForm}
