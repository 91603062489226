import {FC} from 'react'
import {FamilyMember} from "../../../../shared/models/family_member";


type Props = {
    familyMember: FamilyMember
}

const ChildrenInfoCell: FC<Props> = ({familyMember}) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <div className='text-gray-800 mb-1'>
                {familyMember?.first_name} {familyMember?.last_name}
            </div>
        </div>
    </div>
)

export {ChildrenInfoCell}
