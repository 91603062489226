import React from 'react';
import {useIntl} from "react-intl";
import {Department} from "../../../shared/models/department";
import {useNavigate} from "react-router-dom";

type Props = {
    department: Department
}

const DepartmentHeader: React.FC<Props> = ({department}) => {
    const navigate = useNavigate();
    const intl = useIntl()
    const technical = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.TECHNICAL'});
    const commercial = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.COMMERCIAL'});
    const global = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.GLOBAL'});

    const navigateToParent = () => {
        navigate(`/department/${department?.parent_id}/overview`);
    }
    const hr = (department.name === intl.formatMessage({id: 'DEPARTMENT.HUMAN.RESOURCES.DEPARTMENT'}))
        return (
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-1'>
                    <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap'>
                            <div className='d-flex flex-column'>
                                {hr && <div className='d-flex align-items-center mb-2'>
                                    <h1 className='text-gray-800 fs-2 fw-bolder me-1'>
                                        HR
                                    </h1>
                                </div>
                                }
                                {!hr && <div className='d-flex align-items-center mb-2'>
                                    <h1 className='text-gray-800 fs-2 fw-bolder me-1'>
                                        {department.name}
                                    </h1>
                                </div>}
                                <div className='d-flex flex-wrap fw-bold fs-6 pe-2'>
                                    <label
                                        className={department?.parent?.name ? 'd-flex align-items-center text-gray-400 me-5' : 'd-none'}>
                                    <span className='text-dark text-hover-primary cursor-pointer'
                                          onClick={navigateToParent}>{department?.parent?.name}</span>
                                    </label>
                                    <label
                                        className={department?.sector ? 'd-flex align-items-center text-gray-400 me-5' : 'd-none'}>
                                        {department?.sector === 'technical' ? technical : department?.sector === 'commercial' ?
                                            commercial : department?.sector === 'global' ? global : 'N/A'
                                        } {intl.formatMessage({id: 'DEPARTMENT.HEADER'})}
                                    </label>
                                    <label
                                        className={department?.code ? 'd-flex align-items-center text-gray-400 me-5' : 'd-none'}>
                                        {department?.code || ''}
                                    </label>
                                    <label
                                        className={department?.we_level ? 'd-flex align-items-center text-gray-400 me-5 ' : 'd-none'}>
                                        {department?.we_level}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export {DepartmentHeader}
