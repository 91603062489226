import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { getContracts } from "../../../shared/services/contract-service";
import { Contract } from "../../../shared/models/contract";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { ContractStatusHelper } from "../../../shared/helper/ContractStatusHelper";
import Pagination from "react-js-pagination";
import { SimpleLoading } from "../../../modules/apps/shared/list/components/loading/SimpleLoading";
import { ScrollTopComponent } from "../../../../_metronic/assets/ts/components";

const HRContractDashboard: React.FC = () => {
    const intl = useIntl();

    const navigate = useNavigate();
    const allowedWeLevels = ['WE1', 'WE2'];
    const allowedRoles = ['hr', 'hr1', 'hr2', 'admin'];
    const currentUser = JSON.parse(localStorage.getItem('current_user') || '');
    const userPermission =
        !!(allowedWeLevels.includes(currentUser?.job_position?.we_level)) ||
        !!(currentUser?.roles?.filter((item: any) => (allowedRoles.includes(item)))?.length > 0);
    const [contracts, setContracts] = useState([] as Contract[] | undefined);
    const [pagination, setPagination] = useState({} as any);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);

    useEffect(() => {
        getContracts('is_resolved=0&expiring=true&page=' + page).then(response => {
            setContracts(response?.data);
            setPagination((response._meta));
            setLoading(false);
        })
    },
        [page]);

    function redirectToContract(contract: any) {
        if (userPermission) {
            navigate(`/contract/${contract?.id}/overview`);
        }
    }

    function handlePageChange(pageNumber: number) {
        if (pagination?.currentPage !== pageNumber) {
            setLoading(true);
            setPage(pageNumber);
            ScrollTopComponent.goTop();
        }
    }

    function navigateToContracts() {
        navigate(`/contract`)
    }

    return (
        <>
            <div className='card card-xxl-stretch-50 mb-5 mb-xl-8'>
                <div className='card-header border-bottom border-2'>
                    <h3 className='card-title d-flex align-items-center text-dark fw-bold'>
                        {intl.formatMessage({ id: 'DASHBOARD.HR.CONTRACT.HEADER' })}
                    </h3>
                    <span
                        className='fs-6 text-dark fw-bold opacity-75 d-flex align-items-center justify-content-end'>{pagination?.totalCount ? (pagination?.totalCount % 10 === 1 ? (pagination?.totalCount + intl.formatMessage({ id: 'DASHBOARD.HR.CONTRACT' })) : (pagination?.totalCount + intl.formatMessage({ id: 'DASHBOARD.HR.CONTRACTS' }))) : ''}</span>
                </div>
                <div className='card-body pt-2'>
                    {!loading ? (contracts && contracts?.length > 0 ? contracts?.map((contract) => {
                        let contractStatus = ContractStatusHelper.calculate(contract);

                        return (
                            <div className='row mt-2 bg-hover-light-primary rounded py-2 px-1' key={contract?.id}
                                onClick={() => redirectToContract(contract)}>
                                <div className='col-sm-4 col-6 col-md-4 col-lg-4 d-flex  align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <span
                                            className={`bullet bullet-vertical h-40px me-3 bg-${contractStatus?.status}`}
                                        ></span>
                                        <div className='flex-grow-1'>
                                            <span
                                                className='text-gray-800 fw-bold d-block'>
                                                {contract?.user?.first_name + ' ' + contract?.user?.last_name}
                                            </span>
                                            <span className='text-gray-800 fw-semibold fs-6'>
                                                {contract?.code}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-4 col-6 col-md-4 col-lg-4 d-flex  align-items-center justify-content-end justify-content-sm-end justify-content-md-center
                                     justify-content-lg-center'>
                                    <div className=''>
                                        <div>
                                            <span
                                                className='text-gray-800 fw-bold fs-6 d-block'>
                                                {contract?.type === 'undefined' && intl.formatMessage({ id: 'CONTRACT.TYPE.UNDEFINED' })}
                                                {contract?.type === 'defined' && intl.formatMessage({ id: 'CONTRACT.TYPE.DEFINED' })}
                                            </span>
                                            <span className='text-gray-800 fw-semibold fs-6'>
                                                {moment(contract?.end_date).format('DD.MM.YYYY')}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div
                                    className='col-sm-4 col-12 col-md-4 col-lg-4 d-flex align-items-center justify-content-start justify-content-sm-end justify-content-md-end justify-content-lg-end mt-4 mt-sm-0 mt-md-0 md-lg-0'>
                                    <span
                                        className={`badge fs-7 fw-bold badge-light-${contractStatus?.status} `}
                                    >
                                        {contractStatus?.days > 45 && intl.formatMessage({ id: 'CONTRACT.STATUS.ACTIVE' })}
                                        {contractStatus?.days <= 45 && contractStatus?.days > 0 && intl.formatMessage({ id: 'CONTRACT.STATUS.EXPIRED_FOR' }) + contractStatus.days + ((contractStatus?.days.toString().charAt(contractStatus?.days.toString().length - 1) === '1' && contractStatus?.days?.toString().charAt(contractStatus?.days?.toString()?.length - 2) !== '1') ? intl.formatMessage({ id: 'CONTRACT.STATUS.EXPIRED_DAY' }) : intl.formatMessage({ id: 'CONTRACT.STATUS.EXPIRED_DAYS' }))}
                                        {contractStatus?.days < 1 && intl.formatMessage({ id: 'CONTRACT.STATUS.EXPIRED' })}
                                    </span>
                                </div>
                            </div>
                        )
                    }
                    ) : (
                        <div className='pt-5'>
                            <span className='text-gray-800 fw-bold fs-6'>
                                {intl.formatMessage({ id: 'DASHBOARD.CONTRACT.INFO.NOT_FOUND' })}
                            </span>
                        </div>
                    )) : <div className='py-3'><SimpleLoading /></div>}
                    <div className='row mt-4'>
                        <div
                            className='col-7 col-md-7 col-sm-7 col-lg-7 d-flex align-items-center'>
                            <div className='mb-md-0 mb-2' id='kt_table_users_paginate'>
                                {pagination?.totalCount > 10 && <Pagination
                                    activePage={pagination?.currentPage}
                                    hideFirstLastPages={true}
                                    innerClass='pagination'
                                    activeClass='active'
                                    activeLinkClass='page-link'
                                    itemClass='page-item'
                                    itemClassFirst='page-item'
                                    itemClassPrev='page-item'
                                    itemClassNext='page-item'
                                    itemClassLast='page-item'
                                    linkClass='page-link'
                                    linkClassFirst='page-link bg-light'
                                    linkClassPrev='page-link bg-light'
                                    linkClassNext='page-link bg-light'
                                    linkClassLast='page-link bg-light'
                                    disabledClass='disabled'
                                    itemsCountPerPage={pagination?.perPage}
                                    totalItemsCount={pagination?.totalCount}
                                    pageRangeDisplayed={3}
                                    onChange={handlePageChange.bind(this)}
                                />}
                            </div>
                        </div>
                        {contracts && contracts.length > 0 && (userPermission) &&
                            <div className='col-5 col-md-5 col-sm-5 col-lg-5 mb-md-0 mb-2 '>
                                <button className='btn btn-primary btn-sm btn-active-light-primary float-end'
                                    onClick={navigateToContracts}>
                                    {intl.formatMessage({ id: 'DASHBOARD.REQUEST.INFO.SEE_ALL' })}
                                    <i className="ms-1 fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export { HRContractDashboard }
