import React, {useEffect, useState} from 'react';
import {KTSVG} from "../../../../_metronic/helpers";
import {getRequest} from "../../../shared/services/request-service";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {Request} from "../../../shared/models/request";
import {logoutCurrentUser} from "../../../modules/auth";
import {SimpleLoading} from "../../../modules/apps/shared/list/components/loading/SimpleLoading";
import Pagination from "react-js-pagination";
import { RequestInfoField } from './RequestInfoField';
import {ScrollTopComponent} from "../../../../_metronic/assets/ts/components";
import { RequestCreateModal } from '../../../modules/apps/request-menagement/components/modal/RequestCreateModal';

const RequestInfoDashboard: React.FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [requests, setRequests] = useState([] as Request[] | undefined);
    const [pagination, setPagination] = useState({} as any);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);

    let current_user = JSON.parse(localStorage.getItem('current_user') || logoutCurrentUser() as unknown as string);

    useEffect(() => {
            if (current_user.job_position_id) {
                getRequest('created_by=' + current_user.id + '&page=' + page).then(response => {
                    setRequests(response?.data);
                    setPagination((response._meta));
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        },
        // eslint-disable-next-line
        [page]);

    function handlePageChange(pageNumber: number) {
        if (pagination?.currentPage !== pageNumber) {
            setLoading(true);
            setPage(pageNumber);
            ScrollTopComponent.goTop();
        }
    }

    function goToRequest(request: any) {
        if (request?.status === 'new') {
            navigate(`/request/${request?.id}/edit`);
        } else {
            navigate(`/request/${request?.id}/overview`);
        }
    }

    return (
        <>
            <div className='card card-xxl-stretch-50 mb-5 mb-xl-8'>
                <div className='card-header border-bottom border-2'>
                    <h3 className='card-title fw-bold text-dark'>
                        {intl.formatMessage({id: 'MY_REQUESTS'})}
                    </h3>
                    <div className='card-toolbar'>
                        <button type='button' onClick={() => setOpenCreateModal(true)}
                                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'>
                            <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2x'/>
                        </button>
                    </div>
                </div>
                <div className='card-body pt-2'>
                    {!loading ? (requests && requests?.length > 0 ? requests?.map((request) =>
                        <div className='d-flex align-items-center mt-2 bg-hover-light-primary rounded px-1'
                             key={request?.id} onClick={() => goToRequest(request)}
                             style={{paddingTop: '7px', paddingBottom: '7.5px'}}>
                            <RequestInfoField request={request} />
                        </div>
                    ) : (
                        <div className='pt-5'>
                            <span className='text-gray-800 fw-bold fs-6'>
                                {intl.formatMessage({id: 'DASHBOARD.REQUEST.INFO.NOT_FOUND'})}
                            </span>
                        </div>
                    )) : <div className='py-3'><SimpleLoading/></div>}
                    <div className='row mt-4'>
                        <div
                            className='col-12 d-flex align-items-center justify-content-center'>
                            <div className='mb-md-0 mb-2' id='kt_table_users_paginate'>
                                {pagination?.totalCount > 10 && <Pagination
                                    activePage={pagination?.currentPage}
                                    hideFirstLastPages={true}
                                    innerClass='pagination'
                                    activeClass='active'
                                    activeLinkClass='page-link'
                                    itemClass='page-item'
                                    itemClassFirst='page-item'
                                    itemClassPrev='page-item'
                                    itemClassNext='page-item'
                                    itemClassLast='page-item'
                                    linkClass='page-link'
                                    linkClassFirst='page-link bg-light'
                                    linkClassPrev='page-link bg-light'
                                    linkClassNext='page-link bg-light'
                                    linkClassLast='page-link bg-light'
                                    disabledClass='disabled'
                                    itemsCountPerPage={pagination?.perPage}
                                    totalItemsCount={pagination?.totalCount}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange.bind(this)}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
                {openCreateModal && <RequestCreateModal setOpenCreateModal={setOpenCreateModal}/>}
            </div>
        </>
    )
}

export {RequestInfoDashboard}
