import {useEffect, useState} from 'react'
import {ListLoading} from "../../shared/list/components/loading/ListLoading";
import {JobPositionCreateModalFormWrapper} from "./JobPositionCreateModalFormWrapper";
import {useIntl} from "react-intl";
import {useListView} from "../../shared/list/core/ListViewProvider";
import {KTSVG} from "../../../../../_metronic/helpers";

const JobPositionCreateModal = () => {
    const [isLoadingModal, setIsLoadingModal] = useState(true);
    const intl = useIntl()
    const {itemIdForUpdate, setItemIdForUpdate} = useListView()

    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='fw-bolder'>{itemIdForUpdate ? intl.formatMessage({id: 'JOB_POSITION.EDIT_HEADER'}) : intl.formatMessage({id: 'JOB_POSITION.NEW_HEADER'})}</h2>
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => setItemIdForUpdate(undefined)}
                                style={{cursor: 'pointer'}}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                            </div>
                        </div>
                        <div className='modal-body scroll-y'>
                            <JobPositionCreateModalFormWrapper isLoadingModal={isLoadingModal}
                                                               setIsLoadingModal={setIsLoadingModal}/>
                        </div>
                    </div>
                </div>
                {isLoadingModal && <ListLoading/>}
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {JobPositionCreateModal}
