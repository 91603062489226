import {useIntl} from "react-intl"
import {Contract} from "../models/_models"
import React from "react";
import {ContractStatusHelper} from "./ContractStatusHelper";

const ContractDetailedHelper = (contract: Contract, loading: boolean) => {
    const intl = useIntl();
    let contractStatus = ContractStatusHelper.calculate(contract);

    return (
        <>
            {!contract.is_resolved ? <>
                {!loading && contractStatus.days > 45 && contract.status !== 'expired' && <span
                    className={`ms-lg-3 ms-sm-0 ms-md-0 ms-0 mb-lg-0 mb-sm-2 mb-md-2 mb-2 notice d-flex bg-light-${contractStatus.status} rounded border-${contractStatus.status} border border-dashed px-2 py-1`}>
                            <span
                                className={`fs-8 text-${contractStatus.status}`}>{intl.formatMessage({id: 'CONTRACT.STATUS.ACTIVE'})}</span>
                        </span>}
                {!loading && contractStatus.days > 45 && contract.status === 'expired' && <span
                    className={`ms-lg-3 ms-sm-0 ms-md-0 ms-0 mb-lg-0 mb-sm-2 mb-md-2 mb-2 notice d-flex bg-light-${contractStatus.status} rounded border-${contractStatus.status} border border-dashed px-2 py-1`}>
                            <span
                                className={`fs-8 text-${contractStatus.status}`}>{intl.formatMessage({id: 'CONTRACT.STATUS.FINISHED'})}</span>
                        </span>}
                {!loading && contractStatus.days <= 45 && contractStatus.days > 0 && contract.status !== 'expired' &&
                    <span
                        className={`ms-lg-3 ms-sm-0 ms-md-0 ms-0 mb-lg-0 mb-sm-2 mb-md-2 mb-2 notice d-flex bg-light-${contractStatus.status} rounded border-${contractStatus.status} border border-dashed px-2 py-1`}>
                            <span
                                className={`fs-8 text-${contractStatus.status}`}>{intl.formatMessage({id: 'CONTRACT.STATUS.EXPIRED_FOR'}) + contractStatus?.days + ((contractStatus?.days.toString().charAt(contractStatus?.days.toString().length - 1) === '1' && contractStatus?.days?.toString().charAt(contractStatus?.days?.toString()?.length - 2) !== '1') ? intl.formatMessage({id: 'CONTRACT.STATUS.EXPIRED_DAY'}) : intl.formatMessage({id: 'CONTRACT.STATUS.EXPIRED_DAYS'}))}</span>
                        </span>}
                {!loading && contractStatus.days <= 45 && contractStatus.days > 0 && contract.status === 'expired' &&
                    <span
                        className={`ms-lg-3 ms-sm-0 ms-md-0 ms-0 mb-lg-0 mb-sm-2 mb-md-2 mb-2 notice d-flex bg-light-${contractStatus.status} rounded border-${contractStatus.status} border border-dashed px-2 py-1`}>
                            <span
                                className={`fs-8 text-${contractStatus.status}`}>{intl.formatMessage({id: 'CONTRACT.STATUS.FINISHED'})}</span>
                        </span>}
                {!loading && contractStatus.days <= 0 && <span
                    className={`ms-lg-3 ms-sm-0 ms-md-0 ms-0 mb-lg-0 mb-sm-2 mb-md-2 mb-2 notice d-flex bg-light-${contractStatus.status} rounded border-${contractStatus.status} border border-dashed px-2 py-1`}>
                            <span
                                className={`fs-8 text-${contractStatus.status}`}>{intl.formatMessage({id: 'CONTRACT.STATUS.EXPIRED'})}</span>
                        </span>}
            </> : <>
                {!loading && contract.status !== 'expired' && <span
                    className={`ms-lg-3 ms-sm-0 ms-md-0 ms-0 mb-lg-0 mb-sm-2 mb-md-2 mb-2 notice d-flex bg-light-success rounded border-success border border-dashed px-2 py-1`}>
                            <span
                                className={`fs-8 text-success`}>{intl.formatMessage({id: 'CONTRACT.STATUS.ACTIVE'})}</span>
                        </span>}
                {!loading && (contractStatus.days <= 0 || contract.status === 'expired') && <span
                    className={`ms-lg-3 ms-sm-0 ms-md-0 ms-0 mb-lg-0 mb-sm-2 mb-md-2 mb-2 notice d-flex bg-light-danger rounded border-danger border border-dashed px-2 py-1`}>
                            <span
                                className={`fs-8 text-danger`}>{intl.formatMessage({id: 'CONTRACT.STATUS.EXPIRED'})}</span>
                        </span>}
            </>}
        </>
    )
}
export {ContractDetailedHelper}
