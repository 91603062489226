import React from "react";

const outStyle = {display: 'flex', justifyContent: 'center', alignItems: 'center'};
const inStyle = {display: 'flex', justifyContent: 'space-between', alignItems: 'center'};
const elementStyle = {display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center'}

const ListLoading = () => {
    return (
        <>
            <div
                className='modal fade show d-block m-0 p-0'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered mw-200px m-auto'
                     style={outStyle}>
                    <div style={{backgroundColor: 'transparent'}}>
                        <div className="m-auto border-0"
                             style={inStyle}>
                            <h3 className="modal-title">
                                <div style={elementStyle}>
                                    <div className="loading-back">
                                        <div className="loading-dot loading-one"></div>
                                    </div>
                                    <div className="loading-back">
                                        <div className="loading-dot loading-two"></div>
                                    </div>
                                    <div className="loading-back">
                                        <div className="loading-dot loading-three"></div>
                                    </div>
                                    <div className="loading-back">
                                        <div className="loading-dot loading-four"></div>
                                    </div>
                                </div>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show m-0 p-0'></div>
        </>
    )
}

export {ListLoading}
