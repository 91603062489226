import {ListViewProvider, useListView} from "../../../../modules/apps/shared/list/core/ListViewProvider";
import {KTCard, KTSVG} from "../../../../../_metronic/helpers";
import {
    ChildrenCreateModal
} from "../../../../modules/apps/children-menagement/children-create-modal/ChildrenCreateModal";
import {ChildrensTable} from "./ChildrensTable";
import {
    QueryResponseProviderFamilyMember
} from "../../../../modules/apps/children-menagement/core/QueryResponseProviderChildren";
import React from "react";
import {DeleteChildrenModal} from "../../../../shared/components/DeleteChildrenModal";
import {useIntl} from "react-intl";

type Props = {
    asyncFetch?: Function
}


const ChildrensList: React.FC<Props> = ({asyncFetch}) => {
    const {itemIdForUpdate} = useListView()
    const {itemIdForDelete} = useListView()
    const {setItemIdForUpdate} = useListView()
    const {selected} = useListView();
    const intl = useIntl();
    const openAddChildModal = () => {
        setItemIdForUpdate(null)
    }
    return (
        <>
            <KTCard>
                <div className='card-header border-0 pt-6'>
                    <div className='card-title'>
                        <div className='d-flex align-items-center position-relative my-1'>
                            <h4>{intl.formatMessage({id: 'ACCOUNT.CHILDREN'})}</h4>
                        </div>
                    </div>
                    <div className='card-toolbar'>
                        {selected.length > 0 ? <div className='d-flex justify-content-end align-items-center'>
                            <div className='fw-bolder me-5'>
                                <span className='me-2'>{selected.length}</span> Selected
                            </div>

                            <button type='button' className='btn btn-danger'>
                                Delete Selected
                            </button>
                        </div> : <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

                            <div>
                                <button type='button' className='btn btn-sm btn-primary' onClick={openAddChildModal}>
                                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                                    {intl.formatMessage({id: 'TABLE.CHILDREN.ADD_CHILDREN'})}
                                </button>

                            </div>
                        </div>}
                    </div>
                </div>
                <ChildrensTable/>
            </KTCard>
            {itemIdForUpdate !== undefined && <ChildrenCreateModal asyncFetch={asyncFetch}/>}
            {itemIdForDelete !== undefined && <DeleteChildrenModal id={itemIdForDelete} asyncFetch={asyncFetch}/>}
        </>
    )
}
const ChildrensListWrapper : React.FC<Props> = ({asyncFetch})=> (
    <QueryResponseProviderFamilyMember>
        <ListViewProvider>
        <ChildrensList asyncFetch ={asyncFetch}/>
        </ListViewProvider>
    </QueryResponseProviderFamilyMember>

)

export {ChildrensListWrapper}
