import React, {FC} from 'react';
import {JobPosition} from "../../../../shared/models/job_position";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

type Props = {
    jobPosition: JobPosition
    risk: string
}

const JobPositionNameCell: FC<Props> = ({jobPosition, risk}) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <span className='text-dark text-gray-800'>{jobPosition.name || '-'}
                {jobPosition.is_risk ?
                    <OverlayTrigger placement="top"
                                    overlay={<Tooltip>{risk}</Tooltip>}>
                        <div className="blink bg-danger ms-2 d-inline-block rounded-circle h-10px w-10px "></div>
                    </OverlayTrigger> : ''
                }
            </span>
            <span>{jobPosition.we_level}</span>

        </div>

    </div>
)

export {JobPositionNameCell}
