import {FC, useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import {useMutation} from "react-query";
import { KTSVG } from '../../../../../_metronic/helpers';
import { NotificationMessage } from '../../../../shared/helper/Notification';
import { deleteAttachmentFile } from '../../../../shared/services/file-service';

type Props = {
    item: any
    openDeleteModal: any
    asyncFetch: any
    setClosedWithAction: any
}

const DeleteModalForm: FC<Props> = ({item, openDeleteModal, asyncFetch, setClosedWithAction}) => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false);    

    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    const deleteItem = useMutation(() => deleteAttachmentFile(item?.id), {
        onSuccess: () => {
            try {
                asyncFetch()
                openDeleteModal(false);
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.PROFILE.DOCUMENT.DELETE'}), '', 'success')
            } catch (ex) {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.DELETE_FAILED'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_DELETING_EMPLOYEE!_PLEASE_TRY_AGAIN.'}), 'danger')
            } finally {
                openDeleteModal(false);
            }
        },

    })

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_delete'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered mw-400px'>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{intl.formatMessage({id: 'PROFILE.DOCUMENT.DELETE.MODAL.HEADER'})}</h3>
                            <div 
                                 onClick={() => openDeleteModal(false)}
                                 className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                 data-bs-dismiss="modal"
                                 aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body text-center">
                            <h5>{intl.formatMessage({id: 'PROFILE.DOCUMENT.DELETE.MODAL'})}</h5>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => openDeleteModal(false)}
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    disabled={loading}
                            >
                                {intl.formatMessage({id: 'MODAL.CANCEL'})}
                            </button>
                            <button type="button" className="btn btn-danger"
                                    disabled={loading}
                                    onClick={async () => {
                                        setLoading(true);
                                        await deleteItem.mutateAsync();
                                        await setClosedWithAction(true);
                                    }}
                                    >
                                {!loading && intl.formatMessage({id: 'MODAL.DELETE'})}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                    {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {DeleteModalForm}
