/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Select from "react-select";
import {useIntl} from "react-intl";

type Props = {
    className_condition: any
    placeholder: any
    name: any
    value: any
    options: any
    onChange: any
    isSearchable: any
    formatOptionLabel: any
}

const SelectField: React.FC<Props> = ({className_condition, placeholder, value, options, name, onChange, isSearchable, formatOptionLabel}) => {
    const intl = useIntl();

    return (
        <Select
            placeholder={placeholder}
            value={value}
            name={name}
            className={className_condition ?
                'mb-3 mb-lg-0 border border-danger rounded'
                : 'mb-3 mb-lg-0'}
            classNamePrefix="select"
            options={options}
            noOptionsMessage={() => intl.formatMessage({id: 'NO_DATA'})}
            theme={theme => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    neutral50: localStorage.getItem('kt_theme_mode_menu') === 'dark' ? '#495674' : '#a1a5b7',
                },
            })}
            onChange={onChange}
            menuPortalTarget={document.body}
            isSearchable={isSearchable}
            formatOptionLabel={formatOptionLabel}
        />
    )
}

export {SelectField}

