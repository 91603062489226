import clsx from 'clsx';
import moment from 'moment';
import {FC} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import { KTSVG } from '../../../../../_metronic/helpers';
import { RequestStatusHelper } from '../../../../shared/helper/RequestStatusHelper';
import {Request} from "../../../../shared/models/_models";


type Props = {
    request: Request
}

const InfoField: FC<Props> = ({request}) => {
    const intl = useIntl();

    const imageCssClass = clsx('svg-icon-2x', {
        // eslint-disable-next-line
        ['svg-icon-warning']: request.status === intl.formatMessage({id: 'REQUEST.STATUS.NEW'}),
        // eslint-disable-next-line
        ['svg-icon-danger']: request.status === intl.formatMessage({id: 'REQUEST.STATUS.REJECT'}),
        // eslint-disable-next-line
        ['svg-icon-primary']: [intl.formatMessage({id: 'REQUEST.STATUS.SUCCESS'}), intl.formatMessage({id: 'REQUEST.STATUS.PROCESSING'}), intl.formatMessage({id: 'REQUEST.STATUS.PRINT'})].includes(request?.status || ''),
        // eslint-disable-next-line
        ['svg-icon-success']: request.status === intl.formatMessage({id: 'REQUEST.STATUS.FINISHED'}),
    })

    var dateString = moment.unix(request?.created_at || 0).format("DD.MM.YYYY");

    return (
           <>
           <div className='position-relative ps-5'>
             {request?.signed_approval_file_id && <span className='position-absolute bottom-0 start-75 translate-middle'>
                    <KTSVG
                        path='/media/icons/duotune/communication/com008.svg'
                        className={'svg-icon-primary svg-icon-1'}
                    />
                </span>}
                <div className='symbol symbol-100px me-3'>
                <OverlayTrigger placement="top" overlay={<Tooltip>{RequestStatusHelper(request)}</Tooltip>}>
                <span>
                    <KTSVG
                        path='/media/icons/duotune/abstract/abs009.svg'
                        className={imageCssClass}
                    />
                </span>
                </OverlayTrigger>
            </div>
           </div>
           <div className='w-100 d-block d-sm-flex d-md-flex d-lg-flex'>
                <div className='ps-3 flex-grow-1'>
                    <span className='text-gray-800 fw-bold fs-6 d-block pe-1'>
                        {request?.request_type?.name}
                    </span>
                    {(request.user_id !== request.created_by) &&
                        <div className='d-flex d-sm-block'>
                            <span className='text-gray-600 fw-semibold text-sm-left fs-7'>{intl.formatMessage({id: 'ACCOUNT.REQUEST.USER'})}:</span>
                            <span className='w-50 ps-2 text-sm-left fs-7'>{request?.created_by_user}</span>
                        </div>
                    }
                </div>
                <span className='d-flex ps-3 text-gray-800 fw-semibold fs-6 pe-3 justify-content-start justify-content-sm-end justify-content-md-end justify-content-lg-end aling-items-center'>
                    <span className='pe-2 d-sm-none d-md-none d-lg-none fs-7 text-gray-600'>{intl.formatMessage({id: 'ACCOUNT.CREATE_DATE'})}: </span>
                    <span className='fs-7 fs-sm-6'>{dateString}</span>
                </span>
            </div>
        </>
    )
}

export {InfoField}
