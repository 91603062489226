import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ReactNotifications} from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {NotificationContext} from "./shared/components/NotificationContext";

const App = () => {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <I18nProvider>
                <LayoutProvider>
                    <AuthInit>
                        <NotificationContext>
                            <ReactNotifications/>
                            <Outlet/>
                            <MasterInit/>
                        </NotificationContext>
                    </AuthInit>
                </LayoutProvider>
            </I18nProvider>
        </Suspense>
    )
}

export {App}
