import axios from "axios";
import {environment} from "../../../environment/environment";
import {NotificationMessage} from "../helper/Notification";

declare global {
    interface Window {
        isExpired: boolean;
    }
}
window.isExpired = false;

const axiosInstance = axios.create({
    baseURL: `${environment.API_URL}`,
});

const removeLocalStorage = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('current_user');
}

axiosInstance.interceptors.request.use((confiq) => {
    const token = localStorage.getItem('access_token');

    token ? confiq.headers = {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }

        : confiq.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

    return confiq;
}, function (error) {

    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error?.response?.status === 401 && !window.location.href.includes('/login')) {
        !window.isExpired && NotificationMessage('Istekla sesija!', 'Vaša sesija je istekla! Molimo Vas da se opet prijavite.', 'danger');

        window.isExpired = true;
        setTimeout(() => {
            removeLocalStorage();
            window.location.reload();
        }, 2500);
    }

    if (error?.response?.status === 403) {
        window.location.href = "/error/403";
    }

    if (error?.response?.status === 404) {
        window.location.href = "/error/404";
    }

    // if (error?.response?.status === 500) {
    //     window.location.href = "/error/500";
    // }

    return Promise.reject(error);
});


export default axiosInstance;
