function ObjectFieldTemplate(props: any) {
    return (
    <>
      <span className="mt-2">{props.title}</span>
        {props.description}
      <div className="row">{props.properties.map((element: { content: any }) => element.content)}</div>
    </>
    ); 
  }

  export {ObjectFieldTemplate}