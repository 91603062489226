import {FC} from 'react';
import {apuLevels} from "../../../../../../shared/SelectLists";

type Props = {
    user: any
}

const UserApuCell: FC<Props> = ({user}) => {
    const apuLevel = apuLevels.find(apu => apu.value === user?.profile?.apu);

    return (
        <div className='d-flex align-items-center'>
            <div className='d-flex flex-column'>
                {apuLevel ? apuLevel.label : '-'}
            </div>
        </div>
    )
}

export {UserApuCell}