import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import Pagination from 'react-js-pagination'
import {ScrollTopComponent} from '../../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../../_metronic/helpers'
import {SimpleLoading} from '../../../../modules/apps/shared/list/components/loading/SimpleLoading'
import {User} from '../../../../shared/models/_models'
import {getVacations} from '../../../../shared/services/vacation-service'
import {DeleteVacationModal} from './DeleteVacationModal'
import {VacationField} from './VacationField'
import {VacationModal} from './VacationModal'

type Props = {
  user: User
}

const Vacation: React.FC<Props> = ({user}) => {
  const intl = useIntl()
  const [vacations, setVacations] = useState([] as any)
  const [pagination, setPagination] = useState({} as any)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [modalOpen, setModalOpen] = useState(false)
  const [vacationData, setVacationData] = useState({} as any)
  const [deleteModal, setDeleteModal] = useState(false)

  const asyncFetch = async () => {
    if (user?.job_position_id) {
      setLoading(true)
      getVacations('user_id=' + user?.id + '&page=' + page).then((response: any) => {
        setVacations(response?.data)
        setPagination(response._meta)
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }

  useEffect(
    () => {
      asyncFetch()
    },
    // eslint-disable-next-line
    [page]
  )

  function handlePageChange(pageNumber: number) {
    if (pagination?.currentPage !== pageNumber) {
      setLoading(true)
      setPage(pageNumber)
      ScrollTopComponent.goTop()
    }
  }

  const openDataModal = () => {
    setModalOpen(true)
    setVacationData(null)
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10 card-xl-stretch overflow-auto'>
        <div className='card-header border-1 w-100 border-bottom'>
          {!loading ? (
            <div className='card-title m-0 d-flex w-100 d-sm-none'>
              <h3 className='fw-bolder m-0 w-50'>
                {intl.formatMessage({id: 'ACCOUNT.HEADER.VACATION'})}
              </h3>
              <div className='d-flex w-50 d-flex justify-content-end'>
                <button type='submit' className='btn btn-primary' onClick={() => openDataModal()}>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                  {intl.formatMessage({id: 'ACCOUNT.VACATION.ADD.NEW'})}
                </button>
              </div>
            </div>
          ) : (
            <div className='d-sm-none w-100 d-flex justify-content-center'>
              <SimpleLoading />
            </div>
          )}
          <div className='card-title m-0 d-flex w-100 d-none d-sm-flex'>
            <h3 className='fw-bolder m-0 w-50'>
              {intl.formatMessage({id: 'ACCOUNT.HEADER.VACATION'})}
            </h3>
            <div className='d-flex w-50 d-flex justify-content-end'>
              <button type='submit' className='btn btn-primary' onClick={() => openDataModal()}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                {intl.formatMessage({id: 'ACCOUNT.VACATION.ADD.NEW'})}
              </button>
            </div>
          </div>
        </div>
        <div className='card-body pt-2'>
          {!loading ? (
            <>
              <table className='table align-middle contract-table'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='text-center align-middle text-uppercase'>
                      {intl.formatMessage({id: 'ACCOUNT.VACATION.YEAR'})}
                    </th>
                    <th
                      className='fw-bolder text-uppercase text-center align-middle fs-7'
                      style={{minWidth: '95px'}}
                    >
                      <div className='d-sm-flex justify-content-center'>
                        <div className='pe-1'>
                          {intl.formatMessage({id: 'ACCOUNT.VACATION.TOTAL'})}
                        </div>
                        <div>
                          ({intl.formatMessage({id: 'ACCOUNT.VACATION.DAYS'})}/
                          {intl.formatMessage({id: 'ACCOUNT.VACATION.ADDITIONA_DAYS'})}/
                          {intl.formatMessage({id: 'ACCOUNT.VACATION.PREVIOUSLY_DAYS'})})
                        </div>
                      </div>
                    </th>
                    <th className='text-uppercase text-center align-middle'>
                      {intl.formatMessage({id: 'ACCOUNT.VACATION.USED'})}
                    </th>
                    <th className='text-uppercase text-center align-middle'>
                      {intl.formatMessage({id: 'ACCOUNT.VACATION.NOT.USED'})}
                    </th>
                    <th className='text-uppercase text-end align-middle pe-6'></th>
                  </tr>
                </thead>
                <tbody>
                  {vacations && vacations?.length > 0 ? (
                    vacations.map((vacation: any) => (
                      <VacationField
                        user={user}
                        key={vacation?.id}
                        vacation={vacation}
                        setModalOpen={setModalOpen}
                        setVacationData={setVacationData}
                        setDeleteModal={setDeleteModal}
                      />
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={5}
                        className='text-center pt-5 justify-content-center text-center'
                      >
                        <span className='text-gray-800 fs-6'>
                          {intl.formatMessage({id: 'ACCOUNT.VACATION.INFO.NOT_FOUND'})}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className='position-absolute bottom-0 start-50 translate-middle w-100'>
                <div className='col-12 d-flex align-items-center justify-content-center'>
                  <div className='mb-md-0 mb-2' id='kt_table_users_paginate'>
                    {pagination?.totalCount > 10 && (
                      <Pagination
                        activePage={pagination?.currentPage}
                        hideFirstLastPages={true}
                        innerClass='pagination'
                        activeClass='active'
                        activeLinkClass='page-link'
                        itemClass='page-item'
                        itemClassFirst='page-item'
                        itemClassPrev='page-item'
                        itemClassNext='page-item'
                        itemClassLast='page-item'
                        linkClass='page-link'
                        linkClassFirst='page-link bg-light'
                        linkClassPrev='page-link bg-light'
                        linkClassNext='page-link bg-light'
                        linkClassLast='page-link bg-light'
                        disabledClass='disabled'
                        itemsCountPerPage={pagination?.perPage}
                        totalItemsCount={pagination?.totalCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange.bind(this)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className='d-none d-sm-flex position-absolute top-50 start-50 translate-middle'>
              <SimpleLoading />
            </div>
          )}
        </div>
      </div>
      {modalOpen && (
        <VacationModal
          user={user}
          setModalOpen={setModalOpen}
          vacationData={vacationData}
          asyncFetch={asyncFetch}
        />
      )}
      {deleteModal && (
        <DeleteVacationModal
          setDeleteModal={setDeleteModal}
          user={user}
          vacationData={vacationData}
          asyncFetch={asyncFetch}
        />
      )}
    </>
  )
}

export {Vacation}
