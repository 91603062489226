import Flatpickr from "react-flatpickr";
import moment from "moment";
import clsx from 'clsx'

const CustomDateWidget = function(props: any) {    
    return (
      <div className='input-group'>
        <Flatpickr
          disabled={props.disabled}
          className={clsx('form-control form-control-solid mb-lg-0')}
          value={props?.value}
          options={{
            minDate: props?.options?.mindate ? moment().subtract(-(props?.options?.mindate),'d').format('YYYY.MM.DD') : '',
            dateFormat: "d.m.Y",
            enableTime: false,
            locale: "sr",
            allowInput: true
          }}
          title = {props?.label}
          placeholder = {props?.label}
          onChange={(selectedDates) => {
            props.onChange(moment(selectedDates[0]).format('DD.MM.YYYY'))
        }}
        ></Flatpickr>
        <span className="input-group-text border-0 ">
          <i className='fa fa-calendar'></i>
        </span>
      </div>
    )
  };

  export {CustomDateWidget}
