import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {getIn, useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {Department, JobPosition} from '../../../../shared/models/_models'
import {useListView} from '../../shared/list/core/ListViewProvider'
import {createJobPosition, updateJobPosition} from '../../../../shared/services/job-position-service'
import {useIntl} from "react-intl";
import {listJobPositionCategories, listWELevelJobPosition} from "../../../../shared/SelectLists";
import {listSectors} from "../../../../shared/SelectLists";
import {useQueryResponse} from "../core/QueryResponseProviderJobPositions";
import {SelectField} from "../../../../shared/helper/Select";
import {NotificationMessage} from "../../../../shared/helper/Notification";
import { getDepartmentBySector } from '../../../../shared/services/department-service'

type Props = {
    isJobPositionLoading: boolean
    setIsJobPositionLoading: React.Dispatch<React.SetStateAction<boolean>>
    jobPosition: JobPosition
    departments: Department[] | undefined
}

const JobPositionCreateModalForm: FC<Props> = ({
                                                   jobPosition,
                                                   isJobPositionLoading,
                                                   setIsJobPositionLoading
                                               }) => {
    const intl = useIntl()
    const technical = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.TECHNICAL'})
    const commercial = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.COMMERCIAL'})
    const global = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.GLOBAL'})
    const direct = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.DIRECT'})
    const indirect = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.INDIRECT'})
    const indirect_direct = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.INDIRECT_DIRECT'})
    const overhead = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.OVERHEAD'})
    const [department, setDepartment] = useState(jobPosition?.department)
    const [sectorDepartment, setSectorDepartment] = useState(jobPosition?.sector)
    const [WELevel, setWELevel] = useState(jobPosition?.we_level)
    const [category, setCategory] = useState(jobPosition?.category)
    const [waitLoading, setWaitLoading] = useState(false)
    const [departments, setDepartments] =  useState([] as any);

    const {setItemIdForUpdate} = useListView()
    const {refetch} = useQueryResponse()
    const [loading, setLoading] = useState(false)

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdate(undefined)
    }

    useEffect(() => {
        setIsJobPositionLoading(false)
        if (sectorDepartment) {
            setWaitLoading(true)
            getDepartmentBySector(`sector=${sectorDepartment === 'global' ? '' : sectorDepartment}`)
            .then((response) => {
               setDepartments(response)
               setWaitLoading(false)
            }
          )
        }
    },
     // eslint-disable-next-line
    [sectorDepartment]);


    const departmentsList = departments?.map((department: any) => (
        {value: department.id, label: department.name}))

    const jobPositionSchema = Yup.object().shape({
        name: Yup.string()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'})).nullable(),
        sector: Yup.string()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'})).nullable(),
        department_id: Yup.string()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'})).nullable(),
        category: Yup.string()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'})).nullable(),
        we_level: Yup.string()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'})).nullable(),
    })

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: jobPosition.id ? jobPosition : {
            name: '',
            sector: '',
            department_id: '',
            category: '',
            we_level: ''
        },
        validationSchema: jobPositionSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setLoading(true)
            setSubmitting(true)

            try {
                if (isNotEmpty(values.id)) {
                    await updateJobPosition(values)
                    NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.JOB_POSITION_SUCCESSFULLY_UPDATED'}), '', 'success')
                } else {
                    await createJobPosition({...values})
                    NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.JOB_POSITION_SUCCESSFULLY_CREATED'}), '', 'success')
                }
            } catch (ex) {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.SAVE_FAILED!'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SAVING_DATA!_PLEASE_TRY_AGAIN.'}), 'danger')
            } finally {
                setSubmitting(true)
                cancel(true)
            }
        },
    })

    return (
        <>
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                >
                    <div className="row">
                        <div className='col-12 col-lg-6 mb-3'>
                            <label
                                className='required fw-bold fs-6 mb-3'>{intl.formatMessage({id: 'JOB_POSITION.NAME'})}</label>
                            <input
                                placeholder={intl.formatMessage({id: 'JOB_POSITION.NAME'})}
                                {...formik.getFieldProps('name')}
                                type='text'
                                name='name'
                                className={formik.touched.name && formik.errors.name ?
                                    'form-control form-control-solid mb-3 mb-lg-0 border border-danger' : 'form-control form-control-solid mb-3 mb-lg-0'}
                                autoComplete='off'
                            />
                            {formik.touched.name && formik.errors.name &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'name')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='col-12 col-lg-6 mb-3'>
                            <label className='fw-bold fs-6 mb-3'>
                                {intl.formatMessage({id: 'JOB_POSITION.SECTOR'})}
                            </label>
                            <SelectField
                                name={'sector'}
                                className_condition={formik.touched.sector && formik.errors.sector}
                                placeholder={intl.formatMessage({id: 'SELECT_SECTOR'})}
                                value={{
                                    value: sectorDepartment || '',
                                    label: sectorDepartment === 'technical' ? technical : sectorDepartment === 'commercial' ?
                                        commercial : sectorDepartment === 'global' ? global : intl.formatMessage({id: 'SELECT_SECTOR'})
                                }}
                                options={listSectors}
                                onChange={(
                                    newValue: any,
                                ) => {
                                    formik.setFieldValue('sector', newValue?.value)
                                    setSectorDepartment(newValue.value)
                                }}
                                isSearchable={false}
                                formatOptionLabel={{}}
                            />
                            {getIn(formik.errors, 'sector') && getIn(formik.touched, 'sector') &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'sector')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                       {sectorDepartment && 
                       <>
                       <div className='col-12 col-lg-6 mb-3'>
                            <label className='fw-bold fs-6 mb-3'>{intl.formatMessage({id: 'JOB_POSITION.DEPARTMENT'})}
                            </label>
                            <SelectField
                                name={'department_id'}
                                className_condition={formik.touched.department_id && formik.errors.department_id}
                                placeholder={intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.SELECT_PARENT'})}
                                value={{
                                    value: jobPosition.department_id || '',
                                    label: department?.name || intl.formatMessage({id: 'SELECT_DEPARTMENT.PARENT'})
                                }}
                                options={departmentsList}
                                onChange={(
                                    newValue: any,
                                ) => {
                                    formik.setFieldValue('department_id', newValue.value);
                                    setDepartment(departments?.filter((department: any) => department?.id?.toString() === newValue.value.toString())[0])
                                }}
                                isSearchable={true}
                                formatOptionLabel={{}}
                            />
                            {getIn(formik.errors, 'department_id') && getIn(formik.touched, 'department_id') &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'department_id')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='col-12 col-lg-3 mb-3'>
                            <label className='fw-bold fs-6 mb-3'>
                                {intl.formatMessage({id: 'JOB_POSITION.CATEGORY'})}
                            </label>
                            <SelectField
                                name={'category'}
                                className_condition={formik.touched.category && formik.errors.category}
                                placeholder={intl.formatMessage({id: 'SELECT_CATEGORY'})}
                                value={{
                                    value: category || '',
                                    label: category === 'DIR' ? direct : category === 'ID/DIR' ?
                                        indirect_direct : category === 'ID' ? indirect : category === 'OH' ? overhead : intl.formatMessage({id: 'SELECT_CATEGORY'})
                                }}
                                options={listJobPositionCategories}
                                onChange={(
                                    newValue: any,
                                ) => {
                                    formik.setFieldValue('category', newValue?.value)
                                    setCategory(newValue.value)
                                }}
                                isSearchable={false}
                                formatOptionLabel={{}}
                            />
                            {getIn(formik.errors, 'category') && getIn(formik.touched, 'category') &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'category')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='col-12 col-lg-3 mb-5'>
                            <label className='fw-bold fs-6 mb-3'>
                                {intl.formatMessage({id: 'JOB_POSITION.WE_LEVEL'})}
                            </label>
                            <SelectField
                                name={'we_level'}
                                className_condition={formik.touched.we_level && formik.errors.we_level}
                                placeholder={intl.formatMessage({id: 'SELECT_WE_LEVEL'})}
                                value={{
                                    value: WELevel || '',
                                    label: WELevel || intl.formatMessage({id: "SELECT_WE_LEVEL"})
                                }}
                                options={listWELevelJobPosition}
                                onChange={(
                                    newValue: any,
                                ) => {
                                    formik.setFieldValue('we_level', newValue?.value)
                                    setWELevel(newValue.value)
                                }}
                                isSearchable={false}
                                formatOptionLabel={{}}
                            />
                            {getIn(formik.errors, 'we_level') && getIn(formik.touched, 'we_level') &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'we_level')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='col-12'>
                            <div className=" form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" id='is_risk'
                                       name="is_risk"
                                       checked={formik.getFieldProps('is_risk').value === 1}
                                       onChange={() => {
                                           formik.setFieldValue(
                                               'is_risk',
                                               formik.getFieldProps('is_risk').value === 1 ? 0 : 1
                                           )
                                       }}
                                />
                                <label className='fw-bold fs-6 ms-3' htmlFor='is_risk'>
                                    {intl.formatMessage({id: 'JOB_POSITION.IS_RISK'})}
                                </label></div>
                        </div>
                        </>}
                    </div>
                </div>
                <div className='text-center pt-10'>
                    <button
                        type='submit'
                        className='btn btn-primary float-end'
                        data-kt-users-modal-action='submit'
                        disabled={loading}
                    >
                        {(!loading && !waitLoading) && (jobPosition.id === undefined ? intl.formatMessage({id: 'BUTTON.CREATE'}) : intl.formatMessage({id: 'BUTTON.SUBMIT'}))}
                        {(loading || waitLoading) && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                    {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3 float-end'
                        data-kt-users-modal-action='cancel'
                        disabled={waitLoading || isJobPositionLoading || loading}
                    >
                        {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                    </button>
                </div>
            </form>
        </>
    )
}

export {JobPositionCreateModalForm}
