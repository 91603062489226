import {get, put} from './api-client'
import {ID} from '../../../_metronic/helpers'

const ENDPOINT = `/notification`

const getUnreadNotifications = () => {
  return get(ENDPOINT, 'is_read=0&list=true').then((response) => response.data)
}

const getNotifications = (query: string = ''): Promise<any> => {
  return get(ENDPOINT, query).then((response) => response.data)
}

const updateNotification = (id: ID): Promise<void> => {
  const markAsRead = {
    is_read: 1,
  }

  return put(`${ENDPOINT}/${id}`, markAsRead).then()
}

const markAllAsRead = (id: ID): Promise<void | boolean> => {
  const markAsRead = {
    is_read: 1,
  }

  return put(`${ENDPOINT}/mark-all-as-read/${id}`, markAsRead).then();
}

const NotificationService = {
  getNotifications,
  updateNotification,
  markAllAsRead,
  getUnreadNotifications
}

export default NotificationService
