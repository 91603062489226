import {AxiosResponse} from 'axios'
import {environment} from '../../../environment/environment'
import {Response} from '../../../_metronic/helpers'
import {post} from './api-client'

const ENDPOINT_TOKEN = `oauth/token-from-hash`
const ENDPOINT_REQUEST = `oauth/request-from-hash`
const CLIENT_ID = environment.CLIENT_ID

const postAccessToken = (access_code: any): Promise<any | undefined> => {
    const body = {
        access_code: access_code,
        client_id: CLIENT_ID
    }
    return post(ENDPOINT_TOKEN, body)
        .then((response: AxiosResponse<Response<any>>) => response.data)
}


const postRequestCode = (request_code: any): Promise<any | undefined> => {
    const body = {
        request_code: request_code,
    }
    return post(ENDPOINT_REQUEST, body)
        .then((response: AxiosResponse<Response<any>>) => response.data)
}

export {postAccessToken, postRequestCode}