import {ListViewProvider, useListView} from '../../modules/apps/shared/list/core/ListViewProvider'
import {QueryRequestProvider} from '../../modules/apps/shared/list/core/QueryRequestProvider'
import {KTCard} from '../../../_metronic/helpers'
import React from 'react'
import {
    QueryResponseProviderContracts
} from "../../modules/apps/contract-menagement/core/QueryResponseProviderContracts";
import {ContractsTable} from "./ContractsTable";
import {ContractsListHeader} from "../../modules/apps/contract-menagement/components/header/ContractsListHeader";
import {DeleteContractModal} from "../../shared/components/DeleteContractModal";
const ContractsList = () => {
    const {itemIdForDelete} = useListView()
    return (
        <>
            <KTCard>
                <ContractsListHeader/>
                <ContractsTable/>
            </KTCard>
            {itemIdForDelete !== undefined && <DeleteContractModal id={itemIdForDelete}/>}
        </>
    )
}

const ContractsListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProviderContracts>
            <ListViewProvider>
                <ContractsList/>
            </ListViewProvider>
        </QueryResponseProviderContracts>
    </QueryRequestProvider>
)

export {ContractsListWrapper}
