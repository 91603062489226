import { FC } from "react"
import Form from "react-jsonschema-form"
import { ObjectFieldTemplate } from "../jsonSchemaAccessories/ObjectFieldTemplate"

type Props = {
    schema: any
    schemaData: any
}

const AprovalSchema: FC<Props> = ({schema, schemaData}) => {
    return (
        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
            <div className='card-body p-0'>
                <Form
                    id="json-schema-preview"
                    autocomplete="off"
                    schema={schema}
                    ObjectFieldTemplate={ObjectFieldTemplate}
                    formData={schemaData}
                    disabled
                >
                <div className='d-none'>
                <button></button>
                </div>
                </Form>
            </div>
        </div>
    )
}

export {AprovalSchema}