import {useEffect, useRef, useState} from 'react'
import {Request} from '../../../shared/models/_models'
import {OverviewHeader} from './OverviewHeader'
import {RequestPreview} from './RequestPreview'
import {useIntl} from 'react-intl'
import {OverviewStepper} from './OverviewStepper'
import {StatusChanges} from './StatusChanges'
import {RequestModal} from './RequestModal'
import {useNavigate, useParams} from 'react-router-dom'
import {getRequestActions, getRequestById} from '../../../shared/services/request-service'
import {ListLoading} from '../../../modules/apps/shared/list/components/loading/ListLoading'
import {ActionsForMobile} from './ActionsForMobile'
import {Comments} from './Comments'
import {ApprovalRequestPreview} from './ApprovalRequestPreview'
import {RoutesHelper} from '../../../shared/helper/RoutesHelper'

const Overview: React.FC = () => {
  window.validationFormData = {}
  const intl = useIntl()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)
  const requestId = useParams().id
  const [request, setRequest] = useState({} as Request)
  const [actions, setActions] = useState([] as any)
  const [requestAction, setRequestAction] = useState({})
  const currentUser = JSON.parse(localStorage.getItem('current_user') || '')
  const restrictionAccess =
    request?.status === intl.formatMessage({id: 'REQUEST.STATUS.NEW'}) &&
    currentUser?.id === request?.created_by
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    asyncFetch()
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloading])

  const asyncFetch = async () => {
    setActions(await getRequestActions(requestId))
    setRequest((await getRequestById(requestId)) as Request)
  }

  useEffect(
    () => {
      if (restrictionAccess) {
        navigate(`/request/${requestId}/edit`)
      }
    },
    // eslint-disable-next-line
    [restrictionAccess]
  )

  return (
    <>
      {(isLoading || !request?.status) && <ListLoading />}
      {isDownloading && <ListLoading />}
      {request?.status && (
        <div className='d-flex flex-column flex-xl-row flex-row-fluid'>
          <div className='d-inline flex-row-auto'>
            <OverviewStepper
              request={request}
              stepperRef={stepperRef}
              setIsDownloading={setIsDownloading}
              setOpenModal={setOpenModal}
              setRequestAction={setRequestAction}
              actions={actions && actions?.data}
            />
            <StatusChanges request={request} />
            {(RoutesHelper(['WE1', 'WE2', 'WE3']) && !(request?.created_by_we_level === 'WE3' && request?.created_by === currentUser?.id)) && <Comments request={request} />}
            {/*condition for WE4 employees */}
            {
                (['Obrazac za ocjenu rada (TL/TK)', 'Obrazac za ocjenu probnog rada (TL/TK)'].includes(request?.request_type?.name || '')) &&
                request?.created_by_we_level === 'WE4' &&
                currentUser?.job_position?.we_level === 'WE4' &&
                <Comments request={request} />
            }
          </div>
          <div className='flex-row-fluid  bg-body rounded'>
            <div className='card-body'>
              <OverviewHeader
                setIsDownloading={setIsDownloading}
                request={request}
                setOpenModal={setOpenModal}
                setRequestAction={setRequestAction}
                actions={actions && actions?.data}
              />
            </div>
            <div className='d-flex flex-row-fluid flex-center'>
              <RequestPreview request={request} openModal={openModal} />
            </div>
            <div className='d-flex flex-row-fluid flex-center'>
              {JSON.stringify(request?.approval_form_data) !== '[]' && (
                <ApprovalRequestPreview request={request} openModal={openModal} />
              )}
            </div>
            <div className='d-flex d-lg-none flex-row-fluid flex-center card-body'>
              {request.status !== intl.formatMessage({id: 'REQUEST.STATUS.FINISHED'}) && (
                <ActionsForMobile
                  setIsDownloading={setIsDownloading}
                  request={request}
                  setOpenModal={setOpenModal}
                  setRequestAction={setRequestAction}
                  actions={actions && actions?.data}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {openModal && (
        <RequestModal
          action={requestAction}
          setOpenModal={setOpenModal}
          request={request}
          setActionApproved={asyncFetch}
        />
      )}
    </>
  )
}

export {Overview}
