import {ID, Response} from '../../../_metronic/helpers'
import {JobPosition} from '../models/job_position'
import {get, post, put, remove} from './api-client'
import {AxiosResponse} from 'axios'
import {Department, DepartmentsQueryResponse} from "../models/department";

const ENDPOINT = `/department`

const getDepartments = (query: string): Promise<DepartmentsQueryResponse> => {
  return get(ENDPOINT, query).then((d: AxiosResponse<DepartmentsQueryResponse>) => d.data)
}

const getDepartmentById = (id: ID): Promise<Department | undefined> => {
  return get(`${ENDPOINT}/${id}`, '')
    .then((response: AxiosResponse<Response<Department>>) => response.data)
    .then((response: Response<Department>) => response.data)
}

const getDepartmentBySector = (query: string): Promise<DepartmentsQueryResponse> => {  
  return get(`${ENDPOINT}/sector`, query)
    .then((response: AxiosResponse<DepartmentsQueryResponse>) => response.data)
}

const getDepartmentPositions = (id: ID): Promise<Array<JobPosition>> => {
  return get(`${ENDPOINT}/${id}/positions`, '')
      .then((response: AxiosResponse<Response<Array<JobPosition>>>) => response.data)
      .then((response: Response<Array<JobPosition>>) => (response as unknown as Array<JobPosition>));
}

const createDepartment = (department: any): Promise<Department | undefined> => {  
  return post(ENDPOINT, department)
    .then((response: AxiosResponse<Response<Department>>) => response.data)
    .then((response: Response<Department>) => response.data)
}

const updateDepartment = (department: Department): Promise<Department | undefined> => {
  return put(`${ENDPOINT}/${department.id}`, department)
    .then((response: AxiosResponse<Response<Department>>) => response.data)
    .then((response: Response<Department>) => response.data)
}

const deleteDepartment = (departmentId: ID): Promise<void> => {
  return remove(`${ENDPOINT}/${departmentId}`).then(() => {})
}

const getDepartmentList = (): Promise<any> => {
  return get(`${ENDPOINT}/list`, '')
      .then((response: AxiosResponse<any>) => response.data)
}

const getParentDepartmentList = (): Promise<any> => {
  return get(`${ENDPOINT}/parent`, '')
      .then((response: AxiosResponse<any>) => response.data)
}

const getUsersStatistics = (): Promise<any> => {
  return get(`${ENDPOINT}/department-statistics`, '')
      .then((response: AxiosResponse<any>) => response.data)
}

export {getDepartments, deleteDepartment, getDepartmentById, getDepartmentBySector, createDepartment, updateDepartment, getDepartmentPositions, getDepartmentList, getParentDepartmentList, getUsersStatistics}
