import React, {Dispatch, SetStateAction, useState} from 'react';
import {useIntl} from "react-intl";
import {KTSVG} from "../../../_metronic/helpers";
import {updateContract} from "../../shared/services/contract-service";
import {Contract} from "../../shared/models/contract";
import {NotificationMessage} from "../../shared/helper/Notification";

type props = {
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    contract: Contract,
    setEnd: any
}

const ContractEndConfirmation: React.FC<props> = ({setOpenModal, contract, setEnd}) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    const cancel = () => {
        setOpenModal(false);
    }

    const endContract = () => {
        setLoading(true);
        contract.status = 'expired';

        updateContract(contract).then(() => {
            setLoading(false);
            setOpenModal(false);
            setEnd(true);
            NotificationMessage(intl.formatMessage({id: 'CONTRACT.MODAL.END_SUCCESS'}), '', 'success');
        })
    }

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_confirmation_contract_end'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered mw-500px'>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">
                                {intl.formatMessage({id: 'CONTRACT.MODAL.END.HEADER'})}
                            </h3>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setOpenModal(false)}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body text-center">
                            <h5 className='my-5'>{intl.formatMessage({id: 'CONTRACT.MODAL.END.INFO'})}</h5>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => cancel()}
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    disabled={loading}
                            >
                                {intl.formatMessage({id: 'MODAL.CANCEL'})}
                            </button>
                            <button type="button" className="btn btn-danger"
                                    disabled={loading}
                                    onClick={endContract}
                            >
                                {!loading && intl.formatMessage({id: 'CONTRACT.MODAL.END'})}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                    {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {ContractEndConfirmation}
