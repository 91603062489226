/* eslint-disable */
import {FC, useEffect} from 'react'
import { RequestCreateModalFormWrapper } from './RequestCreateModalFormWrapper'
import { RequestCreateModalHeader } from './RequestCreateModalHeader'

type Props = {
  setOpenCreateModal?: any
}

const RequestCreateModal: FC<Props> = ({setOpenCreateModal}) => {
    useEffect(() => {
      document.body.classList.add('modal-open')
      return () => {
          document.body.classList.remove('modal-open')
      }
    }, [])

    return (
      <>
        <div
          className='modal fade show d-block'
          id='kt_modal_add_user'
          role='dialog'
          tabIndex={-1}
          aria-modal='true'
        >
        <div className='modal-dialog modal-dialog-centered modal'>
            <div className='modal-content'>
              <RequestCreateModalHeader setOpenCreateModal={setOpenCreateModal}/>
              <div className='modal-body scroll-y'>
                  <RequestCreateModalFormWrapper setOpenCreateModal={setOpenCreateModal}/>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-backdrop fade show'></div>
      </>
    )
}

export {RequestCreateModal}
