import {FC} from 'react'
import {Contract} from "../../../../shared/models/contract";
import moment from "moment/moment";


type Props = {
    contract: Contract
}

const ContractPeriodCell: FC<Props> = ({contract}) => {
    if (contract.type === "defined")
        return (
            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column'>
                    {contract?.start_date ? moment(contract?.start_date).format('DD.MM.YYYY') : moment().format('DD.MM.YYYY')} - {contract?.end_date ? moment(contract?.end_date).format('DD.MM.YYYY') : 'N/A'}
                </div>
            </div>
        )
    else
        return(
            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column'>
                    {contract?.start_date ? moment(contract?.start_date).format('DD.MM.YYYY') : moment().format('DD.MM.YYYY')}
                </div>
            </div>
        )
}
export {ContractPeriodCell}
