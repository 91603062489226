import {FC} from 'react'
import {FamilyMember} from "../../../../shared/models/family_member";



type Props = {
    familyMember: FamilyMember
    male: string
    female: string

}


const ChildrenGenderCell: FC<Props> =({familyMember,male,female}) =>(

    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            <div className='text-gray-800 mb-1'>
                {familyMember?.gender === 'male'? male : female}
            </div>
        </div>
    </div>
)

export {ChildrenGenderCell}
