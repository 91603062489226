import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {getIn, useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {Department} from '../../../../shared/models/department'
import {useListView} from '../../shared/list/core/ListViewProvider'
import {createDepartment, getDepartmentById, getDepartmentBySector, updateDepartment} from '../../../../shared/services/department-service'
import {useIntl} from "react-intl";
import {useQueryResponse} from "../core/QueryResponseProviderDepartments";
import {listWELevels} from "../../../../shared/SelectLists";
import {listSectors} from "../../../../shared/SelectLists";
import {SelectField} from "../../../../shared/helper/Select";
import {NotificationMessage} from "../../../../shared/helper/Notification";

type Props = {
    isDepartmentLoading: boolean
    setIsDepartmentLoading: React.Dispatch<React.SetStateAction<boolean>>
    department: Department
    departments: Department[] | undefined
}

const DepartmentCreateModalForm: FC<Props> = ({
                                                  department,
                                                  setIsDepartmentLoading
                                              }) => {
    const intl = useIntl();
    const {setItemIdForUpdate} = useListView();
    const {refetch} = useQueryResponse();
    const [loading, setLoading] = useState(false);
    const weLevels = ['WE6', 'WE5', 'WE4', 'WE3', 'WE2', 'WE1'];

    const technical = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.TECHNICAL'});
    const commercial = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.COMMERCIAL'});
    const global = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.GLOBAL'});
    const [parent, setParent] = useState(department?.parent);
    const [sectorDepartment, setSectorDepartment] = useState(department?.sector);
    const [WELevel, setWELevel] = useState(department?.we_level);
    const [departments, setDepartments] =  useState([] as any);
    const [waitLoading, setWaitLoading] = useState(false)

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdate(undefined);
    }

    const departmentsList = departments && departments?.map((department: any) => (
        {value: department.id, label: department.name}))

    const departmentSchema = () => {
        if (parent) {
            return  Yup.object().shape({
                        name: Yup.string().required(intl.formatMessage({id: 'REQUIRED_FIELD'})).nullable(),
                        sector: Yup.string().required(intl.formatMessage({id: 'REQUIRED_FIELD'})).nullable(),
                    });
        } else {
            return  Yup.object().shape({
                        name: Yup.string().required(intl.formatMessage({id: 'REQUIRED_FIELD'})).nullable(),
                        sector: Yup.string().required(intl.formatMessage({id: 'REQUIRED_FIELD'})).nullable(),
                        we_level: Yup.string().required(intl.formatMessage({id: 'REQUIRED_FIELD'})).nullable(),
                    });
        }
    }

    useEffect(() => {
        setIsDepartmentLoading(false);
        if (sectorDepartment) {
            setWaitLoading(true)
            getDepartmentBySector(`sector=${sectorDepartment === 'global' ? '' : sectorDepartment}`)
            .then((response) => {
               setDepartments(response)
               setWaitLoading(false)
            }
          )
        }
    },
     // eslint-disable-next-line
    [sectorDepartment]);

    useEffect(() => {
        setIsDepartmentLoading(false);
        if(parent) {
            setWaitLoading(true)
            getDepartmentById(parent?.id).then((response) => {
                var position = weLevels.indexOf(response?.we_level || '')
                setWELevel(weLevels[position - 1])
                setWaitLoading(false)
            })
        } 
    },
     // eslint-disable-next-line
    [parent]);

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: department.id ? department : {
            name: '',
            code: '',
            sector: '',
            we_level: WELevel ? WELevel : ''
        },
        validationSchema: departmentSchema,
        onSubmit: async (values) => {
            setLoading(true);

            if (WELevel) {
                values.we_level = `${WELevel}`
            }

            try {
                if (values.name === parent?.name) {
                    NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.DEPARTMENT_NAME!'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.THE_NAME_AND_SUPERIOR_DEPARTMENT_NAME'}), 'danger')
                } else {
                    if (isNotEmpty(values.id)) {
                        await updateDepartment(values)
                        NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.DEPARTMENT_SUCCESSFULLY_UPDATED!'}), '', 'success')
                    } else {
                        await createDepartment({...values})
                        NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.DEPARTMENT_SUCCESSFULLY_CREATED!'}), '', 'success')
                    }
                }
            } catch (ex) {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.SAVE_FAILED!'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SAVING_DATA!_PLEASE_TRY_AGAIN.'}), 'danger')
            } finally {
                if (values.name !== parent?.name) {
                    cancel(true);
                }
                setLoading(false);
            }
        },
    })

    return (
        <>
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                >
                    <div className="row">
                        <div className='col-12 col-lg-6 mb-3'>
                            <label
                                className='required fw-bold fs-6 mb-3'>{intl.formatMessage({id: 'ORGANIZATION_UNIT.NAME'})}</label>
                            <input
                                placeholder={intl.formatMessage({id: 'ORGANIZATION_UNIT.NAME'})}
                                {...formik.getFieldProps('name')}
                                type='text'
                                name='name'
                                className={formik.touched.name && formik.errors.name ?
                                    'form-control form-control-solid mb-3 mb-lg-0 border border-danger' : 'form-control form-control-solid mb-3 mb-lg-0'}
                                autoComplete='off'
                            />
                            {formik.touched.name && formik.errors.name &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'name')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='col-12 col-lg-6 mb-3'>
                            <label className='required fw-bold fs-6 mb-3'>
                                {intl.formatMessage({id: 'DEPARTMENT.SECTOR'})}
                            </label>
                            <SelectField
                                name={'sector'}
                                className_condition={formik.touched.sector && formik.errors.sector}
                                placeholder={intl.formatMessage({id: 'SELECT_SECTOR'})}
                                value={{
                                    value: sectorDepartment || '',
                                    label: sectorDepartment === 'technical' ? technical : sectorDepartment === 'commercial' ?
                                        commercial : sectorDepartment === 'global' ? global : intl.formatMessage({id: 'SELECT_SECTOR'})
                                }}
                                options={listSectors}
                                onChange={(
                                    newValue: any,
                                ) => {
                                    formik.setFieldValue('sector', newValue?.value)
                                    setSectorDepartment(newValue.value)
                                }}
                                isSearchable={false}
                                formatOptionLabel={{}}
                            />
                            {getIn(formik.errors, 'sector') && getIn(formik.touched, 'sector') &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'sector')}</span>
                                    </div>
                                </div>
                            }
                        </div>

                        {sectorDepartment &&  <>
                         <div className='col-12 col-lg-6 mb-3'>
                            <label
                                className='fw-bold fs-6 mb-3'>{intl.formatMessage({id: 'ORGANIZATION_UNIT.PARENT'})}
                            </label>
                            <SelectField
                                name={'parent_id'}
                                className_condition={false}
                                placeholder={intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.SELECT_PARENT'})}
                                value={{
                                    value: department?.parent_id || '',
                                    label: parent?.name || intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.SELECT_PARENT'})
                                }}
                                options={departmentsList}
                                onChange={(
                                    newValue: any,
                                ) => {
                                    formik.setFieldValue('parent_id', newValue.value);
                                    setParent(departments?.filter((department: any) => department?.id?.toString() === newValue.value.toString())[0])
                                }}
                                isSearchable={true}
                                formatOptionLabel={{}}
                            />
                        </div>
                        <div className='col-12 col-lg-3 mb-3'>
                            <label
                                className='fw-bold fs-6 mb-3'>{intl.formatMessage({id: 'DEPARTMENT.COST_CENTER'})}</label>
                            <input
                                placeholder={intl.formatMessage({id: 'DEPARTMENT.COST_CENTER'})}
                                {...formik.getFieldProps('code')}
                                type='text'
                                name='code'
                                className={formik.errors.code && formik.touched.code ?
                                    'form-control form-control-solid mb-3 mb-lg-0 border border-danger' : 'form-control form-control-solid mb-3 mb-lg-0'}
                                autoComplete='off'
                            />
                        </div>
                        <div className='col-12 col-lg-3 mb-3'>
                            <label
                                className='fw-bold fs-6 mb-3'>{intl.formatMessage({id: 'DEPARTMENT.WE_LEVEL'})}</label>
                            <SelectField
                                name={'we_level'}
                                className_condition={formik.touched.we_level && formik.errors.we_level}
                                placeholder={intl.formatMessage({id: 'SELECT_WE_LEVEL'})}
                                value={{
                                    value: WELevel || '',
                                    label: WELevel || intl.formatMessage({id: 'SELECT_WE_LEVEL'})
                                }}
                                options={listWELevels}
                                onChange={(
                                    newValue: any,
                                ) => {
                                    formik.setFieldValue('we_level', newValue?.value)
                                    setWELevel(newValue.value)
                                }}
                                isSearchable={false}
                                formatOptionLabel={{}}
                            />
                            {getIn(formik.errors, 'we_level') && getIn(formik.touched, 'we_level') &&
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{getIn(formik.errors, 'we_level')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        </>}
                    </div>
                </div>
                <div className='text-center pt-15'>
                    <button
                        type='submit'
                        className='btn btn-primary float-end'
                        data-kt-users-modal-action='submit'
                        disabled={loading || waitLoading}
                    >
                        {(!loading && !waitLoading) && (department.id === undefined ? intl.formatMessage({id: 'BUTTON.CREATE'}) : intl.formatMessage({id: 'BUTTON.SUBMIT'}))}
                        {(loading || waitLoading) && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                    {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3 float-end'
                        data-kt-users-modal-action='cancel'
                        disabled={loading || waitLoading}
                    >
                        {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                    </button>
                </div>
            </form>
        </>
    )
}

export {DepartmentCreateModalForm}
