import {FC, useEffect} from 'react'
import {RequestModalForm} from "./RequestModalForm";
import {RequestModalHeader} from "./RequestModalHeader";
import {Request} from "../../../shared/models/_models";

type Props = {
    action: any
    setOpenModal: any
    request: Request
    setActionApproved: Function
}

const RequestModal: FC<Props> = ({action, setOpenModal, request, setActionApproved}) => {

    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <RequestModalHeader setOpenModal={setOpenModal} action={action} />
                        <div className='modal-body scroll-y'>
                            <RequestModalForm setOpenModal={setOpenModal} action={action} request={request} setActionApproved={setActionApproved}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {RequestModal}
