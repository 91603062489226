import {JobPosition, JobPositionsQueryResponse} from '../models/_models';
import {get, post, put, remove} from './api-client';
import {AxiosResponse} from 'axios';
import {ID, Response} from "../../../_metronic/helpers";

const ENDPOINT = `/job-position`

const getJobPositions = (query: string): Promise<JobPositionsQueryResponse> => {
    return get(ENDPOINT, query).then((d: AxiosResponse<JobPositionsQueryResponse>) => d.data)
}

const getJobPositionById = (id: ID): Promise<JobPosition | undefined> => {
    return get(`${ENDPOINT}/${id}`, '')
        .then((response: AxiosResponse<Response<JobPosition>>) => response.data)
        .then((response: Response<JobPosition>) => response.data)
}

const createJobPosition = (jobPosition: any): Promise<JobPosition | undefined> => {
    return post(ENDPOINT, jobPosition)
        .then((response: AxiosResponse<Response<JobPosition>>) => response.data)
        .then((response: Response<JobPosition>) => response.data)
}

const updateJobPosition = (jobPosition: JobPosition): Promise<JobPosition | undefined> => {
    return put(`${ENDPOINT}/${jobPosition.id}`, jobPosition)
        .then((response: AxiosResponse<Response<JobPosition>>) => response.data)
        .then((response: Response<JobPosition>) => response.data)
}

const deleteJobPosition = (jobPositionId: ID): Promise<void> => {
    return remove(`${ENDPOINT}/${jobPositionId}`).then(() => {})
}

export {getJobPositions, createJobPosition, updateJobPosition, deleteJobPosition, getJobPositionById}
