/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import {initialQueryState} from '../../../../_metronic/helpers';
import { KTSVG } from '../../../../_metronic/helpers';
import { useQueryRequest } from '../../../modules/apps/shared/list/core/QueryRequestProvider';
import { NotificationMessage } from '../../../shared/helper/Notification';
import { getDepartmentList } from '../../../shared/services/department-service';
import {getRequestStatuses, getRequestTypesFilterList} from '../../../shared/services/request-service';
import moment from 'moment';
import {FilterDateOption, FilterDateOptionRight} from '../../../shared/helper/FilterDateOption';

const RequestStatusFilter: FC = () => {
    const intl = useIntl()
    const [statuses, setStatuses] = useState({} as any)
    const [departments, setDepartments] = useState({} as any)
    const [types, setTypes] = useState({} as any)
    const [statusValue, setStatusValue] = useState({} as any)
    const [typeValue, setTypeValue] = useState({} as any)
    const [buttonColor, setButtonColor] = useState('primary')
    const [buttonColorActive, setButtonColorActive] = useState('btn-active-light-primary me-2 mb-1')
    const [selectDepartmentValue, setSelectDepartmentValue] = useState({} as any)
    const {updateState} = useQueryRequest()
    const [dateFrom, setDateFrom] = useState('');    
    const [dateTo, setDateTo] = useState('');   
  
    useEffect(() => {
        getRequestStatuses().then(result => {
            setStatuses(result?.data)
        })
        getDepartmentList().then(result => {
            setDepartments(result)
        })
        getRequestTypesFilterList('').then(result => {
            setTypes(result?.data)
        })
    }, []) 

    var departmentsList = Object.entries(departments).map(function(item: any){        
        return {
          value: item[1]?.id,
          label: item[1]?.name
        };
     });

    var typesList = Object.entries(types).map(function(item: any){
        return {
            value: item[1]?.id,
            label: "[" + item[1]?.max_we_level + "-" + item[1]?.we_level + "] " + item[1]?.name
        };
    });

     function filter(obj: any) {
      for (var key in obj) {
        if (obj[key] === undefined) {
          delete obj[key];
          continue;
        }
        if (obj[key] && typeof obj[key] === "object") {
          filter(obj[key]);
          if (!Object.keys(obj[key]).length) {
            delete obj[key];
          }
        }
      }
      return obj;
    }

    const setFilter = async () =>  {        
      const defaultFilterValues = {
        type: {value: typeValue?.value},
        department_id: {value: selectDepartmentValue?.value},
        status: {value: statusValue?.value},
        start_date: {value: dateFrom || undefined},
        end_date: {value: dateTo || undefined},
      }

      const filteredObjectValues = filter(defaultFilterValues)

      if(Object.keys(filteredObjectValues).length !== 0) {
          Object.assign(filteredObjectValues, {...initialQueryState});

          await updateState(filteredObjectValues)
          setButtonColor('success')
          setButtonColorActive('btn-active-light-success me-2 mb-1')
      
          NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FILTER_SUCCESSFULLY_APPLIED'}), '', 'success')
      } else {
          setButtonColor('primary')
          setButtonColorActive('btn-active-light-success me-2 mb-1')
          NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.MUST_CHOOSE_FILTER_VALUE'}), '', 'warning')
      }
    }

    const resetFilter = async () => {
      setDateFrom('')
      setDateTo('')
      setStatusValue({})
      setTypeValue({})
      setSelectDepartmentValue({})

      const defaultFilterValues = {
          type: {value: ''},
          department_id: {value: ''},
          status: {value: ''},
          start_date: {value: ''},
          end_date: {value: ''},
      }
      const filteredObjectValues = filter(defaultFilterValues)
      await updateState(filteredObjectValues)

      setButtonColor('primary')
      setButtonColorActive('btn-active-light-primary me-2 mb-1')
      NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FILTER_RESET_SUCCESSFULLY'}), '', 'success')
    }

    const statusesList =  statuses && statuses?.statuses?.filter((status: any) => status !== intl.formatMessage({id: 'REQUEST.STATUS.PRINT'}))?.map((item: any) => {
        switch (item) {
          case intl.formatMessage({id: 'REQUEST.STATUS.NEW'}): 
            return {
              value: item, label: intl.formatMessage({id: 'REQUEST.STATUS.NEW.DISPLAY'})
            }
          case intl.formatMessage({id: 'REQUEST.STATUS.PROCESSING'}): 
            return {
                value: item, label: intl.formatMessage({id: 'REQUEST.STATUS.PROCESSING.DISPLAY'})
            }
          case intl.formatMessage({id: 'REQUEST.STATUS.REJECT'}): 
            return {
                value: item, label: intl.formatMessage({id: 'REQUEST.STATUS.REJECT.DISPLAY'})
            }
          case intl.formatMessage({id: 'REQUEST.STATUS.FINISHED'}): 
            return {
                value: item, label: intl.formatMessage({id: 'REQUEST.STATUS.FINISHED.DISPLAY'})
            }
          case intl.formatMessage({id: 'REQUEST.STATUS.PRINT'}): 
            return {
                value: item, label: intl.formatMessage({id: 'REQUEST.STATUS.PRINT.DISPLAY'})
            }
        }
        return null
    })

    return (
        <Dropdown>
            <Dropdown.Toggle variant={buttonColor} className={buttonColorActive}>
                <KTSVG
                    path='/media/icons/duotune/general/gen031.svg'
                    className='svg-icon-5 svg-icon-gray-500 me-2'
                />
                {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER'})}
            </Dropdown.Toggle>
            <Dropdown.Menu className='px-4 py-4menu menu-sub menu-sub-dropdown w-250px w-sm-400px' align="end" id="dropdown-menu-align-end">
                <div className='px-2 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>{intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.OPTION'})}</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className='px-2 py-5'>
                    <div className='mb-3'>
                        <label className='form-label fw-bold'>{intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.TYPE'})}:</label>
                        <Select
                            placeholder={intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.TYPE'})}
                            options={typesList}
                            value={{
                                value: typeValue ? typeValue?.id : '',
                                label: typeValue?.label ? typeValue?.label : 'Izberite tip'
                            }}
                            onChange={(value: any) => setTypeValue(value)}
                            isSearchable={true}
                            classNamePrefix="select"
                            className={'mb-3 mb-lg-0'}
                        />
                    </div>
                    <div className='mb-3'>
                        <label className='form-label fw-bold'>{intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.STATUS'})}:</label>
                        <Select
                            placeholder={intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.STATUS'})}
                            options={statusesList}
                            value={{
                                value: statusValue ? statusValue?.id : '',
                                label: statusValue?.label ? statusValue?.label : 'Izberite status'
                            }}
                            onChange={(value: any) => setStatusValue(value)}
                            isSearchable={false}
                            classNamePrefix="select"
                            className={'mb-3 mb-lg-0'}
                        />
                    </div>
                    <div className='mb-3'>
                        <label className='form-label fw-bold'>{`${intl.formatMessage({id: 'JOB_POSITION.DEPARTMENT.PARENT'})}/${intl.formatMessage({id: 'JOB_POSITION.DEPARTMENT'})}`}:</label>
                        <Select
                            options={departmentsList}
                            value={{
                                value: selectDepartmentValue ? selectDepartmentValue?.value : '',
                                label: selectDepartmentValue?.label ? selectDepartmentValue?.label : intl.formatMessage({id: 'DEPARTMENT.SELECT'})
                            }}
                            onChange={(value: any) => setSelectDepartmentValue(value)}
                            isSearchable={true}
                            classNamePrefix="select"
                            className={'mb-3 mb-lg-0'}
                        />
                    </div>
                    <label className='form-label fs-6 fw-bold mt-2 mb-3'>{intl.formatMessage({id: 'NOTIFICATIONS.FILTER.DATE_RANGE'})}:</label>
                    <div className='d-flex'>
                        <div className='mb-3 pe-2'>
                            <label className='form-label fs-6 fw-bold'>{intl.formatMessage({id: 'CONTRACT.TOOLBAR.FILTER.DATE.FROM'})}:</label>
                            <Flatpickr
                                options={FilterDateOption}
                                className={'form-control form-control-solid mb-1 mb-lg-0'}
                                placeholder={intl.formatMessage({id: 'CONTRACT.TOOLBAR.FILTER.DATE.FROM'})}
                                name='profile.date_of_birth'
                                onChange={(newDates) => {
                                    setDateFrom(moment(newDates[0]).format('YYYY-MM-DD'));
                                }}
                                value={dateFrom ? moment(dateFrom).format('DD.MM.YYYY') : ''}
                            >
                            </Flatpickr>
                        </div>
                        <div className='mb-10'>
                            <label className='form-label fs-6 fw-bold'>{intl.formatMessage({id: 'CONTRACT.TOOLBAR.FILTER.DATE.TO'})}:</label>
                            <Flatpickr
                                options={FilterDateOptionRight}
                                className={'form-control form-control-solid mb-1 mb-lg-0'}
                                placeholder={intl.formatMessage({id: 'CONTRACT.TOOLBAR.FILTER.DATE.TO'})}
                                name='profile.date_of_birth'
                                onChange={(newDates) => {
                                    setDateTo(moment(newDates[0]).format('YYYY-MM-DD'));
                                }}
                                value={dateTo ? moment(dateTo).format('DD.MM.YYYY') : ''}
                            >
                            </Flatpickr>
                        </div>
                    </div>
                <div className='d-flex justify-content-end'>
                <button
                    type='reset'
                    className='btn btn-sm btn-light btn-active-light-danger me-2'
                    data-kt-menu-dismiss='true'
                    onClick={() => resetFilter()}
                >
                    {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.RESET'})}
                </button>
                <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'
                  onClick={() => setFilter()}
                >
                    {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.APPLY'})}
                </button>
                </div>
            </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export {RequestStatusFilter}
