/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from "react-intl";
import {DetailsHeader} from "../DetailsHeader";
import {User} from "../../../../../shared/models/_models";
import moment from 'moment';

type Props = {
    className: string,
    user?: User
}

const PersonalDetails: React.FC<Props> = ({className, user}) => {
    const intl = useIntl()

    let languagesArray = user?.profile?.foreign_languages?.split(' ').slice(0, -1);
    let drivingLicenceArray = user?.profile?.driving_licence?.split(' ').slice(0, -1);

    return (
        <div className={`card ${className}`} id='kt_profile_details_view'>
            <div className='card-header cursor-pointer'>
                <DetailsHeader user={user}/>
            </div>
            <div className='card-body mb-xl-4'>
                <div className='row pb-4'>
                    <label
                        className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.FULLNAME'})}</label>
                    <div className='col-sm-6 mb-5'>
                        <span
                            className='fs-6'>{(user && `${user?.first_name} (${user.profile?.parent_name || 'N/A'}) ${user?.last_name}`) || 'N/A'}</span>
                    </div>
                    <label
                        className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.ADDRESS'})}</label>
                    <div className='col-sm-6 mb-5'>
                        <span
                            className='fs-6'>{user?.profile?.address ? `${user.profile?.address}, ${user.profile?.municipality}, ${user.profile?.postal_number} ${user.profile?.city}` : 'N/A'}</span>
                    </div>
                    <label
                        className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.BIRTH_DATE'})}</label>
                    <div className='col-sm-6 mb-5'>
                        <span
                            className='fs-6'>{user?.profile?.date_of_birth ? moment(user?.profile?.date_of_birth).format('DD.MM.YYYY') : 'N/A'}</span>
                    </div>
                    <label
                        className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.BIRTH_PLACE'})}</label>
                    <div className='col-sm-6 mb-5'>
                        <span className='fs-6'>{user?.profile?.place_of_birth || 'N/A'}</span>
                    </div>
                    <label
                        className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.SERIAL_NUMBER'})}</label>
                    <div className='col-sm-6 mb-5'>
                        <span className='fs-6'>{user?.profile?.jmbg || 'N/A'}</span>
                    </div>
                    <label
                        className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.IDCARD_NUMBER'})}</label>
                    <div className='col-sm-6 mb-5'>
                        <span className='fs-6'>{user?.profile?.id_number || 'N/A'}</span>
                    </div>
                    <label
                        className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.GENDER'})}</label>
                    <div className='col-sm-6 mb-5'>
                        <span className='fs-6'>
                            {user?.profile?.gender === 'male' ? intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.GENDER.MALE'})
                                : user?.profile?.gender === 'female' ? intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.GENDER.FEMALE'}) :
                                    'N/A'}
                        </span>
                    </div>
                    <label
                        className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.PHONE_NUMBER'})}</label>
                    <div className='col-sm-6 mb-5'>
                        <span className='fs-6'>{user?.profile?.phone_number || 'N/A'}</span>
                    </div>
                    <label
                        className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.SCHOOL'})}</label>
                    <div className='col-sm-6 mb-5'>
                        <span className='fs-6'>{user?.profile?.education || 'N/A'}</span>
                    </div>
                    <label
                        className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.PROFESSION'})}</label>
                    <div className='col-sm-6 mb-5'>
                        <span className='fs-6'>{user?.profile?.profession || 'N/A'}</span>
                    </div>
                    <label
                        className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.DRIVING_LICENCE'})}</label>
                    <div className='col-sm-6 mb-5'>
                        <span className='fs-6'>{drivingLicenceArray?.join(', ') || 'N/A'}</span>
                    </div>
                    <label
                        className='col-sm-6 fw-semibold text-muted'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.LANGUAGES'})}</label>
                    <div className='col-sm-6 mb-5'>
                        <span className='fs-6'>{languagesArray?.join(', ') || 'N/A'}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {PersonalDetails}

