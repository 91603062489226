import Flatpickr from "react-flatpickr";
import moment from "moment";
import clsx from 'clsx'
import { CustomFlatPickerForTime } from "../../../shared/helper/CustomFlatPickerForTime";

const CustomTimeWidget = function(props: any) {
    return (
      <div  className='input-group'>
        <Flatpickr
          disabled={props.disabled}
          className={clsx('form-control form-control-solid mb-lg-0')}
          value={props?.value}
          options={CustomFlatPickerForTime}
          title = {props?.label}
          placeholder = {props?.label}
          onChange={(selectedTimes) => {
            props.onChange(moment(selectedTimes[0]).format('HH:mm'))
        }}
        ></Flatpickr>
        <span className="input-group-text border-0 ">
          <i className='fa fa-clock'></i>
        </span>
      </div>
    )
  };

  export {CustomTimeWidget}
