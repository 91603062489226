/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../../_metronic/partials'
import {getCSS, getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {useIntl} from 'react-intl'
import {ChartFilter} from './ChartFilter'
import {getRequestContractStatistics} from '../../../../shared/services/user-service'
import {SimpleLoading} from '../../../../modules/apps/shared/list/components/loading/SimpleLoading'

const ChartContractRequestStatistics: React.FC = () => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const intl = useIntl()
  const date = new Date();
  const [selectedYear, setSelectedYear] = useState(date.getFullYear() as any)
  const {mode} = useThemeMode()
  const [loading, setLoading] = useState(false)
  const [statistics, setStatistics] = useState([] as any)

  const asyncFetch = async () => {
    await getRequestContractStatistics(selectedYear).then((response: any) => {
      setStatistics(response)
    })
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    asyncFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear])

  useEffect(
    () => {
      const chart = refreshChart()

      return () => {
        if (chart) {
          chart.destroy()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartRef, mode, statistics]
  )

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, statistics))
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <div className='card card-xl-stretch h-100'>
      <div className='card-header border-bottom border-2 d-flex'>
        <h3 className='card-title align-items-start flex-column w-50'>
          <span className='card-label fw-bold mb-1'>
            {intl.formatMessage({id: 'DASHBOARD.HR.REQUEST.TITLE'})}
          </span>
        </h3>
        <div className='card-toolbar'>
          <ChartFilter setSelectedYear={setSelectedYear} />
        </div>
      </div>
      {!loading ? <> <div className='align-items-center ps-8 pt-5'>
        <div className='bg-danger ms-2 d-inline-block rounded-circle h-10px w-10px me-2'></div>
        <span className='fw-semibold fs-7 text-gray-800'>
          {intl.formatMessage({id: 'REQUESTS'})}
        </span>
        <div className='bg-primary ms-2 d-inline-block rounded-circle h-10px w-10px me-2'></div>
        <span className='fw-semibold fs-7 text-gray-800'>
          {intl.formatMessage({id: 'ACCOUNT.HEADER.CONTRACTS'})}
        </span>
      </div>
      <span className='ps-10 p-2 fw-semibold fs-6 text-muted'>
        {selectedYear}.
        {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.YEARS'})}
      </span>
        <div className='card-body'>
          <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />
        </div> </>
       :
        <div className='position-absolute top-50 start-50 translate-middle'>
          <SimpleLoading />
        </div>
      }
    </div>
  )
}

export {ChartContractRequestStatistics}

function getChartOptions(height: number, statistics: any): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-primary')
  const secondaryColor = getCSSVariableValue('--kt-danger')

  return {
    series: [
      {
        name: 'Ugovori',
        data: statistics[1]?.data,
      },
      {
        name: 'Zahtjevi',
        data: statistics[0]?.data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Maj',
        'Jun',
        'Jul',
        'Avg',
        'Sep',
        'Okt',
        'Nov',
        'Dec',
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ''
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
