import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useIntl} from "react-intl";

const themeValue = localStorage.getItem('kt_theme_mode_value');

const Error403: FC = () => {
    const intl = useIntl();

    return (
        <div className='d-flex flex-column flex-root align-items-center'>
            <div className='pt-20'>
                <h1 className='fw-bolder fs-2qx text-gray-800 text-center'>{intl.formatMessage({id: 'ERROR403.HEADER'})}</h1>
                <div className='fw-bold fs-3 text-muted mb-10 d-flex justify-content-center align-items-center'>
                    {intl.formatMessage({id: 'ERROR403.INFO'})}
                </div>
                <div className='text-center'>
                    <Link to='/' className='btn btn-lg btn-primary btn-hover-scale btn-active-light-primary fw-bolder'>
                        {intl.formatMessage({id: 'ERROR403.BUTTON'})}
                    </Link>
                </div>
            </div>
            <div className='d-flex flex-column flex-center flex-column-fluid'>
                {themeValue === 'light' ? <img
                    src={toAbsoluteUrl('/media/images/403_image.png')}
                    alt='404_image'
                    className='mw-100 h-lg-450px'
                /> : <img
                    src={toAbsoluteUrl('/media/images/403_image_dark.png')}
                    alt='404_image_dark'
                    className='mw-100 h-sm-500px'
                />}
            </div>
        </div>
    )
}

export {Error403}
