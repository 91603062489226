import {FC} from 'react'
import {Department} from '../../../../../shared/models/department'

type Props = {
    department: Department
    technical: string
    commercial: string
    global: string
}

const DepartmentSectorCell: FC<Props> = ({department, technical, commercial, global}) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            {department.sector === 'technical' ? technical : department.sector === 'commercial' ?
                commercial : department.sector === 'global' ? global : '-'
            }
        </div>
    </div>
)

export {DepartmentSectorCell}
