/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Select from "react-select";
import { initialQueryState, KTSVG } from '../../../_metronic/helpers';
import { useQueryRequest } from '../../modules/apps/shared/list/core/QueryRequestProvider';
import { NotificationMessage } from '../../shared/helper/Notification';
import { apuLevels } from '../../shared/SelectLists';
import { getParentDepartmentList} from '../../shared/services/department-service';

const UserFilter: FC = () => {
    const intl = useIntl();
    const [departments, setDepartments] = useState({} as any);
    const [activityValue, setActivityValue] = useState({} as any);
    const [apuSelectedValue, setSelectedApuValue] = useState({} as any);
    const [buttonColor, setButtonColor] = useState('primary');
    const [buttonColorActive, setButtonColorActive] = useState('btn-active-light-primary mb-1');

    const [selectDepartmentValue, setSelectDepartmentValue] = useState({} as any);
    const {updateState} = useQueryRequest();
    const activityList =  [{value: 1, label: 'Aktivan'}, {value: 0, label: 'Neaktivan'}];

    useEffect(() => {
        setButtonColor('success');
        setButtonColorActive('btn-active-light-success mb-1');
        setActivityValue({value: 1, label: 'Aktivan'});
        updateState({is_active: {value: 1}, ...initialQueryState});

        getParentDepartmentList().then(result => {
            setDepartments(result)
        });
    },
        // eslint-disable-next-line
        [])

    var departmentsList = Object.entries(departments).map(function(item: any){
        return {
          value: item[1]?.id,
          label: item[1]?.name
        };
     });


     function filter(obj: any) {
        for (var key in obj) {
          if (obj[key] === undefined) {
            delete obj[key];
            continue;
          }
          if (obj[key] && typeof obj[key] === "object") {
            filter(obj[key]);
            if (!Object.keys(obj[key]).length) {
              delete obj[key];
            }
          }
        }
        return obj;
      }

    const setFilter = async () =>  {                
        const defaultFilterValues = {
            is_active: {value: activityValue?.value},
            department_id: {value: selectDepartmentValue?.value},
            apu_value: {value: apuSelectedValue?.value}
        }
  
        const filteredObjectValues = filter(defaultFilterValues)
  
        if(Object.keys(filteredObjectValues).length !== 0) {
            Object.assign(filteredObjectValues, {...initialQueryState});
  
            await updateState(filteredObjectValues)
            setButtonColor('success')
            setButtonColorActive('btn-active-light-success mb-1')
        
            NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FILTER_SUCCESSFULLY_APPLIED'}), '', 'success')
        } else {
            setButtonColor('primary')
            setButtonColorActive('btn-active-light-success mb-1')
            NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.MUST_CHOOSE_FILTER_VALUE'}), '', 'warning')
        }
      }

    const resetFilter = async () => {
        setActivityValue({})
        setSelectDepartmentValue({})
        setSelectedApuValue({})
  
        const defaultFilterValues = {
            is_active: {value: ''},
            department_id: {value: ''},
            apu_value: {value: ''}
        }
        const filteredObjectValues = filter(defaultFilterValues)
        await updateState(filteredObjectValues)
  
        setButtonColor('primary')
        setButtonColorActive('btn-active-light-primary mb-1')
        NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FILTER_RESET_SUCCESSFULLY'}), '', 'success')
      }

    return (
        <Dropdown>
            <Dropdown.Toggle variant={buttonColor} className={buttonColorActive}>
                <KTSVG
                    path='/media/icons/duotune/general/gen031.svg'
                    className='svg-icon-5 svg-icon-gray-500 me-2'
                />
                {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER'})}
            </Dropdown.Toggle>
            <Dropdown.Menu className='px-4 py-4menu menu-sub menu-sub-dropdown w-250px w-sm-300px' align="start" id="dropdown-menu-align-end">
                <div className='px-2 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>{intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.OPTION'})}</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className='px-2 py-5'>
                    <div className='mb-3'>
                        <label className='form-label fw-bold'>{`${intl.formatMessage({id: 'DEPARTMENT'})}`}:</label>
                        <Select
                            options={departmentsList}
                            value={{
                                value: selectDepartmentValue ? selectDepartmentValue?.value : '',
                                label: selectDepartmentValue?.label ? selectDepartmentValue?.label : intl.formatMessage({id: 'SELECT_DEPARTMENT'})
                            }}
                            onChange={(value: any) => setSelectDepartmentValue(value)}
                            isSearchable={true}
                            classNamePrefix="select"
                            className={'mb-3 mb-lg-0'}
                        />
                    </div>
                     <div className='mb-3'>
                        <label className='form-label fw-bold'>{intl.formatMessage({id: 'TABLE.USER.ACTIVITY'})}:</label>
                        <Select
                            options={activityList}
                            value={{
                                value: activityValue ? activityValue?.id : '',
                                label: activityValue?.label ? activityValue?.label : intl.formatMessage({id: 'SELECT_ACTIVITY'})
                            }}
                            onChange={(value: any) => setActivityValue(value)}
                            isSearchable={false}
                            classNamePrefix="select"
                            className={'mb-3 mb-lg-0'}
                        />
                    </div>
                    <div className='mb-10'>
                        <label className='form-label fw-bold'>{intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.APU_LEVEL'})}:</label>
                        <Select
                            options={apuLevels}
                            value={{
                                value: apuSelectedValue ? apuSelectedValue?.id : '',
                                label: apuSelectedValue?.label ? apuSelectedValue?.label : intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.APU_LEVEL.CHOOSE'})
                            }}
                            onChange={(value: any) => setSelectedApuValue(value)}
                            isSearchable={false}
                            classNamePrefix="select"
                            className={'mb-3 mb-lg-0'}
                        />
                    </div>
                <div className='d-flex justify-content-end'>
                <button
                    type='reset'
                    className='btn btn-sm btn-light btn-active-light-danger me-2'
                    data-kt-menu-dismiss='true'
                    onClick={() => resetFilter()}
                >
                    {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.RESET'})}
                </button>
                <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'
                  onClick={() => setFilter()}
                >
                    {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.APPLY'})}
                </button>
                </div>
            </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export {UserFilter}
