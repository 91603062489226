import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { RequestForm } from './RequestForm'
import { Overview } from './RequestPage/Overview';

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Requests',
    path: '/overview',
    isSeparator: false,
    isActive: false,
  }
]

const RequestPage: React.FC = () => {  

return (
<Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
    <Route
    path='/overview'
    element={
      <>
        <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
        <Overview/>
      </>
    }
    />
    <Route
      path='/edit'
      element={
        <>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Request</PageTitle>
          <RequestForm/>
        </>
      }
    />
      <Route index element={<Navigate to='/overview'/>} />
    </Route>
  </Routes>
)}

export {RequestPage}
