/* eslint-disable react-hooks/exhaustive-deps */
import {UserCreateModalForm} from './UserCreateModalForm'
import {useListView} from '../../../shared/list/core/ListViewProvider'

const UserCreateModalFormWrapper = () => {
    const {itemIdForUpdate} = useListView()

    if (!itemIdForUpdate) {
        return (
            <UserCreateModalForm/>
        )
    }

    return null
}

export {UserCreateModalFormWrapper}
