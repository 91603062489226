import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import {useFormik} from 'formik';
import {useIntl} from "react-intl";
import {isNotEmpty} from "../../../../_metronic/helpers";
import {useNavigate, useParams} from "react-router-dom";
import {NotificationMessage} from "../../../shared/helper/Notification";
import {getUserForResetPassword, resetPasswordUser} from "../../../shared/services/user-service";

const initialValues = {
    resetPasswordToken: '',
    newPassword: '',
    newPassword_repeat: '',
}

export function ResetPassword() {
    const intl = useIntl();
    const navigate = useNavigate();
    const resetPasswordToken = useParams()['*'];
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({} as any);

    useEffect(() => {
            getUserForResetPassword(resetPasswordToken, '')
                .then((response: any) => {
                    if (response?.errors) {
                        backToLogin();
                    } else {
                        setUser(response);
                    }
                })
        },
        // eslint-disable-next-line
        []);

    const resetPasswordAccountSchema = Yup.object().shape({
        resetPasswordToken: Yup.string(),
        newPassword: Yup.string()
            .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.INVALID_PASSWORD'})
            )
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .nullable(),
        newPassword_repeat: Yup.string()
            .nullable()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .when('newPassword', {
                is: (password: string) => isNotEmpty(password),
                then: (password_repeat) => password_repeat.required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
                    .oneOf([Yup.ref('newPassword'), null], intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.EQUAL_PASSWORD'}))
            })
    });

    function backToLogin() {
        navigate(`/login`);
    }

    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordAccountSchema,
        onSubmit: (values) => {
            setLoading(true);
            try {
                resetPasswordToken && (values.resetPasswordToken = resetPasswordToken);
                resetPasswordUser(user?.id, values).then(() => {
                    NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.RESET_PASSWORD_SUCCESSFUL'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.LOGIN_PLEASE'}), 'success');
                    backToLogin();
                })
            } catch (e) {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.RESET_PASSWORD_NOT_SUCCESSFUL'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.CONTACT_US'}), 'danger');
                backToLogin();
            }
        },
    })

    return (
        <>
            {!user.id ? <div style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#7e8299'
                }}>
                    <div className="spinner-border" role="status"></div>
                    <span className='text-gray-600 fw-bold'>{intl.formatMessage({id: 'LOADING'})}</span>
                </div> :
                <>
                    <div className='text-center mb-5'>
                        <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'RESET_PASSWORD'})}</h1>
                        <div className='text-gray-400 fw-bold fs-4'>
                            {intl.formatMessage({id: 'RESET_PASSWORD.INFO'})}
                        </div>
                    </div>
                    <form
                        className='form w-100'
                        onSubmit={formik.handleSubmit}
                        noValidate
                    >
                        <div className='fv-row mb-10'>
                            <label
                                className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({id: 'RESET_PASSWORD.NEW_PASSWORD'})}</label>
                            <input
                                placeholder={intl.formatMessage({id: 'RESET_PASSWORD.NEW_PASSWORD'})}
                                {...formik.getFieldProps('newPassword')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid border border-danger': formik.touched.newPassword && formik.errors.newPassword,
                                        'is-valid border border-success': formik.touched.newPassword && !formik.errors.newPassword,
                                    },
                                )}
                                type='password'
                                name='newPassword'
                                autoComplete='off'
                            />
                            {formik.touched.newPassword && formik.errors.newPassword && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.newPassword}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='fv-row mb-10'>
                            <div className='d-flex justify-content-between mt-n5'>
                                <label
                                    className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({id: 'ACTIVATION_USER.PASSWORD_REPEAT'})}</label>
                            </div>
                            <input
                                type='password'
                                {...formik.getFieldProps('newPassword_repeat')}
                                name='newPassword_repeat'
                                placeholder={intl.formatMessage({id: 'ACTIVATION_USER.PASSWORD_REPEAT'})}
                                autoComplete='off'
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid border border-danger': formik.touched.newPassword_repeat && formik.errors.newPassword_repeat,
                                        'is-valid border border-success': formik.touched.newPassword_repeat && !formik.errors.newPassword_repeat,
                                    },
                                )}
                            />
                            {formik.touched.newPassword_repeat && formik.errors.newPassword_repeat && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.newPassword_repeat}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                            <button
                                type='submit'
                                className='btn btn-lg btn-primary fw-bolder me-4'
                                disabled={loading}
                            >
                                {!loading && intl.formatMessage({id: 'RESET_PASSWORD.RESET'})}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                    {intl.formatMessage({id: 'RESET_PASSWORD.RESETTING'})}...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </button>
                            <button
                                type='button'
                                onClick={() => backToLogin()}
                                className='btn btn-lg btn-light-primary fw-bolder'
                                disabled={loading}
                            >
                                {intl.formatMessage({id: 'RESET_PASSWORD.CANCEL'})}
                            </button>
                        </div>
                    </form>
                </>
            }
        </>
    )
}
