import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useIntl} from "react-intl";

const themeValue = localStorage.getItem('kt_theme_mode_value');

const Error404: FC = () => {
    const intl = useIntl();

    return (
        <div className='d-flex flex-column flex-root'>
            <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
                {themeValue === 'light' ? <img
                    src={toAbsoluteUrl('/media/images/404_image.png')}
                    alt='404_image'
                    className='mw-100 mb-10 h-lg-450px'
                /> : <img
                    src={toAbsoluteUrl('/media/images/404_image_dark.png')}
                    alt='404_image_dark'
                    className='mw-100 mb-10 h-lg-450px'
                />}
                <h1 className='fw-bold mb-10 text-gray-500'>
                    {intl.formatMessage({id: 'ERROR404.INFO'})}
                </h1>
                <Link to='/' className='btn btn-lg btn-primary btn-hover-scale btn-active-light-primary fw-bolder'>
                    {intl.formatMessage({id: 'ERROR404.BUTTON'})}
                </Link>
            </div>
        </div>
    )
}

export {Error404}
