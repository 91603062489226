/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import {KTSVG} from '../../../_metronic/helpers';
import {NotificationMessage} from '../../shared/helper/Notification';
import {
    exportFamilyDetailsToExcel,
    exportUsersToExcel,
    exportVacationDetailsToExcel
} from "../../shared/services/user-service";
import moment from "moment/moment";

type props = {
    setIsExporting: any
}

const UsersReport: React.FC<props> = ({setIsExporting}) => {
    const intl = useIntl();

    const exportUsers = () => {
        setIsExporting(true);
        exportUsersToExcel()
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'Poslovni podaci - VERITAS AUTOMOTIVE - ' + moment().format('YYYY-MM-DD HH-mm-ss') + '.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                setIsExporting(false);
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.EXPORT.USER'}), '', 'success');
            });
    }

    const exportFamilyDetails = () => {
        setIsExporting(true);
        exportFamilyDetailsToExcel()
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'Porodični podaci - VERITAS AUTOMOTIVE - ' + moment().format('YYYY-MM-DD HH-mm-ss') + '.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                setIsExporting(false);
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.EXPORT.FAMILY'}), '', 'success');
            });
    }

    const exportVacationDetails = () => {
        setIsExporting(true);
        exportVacationDetailsToExcel()
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'Podaci o godišnjim odmorima - VERITAS AUTOMOTIVE - ' + moment().format('YYYY-MM-DD HH-mm-ss') + '.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                setIsExporting(false);
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.EXPORT.VACATION'}), '', 'success');
            });
    }

    return (

        <Dropdown className='me-2'>
            <OverlayTrigger placement="top"
                            overlay={
                                <Tooltip>{intl.formatMessage({id: 'TOOLTIP.COMMENT.EXPORT.USER'})}</Tooltip>}>
                <Dropdown.Toggle className='mb-1 btn-active-light-primary'>
                    <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-2 m-0'/>
                </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu className='px-4 py-4menu menu-sub menu-sub-dropdown w-200px w-sm-250px' align="end"
                           id="dropdown-menu-align-end">
                <div className='px-2 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>{intl.formatMessage({id: 'REPORT.OPTIONS'})}:</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className='px-2 py-5'>
                    <div className='mb-3'>
                        <button type='button' className='btn btn-light btn-active-light-primary w-100 fw-bold'
                                onClick={exportUsers}
                        >
                            <KTSVG path='/media/icons/duotune/files/fil010.svg' className='svg-icon-2 m-0 me-2'/>
                            {intl.formatMessage({id: 'REPORT.OPTIONS.BUSINESS'})}
                        </button>
                    </div>
                    <div className='mb-3'>
                        <button type='button' className='btn btn-light btn-active-light-primary w-100 fw-bold'
                                onClick={exportFamilyDetails}
                        >
                            <KTSVG path='/media/icons/duotune/communication/com014.svg'
                                   className='svg-icon-2 m-0 me-2'/>
                            {intl.formatMessage({id: 'REPORT.OPTIONS.FAMILY'})}
                        </button>
                    </div>
                    <div>
                        <button type='button' className='btn btn-light btn-active-light-primary w-100 fw-bold'
                                onClick={exportVacationDetails}
                        >
                            <KTSVG path='/media/icons/duotune/general/gen060.svg' className='svg-icon-2 m-0 me-2'/>
                            {intl.formatMessage({id: 'REPORT.OPTIONS.VACATION'})}
                        </button>
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export {UsersReport}
