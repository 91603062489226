import React, {useState} from 'react';
import {Dropdown} from "react-bootstrap";
import {useIntl} from "react-intl";
import clsx from "clsx";
import {FC} from 'react'
import {
    finishProbationWorkContract,
    getPrintedContract,
    resolveExpiringContract
} from '../../shared/services/contract-service';
import {Contract} from '../../shared/models/_models';
import {useNavigate} from 'react-router-dom';
import {ContractEndConfirmation} from "./ContractEndConfirmation";

type Props = {
    userId: any,
    setOpenModal: any,
    setActions: any,
    setIsDownloading: any,
    contract: Contract,
    setEnd: any
}

const ContractStatusActionsDropdown: FC<Props> = ({
                                                      userId,
                                                      setOpenModal,
                                                      setActions,
                                                      setIsDownloading,
                                                      contract,
                                                      setEnd
                                                  }) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const [openConfirmation, setOpenConfirmation] = useState(false)

    const setActionData = async (action: any) => {
        if (action?.name === intl.formatMessage({id: 'CONTRACT.ACTIONS.UPLOAD.DISPLAY'})) {
            setOpenModal(true)
            setActions(action)
        } else if (action?.name === intl.formatMessage({id: 'CONTRACT.DROPDOWN.STATUS.PRINT'})) {
            setIsDownloading(true)
            await getPrintedContract({...contract})
                .then((response: any) => {
                        window.open(`${response?.data?.url}`, `_blank`)
                    }
                )
            setIsDownloading(false)
        } else if (action?.name === intl.formatMessage({id: 'CONTRACT.ACTION.CREATE.NEW'})) {
            navigate(`/contract/create/${userId}`)
        } else if (action?.name === intl.formatMessage({id: 'CONTRACT.ACTION.END'})) {
            setOpenConfirmation(true);
        } else if (action?.name === intl.formatMessage({id: 'CONTRACT.ACTION.PROBATION_END'})) {
            setIsDownloading(true)
            await finishProbationWorkContract({...contract})
                .then((response: any) => {
                    window.location.reload();
                })
        } else if (action?.name === intl.formatMessage({id: 'CONTRACT.ACTION.RESOLVE'})) {
            setIsDownloading(true)
            await resolveExpiringContract({...contract})
                .then((response: any) => {
                    window.location.reload();
                })
        }
    }

    let actions = [
        {
            id: 'new',
            name: intl.formatMessage({id: 'CONTRACT.ACTION.CREATE.NEW'})
        },
        {
            id: 'end',
            name: intl.formatMessage({id: 'CONTRACT.ACTION.END'})
        },
        {
            id: 'upload',
            name: intl.formatMessage({id: 'CONTRACT.ACTIONS.UPLOAD.DISPLAY'})
        },
        {
            id: 'print',
            name: intl.formatMessage({id: 'CONTRACT.DROPDOWN.STATUS.PRINT'})
        }
    ]

    let shouldAddOptionToFinishProbationWork = contract?.is_on_probation && !contract?.is_finished_probation;
    let shouldAddOptionToResolveContract = !contract?.is_resolved;

    if (shouldAddOptionToFinishProbationWork) {
        actions.unshift({
            id: 'probation_end',
            name: intl.formatMessage({id: 'CONTRACT.ACTION.PROBATION_END'})
        });
    }

    if (shouldAddOptionToResolveContract) {
        actions.unshift({
            id: 'resolve',
            name: intl.formatMessage({id: 'CONTRACT.ACTION.RESOLVE'})
        });
    }

    // eslint-disable-next-line
    const optionsLists = actions?.map((item: any) => {
        switch (item?.name) {
            case intl.formatMessage({id: 'CONTRACT.ACTION.CREATE.NEW'}):
                return {
                    id: item?.id,
                    name: intl.formatMessage({id: 'CONTRACT.ACTION.CREATE.NEW'}),
                    icon: 'fa-solid fa-file'
                }
            case intl.formatMessage({id: 'CONTRACT.ACTIONS.UPLOAD.DISPLAY'}):
                return {
                    id: item?.id,
                    name: intl.formatMessage({id: 'CONTRACT.ACTIONS.UPLOAD.DISPLAY'}),
                    icon: 'fa-solid fa-upload'
                }
            case intl.formatMessage({id: 'CONTRACT.DROPDOWN.STATUS.PRINT'}):
                return {
                    id: item?.id,
                    name: intl.formatMessage({id: 'CONTRACT.DROPDOWN.STATUS.PRINT'}),
                    icon: 'fa-solid fa-print'
                }
            case intl.formatMessage({id: 'CONTRACT.ACTION.END'}):
                return {
                    id: item?.id, name: intl.formatMessage({id: 'CONTRACT.ACTION.END'}), icon: 'fa-solid fa-solid fa-x'
                }
            case intl.formatMessage({id: 'CONTRACT.ACTION.PROBATION_END'}):
                return {
                    id: item?.id,
                    name: intl.formatMessage({id: 'CONTRACT.ACTION.PROBATION_END'}),
                    icon: 'fa-solid fa-hourglass-end'
                }
            case intl.formatMessage({id: 'CONTRACT.ACTION.RESOLVE'}):
                return {
                    id: item?.id,
                    name: intl.formatMessage({id: 'CONTRACT.ACTION.RESOLVE'}),
                    icon: 'fa-solid fa-check'
                }
        }
        return null
    })

    return (
        <Dropdown>
            <Dropdown.Toggle variant="primary" className='btn-active-light-primary'>
                {intl.formatMessage({id: 'CONTRACT.ACTIONS'})}
            </Dropdown.Toggle>
            <Dropdown.Menu
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-250px'
                align="end" id="dropdown-menu-align-end">
                <div className='menu-item px-3'>
                    <div
                        className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>{intl.formatMessage({id: 'CONTRACT.DROPDOWN'})}</div>
                </div>
                <div className='separator mb-3 opacity-75'></div>
                {optionsLists?.length > 0 ?
                    optionsLists?.map((action: any) => (
                        action?.name !== intl.formatMessage({id: 'CONTRACT.ACTION.END'}) ?
                            <div className='menu-item px-3' key={action?.id}>
                                <div onClick={() => setActionData(action)} className='menu-link px-3'>
                                    <i className={clsx(action?.icon, 'me-2 text-primary fa-lg')}></i>
                                    {action?.name}
                                </div>
                            </div> :
                            <div key={action?.id}>
                                <div className='menu-item px-3'>
                                    <div onClick={() => setActionData(action)} className='menu-link px-3'>
                                        <i className={clsx(action?.icon, 'me-2 text-primary fa-lg')}></i>
                                        {action?.name}
                                    </div>
                                </div>
                                <div className='separator my-3 mx-3'></div>
                            </div>
                    ))
                    : <div className='menu-item px-3'>
                        <div className='menu-link disabled px-3' style={{cursor: 'not-allowed'}}>
                            {intl.formatMessage({id: 'CONTRACT.DROPDOWN.STATUS.REJECT.DISPLAY'})}
                        </div>
                    </div>
                }
                <div className='separator mt-3 opacity-75 border-0'></div>
            </Dropdown.Menu>
            {openConfirmation &&
                <ContractEndConfirmation setOpenModal={setOpenConfirmation} contract={contract} setEnd={setEnd}/>}
        </Dropdown>
    )
}

export {ContractStatusActionsDropdown}
