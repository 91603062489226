import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {postRequestCode} from '../../shared/services/access-token-service';
import {useLocation} from "react-router-dom";
import {ListLoading} from '../apps/shared/list/components/loading/ListLoading';

const AccessRequestCode = () => {
    const myParam = encodeURI(useLocation().search);
    const navigate = useNavigate();
    const request_code = new URLSearchParams(myParam).get('request_code');

    useEffect(() => {
            postRequestCode(request_code).then((response: any) => {
                    navigate(`/request/${response}/overview`)
                }
            )
        },
        // eslint-disable-next-line
        [])

    return (
        <>
            <ListLoading/>
        </>
    )
}

export {AccessRequestCode}
