import React from 'react'
import {useIntl} from 'react-intl'
import Avatar from 'react-avatar'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import {avatarColorConfig} from '../../../../../shared/AvatarConfig'
import {KTSVG} from '../../../../../../_metronic/helpers'

type Props = {
  user: any
}

const UserDasboardInfo: React.FC<Props> = ({user}) => {
  const technical = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.TECHNICAL'})
  const commercial = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.COMMERCIAL'})
  const global = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.GLOBAL'})
  const intl = useIntl()

  return (
    <>
      <div className='card card-xxl-stretch mb-5'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12 col-xl-4 m-auto'>
              <div className='d-flex align-items-center'>
                <div className='me-7'>
                  <div className='symbol symbol-60px symbol-lg-100px symbol-fixed position-relative'>
                    {user?.profile?.image_url ? (
                      <LazyLoadImage
                        src={user?.profile?.image_url}
                        width={'100px'}
                        height={'100px'}
                        style={{borderRadius: '10px'}}
                        effect='blur'
                      />
                    ) : (
                      <Avatar
                        name={user?.first_name + ' ' + user?.last_name}
                        round={'10px'}
                        color={avatarColorConfig.color}
                        fgColor={avatarColorConfig.fgColor}
                        textSizeRatio={2 / 3}
                        title={avatarColorConfig.title}
                        className={avatarColorConfig.style}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <h5 className='font-weight-bolder text-dark-75'>
                    {user?.first_name + ' ' + user?.last_name || '-'}
                  </h5>
                  <div className='text-muted'>{user?.job_position?.name || '-'}</div>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-6 mt-5 mt-xl-2 col-xl-4 m-auto'>
              <div className='d-flex align-items-center mb-2 pt-2'>
                <span className='svg-icon pe-3 svg-icon-2x'>
                  <KTSVG path='/media/icons/duotune/abstract/abs031.svg' className='svg-icon-2' />
                </span>
                <span className='fw-bold me-2 text-muted'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.CODE'})}:
                </span>
                <span className='text-end text-sm-start w-100 w-sm-25'>{user?.profile?.code || '-'}</span>
              </div>
              <div className='d-flex align-items-center mb-2 pt-2'>
                <span className='svg-icon pe-3 svg-icon-2x'>
                  <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-2' />
                </span>
                <span className='fw-bold me-2 text-muted'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.VIEW'})}:
                </span>
                <span className='w-100 text-end text-sm-start'>
                  {user?.job_position?.sector === 'technical'
                    ? technical
                    : user?.job_position?.sector === 'commercial'
                    ? commercial
                    : user?.job_position?.sector === 'global'
                    ? global
                    : 'N/A'}
                </span>
              </div>
            </div>
            <div className='col-12 col-sm-6 mt-xl-2 col-xl-4 m-auto'>
              <div className='d-flex align-items-center mb-2 pt-2'>
                <span className='svg-icon pe-3 svg-icon-2x'>
                  <KTSVG path='/media/icons/duotune/finance/fin001.svg' className='svg-icon-2' />
                </span>
                <span className='text-muted fw-bold me-2'>
                  {intl.formatMessage({id: 'JOB_POSITION.DEPARTMENT.INITIAL'})}:
                </span>
                <span className='text-end text-sm-start w-100'>
                  {user?.job_position?.department?.parent
                    ? user?.job_position?.department?.parent?.name
                    : user?.job_position?.department
                    ? user?.job_position?.department?.name
                    : '-'}
                </span>
              </div>
              <div className='d-flex align-items-center mb-2 pt-2'>
                <span className='svg-icon pe-3 svg-icon-2x'>
                  <KTSVG path='/media/icons/duotune/graphs/gra001.svg' className='svg-icon-2' />
                </span>
                <span className='text-muted fw-bold me-2'>
                  {intl.formatMessage({id: 'JOB_POSITION.DEPARTMENT.PARENT'})}:
                </span>
                <span className='w-100 text-end text-sm-start'>
                  {user?.job_position?.department?.parent
                    ? user?.job_position?.department?.name
                    : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {UserDasboardInfo}
