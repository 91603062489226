import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {ContractElement} from './ContractElement'
import {User} from '../../../../shared/models/_models'
import {getContracts} from '../../../../shared/services/contract-service'
import {Contract} from '../../../../shared/models/contract'
import {ContractDetailsModal} from '../../../dashboard/modals/ContractDetailsModal'
import {SimpleLoading} from '../../../../modules/apps/shared/list/components/loading/SimpleLoading'
import {ContractMobileElement} from './ContractMobileElement'
import {KTSVG} from "../../../../../_metronic/helpers";
import {useNavigate} from "react-router-dom";

type Props = {
    user: User
}

const ContractTable: React.FC<Props> = ({user}) => {
    const intl = useIntl()
    const navigate = useNavigate()

    const [openContractModal, setOpenContractModal] = useState(false)
    const [userContractInfo, setUserContractInfo] = useState({} as Contract)
    const [contracts, setContracts] = useState([] as Contract[] | undefined)
    const [isLoadingContracts, setIsLoadingContracts] = useState(true)
    useEffect(() => {
        if (user?.id !== undefined) {
            getContracts('user_id=' + user.id)
                .then((response) => response.data)
                .then((result) => {
                    setContracts(result)
                    setIsLoadingContracts(false)
                })
        }
    }, [user?.id])

    return (
        <div className='card mb-5 mb-xl-10 card-xl-stretch'>
            <div className='card-header border-0'>
                <div className='card-title m-0 d-flex w-100 d-none d-sm-flex'>
                    <h3 className='fw-bolder m-0 w-50'>
                        {intl.formatMessage({id: 'CONTRACTS'})}
                    </h3>
                    <div className='d-flex w-50 d-flex justify-content-end'>
                        <button type='submit' className='btn btn-primary'
                                onClick={() => navigate(`/contract/create/${user?.id}`)}
                        >
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                            {intl.formatMessage({id: 'CONTRACTS.ADD'})}
                        </button>
                    </div>
                </div>
                {!isLoadingContracts ? (
                    <div className='card-title m-0 d-flex w-100 d-sm-none'>
                        <h3 className='fw-bolder m-0 w-50'>
                            {intl.formatMessage({id: 'CONTRACTS'})}
                        </h3>
                        <div className='d-flex w-50 d-flex justify-content-end'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                onClick={() => navigate(`/contract/create/${user?.id}`)}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                                {intl.formatMessage({id: 'CONTRACTS.ADD'})}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className='d-sm-none w-100'>
                        <SimpleLoading/>
                    </div>
                )}
            </div>
            <div id='kt_account_contract_details' className='collapse show'>
                <div className='card-body border-top pt-1'>
                    {!isLoadingContracts ? (
                        <div className='table-responsive row'>
                            <div className='d-none d-sm-flex d-md-flex d-lg-flex'>
                                <div className='col-12'>
                                    <table className='table align-middle contract-table'>
                                        <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='text-start align-middle text-uppercase'>
                                                {intl.formatMessage({id: 'CONTRACT.TABLE.STATUS'})}
                                            </th>
                                            <th className='min-w-100px text-uppercase text-center align-middle'>
                                                {intl.formatMessage({id: 'CONTRACT.CODE'})}
                                            </th>
                                            <th className='min-w-100px text-uppercase text-center align-middle'>
                                                {intl.formatMessage({id: 'CONTRACT.TYPE'})}
                                            </th>
                                            <th className='min-w-150px text-uppercase text-center align-middle'>
                                                {intl.formatMessage({id: 'CONTRACT.START_DATE'})}
                                            </th>
                                            <th className='min-w-150px text-uppercase text-center align-middle pe-6'>
                                                {intl.formatMessage({id: 'CONTRACT.END_DATE'})}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {contracts && contracts?.length > 0 ? (
                                            contracts
                                                ?.sort((contract1, contract2) =>
                                                    contract1?.contract_date && contract2?.contract_date
                                                        ? contract1?.contract_date > contract2?.contract_date
                                                            ? -1
                                                            : 1
                                                        : -1
                                                )
                                                .map((contract) => (
                                                    <ContractElement
                                                        key={contract.id}
                                                        contract={contract}
                                                        setOpenContractModal={setOpenContractModal}
                                                        setUserContractInfo={setUserContractInfo}
                                                    ></ContractElement>
                                                ))
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={5}
                                                    className='text-center pt-5 justify-content-center text-center'
                                                >
                            <span className='text-gray-800 fs-6'>
                              {intl.formatMessage({id: 'DASHBOARD.CONTRACT.INFO.NOT_FOUND'})}
                            </span>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='d-block d-sm-none d-md-none d-lg-none'>
                                {contracts && contracts?.length > 0 ? (
                                    contracts
                                        ?.sort((contract1, contract2) =>
                                            contract1?.contract_date && contract2?.contract_date
                                                ? contract1?.contract_date > contract2?.contract_date
                                                    ? -1
                                                    : 1
                                                : -1
                                        )
                                        .map((contract) => (
                                            <ContractMobileElement
                                                key={contract.id}
                                                contract={contract}
                                                setOpenContractModal={setOpenContractModal}
                                                setUserContractInfo={setUserContractInfo}
                                            />
                                        ))
                                ) : (
                                    <div className='pt-5 d-flex justify-content-center'>
                    <span className='text-gray-800 fs-6'>
                      {intl.formatMessage({id: 'DASHBOARD.CONTRACT.INFO.NOT_FOUND'})}
                    </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className='d-none d-sm-flex position-absolute top-50 start-50 translate-middle'>
                            <SimpleLoading/>
                        </div>
                    )}
                </div>
            </div>
            {openContractModal && (
                <ContractDetailsModal setOpenModal={setOpenContractModal} contractInfo={userContractInfo}/>
            )}
        </div>
    )
}

export {ContractTable}
