import React, {FC, useState} from 'react'
import {useIntl} from "react-intl";
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone, {IDropzoneProps, IInputProps} from 'react-dropzone-uploader'
import { postUserDocumentation, uploadFile } from '../../../../shared/services/file-service';
import { KTCardBody } from '../../../../../_metronic/helpers';
import { NotificationMessage } from '../../../../shared/helper/Notification';

type Props = {
    setOpenModal: any
    user: any,
    setClosedWithAction:any
    asyncFetch: any
}

declare global {
    interface Window {
        isApprovedButton: boolean,
        validationFormData: object,
    }
}

const AdditionalFilesAddModal: FC<Props> = ({setOpenModal, user, setClosedWithAction, asyncFetch}) => {
    const intl = useIntl()
    const [comment, setComment] = useState('')
    const [loading, setLoading] = useState(false);
    const [uploadFileLoading, setUploadFileLoading] = useState(false)
    const [dropzoneValidation, setDropzoneValidation] = useState({
        minHeight: 200,
        justifyContent: 'center',
        borderColor: "#3699FF",
        borderWidth: 2,
        borderRadius: 7,
        borderStyle: 'dashed',
        overflow: 'hidden'
    })
    const [dropzoneValidationMessage, setDropzoneValidationMessage] = useState(false)
    const [arrayOfFileIdsForUpload, setArrayOfFileIdsForUpload] = React.useState<any[]>([]);
    
    const closeModal = () => {
        setOpenModal(false)
    }

    const uploadAdditionalRequstFiles = () => {
        if (arrayOfFileIdsForUpload.length === 0) {
            setDropzoneValidationMessage(true)
            setDropzoneValidation({
                minHeight: 200,
                justifyContent: 'center',
                borderColor: "var(--kt-text-danger)",
                borderWidth: 2,
                borderRadius: 7,
                borderStyle: 'dashed',
                overflow: 'hidden'
            })
        } else {
            setLoading(true)
            try {
                postUserDocumentation(user.id, comment, arrayOfFileIdsForUpload)
                .then(() => 
                {
                    NotificationMessage(intl.formatMessage({id: 'PROFILE.ADD.DOCUMENT.MESSAGE_NOTIFICATION'}), '', 'success');
                    setLoading(false);
                    closeModal();
                    setClosedWithAction(true);
                    asyncFetch()
                })
            } catch (ex) {
                NotificationMessage(intl.formatMessage({id: 'PROFILE.ADD.DOCUMENT.MESSAGE_NOTIFICATION.BROKE'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SENDING_ACTION_FOR_EXECUTING!_PLEASE_TRY_AGAIN.'}), 'danger')
            }
           
        }
        
    }

    const handleChangeStatus: IDropzoneProps['onChangeStatus'] = (files, status) => {
        if (status === intl.formatMessage({id: 'REQUEST.ACTIONS.DONE'})) {
            setDropzoneValidationMessage(false)
            setDropzoneValidation({
                minHeight: 200,
                justifyContent: 'center',
                borderColor: "#3699FF",
                borderWidth: 2,
                borderRadius: 7,
                borderStyle: 'dashed',
                overflow: 'hidden'
            })
            setUploadFileLoading(true)
            uploadFile(files.file)
                .then(result => {
                    const fileId = result?.data[0]?.id
                    setArrayOfFileIdsForUpload(arrayOfFileIdsForUpload => [...arrayOfFileIdsForUpload, fileId])
                    setUploadFileLoading(false)
                })

        } else if (status === intl.formatMessage({id: 'REQUEST.ACTIONS.REMOVED'})) {
            setArrayOfFileIdsForUpload([]);
        }
    }

    const getUploadParams = (meta: any) => {
        setUploadFileLoading(true)
        const url = 'https://httpbin.org/post'
        return {url, meta: {fileUrl: `${url}/${encodeURIComponent(meta.name)}`}}
    }

    const Input = ({accept, onFiles, files, getFilesFromEvent}: IInputProps) => {
        const text = intl.formatMessage({id: 'PROFILE.DROPZONE.ADD_FILE'})
        const fileSize = files.length

        return (
            fileSize < 1 &&
            <label className='bg-primary mx-2 my-10 text-light fw-semibold justify-content-center rounded-2 p-5 cursor-pointer'>
                {text}
                <input
                    style={{display: 'none'}}
                    type="file"
                    accept={accept}
                    multiple
                    onChange={async e => {
                        setUploadFileLoading(true);
                        const chosenFiles = await getFilesFromEvent(e);
                        onFiles(chosenFiles as File[]);
                    }}
                />
            </label>
        )
    }

    return (
        <KTCardBody>
                <div className="row px-6 d-flex mb-8">
                        <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus}
                            InputComponent={Input}
                            styles={{dropzone: dropzoneValidation}}
                        />
                        {dropzoneValidationMessage && <ul className="error-detail bs-callout bs-callout-info">
                            <li className="text-danger">{intl.formatMessage({id: 'REQUIRED_FIELD'})}</li>
                        </ul>}
                    </div>
                <div className="row px-3">
                    <div className='mb-3'>
                        <label className='col-form-label fw-bold fs-6 fw-bolder'>
                            {intl.formatMessage({id: 'PROFILE.ADD.DOCUMENT.COMMENT'})}
                        </label>
                        <textarea placeholder={intl.formatMessage({id: 'ADDITIONAL_DOCUMENTATION.MODAL.ADD_COMMENT'})}
                                    className="form-control" id="exampleFormControlTextarea1"
                                    autoComplete='off'
                                    style={{borderColor: 'var(--kt-input-solid-bg)', backgroundColor: 'var(--kt-input-solid-bg)'}}
                                    onChange={(event) => setComment(event.target.value)}
                                    rows={3}
                        />
                    </div>
                    <div className='card-footer d-flex flex-wrap justify-content-end pt-6'>
                        <button
                            onClick={() => closeModal()}
                            type="button"
                            className='btn btn-light me-3 mb-2'
                            data-kt-users-modal-action='cancel'
                            disabled={uploadFileLoading || loading}
                        >
                            {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                        </button>
                        <button type='submit' className='btn btn-success mb-2'
                                disabled={loading || uploadFileLoading}
                                onClick = {()=> uploadAdditionalRequstFiles()}
                        >
                            {!loading && !uploadFileLoading && intl.formatMessage({id: 'PROFILE.ADD.DOCUMENT.BUTTON'})}
                            {(loading || uploadFileLoading) && (
                                <span className='indicator-progress text-white fw-semibold' style={{display: 'block'}}>
                                        {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                                    <span className='spinner-border spinner-border-sm text-white align-middle ms-2'></span>
                                    </span>
                            )}
                        </button>
                    </div>
                </div>
        </KTCardBody>
    )
}

export {AdditionalFilesAddModal}
