import React, {FC} from 'react'
import {Contract} from "../../../../shared/models/contract";
import clsx from "clsx";
import {Link} from "react-router-dom";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

import {ContractHelper} from "../../../../shared/helper/ContractHelper";
import { useIntl } from 'react-intl';
import { KTSVG } from '../../../../../_metronic/helpers';
import { RoutesHelper } from '../../../../shared/helper/RoutesHelper';


type Props = {
    contract: Contract
}

const ContractCodeCell: FC<Props> = ({contract}) => {
    const intl = useIntl();
    const hasSolution = contract?.signed_contract_file_url

    const imageCssClass = clsx('svg-icon-2x', {
        // eslint-disable-next-line
        ['svg-icon-danger']: contract.status === intl.formatMessage({id: 'CONTRACT.TABLE.STATUS.EXPIRE'}),
        // eslint-disable-next-line
        ['svg-icon-success']: contract.status === intl.formatMessage({id: 'CONTRACT.TABLE.STATUS.ACTIVE'}),
    })

    return (
        <div className='d-flex align-items-center'>
            <div className='position-relative'>
                <div className='symbol symbol-100px me-5'>
                        <OverlayTrigger placement="top" overlay={<Tooltip>{ContractHelper(contract)}</Tooltip>}>
                        <span>
                            <KTSVG
                                path='/media/icons/duotune/abstract/abs009.svg'
                                className={imageCssClass}
                            />
                        </span>
                    </OverlayTrigger>
                </div>
                {hasSolution && <span className='position-absolute bottom-0 start-50 translate-middle'>
                    <KTSVG
                        path='/media/icons/duotune/communication/com008.svg'
                        className={'svg-icon-primary svg-icon-1'}
                    />
                </span>}
            </div>
                <div className='d-flex flex-column'>
                 {(RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2'])) ?
                    <Link to={`${contract?.id}/overview`} className='text-gray-800 text-hover-primary mb-1'>
                        {contract.code || '-'}
                    </Link> : 
                    <span className='text-gray-800 mb-1'>{contract.code || '-'}</span>
                    }
                </div>
        </div>
    )
}
export {ContractCodeCell}
