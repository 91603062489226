import React, {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'

import {useIntl} from 'react-intl'
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../../../modules/apps/children-menagement/core/QueryResponseProviderChildren'
import {childrenColumns} from '../../../../modules/apps/shared/list/columns/_columns'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {CustomHeaderColumn} from '../../../../modules/apps/shared/list/columns/CustomHeaderColumn'
import {CustomRow} from '../../../../modules/apps/shared/list/columns/CustomRow'
import {FamilyMember} from '../../../../shared/models/family_member'
import {SimpleLoading} from '../../../../modules/apps/shared/list/components/loading/SimpleLoading'

const ChildrensTable = () => {
  const intl = useIntl()

  const familyMembers = useQueryResponseData() as FamilyMember[]
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => familyMembers, [familyMembers])
  const columns = useMemo(() => childrenColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  return (
    <KTCardBody className='pt-0 pb-4'>
      {!isLoading && (
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<FamilyMember>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<FamilyMember>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({id: 'TABLE.NOT_FOUND'})}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {isLoading && (
        <div className='position-absolute start-50 translate-middle'>
          <SimpleLoading />
        </div>
      )}
    </KTCardBody>
  )
}

export {ChildrensTable}
