// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'

type Props = {
    row: Row<User>
}

const CustomRow: FC<Props> = ({row}) => (
    <tr {...row.getRowProps()}>
        {row.cells.map((cell) => {
            return (
                <td
                    {...cell.getCellProps()}
                    className={clsx({'text-end min-w-150px justify-content-end border-0': cell.column.id === 'actions'})}
                >
                    {cell.render('Cell')}
                </td>
            )
        })}
    </tr>
)

export {CustomRow}
