import {useIntl} from 'react-intl';
import {Column} from 'react-table';
import {UserInfoCell} from '../../../user-management/users-list/table/columns/UserInfoCell';
import {UserDepartmentCell} from '../../../user-management/users-list/table/columns/UserDepartmentCell';
import {DepartmentInfoCell} from "../../../department-management/table/columns/DepartmentInfoCell";
import {UserSectorCell} from '../../../user-management/users-list/table/columns/UserSectorCell'
import {UserApuCell} from '../../../user-management/users-list/table/columns/UserApuCell'
import {UserActionsCell} from '../../../user-management/users-list/table/columns/UserActionsCell';
import {UserCustomHeader} from '../../../user-management/users-list/table/columns/UserCustomHeader';
import {Contract, Department, FamilyMember, JobPosition, User} from '../../../../../shared/models/_models';
import {Request} from "../../../../../shared/models/request";
import {DepartmentActionsCell} from '../../../department-management/table/columns/DepartmentActionsCell'
import {DepartmentCustomHeader} from "../../../department-management/table/columns/DepartmentCustomHeader";
import {DepartmentSectorCell} from "../../../department-management/table/columns/DepartmentSectorCell";
import {DepartmentCodeCell} from "../../../department-management/table/columns/DepartmentCodeCell";
import {ChildrenCustomHeader} from "../../../children-menagement/table/ChildrenCustomHeader";
import {ChildrenInfoCell} from "../../../children-menagement/table/ChildrenInfoCell";
import {ChildrenActionCell} from "../../../children-menagement/table/ChildrenActionCell";
import {ChildrenDateBirthCell} from "../../../children-menagement/table/ChildrenDateBirthCell";
import {ChildrenGenderCell} from "../../../children-menagement/table/ChildrenGenderCell";
import {ContractCustomHeader} from "../../../contract-menagement/table/ContractCustomHeader";
import {ContractUserCell} from "../../../contract-menagement/table/ContractUserCell";
import {ContractTypeCell} from "../../../contract-menagement/table/ContractTypeCell";
import {ContractCodeCell} from "../../../contract-menagement/table/ContractCodeCell";
import {ContractPeriodCell} from "../../../contract-menagement/table/ContractPeriodCell";
import {ContractActionsCell} from "../../../contract-menagement/table/ContractActionsCell";
import {JobPositionCustomHeader} from "../../../job-position-management/table/JobPositionCustomHeader";
import {JobPositionActionsCell} from "../../../job-position-management/table/JobPositionActionsCell";
import {JobPositionNameCell} from "../../../job-position-management/table/JobPositionNameCell";
import {JobPositionSectorCell} from "../../../job-position-management/table/JobPositionSectorCell";
import {JobPositionDepartmentCell} from "../../../job-position-management/table/JobPositionDepartmentCell";
import {RequestUserCell} from '../../../request-menagement/table/RequestUserCell';
import {RequestCustomHeader} from '../../../request-menagement/table/RequestCustomHeader';
import {RequestStatusCell} from '../../../request-menagement/table/RequestStatusCell';
import {RequestActionsCell} from '../../../request-menagement/table/RequestActionsCell';
import {RequestManagerCell} from '../../../request-menagement/table/RequestManagerCell';

const usersColumns: ReadonlyArray<Column<User>> = [
    {
        Header: (props) => <UserCustomHeader tableProps={props}
                                             title={useIntl().formatMessage({id: 'TABLE.USER.FULL_NAME'})}
                                             className='min-w-100px'/>,
        id: 'profile.full_name',
        Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => <UserCustomHeader tableProps={props}
                                             title={useIntl().formatMessage({id: 'DEPARTMENT'})}
                                             className='min-w-200px'/>,
        id: 'job_position.department_id',
        Cell: ({...props}) => <UserDepartmentCell user={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'TABLE.USER.SECTOR'})}
                              className='min-w-50px'/>
        ),
        id: 'job_position.sector',
        Cell: ({...props}) => <UserSectorCell user={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'TABLE.USER.APU'})}
                              className='min-w-70px'/>
        ),
        id: 'profile.apu',
        Cell: ({...props}) => <UserApuCell user={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='' className='text-end min-w-55px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id}/>,
    },
]

const departmentsColumns: ReadonlyArray<Column<Department>> = [
    {
        Header: (props) => <DepartmentCustomHeader tableProps={props}
                                                   title={useIntl().formatMessage({id: 'TABLE.DEPARTMENT.NAME'})}
                                                   className='min-w-200px'
                                                   />,
        id: 'name',
        Cell: ({...props}) => <DepartmentInfoCell department={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => <DepartmentCustomHeader tableProps={props}
                                                   title={useIntl().formatMessage({id: 'TABLE.DEPARTMENT.SECTOR'})}
                                                   />,
        id: 'sector',
        Cell: ({...props}) => <DepartmentSectorCell department={props.data[props.row.index]}
                                                    technical={useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.TECHNICAL'})}
                                                    commercial={useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.COMMERCIAL'})}
                                                    global={useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.GLOBAL'})}
        />,
    },
    {
        Header: (props) => <DepartmentCustomHeader tableProps={props}
                                                   title={useIntl().formatMessage({id: 'TABLE.DEPARTMENT.COST_CENTER'})}
                                                   className='min-w-100px'
                                                   />,
        id: 'code',
        Cell: ({...props}) => <DepartmentCodeCell department={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => (
            <DepartmentCustomHeader tableProps={props} title='' className='text-end min-w-55px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <DepartmentActionsCell id={props.data[props.row.index].id}/>,
    },

]

const childrenColumns: ReadonlyArray<Column<FamilyMember>> = [
    {
        Header: (props) => <ChildrenCustomHeader tableProps={props}
                                                 title={useIntl().formatMessage({id: 'TABLE.CHILDREN.FULL_NAME'})}/>,
        id: 'full_name',
        Cell: ({...props}) => <ChildrenInfoCell familyMember={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => <ChildrenCustomHeader tableProps={props}
                                                 title={useIntl().formatMessage({id: 'TABLE.CHILDREN.DATE_OF_BIRTH'})}/>,
        id: 'date_of_birth',
        Cell: ({...props}) => <ChildrenDateBirthCell familyMember={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => <ChildrenCustomHeader tableProps={props}
                                                 title={useIntl().formatMessage({id: 'TABLE.CHILDREN.GENDER'})}/>,
        id: 'gender',
        Cell: ({...props}) => <ChildrenGenderCell familyMember={props.data[props.row.index]}
                                                  male={useIntl().formatMessage({id: 'TABLE.CHILDREN.GENDER.MALE'})}
                                                  female={useIntl().formatMessage({id: 'TABLE.CHILDREN.GENDER.FEMALE'})}/>,
    },
    {
        Header: (props) => (
            <ChildrenCustomHeader tableProps={props} title='' className='text-end min-w-55px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <ChildrenActionCell id={props.data[props.row.index].id}
                                                  familyMember={props.data[props.row.index]}/>,
    },
]

const contractColumns: ReadonlyArray<Column<Contract>> = [
    {
        Header: (props) => <ContractCustomHeader tableProps={props}
                                                 title={useIntl().formatMessage({id: 'CONTRACT.TABLE.CODE'})}
                                                 />,
        id: 'code',
        Cell: ({...props}) => <ContractCodeCell contract={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => <ContractCustomHeader tableProps={props}
                                                 title={useIntl().formatMessage({id: 'CONTRACT.TABLE.USER'})}
                                                 className='min-w-100px'/>,
        id: 'user_id',
        Cell: ({...props}) => <ContractUserCell contract={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => <ContractCustomHeader tableProps={props}
                                                 title={useIntl().formatMessage({id: 'CONTRACT.TABLE.TYPE'})}
                                                 className='min-w-100px'/>,
        id: 'type',
        Cell: ({...props}) => <ContractTypeCell contract={props.data[props.row.index]}
                                                contract_undefined={useIntl().formatMessage({id: 'CONTRACT.TYPE.UNDEFINED'})}
                                                contract_defined={useIntl().formatMessage({id: 'CONTRACT.TYPE.DEFINED'})}
        />,
    },
    {
        Header: (props) => <ContractCustomHeader tableProps={props}
                                                 title={useIntl().formatMessage({id: 'CONTRACT.TABLE.PERIOD'})}
                                                 className='min-w-100px'/>,
        id: 'contract_period',
        Cell: ({...props}) => <ContractPeriodCell contract={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => (
            <ContractCustomHeader tableProps={props} title='' className='text-end min-w-130px !important'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <ContractActionsCell id={props.data[props.row.index].id}/>,
    },
]

const jobPositionColumns: ReadonlyArray<Column<JobPosition>> = [
    {
        Header: (props) => <JobPositionCustomHeader tableProps={props}
                                                    title={useIntl().formatMessage({id: 'JOB_POSITION.NAME'})}
                                                    className='min-w-100px'/>,
        id: 'name',
        Cell: ({...props}) => <JobPositionNameCell jobPosition={props.data[props.row.index]}
                                                   risk={useIntl().formatMessage({id: 'JOB_POSITION.IS_RISK'})}
        />,
    },
    {
        Header: (props) => <JobPositionCustomHeader tableProps={props}
                                                    title={useIntl().formatMessage({id: 'JOB_POSITION.SECTOR'})}
                                                    className='min-w-100px'/>,
        id: 'sector',
        Cell: ({...props}) => <JobPositionSectorCell jobPosition={props.data[props.row.index]}
                                                     technical={useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.TECHNICAL'})}
                                                     commercial={useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.COMMERCIAL'})}
                                                     global={useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.GLOBAL'})}
        />,
    },
    {
        Header: (props) => <JobPositionCustomHeader tableProps={props}
                                                    title={useIntl().formatMessage({id: 'JOB_POSITION.DEPARTMENT'})}
                                                    className='min-w-100px'/>,
        id: 'department_id',
        Cell: ({...props}) => <JobPositionDepartmentCell jobPosition={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => (
            <JobPositionCustomHeader tableProps={props} title='' className='text-end min-w-55px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <JobPositionActionsCell id={props.data[props.row.index].id}/>,
    },
]

const requestColumns: ReadonlyArray<Column<Request>> = [
    {
        Header: (props) => <RequestCustomHeader tableProps={props}
                                                title={useIntl().formatMessage({id: 'REQUEST.TABLE.SORT'})}/>,
        id: 'status',
        Cell: ({...props}) => <RequestStatusCell request={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => <RequestCustomHeader tableProps={props}
                                                title={useIntl().formatMessage({id: 'REQUEST.TABLE.SUBMITTER'})}
                                                className='min-w-200px'/>,
        id: 'user_id',
        Cell: ({...props}) => <RequestUserCell request={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => <RequestCustomHeader tableProps={props}
                                                title={useIntl().formatMessage({id: 'REQUEST.TABLE.MANAGER'})}/>,
        id: 'menager_id',
        Cell: ({...props}) => <RequestManagerCell request={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => (
            <RequestCustomHeader tableProps={props} title='' className='text-end min-w-55px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <RequestActionsCell request={props.data[props.row.index]}/>,
    }
]

export {
    usersColumns,
    departmentsColumns,
    childrenColumns,
    contractColumns,
    jobPositionColumns,
    requestColumns
}
