import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {logoutCurrentUser} from '../../../modules/auth'
import Avatar from 'react-avatar'
import {avatarColorConfig} from '../../../shared/AvatarConfig'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const UserInfoDashboard: React.FC = () => {
  const intl = useIntl()
  let current_user

  try {
    current_user = JSON.parse(localStorage.getItem('current_user') || '')
  } catch (e) {
    logoutCurrentUser()
  }

  return (
    <>
      <div className='card card-xxl-stretch-50 mb-5'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-12 col-xl-4 m-auto'>
              <div className='d-flex align-items-center'>
                <div className='me-7'>
                  <div className='symbol symbol-60px symbol-lg-100px symbol-fixed position-relative'>
                    {current_user?.profile?.image_url ? (
                      <LazyLoadImage
                        src={current_user?.profile?.image_url}
                        width={'110px'}
                        height={'110px'}
                        style={{borderRadius: '10px'}}
                        effect='blur'
                      />
                    ) : (
                      <Avatar
                        name={current_user?.first_name + ' ' + current_user?.last_name}
                        round={'10px'}
                        color={avatarColorConfig.color}
                        fgColor={avatarColorConfig.fgColor}
                        textSizeRatio={2 / 3}
                        title={avatarColorConfig.title}
                        className={avatarColorConfig.style}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <h5 className='font-weight-bolder text-dark-75'>
                    {current_user?.first_name + ' ' + current_user?.last_name || '-'}
                  </h5>
                  <div className='text-muted'>{current_user?.job_position?.name || '-'}</div>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-6 mt-5 mt-xl-2 col-xl-4 m-auto'>
              <div className='d-flex align-items-center mb-2 pt-2'>
                <span className='svg-icon pe-3 svg-icon-2x'>
                  <KTSVG
                    path='/media/icons/duotune/communication/com002.svg'
                    className='svg-icon-2'
                  />
                </span>
                <span className='text-muted fw-bold me-2'>
                  {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.EMAIL'})}:
                </span>
                <span className='w-75 text-end text-sm-start text-wrap'>
                  {current_user?.email || '-'}
                </span>
              </div>
              <div className='d-flex align-items-center mb-2 pt-2'>
                <span className='svg-icon pe-3 svg-icon-2x'>
                  <KTSVG
                    path='/media/icons/duotune/electronics/elc011.svg'
                    className='svg-icon-2'
                  />
                </span>
                <span className='fw-bold me-2 text-muted'>
                  {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.PHONE_NUMBER'})}:
                </span>
                <span className='w-100 w-sm-25 text-end text-sm-start'>
                  {current_user?.profile?.business_number
                    ? current_user?.profile?.business_number
                    : current_user?.profile?.phone_number
                    ? current_user?.profile?.phone_number
                    : '-'}
                </span>
              </div>
              <div className='d-flex align-items-center mb-2 pt-2'>
                <span className='svg-icon pe-3 svg-icon-2x'>
                  <KTSVG path='/media/icons/duotune/maps/map008.svg' className='svg-icon-2' />
                </span>
                <span className='fw-bold me-2 text-muted'>
                  {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.LOCATION'})}:
                </span>
                <span className='w-100 text-end text-sm-start'>
                  {current_user?.profile?.city || '-'}
                </span>
              </div>
            </div>
            <div className='col-12 col-sm-6 mt-xl-2 col-xl-4 m-auto'>
              <div className='d-flex align-items-center mb-2 pt-2'>
                <span className='svg-icon pe-3 svg-icon-2x'>
                  <KTSVG path='/media/icons/duotune/abstract/abs031.svg' className='svg-icon-2' />
                </span>
                <span className='fw-bold me-2 text-muted'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.CODE'})}:
                </span>
                <span className='w-100 w-sm-25 text-end text-sm-start'>
                  {current_user?.profile?.code || '-'}
                </span>
              </div>
              <div className='d-flex align-items-center mb-2 pt-2'>
                <span className='svg-icon pe-3 svg-icon-2x'>
                  <KTSVG path='/media/icons/duotune/finance/fin001.svg' className='svg-icon-2' />
                </span>
                <span className='fw-bold me-2 text-muted '>
                  {intl.formatMessage({id: 'JOB_POSITION.DEPARTMENT.INITIAL'})}:
                </span>
                <span className='w-100 text-end text-sm-start'>
                  {current_user?.job_position?.department?.parent
                    ? current_user?.job_position?.department?.parent?.name
                    : current_user?.job_position?.department
                    ? current_user?.job_position?.department?.name
                    : '-'}
                </span>
              </div>
              <div className='d-flex align-items-center mb-2 pt-2'>
                <span className='svg-icon pe-3 svg-icon-2x'>
                  <KTSVG path='/media/icons/duotune/graphs/gra001.svg' className='svg-icon-2' />
                </span>
                <span className='fw-bold me-2 text-muted'>
                  {intl.formatMessage({id: 'JOB_POSITION.DEPARTMENT.PARENT'})}:
                </span>
                <span className='w-100 text-end text-sm-start'>
                  {current_user?.job_position?.department?.parent
                    ? current_user?.job_position?.department?.name
                    : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {UserInfoDashboard}
