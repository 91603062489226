/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Select from "react-select";
import { initialQueryState, KTSVG } from '../../../../../../_metronic/helpers';
import { NotificationMessage } from '../../../../../shared/helper/Notification';
import { useQueryRequest } from '../../../shared/list/core/QueryRequestProvider';

const DepartmentsFilter: FC = () => {
    const intl = useIntl()
    const [sectorValue, setSectorValue] = useState({} as any)
    const [buttonColor, setButtonColor] = useState('primary')
    const [buttonColorActive, setButtonColorActive] = useState('btn-active-light-primary mb-1')
    const {updateState} = useQueryRequest()
    const sectorList =  [{value: 'technical', label: intl.formatMessage({id: 'DEPARTMENT.SECTOR.TECHNICAL'})}, {value: 'commercial', label:intl.formatMessage({id: 'DEPARTMENT.SECTOR.COMMERCIAL'})}, {value: 'global', label:intl.formatMessage({id: 'DEPARTMENT.SECTOR.GLOBAL'})}]

    const setFilter = async () =>  {        
      const sectorFilteredValue = {value: sectorValue?.value}

      if (sectorFilteredValue?.value !== undefined) {        
        await updateState({sector: sectorFilteredValue, ...initialQueryState})
        setButtonColor('success')
        setButtonColorActive('btn-active-light-success mb-1')

        NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FILTER_SUCCESSFULLY_APPLIED'}), '', 'success')
      } else if (sectorFilteredValue?.value === undefined) {
        setButtonColor('primary')
        setButtonColorActive('btn-active-light-primary me-1')
        NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.MUST_CHOOSE_FILTER_VALUE'}), '', 'warning')
      }
    }

    const resetFilter = async () => {
        const sectorFilteredValue = {value: ''}
        setButtonColor('primary')
        setButtonColorActive('btn-active-light-primary mb-1')
        setSectorValue({})
        await updateState({sector: sectorFilteredValue, ...initialQueryState})
        NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FILTER_RESET_SUCCESSFULLY'}), '', 'success')
      }

    return (
        <Dropdown>
            <Dropdown.Toggle variant={buttonColor} className={buttonColorActive}>
                <KTSVG
                    path='/media/icons/duotune/general/gen031.svg'
                    className='svg-icon-5 svg-icon-gray-500 me-2'
                />
                {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER'})}
            </Dropdown.Toggle>
            <Dropdown.Menu className='px-4 py-4menu menu-sub menu-sub-dropdown w-250px w-sm-300px' align="start" id="dropdown-menu-align-end">
                <div className='px-2 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>{intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.OPTION'})}</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className='px-2 py-5'>
                    <div className='mb-10'>
                        <label className='form-label fw-bold'>{intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR'})}:</label>
                        <Select
                            placeholder={intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR'})}
                            options={sectorList}
                            value={{
                                value: sectorValue ? sectorValue?.id : '',
                                label: sectorValue?.label ? sectorValue?.label : intl.formatMessage({id: 'SELECT_SECTOR'})
                            }}
                            onChange={(value: any) => setSectorValue(value)}
                            isSearchable={false}
                            classNamePrefix="select"
                            className={'mb-3 mb-lg-0'}
                        />
                    </div>
                <div className='d-flex justify-content-end'>
                <button
                    type='reset'
                    className='btn btn-sm btn-light btn-active-light-danger me-2'
                    data-kt-menu-dismiss='true'
                    onClick={() => resetFilter()}
                >
                    {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.RESET'})}
                </button>
                <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'
                  onClick={() => setFilter()}
                >
                    {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.APPLY'})}
                </button>
                </div>
            </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export {DepartmentsFilter}
