import {useEffect} from 'react'
import {UserCreateModalHeader} from './UserCreateModalHeader'
import {UserCreateModalFormWrapper} from './UserCreateModalFormWrapper'

const UserCreateModal = () => {
    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered modal-md'>
                    <div className='modal-content'>
                        <UserCreateModalHeader/>
                        <div className='modal-body'>
                            <UserCreateModalFormWrapper/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {UserCreateModal}
