import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import React from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers/components/KTSVG'
import Dropzone, {IDropzoneProps, IInputProps} from 'react-dropzone-uploader'
import {uploadFile} from '../../../../../shared/services/file-service'
import {getCurrentUser, updateUserImage} from '../../../../../shared/services/user-service'
import {NotificationMessage} from '../../../../../shared/helper/Notification'
import {User} from '../../../../../shared/models/_models'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

type Props = {
  setOpenModal: any
  user?: User
  asyncFetch?: any
}

const AddProfileImageModal: FC<Props> = ({setOpenModal, user, asyncFetch}) => {
  const [loading, setLoading] = useState(false)
  const [uploadFileLoading, setUploadFileLoading] = useState(false)
  const [imagePreview, setImegePreview] = useState('')

  const [dropzoneValidation, setDropzoneValidation] = useState({
    minHeight: 120,
    justifyContent: 'center',
    borderColor: '#3699FF',
    borderWidth: 2,
    borderRadius: 7,
    borderStyle: 'dashed',
    overflow: 'hidden',
    width: 250,
  })
  const [dropzoneValidationMessage, setDropzoneValidationMessage] = useState(false)
  const [arrayOfFileIdsForUpload, setArrayOfFileIdsForUpload] = React.useState<any[]>([])
  const intl = useIntl()

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const closeModal = () => {
    setOpenModal(false)
  }

  const uploadAdditionalRequstFiles = () => {
    if (arrayOfFileIdsForUpload.length === 0) {
      setDropzoneValidationMessage(true)
      setDropzoneValidation({
        minHeight: 120,
        justifyContent: 'center',
        borderColor: 'var(--kt-text-danger)',
        borderWidth: 2,
        borderRadius: 7,
        borderStyle: 'dashed',
        overflow: 'hidden',
        width: 250,
      })
    } else {
      setLoading(true)
      try {
        updateUserImage(user?.id, arrayOfFileIdsForUpload[0]).then(() => {
          NotificationMessage(
            intl.formatMessage({id: 'PROFILE.ADD.PROFILE.IMAGE.MESSAGE_NOTIFICATION'}),
            '',
            'success'
          )
          getCurrentUser('expand=profile.image_url').then((response: any) => {
            localStorage.setItem('current_user', JSON.stringify(response.data as string))
          })
          setLoading(false)
          asyncFetch()
          closeModal()
        })
      } catch (ex) {
        NotificationMessage(
          intl.formatMessage({id: 'PROFILE.ADD.PROFILE.IMAGE.MESSAGE_NOTIFICATION.BROKE'}),
          intl.formatMessage({
            id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SENDING_ACTION_FOR_EXECUTING!_PLEASE_TRY_AGAIN.',
          }),
          'danger'
        )
      }
    }
  }

  const handleChangeStatus: IDropzoneProps['onChangeStatus'] = (files, status) => {
    if (status === intl.formatMessage({id: 'REQUEST.ACTIONS.DONE'})) {
      setDropzoneValidationMessage(false)
      setDropzoneValidation({
        minHeight: 120,
        justifyContent: 'center',
        borderColor: '#3699FF',
        borderWidth: 2,
        borderRadius: 7,
        borderStyle: 'dashed',
        overflow: 'hidden',
        width: 250,
      })
      setUploadFileLoading(true)
      uploadFile(files.file).then((result) => {
        setImegePreview(result?.data[0]?.url)

        const fileId = result?.data[0]?.id
        setArrayOfFileIdsForUpload((arrayOfFileIdsForUpload) => [
          ...arrayOfFileIdsForUpload,
          fileId,
        ])
        setUploadFileLoading(false)
      })
    } else if (status === intl.formatMessage({id: 'REQUEST.ACTIONS.REMOVED'})) {
      setImegePreview('')
      setArrayOfFileIdsForUpload([])
    }
  }

  const getUploadParams = (meta: any) => {
    setUploadFileLoading(true)
    const url = 'https://httpbin.org/post'
    return {url, meta: {fileUrl: `${url}/${encodeURIComponent(meta.name)}`}}
  }

  const Input = ({accept, onFiles, files, getFilesFromEvent}: IInputProps) => {
    const text = intl.formatMessage({id: 'PROFILE.IMAGE.DROPZONE.ADD_FILE'})
    const fileSize = files.length

    return (
      fileSize < 1 && (
        <label className='bg-primary mx-2 my-10 text-light fw-semibold justify-content-center rounded-2 p-4 cursor-pointer'>
          {text}
          <input
            style={{display: 'none'}}
            type='file'
            accept={accept}
            onChange={async (e) => {
              setUploadFileLoading(true)
              const chosenFiles = await getFilesFromEvent(e)
              onFiles(chosenFiles as File[])
            }}
          />
        </label>
      )
    )
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_delete'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-md'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>
                {intl.formatMessage({id: 'PROFILE.IMAGE.DROPZONE.ADD_FILE.MODAL'})}
              </h3>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => closeModal()}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body text-center w-100'>
              {imagePreview && (
                <div className='card d-block mb-5'>
                   <LazyLoadImage
                        src={imagePreview}
                        width={'280px'}
                        height={'280px'}
                        style={{borderRadius: '10px'}}
                        effect='blur'
                      />
                </div>
              )}
              <div className='row d-flex w-100 m-0'>
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  InputComponent={Input}
                  styles={{dropzone: dropzoneValidation}}
                  accept='image/*'
                />
                {dropzoneValidationMessage && (
                  <ul className='error-detail bs-callout bs-callout-info list-unstyled'>
                    <li className='text-danger list'>{intl.formatMessage({id: 'REQUIRED_FIELD'})}</li>
                  </ul>
                )}
              </div>
            </div>
            <div className='modal-footer'>
              <button
                onClick={() => closeModal()}
                type='button'
                className='btn btn-light me-3 mb-2'
                data-kt-users-modal-action='cancel'
                disabled={uploadFileLoading || loading}
              >
                {intl.formatMessage({id: 'BUTTON.DISCARD'})}
              </button>
              <button
                type='submit'
                className='btn btn-success mb-2'
                disabled={loading || uploadFileLoading}
                onClick={() => uploadAdditionalRequstFiles()}
              >
                {!loading &&
                  !uploadFileLoading &&
                  intl.formatMessage({id: 'PROFILE.ADD.DOCUMENT.BUTTON'})}
                {(loading || uploadFileLoading) && (
                  <span
                    className='indicator-progress text-white fw-semibold'
                    style={{display: 'block'}}
                  >
                    {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                    <span className='spinner-border spinner-border-sm text-white align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {AddProfileImageModal}
