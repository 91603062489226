import React, {useEffect, useRef, useState} from 'react'
import {getIn, useFormik} from 'formik'
import {Link, useNavigate} from 'react-router-dom'
import {JobPosition, User} from '../../../shared/models/_models'
import {getCurrentUser, updateUserBusinessData} from '../../../shared/services/user-service'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import 'react-datepicker/dist/react-datepicker.css'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/l10n/sr.js'
import {useQuery} from 'react-query'
import * as Yup from 'yup'
import {getJobPositions} from '../../../shared/services/job-position-service'
import Select from 'react-select'
import {ID, isNotEmpty} from '../../../../_metronic/helpers'
import {NotificationMessage} from '../../../shared/helper/Notification'
import Flatpickr from 'react-flatpickr'
import {FlatpickrDateOptions} from '../../../shared/helper/FlatpickrDateOptions'
import moment from 'moment/moment'
import {SelectField} from '../../../shared/helper/Select'
import {
  listContractType,
  listPrvShifts,
  listRrShifts,
  listShifts,
  listSrShifts,
  apuLevels,
} from '../../../shared/SelectLists'
import {SimpleLoading} from '../../../modules/apps/shared/list/components/loading/SimpleLoading'
import {OverlayTrigger, Tooltip, TooltipProps} from 'react-bootstrap'
import {RoutesHelper} from "../../../shared/helper/RoutesHelper";

type Props = {
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
}

const EditBusinessDetails: React.FC<Props> = ({user, setUser}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const currentDate = new Date()
  const startOfEmployment = new Date((user && user.profile && user.profile.date_of_employment) ? user.profile?.date_of_employment : '');

  const startofEmploymentDate = useRef(user.profile?.date_of_employment);
  const endOfEmploymentDate = useRef(user.profile?.end_of_employment_date);

  const {data: job_positions} = useQuery(
      `job_positions`,
      () => {
        return getJobPositions('list=true')
      },
      {
        cacheTime: 0,
        enabled: true,
        onError: (err) => {
          console.error(err)
        },
      }
  )

  const technical = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.TECHNICAL'})
  const commercial = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.COMMERCIAL'})
  const global = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.GLOBAL'})
  const sector = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.POSITION_SECTOR'})

  const direct = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.DIRECT'})
  const indirect = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.INDIRECT'})
  const indirect_direct = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.INDIRECT_DIRECT'})
  const overhead = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.OVERHEAD'})
  const contract_undefined = intl.formatMessage({
    id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE.UNDEFINED',
  })
  const contract_defined = intl.formatMessage({
    id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE.DEFINED',
  })
  const [contractType, setContractType] = useState(user?.profile?.contract_type)

  const [shift, setShift] = useState(
    !user?.profile?.shift
      ? {
          value: null,
          label: null,
        }
      : {
          value: user?.profile?.shift,
          label: listShifts.filter((shift) => shift.value === user?.profile?.shift)[0]?.label,
        }
  )

  const [apuLevel, setApuLevel] = useState(
    user?.profile && user.profile.apu
      ? {
          value: user?.profile?.apu,
          label: apuLevels.filter((apu_value) => apu_value.value === user?.profile?.apu)[0]?.label,
        }
      : {
          value: null,
          label: null,
        }
  )

  const listShiftsTime = (value: any) => {
    if (value === 'RR') {
      return listRrShifts.filter(
        (hours) => hours.value === user?.profile?.shift_from + ' ' + user?.profile?.shift_to
      )[0]?.label
    } else if (value === 'SR') {
      return listSrShifts.filter(
        (hours) => hours.value === user?.profile?.shift_from + ' ' + user?.profile?.shift_to
      )[0]?.label
    } else {
      return listPrvShifts.filter(
        (hours) => hours.value === user?.profile?.shift_from + ' ' + user?.profile?.shift_to
      )[0]?.label
    }
  }

  const [workingHours, setWorkingHours] = useState(
    !user?.profile?.shift_from && !user?.profile?.shift_to
      ? {
          value: null,
          label: null,
        }
      : {
          value: user?.profile?.shift_from + ' ' + user?.profile?.shift_to,
          label: listShiftsTime(shift.value),
        }
  )

  const deleteTooltip = (
    props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>
  ) => <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.DELETE'})}</Tooltip>

  const currentUser = JSON.parse(localStorage.getItem('current_user') || '')

  const userPermissionHr =
    !!(
      currentUser?.job_position?.we_level === 'WE1' || currentUser?.job_position?.we_level === 'WE2'
    ) || !!(currentUser?.roles?.filter((item: any) => (item === 'hr' || item === 'hr1' || item === 'hr2') )?.length > 0)
  const userPermissionAdmin =
    !!(currentUser?.roles?.filter((item: any) => item === 'admin')?.length > 0) ||
    !!(
      currentUser?.job_position?.we_level === 'WE1' || currentUser?.job_position?.we_level === 'WE2'
    )

  const jobPositionsList = job_positions?.data
    ?.filter((job_position) =>
      job_position.we_level ? job_position?.we_level >= (RoutesHelper(['admin', 'hr']) ? 'WE1' : RoutesHelper(['hr1']) ? 'WE4' : 'WE5') : '')
    .map((item) => ({
      value: item.id,
      label:
        item?.name +
        ',' +
        item.department?.name +
        ',' +
        item.department?.parent?.name +
        ',' +
        (item.sector === 'technical'
          ? ' ' + technical + '' + sector
          : item.sector === 'commercial'
          ? ' ' + commercial + '' + sector
          : item.sector === 'global'
          ? ' ' + global + '' + sector
          : ''),
      job_position: item,
    }))

  const puJobPositionsList = job_positions?.data
    ?.filter((job_position: any) => ['WE2', 'WE3', 'WE4', 'WE5']?.includes(job_position?.we_level))
    .map((item) => ({
      value: item.id,
      label:
        item?.name +
        ',' +
        item.department?.name +
        ',' +
        item.department?.parent?.name +
        ',' +
        (item.sector === 'technical'
          ? ' ' + technical + '' + sector
          : item.sector === 'commercial'
          ? ' ' + commercial + '' + sector
          : item.sector === 'global'
          ? ' ' + global + '' + sector
          : ''),
      job_position: item,
    }))

  function jobPositionCategory(category: any) {
    return category === 'DIR'
      ? direct
      : category === 'ID'
      ? indirect
      : category === 'ID/DIR'
      ? indirect_direct
      : category === 'OH'
      ? overhead
      : ''
  }

  const formatJobPositionOptionLabel = (data: {value: any; label: any; job_position: any}) => {
    return (
      <div className={'d-flex row'}>
        <label className={'text-gray-800 fw-bold'}>{data?.label?.split(',', 1)[0]}</label>
        <label className={'fs-6'}>
          {data.job_position?.department ? data.job_position?.department?.name : ''}
          {data.job_position?.department && data.job_position?.department?.parent
            ? ' - ' + data.job_position?.department?.parent?.name
            : ''}
          {(data.job_position?.department || data.job_position?.department?.parent?.name) &&
          data.job_position?.sector
            ? ' - '
            : ''}
          {data.job_position &&
            (data.job_position?.sector === 'technical'
              ? ' ' + technical + '' + sector
              : data.job_position?.sector === 'commercial'
              ? ' ' + commercial + '' + sector
              : data.job_position?.sector === 'global'
              ? ' ' + global + '' + sector
              : '')}
          {!data.job_position &&
            (data.job_position?.sector === 'technical'
              ? technical + '' + sector
              : data.job_position?.sector === 'commercial'
              ? commercial + '' + sector
              : data.job_position?.sector === 'global'
              ? global + '' + sector
              : '')}
        </label>
      </div>
    )
  }

  const [puPosition, setPuPosition] = React.useState<any[]>([])
  const [jobPosition, setJobPosition] = useState(user?.job_position)
  const [puJobPosition, setPuJobPosition] = useState(
    puPosition.length > 0 ? puPosition[puPosition.length - 1] : user?.job_position
  )
  const [loading, setLoading] = useState(false)

  const deletePuPosition = (puValue: any) => {
    setPuPosition(puPosition.filter((item) => item !== puValue))
    NotificationMessage(
      intl.formatMessage({
        id: 'MESSAGE_NOTIFICATION.PU_JOB_POSITION_INFORMATION_HAS_BEEN_SUCCESSFULLY_REMOVED',
      }),
      intl.formatMessage({id: 'MESSAGE_NOTIFICATION.PU_JOB_POSITION_INFORMATION'}),
      'success'
    )
  }

  const businessDetailsSchema = Yup.object().shape({
    job_position_id: Yup.number()
      .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
      .nullable(),
  })

  useEffect(() => {
    setJobPosition(user.job_position)
  }, [user.job_position])

  useEffect(
    () => {
      setPuJobPosition(puPosition[puPosition.length - 1])
      setPuPosition(user?.secondary_job_position)
    },
    // eslint-disable-next-line
    [user?.secondary_job_position]
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: JSON.parse(JSON.stringify(user).replace(/:null/gi, ':""')),
    validationSchema: businessDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)

      if (isNotEmpty(startofEmploymentDate)) {
        values.profile.date_of_employment = startofEmploymentDate.current
      }

      if (isNotEmpty(endOfEmploymentDate)) {
        values.profile.end_of_employment_date = endOfEmploymentDate.current
      }

      if (values && job_positions?.data) {
        values.job_position = jobPosition
      }

      if (values && job_positions?.data && puPosition.length >= 0) {
        values.secondary_job_position = puPosition
      }

      updateUserBusinessData(values).then((response) => {
        if (response) {
          getCurrentUser('expand=profile').then((response: any) => {
            localStorage.setItem('current_user', JSON.stringify(response.data as string))
            setUser(values)
            NotificationMessage(
              intl.formatMessage({
                id: 'MESSAGE_NOTIFICATION.BUSINESS_INFORMATION_HAS_BEEN_SUCCESSFULLY_UPDATED!',
              }),
              '',
              'success'
            )
            navigate(`/user/${user?.id}/details/business/view/`)
          })
        } else {
          NotificationMessage(
            intl.formatMessage({id: 'MESSAGE_NOTIFICATION.UPDATE_FAILED!'}),
            intl.formatMessage({
              id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_UPDATING_BUSINESS_INFORMATION!_PLEASE_TRY_AGAIN.',
            }),
            'danger'
          )
          setLoading(false)
        }
      })
    },
  })

  function getDateDifference(startDate: any, endDate: any) {
    var start = new Date(startDate);
    var end = new Date(endDate);

    var yearDiff = end.getFullYear() - start.getFullYear();
    var monthDiff = end.getMonth() - start.getMonth();
    var dayDiff = end.getDate() - start.getDate();

    if (dayDiff < 0) {
      var daysInLastMonth = new Date(end.getFullYear(), end.getMonth(), 0).getDate();
      dayDiff = daysInLastMonth - start.getDate() + end.getDate();
      monthDiff--;
    }

    if (monthDiff < 0) {
      monthDiff += 12;
      yearDiff--;
    }

    if (isNaN(yearDiff) || isNaN(monthDiff) || isNaN(dayDiff)) {
      return null;
    }

    return {
      years: yearDiff,
      months: monthDiff,
      days: dayDiff
    };
  }

  const companyExperienceDiff = getDateDifference(startOfEmployment, currentDate);

  return (
    <div className='card mb-5 mb-xl-10 card-xl-stretch'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS'})}</h3>
        </div>
      </div>
      <div id='kt_account_business_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top pt-7'>
            <div className='row'>
              <div className='col-sm-6 col-12 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYMENT'})}
                </label>
                <div className='input-group'>
                  {formik?.values?.profile && (
                    <Flatpickr
                      options={FlatpickrDateOptions}
                      value={
                        user.profile?.date_of_employment
                          ? moment(user?.profile?.date_of_employment).format('DD.MM.YYYY')
                          : ''
                      }
                      className={clsx('form-control form-control-solid mb-1 mb-lg-0')}
                      placeholder={intl.formatMessage({
                        id: 'ACCOUNT.BUSINESS_DETAILS.DATE_OF_EMPLOYMENT',
                      })}
                      name='profile.date_of_employment'
                      onChange={(selectedStartDates) => {
                        if (user?.profile) {
                          if (formik.values.profile) {
                            startofEmploymentDate.current = moment(
                                selectedStartDates[0]
                            ).format('YYYY-MM-DD')
                          }
                        }
                      }}
                      onClose={(selectedStartDates) => {
                        if (selectedStartDates.length === 0) {
                          startofEmploymentDate.current = ''
                        }
                      }}
                    ></Flatpickr>
                  )}
                  <span className='input-group-text border-0 '>
                    <i className='fa fa-calendar'></i>
                  </span>
                </div>
              </div>
              <div className='col-sm-6 col-12 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.END_OF_EMPLOYMENT'})}
                </label>
                <div className='input-group'>
                  {formik?.values?.profile && (
                    <Flatpickr
                      options={FlatpickrDateOptions}
                      value={
                        user.profile?.end_of_employment_date
                          ? moment(user?.profile?.end_of_employment_date).format('DD.MM.YYYY')
                          : ''
                      }
                      className={clsx('form-control form-control-solid mb-1 mb-lg-0')}
                      placeholder={intl.formatMessage({
                        id: 'ACCOUNT.BUSINESS_DETAILS.END_OF_EMPLOYMENT_DATE',
                      })}
                      name='profile.end_of_employment_date'
                      onChange={(selectedEndDates) => {
                        if (user?.profile) {
                          if (formik.values.profile) {
                            endOfEmploymentDate.current = moment(
                                selectedEndDates[0]
                            ).format('YYYY-MM-DD')
                          }
                        }
                      }}
                      onClose={(selectedEndDates) => {
                        if (selectedEndDates.length === 0) {
                          endOfEmploymentDate.current = ''
                        }
                      }}
                    ></Flatpickr>
                  )}
                  <span className='input-group-text border-0 '>
                    <i className='fa fa-calendar'></i>
                  </span>
                </div>
              </div>
              <div className='col-sm-4 col-12 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.CODE'})}
                </label>
                {formik?.values?.profile && (
                  <input
                    placeholder={intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.CODE'})}
                    {...(formik?.getFieldProps('profile.code') || '')}
                    name='profile.code'
                    type='text'
                    aria-describedby='basic-addon2'
                    className={clsx('form-control form-control-solid')}
                    autoComplete='off'
                  />
                )}
              </div>
              <div className='col-sm-4 col-12 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.BUSINESS_NUMBER'})}
                </label>
                {formik?.values?.profile && (
                  <input
                    placeholder={intl.formatMessage({
                      id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.BUSINESS_NUMBER',
                    })}
                    {...(formik?.getFieldProps('profile.business_number') || '')}
                    name='profile.business_number'
                    type='text'
                    aria-describedby='basic-addon2'
                    className={clsx('form-control form-control-solid')}
                    autoComplete='off'
                  />
                )}
              </div>
              <div className='col-sm-4 col-12 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.APU_LEVEL'})}
                </label>
                <SelectField
                  name={'apu'}
                  className_condition={false}
                  placeholder={intl.formatMessage({
                    id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.APU_LEVEL.CHOOSE',
                  })}
                  value={{
                    value: apuLevel?.value || '',
                    label:
                      apuLevel?.label ||
                      intl.formatMessage({
                        id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.APU_LEVEL.CHOOSE',
                      }),
                  }}
                  options={apuLevels}
                  onChange={(newValue: any) => {
                    setApuLevel(newValue)
                    formik.setFieldValue('profile.apu', newValue?.value)
                  }}
                  isSearchable={false}
                  formatOptionLabel={{}}
                />
              </div>
              <div className='col-sm-6 col-12 mb-4'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SHIFT'})}
                </label>
                <SelectField
                  name={'shift'}
                  className_condition={false}
                  placeholder={intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SHIFT.CHOOSE'})}
                  value={{
                    value: shift.value || '',
                    label:
                      shift.label ||
                      intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SHIFT.CHOOSE'}),
                  }}
                  options={listShifts}
                  onChange={(newValue: any) => {
                    setShift(newValue)
                    formik.setFieldValue('profile.shift', newValue?.value)

                    setWorkingHours({value: null, label: null})
                    formik.setFieldValue('profile.shift_from', null)
                    formik.setFieldValue('profile.shift_to', null)
                  }}
                  isSearchable={false}
                  formatOptionLabel={{}}
                />
              </div>
              <div className='col-sm-6 col-12 mb-4'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SHIFT.WORKING_HOURS'})}
                </label>
                <SelectField
                  name={'working_hours'}
                  className_condition={false}
                  placeholder={intl.formatMessage({
                    id: 'ACCOUNT.BUSINESS_DETAILS.SHIFT.WORKING_HOURS.FIRST_CHOOSE',
                  })}
                  value={{
                    value: workingHours.value || '',
                    label:
                      workingHours.label ||
                      (shift.value
                        ? intl.formatMessage({
                            id: 'ACCOUNT.BUSINESS_DETAILS.SHIFT.WORKING_HOURS.CHOOSE',
                          })
                        : intl.formatMessage({
                            id: 'ACCOUNT.BUSINESS_DETAILS.SHIFT.WORKING_HOURS.FIRST_CHOOSE',
                          })),
                  }}
                  options={
                    shift.value === 'RR'
                      ? listRrShifts
                      : shift.value === 'SR'
                      ? listSrShifts
                      : shift.value === 'PRV'
                      ? listPrvShifts
                      : intl.formatMessage({
                          id: 'ACCOUNT.BUSINESS_DETAILS.SHIFT.WORKING_HOURS.FIRST_CHOOSE',
                        })
                  }
                  onChange={(newValue: any) => {
                    const from = newValue.value.split(' ')[0]
                    const to = newValue.value.split(' ')[1]

                    formik.setFieldValue('profile.shift_from', from)
                    formik.setFieldValue('profile.shift_to', to)

                    setWorkingHours(newValue)
                  }}
                  isSearchable={false}
                  formatOptionLabel={{}}
                />
              </div>
              <div className='col-sm-6 col-12 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'CONTRACT.TYPE'})}
                </label>
                {formik?.values && (
                  <SelectField
                    name={'profile.contract_type'}
                    className_condition={formik.touched.type && formik.errors.type}
                    placeholder={intl.formatMessage({id: 'CONTRACT.TYPE.SELECT'})}
                    value={contractType ? {
                      value: contractType,
                      label: contractType === 'defined' ? contract_defined : contract_undefined,
                    } : {
                      value: '',
                      label: intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE.CHOOSE'}),
                    }}
                    options={listContractType}
                    onChange={(newValue: any) => {
                      formik.setFieldValue('profile.contract_type', newValue?.value)
                      setContractType(newValue.value)
                    }}
                    isSearchable={false}
                    formatOptionLabel={{}}
                  />
                )}
              </div>
              <div className='col-sm-6 col-12 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT.END_OF_EMPLOYMENT'})}
                </label>
                <div className='input-group'>
                  <Flatpickr
                    options={FlatpickrDateOptions}
                    value={
                      user.profile?.end_of_contract
                        ? moment(user?.profile?.end_of_contract).format('DD.MM.YYYY')
                        : ''
                    }
                    className={clsx('form-control form-control-solid mb-1 mb-lg-0')}
                    placeholder={intl.formatMessage({
                      id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT.END_OF_EMPLOYMENT',
                    })}
                    name='profile.end_of_contract'
                    onChange={(selectedDates) => {
                      if (user?.profile) {
                        if (formik.values.profile) {
                          formik.values.profile.end_of_contract = moment(selectedDates[0]).format(
                            'YYYY-MM-DD'
                          )
                        }
                      }
                    }}
                  ></Flatpickr>
                  <span className='input-group-text border-0 '>
                    <i className='fa fa-calendar'></i>
                  </span>
                </div>
              </div>
            </div>
            <div className='row'>
              <div
                className={`mb-3 ${
                  formik.values.profile.is_on_probation !== 1
                    ? 'col-sm-6 col-md-6 col-lg-6 col-xxl-6'
                    : 'col-4 col-sm-3 col-md-2 col-lg-2 col-xxl-2'
                }`}
              >
                <label className='col-form-label fw-bold fs-6 mb-2'>
                  {intl.formatMessage({id: 'CONTRACT.IS_ON_PROBATION'})}
                </label>
                <div className='d-flex align-items-center form-check form-check-solid form-switch'>
                  <input
                    className='form-check-input form-check-solid form-switch w-45px h-30px'
                    type='checkbox'
                    name='profile.is_on_probation'
                    checked={formik.values.profile.is_on_probation === 1}
                    onChange={() => {
                      formik.setFieldValue(
                        'profile.is_on_probation',
                        formik.values.profile.is_on_probation === 1 ? 0 : 1
                      )
                    }}
                  />
                </div>
              </div>
              {formik.values.profile.is_on_probation === 1 ? (
                <div className='col-8 col-sm-3 col-md-4 col-lg-4 col-xxl-4'>
                  <label className='col-form-label fw-bold fs-6'>
                    {intl.formatMessage({id: 'CONTRACT.PROBATION_TO'})}
                  </label>
                  <div className='input-group'>
                    <Flatpickr
                      value={
                        user?.profile?.probation_to
                          ? moment(user?.profile?.probation_to).format('DD.MM.YYYY')
                          : ''
                      }
                      className={clsx('form-control form-control-solid mb-lg-0')}
                      placeholder={intl.formatMessage({id: 'CONTRACT.PROBATION_TO'})}
                      name='profile.probation_to'
                      options={FlatpickrDateOptions}
                      onChange={(selectedDates) => {
                        formik.values.profile.probation_to = moment(selectedDates[0]).format(
                          'YYYY-MM-DD'
                        )
                      }}
                    ></Flatpickr>
                    <span className='input-group-text border-0 '>
                      <i className='fa fa-calendar'></i>
                    </span>
                  </div>
                </div>
              ) : (
                ''
              )}
              {(userPermissionHr || userPermissionAdmin) && (
                <div className='col-sm-6 mb-3'>
                  <label className='col-form-label fw-bold fs-6'>
                    {intl.formatMessage({id: 'CONTRACT.GROSS_SALARY'})}
                  </label>
                  <div className='input-group'>
                    <input
                      placeholder={intl.formatMessage({id: 'CONTRACT.GROSS_SALARY'})}
                      {...formik.getFieldProps('profile.gross_salary')}
                      type='text'
                      name='profile.gross_salary'
                      className={'form-control form-control-solid'}
                      autoComplete='off'
                    />
                    <span className='input-group-text d-flex border-0'>
                      {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.GROSS_SALARY.CURRENCY'})}
                    </span>
                  </div>
                </div>
              )}
              <div className='col-12 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.REASON_FOR_TERMINATION'})}
                </label>
                <textarea
                  placeholder={intl.formatMessage({
                    id: 'ACCOUNT.BUSINESS_DETAILS.REASON_FOR_TERMINATION',
                  })}
                  {...(formik?.getFieldProps('profile.reason_for_termination') || '')}
                  name='profile.reason_for_termination'
                  aria-describedby='basic-addon2'
                  className={clsx('form-control form-control-solid')}
                  autoComplete='off'
                />
              </div>
              <div className='col-sm-4 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'CONTRACT.ALL_WORK_EXPERIENCE'})}
                </label>
                <div className='input-group'>
                  <input
                    placeholder={intl.formatMessage({id: 'CONTRACT.WORK_YEARS'})}
                    {...formik.getFieldProps('profile.experience_years')}
                    type='text'
                    name='profile.experience_years'
                    className={'form-control form-control-solid'}
                    autoComplete='off'
                  />
                  <span className='input-group-text d-flex border-0 text-upercase'>
                    {intl.formatMessage({id: 'CONTRACT.YEARS'})}
                  </span>
                </div>
              </div>
              <div className='col-sm-4 mb-3'>
                <label className='col-form-label d-none d-sm-flex fw-bold fs-6'>
                    &nbsp;
                </label>
                <div className='input-group'>
                  <input
                      placeholder={intl.formatMessage({id: 'CONTRACT.WORK_MONTHS'})}
                      {...formik.getFieldProps('profile.experience_months')}
                      type='text'
                      name='profile.experience_months'
                      className={'form-control form-control-solid'}
                      autoComplete='off'
                  />
                  <span className='input-group-text d-flex border-0 text-upercase'>
                    {intl.formatMessage({id: 'CONTRACT.MONTHS'})}
                  </span>
                </div>
              </div>
              <div className='col-sm-4 mb-3'>
                <label className='col-form-label d-none d-sm-flex fw-bold fs-6'>
                    &nbsp;
                </label>
                <div className='input-group'>
                  <input
                      placeholder={intl.formatMessage({id: 'CONTRACT.WORK_DAYS'})}
                      {...formik.getFieldProps('profile.experience_days')}
                      type='text'
                      name='profile.experience_days'
                      className={'form-control form-control-solid'}
                      autoComplete='off'
                  />
                  <span className='input-group-text d-flex border-0 text-upercase'>
                    {intl.formatMessage({id: 'CONTRACT.DAYS'})}
                  </span>
                </div>
              </div>
              <span className="text-muted mt-1 fw-bold fs-7 text-end w-100">*Radni staž na dan 31.12.2023. godine</span>
              <div className='col-sm-4 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'CONTRACT.ALL_WORK_EXPERIENCE_IN_COMPANY'})}
                </label>
                <div className='input-group'>
                  <input
                      placeholder={intl.formatMessage({id: 'CONTRACT.WORK_YEARS'})}
                      type='text'
                      className={'form-control form-control-solid'}
                      autoComplete='off'
                      readOnly={true}
                      value={companyExperienceDiff ? companyExperienceDiff.years : ''}
                  />
                  <span className='input-group-text d-flex border-0 text-upercase'>
                    {intl.formatMessage({id: 'CONTRACT.YEARS'})}
                  </span>
                </div>
              </div>
              <div className='col-sm-4 mb-3'>
                <label className='col-form-label d-none d-sm-flex fw-bold fs-6'>
                  &nbsp;
                </label>
                <div className='input-group'>
                  <input
                      placeholder={intl.formatMessage({id: 'CONTRACT.WORK_MONTHS'})}
                      type='text'
                      className={'form-control form-control-solid'}
                      autoComplete='off'
                      readOnly={true}
                      value={companyExperienceDiff ? companyExperienceDiff.months : ''}
                  />
                  <span className='input-group-text d-flex border-0 text-upercase'>
                    {intl.formatMessage({id: 'CONTRACT.MONTHS'})}
                  </span>
                </div>
              </div>
              <div className='col-sm-4 mb-3'>
                <label className='col-form-label d-none d-sm-flex fw-bold fs-6'>
                  &nbsp;
                </label>
                <div className='input-group'>
                  <input
                      placeholder={intl.formatMessage({id: 'CONTRACT.WORK_DAYS'})}
                      type='text'
                      className={'form-control form-control-solid'}
                      autoComplete='off'
                      readOnly={true}
                      value={companyExperienceDiff ? companyExperienceDiff.days : ''}
                  />
                  <span className='input-group-text d-flex border-0 text-upercase'>
                    {intl.formatMessage({id: 'CONTRACT.DAYS'})}
                  </span>
                </div>
              </div>
              <span className="text-muted mt-1 fw-bold fs-7 text-end w-100">*Radni staž u kompaniji od datuma početka radnog odnosa</span>
              <div className='col-12 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.NOTE'})}
                </label>
                <textarea
                  placeholder={intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.NOTE'})}
                  {...(formik?.getFieldProps('profile.note') || '')}
                  name='profile.note'
                  aria-describedby='basic-addon2'
                  className={clsx('form-control form-control-solid')}
                  autoComplete='off'
                />
              </div>
            </div>
            <div className='row pt-9'>
              <div>
                <label className='fw-bold fs-4'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.JOB_POSITION'})}
                </label>
              </div>
              <div className='col-12 mb-3'>
                <label className='col-form-label fw-bold fs-6 required'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.POSITION'})}
                </label>
                <Select
                  placeholder={intl.formatMessage({
                    id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.SELECT_POSITION',
                  })}
                  value={
                    jobPosition
                      ? {
                          value: jobPosition?.id,
                          label: jobPosition?.name,
                          job_position: jobPosition,
                        }
                      : {
                          value: 1 as ID,
                          label: intl.formatMessage({
                            id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.SELECT_POSITION',
                          }) as string | undefined,
                          job_position: {} as JobPosition,
                        }
                  }
                  name='job_position_id'
                  className={
                    getIn(formik.errors, 'job_position_id') &&
                    getIn(formik.touched, 'job_position_id')
                      ? 'mb-3 mb-lg-0 border border-danger rounded'
                      : 'mb-3 mb-lg-0'
                  }
                  classNamePrefix='select'
                  options={jobPositionsList}
                  noOptionsMessage={() => intl.formatMessage({id: 'NO_DATA'})}
                  formatOptionLabel={formatJobPositionOptionLabel}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral50:
                        localStorage.getItem('kt_theme_mode_menu') === 'dark'
                          ? '#495674'
                          : '#a1a5b7',
                    },
                  })}
                  onChange={(newValue: any) => {
                    formik.setFieldValue('job_position_id', newValue.value)
                    setJobPosition(
                      job_positions?.data?.filter(
                        (position) => position.id?.toString() === newValue.value.toString()
                      )[0]
                    )
                  }}
                  menuPortalTarget={document.body}
                />
                {getIn(formik.errors, 'job_position_id') &&
                  getIn(formik.touched, 'job_position_id') && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{getIn(formik.errors, 'job_position_id')}</span>
                      </div>
                    </div>
                  )}
              </div>
              <div className='col-sm-4 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'JOB_POSITION.SECTOR'})}
                </label>
                <div className='input-group'>
                  <input
                    readOnly={true}
                    placeholder={intl.formatMessage({id: 'JOB_POSITION.SECTOR'})}
                    value={
                      jobPosition?.sector === 'technical'
                        ? technical
                        : jobPosition?.sector === 'commercial'
                        ? commercial
                        : jobPosition?.sector === 'global'
                        ? global
                        : ''
                    }
                    type='text'
                    aria-describedby='basic-addon2'
                    className={clsx('form-control form-control-solid')}
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='col-sm-4 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'JOB_POSITION.DEPARTMENT'})}
                </label>
                <div className='input-group'>
                  <input
                    readOnly={true}
                    placeholder={intl.formatMessage({id: 'JOB_POSITION.DEPARTMENT'})}
                    value={
                      jobPosition?.department?.parent
                        ? jobPosition?.department?.parent?.name
                        : jobPosition?.department
                        ? jobPosition?.department?.name
                        : '-'
                    }
                    type='text'
                    aria-describedby='basic-addon2'
                    className={clsx('form-control form-control-solid')}
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='col-sm-4 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'JOB_POSITION.DEPARTMENT.PARENT'})}
                </label>
                <div className='input-group'>
                  <input
                    readOnly={true}
                    placeholder={intl.formatMessage({id: 'JOB_POSITION.DEPARTMENT.PARENT'})}
                    value={jobPosition?.department?.parent ? jobPosition?.department?.name : '-'}
                    type='text'
                    aria-describedby='basic-addon2'
                    className={clsx('form-control form-control-solid')}
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='col-sm-6 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'JOB_POSITION.WE_LEVEL'})}
                </label>
                <div className='input-group'>
                  <input
                    readOnly={true}
                    placeholder={intl.formatMessage({id: 'JOB_POSITION.WE_LEVEL'})}
                    value={jobPosition?.we_level || ''}
                    type='text'
                    aria-describedby='basic-addon2'
                    className={clsx('form-control form-control-solid')}
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='col-sm-6 col-12 mb-3'>
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CATEGORY'})}
                </label>
                <div className='input-group'>
                  <input
                    readOnly={true}
                    placeholder={intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CATEGORY'})}
                    value={jobPositionCategory(jobPosition?.category)}
                    type='text'
                    aria-describedby='basic-addon2'
                    className={clsx('form-control form-control-solid')}
                    autoComplete='off'
                  />
                </div>
              </div>
            </div>
            <div className='row pt-9'>
              <div>
                <label className='fw-bold fs-4'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.PU'})}
                </label>
              </div>
            </div>
            <div className='col-12 mb-3'>
              <label className='col-form-label fw-bold fs-6 required'>
                {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.POSITION'})}
              </label>
              <Select
                placeholder={intl.formatMessage({
                  id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.SELECT_POSITION',
                })}
                value={
                  puJobPosition
                    ? {
                        value: puJobPosition?.id,
                        label: puJobPosition?.name,
                        job_position: puJobPosition,
                      }
                    : {
                        value: 1 as ID,
                        label: intl.formatMessage({
                          id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.SELECT_POSITION',
                        }) as string | undefined,
                        job_position: {} as JobPosition,
                      }
                }
                name='pu_position_id'
                className={
                  getIn(formik.errors, 'pu_position_id') && getIn(formik.touched, 'pu_position_id')
                    ? 'mb-3 mb-lg-0 border border-danger rounded'
                    : 'mb-3 mb-lg-0'
                }
                classNamePrefix='select'
                options={puJobPositionsList}
                noOptionsMessage={() => intl.formatMessage({id: 'NO_DATA'})}
                formatOptionLabel={formatJobPositionOptionLabel}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50:
                      localStorage.getItem('kt_theme_mode_menu') === 'dark' ? '#495674' : '#a1a5b7',
                  },
                })}
                onChange={(newValue: any) => {
                  formik.setFieldValue('pu_position_id', newValue.value)
                  setPuJobPosition(
                    job_positions?.data?.filter(
                      (position) => position.id?.toString() === newValue.value.toString()
                    )[0]
                  )

                  !!!puPosition.includes(
                    job_positions?.data?.filter(
                      (position) => position.id?.toString() === newValue.value.toString()
                    )[0]
                  ) &&
                    setPuPosition((puPosition) => [
                      ...puPosition,
                      job_positions?.data?.filter(
                        (position) => position.id?.toString() === newValue.value.toString()
                      )[0],
                    ])
                }}
                menuPortalTarget={document.body}
              />
              {getIn(formik.errors, 'pu_position_id') && getIn(formik.touched, 'pu_position_id') && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{getIn(formik.errors, 'pu_position_id')}</span>
                  </div>
                </div>
              )}
            </div>
            <div>
              {puPosition.length > 0 && (
                <label className='col-form-label fw-bold fs-6'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.PU.POSITION.VIEW'})}
                </label>
              )}
              {puPosition.length > 0 &&
                puPosition.map((puValue: any) => {
                  return (
                    <div key={puValue?.id} className='ps-2 pe-2'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-inline'>
                          <label className='col-form-label fw-bold fs-6 pt-0 w-100 pb-0'>
                            {puValue?.name}
                          </label>
                          <label className='col-form-label fw-bold fs-7 pt-0 text-gray-700'>
                            {puValue?.department ? puValue?.department?.name : ''}
                          </label>
                          <span className='ps-2 pe-2'>{puValue?.department?.name ? '-' : ''}</span>
                          <label className='col-form-label fw-bold fs-7 pt-0 text-gray-700'>
                            {puValue?.department?.sector === 'technical'
                              ? technical
                              : puValue?.department?.sector === 'commercial'
                              ? commercial
                              : puValue?.department?.sector === 'global'
                              ? global
                              : ''}
                          </label>
                        </div>
                        <OverlayTrigger placement='top' overlay={deleteTooltip}>
                          <label
                            onClick={() => deletePuPosition(puValue)}
                            className='pt-0 col-form-label fw-bold fs-4 cursor-pointer text-hover-danger'
                          >
                            x
                          </label>
                        </OverlayTrigger>
                      </div>
                      <span></span>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link to={`/user/${user.id}/details/business/view`}>
              <button
                type='reset'
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                disabled={loading}
              >
                {intl.formatMessage({id: 'BUTTON.DISCARD'})}
              </button>
            </Link>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && intl.formatMessage({id: 'BUTTON.SUBMIT'})}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
      {loading && (
        <div className='position-absolute top-50 start-50 translate-middle'>
          <SimpleLoading />
        </div>
      )}
    </div>
  )
}

export {EditBusinessDetails}
