import React from "react";

const elementStyle = {display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center'}

const SimpleLoading = () => {
    return (
        <>
            <div style={elementStyle}>
                <div className="simple-loading-back">
                    <div className="simple-loading-dot simple-loading-one"></div>
                </div>
                <div className="simple-loading-back">
                    <div className="simple-loading-dot simple-loading-two"></div>
                </div>
                <div className="simple-loading-back">
                    <div className="simple-loading-dot simple-loading-three"></div>
                </div>
                <div className="simple-loading-back">
                    <div className="simple-loading-dot simple-loading-four"></div>
                </div>
            </div>
        </>
    )
}

export {SimpleLoading}
