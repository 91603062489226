import React, {useEffect, useState} from 'react'
import {Route, Routes, Outlet, useParams, Navigate, useNavigate} from 'react-router-dom'
import {UserLeftPanel} from './UserLeftPanel'
import {PersonalDetails} from '../overview/userDetails/cards/PersonalDetails'
import {BusinessDetails} from '../overview/userDetails/cards/BusinessDetails'
import {FamilyDetails} from '../overview/userDetails/cards/FamilyDetails'
import {EditPersonalDetails} from '../edit/EditPersonalDetails'
import {EditFamilyDetails} from '../edit/EditFamilyDetails'
import {EditBusinessDetails} from '../edit/EditBusinessDetails'
import {getUserById, getUsers} from '../../../shared/services/user-service'
import {User} from '../../../shared/models/user'
import {ListLoading} from '../../../modules/apps/shared/list/components/loading/ListLoading'
import {EditUserDetails} from '../edit/EditUserDetails'
import {useListView} from '../../../modules/apps/shared/list/core/ListViewProvider'
import {UserDetails} from '../overview/userDetails/cards/UserDetails'
import {RoutesHelper} from '../../../shared/helper/RoutesHelper'
import {AdditionalUserDocumentation} from '../overview/AdditionalFiles/AdditionalUserDocumentation'
import {AdditionalUserDocumentationPreview} from '../overview/AdditionalFiles/AdditionalUserDocumentationPreview'
import {Requests} from '../overview/requests/Requests'
import {ContractTable} from '../overview/contracts/ContractTable'
import {UserDasboardInfo} from '../overview/userDetails/cards/UserDasboardInfo'
import {Vacation} from '../overview/vacation/Vacation'
import {VacationPreview} from '../overview/vacation/VacationPreview'
import {Evaluations} from "../overview/requests/Evaluations";

const UserPage: React.FC = () => {
  const userId = useParams().id
  const navigate = useNavigate()
  const {setUserIDForFamily} = useListView()
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState({} as User)
  const current_user = JSON.parse(localStorage.getItem('current_user') || '')

  const mainMenuCss = RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) ? 'col-xl-8' : 'mt-0'
  const profileQuery = RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2'])
    ? 'expand=profile.image_url,contracts,immediate_superiors'
    : 'expand=profile.image_url,immediate_superiors'

  setUserIDForFamily(userId)
  const asyncFetch = async () => {
    setUser((await getUserById(userId, profileQuery)) as User)
    setIsLoading(false)
  }

  const asyncFetchUsers = async () => {
    await getUsers('list=true').then((response) => {
      if (response?.data?.find((user: any) => user?.id?.toString() === userId)) {
        asyncFetch()
      } else {
        navigate(`/dashboard`)
      }
    })
  }
  useEffect(
    () => {
      setIsLoading(true)
      asyncFetchUsers()
    },
    // eslint-disable-next-line
    [userId]
  )

  return (
    <>
      {user?.id ? (
        <>
          <Routes>
            <Route
              element={
                <>
                  <Outlet />
                </>
              }
            >
              {(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) || current_user?.id === user?.id) && (
                <Route
                  path='details/personal/view'
                  element={
                    <>
                      <div className='row g-5 g-xl-8'>
                        {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) ? (
                          <div className='col-xl-4'>
                            <UserLeftPanel user={user} />
                          </div>
                        ) : (
                          <div className='h-100'>
                            <UserDasboardInfo user={user} />
                          </div>
                        )}
                        <div className={mainMenuCss}>
                          <PersonalDetails className='card-xl-stretch' user={user} />
                        </div>
                        {isLoading && <ListLoading />}
                      </div>
                    </>
                  }
                />
              )}
              <Route
                path='details/business/view'
                element={
                  <>
                    <div className='row g-5 g-xl-8'>
                      {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) ? (
                        <div className='col-xl-4'>
                          <UserLeftPanel user={user} />
                        </div>
                      ) : (
                        <div className='h-100'>
                          <UserDasboardInfo user={user} />
                        </div>
                      )}
                      <div className={mainMenuCss}>
                        <BusinessDetails className='card-xl-stretch' user={user} />
                      </div>
                      {isLoading && <ListLoading />}
                    </div>
                  </>
                }
              />
              {(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) || current_user?.id === user?.id) && (
                <Route
                  path='details/family/view'
                  element={
                    <>
                      <div className='row g-5 g-xl-8'>
                        {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) ? (
                          <div className='col-xl-4'>
                            <UserLeftPanel user={user} />
                          </div>
                        ) : (
                          <div className='h-100'>
                            <UserDasboardInfo user={user} />
                          </div>
                        )}
                        <div className={mainMenuCss}>
                          <FamilyDetails className='card-xl-stretch' user={user} />
                        </div>
                        {isLoading && <ListLoading />}
                      </div>
                    </>
                  }
                />
              )}
              {(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) || current_user?.id === user?.id) && (
                <Route
                  path='details/documentation/view'
                  element={
                    <>
                      <div className='row g-5 g-xl-8'>
                        {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) ? (
                          <div className='col-xl-4'>
                            <UserLeftPanel user={user} />
                          </div>
                        ) : (
                          <div className='h-100'>
                            <UserDasboardInfo user={user} />
                          </div>
                        )}
                        <div className={mainMenuCss}>
                          <AdditionalUserDocumentationPreview user={user} />
                        </div>
                        {isLoading && <ListLoading />}
                      </div>
                    </>
                  }
                />
              )}
              {(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) || current_user?.id === user?.id) && (
                <Route
                  path='details/vacation/view'
                  element={
                    <>
                      <div className='row g-5 g-xl-8'>
                        {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) ? (
                          <div className='col-xl-4'>
                            <UserLeftPanel user={user} />
                          </div>
                        ) : (
                          <div className='h-100'>
                            <UserDasboardInfo user={user} />
                          </div>
                        )}
                        <div className={mainMenuCss}>
                          <VacationPreview user={user} />
                        </div>
                        {isLoading && <ListLoading />}
                      </div>
                    </>
                  }
                />
              )}
              {(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) || current_user?.id === user?.id) && (
                <Route
                  path='details/settings/view'
                  element={
                    <>
                      <div className='row g-5 g-xl-8'>
                        {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) ? (
                          <div className='col-xl-4'>
                            <UserLeftPanel user={user} />
                          </div>
                        ) : (
                          <div className='h-100'>
                            <UserDasboardInfo user={user} />
                          </div>
                        )}
                        <div className={mainMenuCss}>
                          <UserDetails
                            className='card-xl-stretch mb-xl-8'
                            user={user}
                            asyncFetch={asyncFetch}
                          />
                        </div>
                        {isLoading && <ListLoading />}
                      </div>
                    </>
                  }
                />
              )}
              <Route />
              {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) && (
                <Route
                  path='details/personal/edit'
                  element={
                    <>
                      <div className='row g-5 g-xl-8'>
                        <div className='col-xl-4'>
                          <UserLeftPanel user={user} />
                        </div>
                        <div className='col-xl-8'>
                          <EditPersonalDetails user={user} setUser={setUser} />
                        </div>
                        {isLoading && <ListLoading />}
                      </div>
                    </>
                  }
                />
              )}
              {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) && (
                <Route
                  path='details/business/edit'
                  element={
                    <>
                      <div className='row g-5 g-xl-8'>
                        <div className='col-xl-4'>
                          <UserLeftPanel user={user} />
                        </div>
                        <div className='col-xl-8'>
                          <EditBusinessDetails user={user} setUser={setUser} />
                        </div>
                        {isLoading && <ListLoading />}
                      </div>
                    </>
                  }
                />
              )}
              {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) && (
                <Route
                  path='details/family/edit'
                  element={
                    <>
                      <div className='row g-5 g-xl-8'>
                        <div className='col-xl-4'>
                          <UserLeftPanel user={user} />
                        </div>
                        <div className='col-xl-8'>
                          <EditFamilyDetails
                            user={user}
                            setUser={setUser}
                            asyncFetch={asyncFetch}
                          />
                        </div>
                        {isLoading && <ListLoading />}
                      </div>
                    </>
                  }
                />
              )}
              {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) && (
                <Route
                  path='details/user/edit'
                  element={
                    <>
                      <div className='row g-5 g-xl-8'>
                        <div className='col-xl-4'>
                          <UserLeftPanel user={user} />
                        </div>
                        <div className='col-xl-8'>
                          <EditUserDetails user={user} setUser={setUser} asyncFetch={asyncFetch} />
                        </div>
                        {isLoading && <ListLoading />}
                      </div>
                    </>
                  }
                />
              )}
              {(RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) || current_user?.id === user?.id) && (
                <Route
                  path='details/additional_documentation'
                  element={
                    <>
                      <div className='row g-5 g-xl-8'>
                        <div className='col-xl-4'>
                          <UserLeftPanel user={user} />
                        </div>
                        <div className='col-xl-8'>
                          <AdditionalUserDocumentation user={user} />
                        </div>
                        {isLoading && <ListLoading />}
                      </div>
                    </>
                  }
                />
              )}
              {(RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) ||
                current_user?.id === user?.id) && (
                <Route
                  path='details/personal_requests'
                  element={
                    <>
                      <div className='row g-5 g-xl-8'>
                        <div className='col-xl-4'>
                          <UserLeftPanel user={user} />
                        </div>
                        <div className='col-xl-8'>
                          <Requests user={user} />
                        </div>
                        {isLoading && <ListLoading />}
                      </div>
                    </>
                  }
                />
              )}
              {(RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) ||
                  current_user?.id === user?.id) && (
                  <Route
                      path='details/evaluations'
                      element={
                        <>
                          <div className='row g-5 g-xl-8'>
                            <div className='col-xl-4'>
                              <UserLeftPanel user={user} />
                            </div>
                            <div className='col-xl-8'>
                              <Evaluations user={user} />
                            </div>
                            {isLoading && <ListLoading />}
                          </div>
                        </>
                      }
                  />
              )}
              {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) && (
                <Route
                  path='details/all_contracts'
                  element={
                    <>
                      <div className='row g-5 g-xl-8'>
                        <div className='col-xl-4'>
                          <UserLeftPanel user={user} />
                        </div>
                        <div className='col-xl-8'>
                          <ContractTable user={user} />
                        </div>
                        {isLoading && <ListLoading />}
                      </div>
                    </>
                  }
                />
              )}
              {(RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) || current_user?.id === user?.id) && (
                <Route
                  path='details/vacations'
                  element={
                    <>
                      <div className='row g-5 g-xl-8'>
                        <div className='col-xl-4'>
                          <UserLeftPanel user={user} />
                        </div>
                        <div className='col-xl-8'>
                          <Vacation user={user} />
                        </div>
                        {isLoading && <ListLoading />}
                      </div>
                    </>
                  }
                />
              )}
            </Route>
            <Route path='*' element={<Navigate to='/error/404' />} />
          </Routes>
        </>
      ) : (
        <>{isLoading && <ListLoading />}</>
      )}
    </>
  )
}

export default UserPage
