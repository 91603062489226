import React, {FC} from 'react';
import {ID, KTSVG} from '../../../../../../../_metronic/helpers';
import {Link} from 'react-router-dom';
import {useListView} from "../../../../shared/list/core/ListViewProvider";
import {OverlayTrigger, Tooltip, TooltipProps} from "react-bootstrap";
import {useIntl} from "react-intl";
import { RoutesHelper } from '../../../../../../shared/helper/RoutesHelper';

type Props = {
    id: ID
}

const UserActionsCell: FC<Props> = ({id}) => {
    const intl = useIntl();
    const {setItemIdForDelete} = useListView();
    const openDeleteModal = () => {
        setItemIdForDelete(id)
    }

    const editTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
        <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.EDIT'})}</Tooltip>
    );

    const deleteTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
        <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.DELETE'})}</Tooltip>
    );

    return (
        <>
            {(RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2'])) && <>
                <OverlayTrigger placement="top" overlay={editTooltip}>
                    <Link
                        to={`/user/${id}/details/personal/edit/`}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                    </Link>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={deleteTooltip}>
                    <button
                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm modal-open'
                        onClick={openDeleteModal}
                    >
                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3'/>
                    </button>
                </OverlayTrigger>
            </>}
        </>
    )
}

export {UserActionsCell}
