import {useState, useEffect} from "react";
import {useIntl} from "react-intl";
import CreatableSelect from "react-select/creatable";

const CustomSelectWidget = function (props: any) {
    const intl = useIntl();
    const optionsLists = props?.options.enumOptions.map((item: any) => ({
        value: item?.value,
        label: item?.label,
    }));

    const [item, setItem] = useState(
        props?.options?.enumOptions?.filter(
            (item: any) => item?.value === props?.value
        )[0]
    );

    useEffect(() => {
        const selectedItem = optionsLists.find(
            (option: any) => option.value === props.value
        );
        setItem(selectedItem);
    }, [props.value, optionsLists]);

    return (
        <CreatableSelect
            className={'mb-lg-0'}
            name={item?.label}
            placeholder={props?.placeholder}
            options={optionsLists}
            classNamePrefix="select"
            noOptionsMessage={() => intl.formatMessage({ id: 'NO_DATA' })}
            formatCreateLabel={(userInput) => `Dodaj "${userInput}"`}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    neutral50:
                        localStorage.getItem('kt_theme_mode_menu') === 'dark'
                            ? '#495674'
                            : '#a1a5b7',
                },
            })}
            onChange={(newValue: any) => {
                props.onChange(newValue?.value);
                setItem(newValue);
            }}
            menuPortalTarget={document.body}
            isSearchable={props?.isSearchable}
            value={item}
        />
    );
};

export {CustomSelectWidget};

