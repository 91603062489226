import React from 'react';
import {useIntl} from 'react-intl';
import { RoutesHelper } from '../../../../app/shared/helper/RoutesHelper';
import {AsideMenuItem} from './AsideMenuItem';

export function AsideMenuMain() {
    const intl = useIntl()

    return (
        <>
            <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/art/art002.svg'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon='bi-app-indicator'
            />

            {(RoutesHelper(['WE1', 'WE2', 'WE3', 'WE4', 'admin', 'hr', 'hr1', 'hr2'])) && <div className="menu-content ps-7 pt-8 pb-2"><span
                className="menu-section text-muted text-uppercase fs-8 ls-1">{intl.formatMessage({id: 'MENU.SEPARATOR.DATA'})}</span>
            </div>
            }
            {(RoutesHelper(['WE1', 'WE2', 'WE3', 'WE4', 'admin', 'hr', 'hr1', 'hr2'])) &&
                <AsideMenuItem
                    to='user'
                    title={intl.formatMessage({id: 'MENU.USERS'})}
                    icon='/media/icons/duotune/communication/com006.svg'
                >
                </AsideMenuItem>
            }

            {(RoutesHelper(['WE1', 'WE2', 'WE3', 'admin', 'hr', 'hr1', 'hr2'])) &&
                <AsideMenuItem
                    to='/department'
                    title={intl.formatMessage({id: 'MENU.DEPARTMENTS'})}
                    icon='/media/icons/duotune/general/gen025.svg'
                    fontIcon='bi-person'
                >
                </AsideMenuItem>}

            {(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2'])) &&
                <AsideMenuItem
                    to='/contract'
                    title={intl.formatMessage({id: 'MENU.CONTRACTS'})}
                    icon='/media/icons/duotune/files/fil012.svg'
                    fontIcon='bi-person'
                >
                </AsideMenuItem>}

            {(RoutesHelper(['WE1', 'WE2', 'WE3', 'WE4', 'admin', 'hr', 'hr1', 'hr2'])) && <AsideMenuItem
                to='/request'
                title={intl.formatMessage({id: 'MENU.REQUESTS'})}
                icon='/media/icons/duotune/files/fil003.svg'
                fontIcon='bi-person'
            >
            </AsideMenuItem>}

            {(RoutesHelper(['WE1', 'WE2', 'WE3', 'WE4', 'admin', 'hr', 'hr1', 'hr2'])) &&
                <div className="menu-content ps-7 pt-8 pb-2"><span
                    className="menu-section text-muted text-uppercase fs-8 ls-1">{intl.formatMessage({id: 'MENU.SEPARATOR.SETTINGS'})}</span>
                </div>}

            {RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) &&
                <AsideMenuItem
                    to='/job_position'
                    title={intl.formatMessage({id: 'MENU.JOB_POSITIONS'})}
                    icon='/media/icons/duotune/communication/com005.svg'
                    fontIcon='bi-person'
                >
                </AsideMenuItem>}

            <AsideMenuItem
                to='/notification'
                title={intl.formatMessage({id: 'MENU.NOTIFICATIONS'})}
                icon='/media/icons/duotune/communication/com007.svg'
                fontIcon='bi-person'
            >
            </AsideMenuItem>
        </>
    )
}
