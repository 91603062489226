import clsx from 'clsx'
import {FC, useContext, useEffect, useState} from 'react'
import {ThemeModeSwitcher, HeaderUserMenu} from '../../../partials'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {logoutCurrentUser} from '../../../../app/modules/auth'
import {HeaderNotificationsMenu} from '../../../partials/layout/header-menus/HeaderNotificationsMenu'
import {Notification} from '../../../../app/shared/models/notification'
import NotificationService from '../../../../app/shared/services/notification-service'
import {
  NotificationAlertContext,
  NotificationUpdateContext,
} from '../../../../app/shared/components/NotificationContext'
import {NotificationMessage} from '../../../../app/shared/helper/Notification'
import {useIntl} from 'react-intl'
import {avatarColorConfig} from '../../../../app/shared/AvatarConfig'
import Avatar from 'react-avatar'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-2x'

const Topbar: FC = () => {
  let all = 0
  let refresh = true
  let current_user = JSON.parse(
    localStorage.getItem('current_user') || (logoutCurrentUser() as unknown as string)
  )

  const {config} = useLayout()
  const intl = useIntl()
  const [showNotifications, setShowNotifications] = useState(false)
  const [readAll, setReadAll] = useState(false)
  const [notifications, setNotifications] = useState([] as Notification[] | undefined)
  const [numberOfUnreadNotifications, setNumberOfUnreadNotifications] = useState()

  // eslint-disable-next-line
  const [numberOfUnread, setNumberOfUnread] = useContext(NotificationAlertContext)

  // eslint-disable-next-line
  const [updated, setUpdated] = useContext(NotificationUpdateContext)

  useEffect(
    () => {
      setNumberOfUnreadNotifications(numberOfUnread)
    },
    // eslint-disable-next-line
    [numberOfUnread]
  )

  useEffect(
    () => {
      setShowNotifications(false)
      NotificationService.getUnreadNotifications().then((response) => {
        if (response) {
          setNotifications(response.data)
          setNumberOfUnreadNotifications(
            response.data.filter((notification: Notification) => !notification.is_read).length
          )

          // eslint-disable-next-line
          all = response.data.length
          setShowNotifications(true)
          setReadAll(false)
        }
      })
    },
    // eslint-disable-next-line
    [readAll]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      // eslint-disable-next-line
      refresh = !refresh

      setUpdated(refresh)
      setShowNotifications(false)

      NotificationService.getUnreadNotifications().then((response) => {
        if (response) {
          setNotifications(response.data)

          if (response.data.length > all) {
            NotificationMessage(
              intl.formatMessage({id: 'NOTIFICATIONS.NEW'}),
              intl.formatMessage({id: 'NOTIFICATIONS.NEW.INFO'}),
              'info'
            )
          }

          // eslint-disable-next-line
          all = response.data.length
          setNumberOfUnreadNotifications(
            response.data.filter((notification: Notification) => !notification.is_read).length
          )
          setShowNotifications(true)
        }
      })
    }, 60000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div
        className={clsx(
          'd-none d-md-flex align-items-center fw-bold text-gray-600 me-5 cursor-default',
          toolbarButtonMarginClass
        )}
      >
        {current_user?.first_name + ' ' + current_user?.last_name}
      </div>

      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen007.svg'
            className={toolbarButtonIconSizeClass}
          />
          {numberOfUnreadNotifications && numberOfUnreadNotifications > 0 ? (
            <span className='bullet bullet-dot bg-danger fs-9 h-15px w-20px position-absolute translate-middle top-25 start-75 text-white'>
              {numberOfUnreadNotifications}
            </span>
          ) : (
            ''
          )}
        </div>
        <HeaderNotificationsMenu
          notifications={notifications}
          numberOfUnreadNotifications={numberOfUnreadNotifications}
          showNotifications={showNotifications}
          setNotifications={setNotifications}
          setReadAll={setReadAll}
        />
      </div>

      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
        />
      </div>

      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {current_user?.profile?.image_url ? (
            <LazyLoadImage
              src={current_user?.profile?.image_url}
              width={'30px'}
              height={'30px'}
              style={{borderRadius: '50px'}}
              effect='blur'
            />
          ) : (
            <Avatar
              className='bg-hover-primary bg-active-primary text-hover-light'
              name={current_user?.first_name + ' ' + current_user?.last_name}
              round={true}
              color={avatarColorConfig.color}
              fgColor={avatarColorConfig.fgColor}
              textSizeRatio={2 / 3}
              size={'3rem'}
              title={avatarColorConfig.title}
            />
          )}
        </div>
        <HeaderUserMenu />
      </div>

      {config.header.left === 'menu' && (
        <div
          className='d-flex align-items-center d-lg-none ms-2 me-n3 d-none'
          title='Show header menu'
        >
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export {Topbar}
