import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from "react-intl";
import {sendResetPasswordMail} from "../../../shared/services/user-service";
import {NotificationMessage} from "../../../shared/helper/Notification";

const initialValues = {
    email: '',
}

export function ForgotPassword() {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [resetEmail, setResetEmail] = useState(false);

    const forgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({id: 'WRONG_EMAIL'}))
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'})),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values) => {
            setLoading(true);
            sendResetPasswordMail(values.email, '').then((response: any) => {
                if (response?.errors) {
                    NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.CONTACT_US'}), 'danger');
                    setLoading(false);
                } else {
                    setResetEmail(true);
                }
            });
        },
    });

    return (
        <>
            {resetEmail ? <>
                <div className='text-center mb-5'>
                    <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'FORGOT_PASSWORD'})}</h1>
                </div>
                <div className=' text-center bg-light-info p-8 border border-dashed border-info rounded'>
                    <div className='text-info'>{intl.formatMessage({id: 'FORGOT_PASSWORD.SENT'})}</div>
                </div>
            </> : <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='text-center mb-5'>
                    <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'FORGOT_PASSWORD'})}</h1>
                    <div className='text-gray-400 fw-bold fs-4'>{intl.formatMessage({id: 'FORGOT_PASSWORD.INFO'})}</div>
                </div>

                <div className='fv-row mb-10'>
                    <label
                        className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({id: 'FORGOT_PASSWORD.EMAIL'})}</label>
                    <input
                        type='email'
                        placeholder={intl.formatMessage({id: 'FORGOT_PASSWORD.EMAIL'})}
                        autoComplete='off'
                        {...formik.getFieldProps('email')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {'is-invalid border border-danger': formik.touched.email && formik.errors.email},
                            {
                                'is-valid border border-success': formik.touched.email && !formik.errors.email,
                            }
                        )}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.email}</span>
                            </div>
                        </div>
                    )}
                </div>

                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <button
                        type='submit'
                        id='kt_password_reset_submit'
                        className='btn btn-lg btn-primary fw-bolder me-4'
                        disabled={loading}
                    >
                        {!loading && intl.formatMessage({id: 'FORGOT_PASSWORD.RESET'})}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                    {intl.formatMessage({id: 'FORGOT_PASSWORD.SENDING'})}...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                        )}
                    </button>
                    <Link to='/login'>
                        <button
                            type='button'
                            id='kt_login_password_reset_form_cancel_button'
                            className='btn btn-lg btn-light-primary fw-bolder'
                            disabled={loading}
                        >
                            {intl.formatMessage({id: 'FORGOT_PASSWORD.BACK'})}
                        </button>
                    </Link>{' '}
                </div>
            </form>}
        </>
    )
}
