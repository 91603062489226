import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {Request} from '../../../shared/models/_models';


type Props = {
  request: Request
}

const ContractAction: FC<Props> = ({request})  => {
    const navigate = useNavigate();
    const intl = useIntl()
    const [extendContract, setExtendContract] = useState(intl.formatMessage({id: 'REQUEST.CONTRACT.ACTION'}))
    const lastActiveContract = request?.last_active_contract_id
    const contractId = request?.user?.id    

    useEffect(() => {
        if (lastActiveContract === intl.formatMessage({id: 'REQUEST.CONTRACT.QUESTION'})) {
          setExtendContract(intl.formatMessage({id: 'REQUEST.CONTRACT.EMPTY'}))
        } else {
          setExtendContract(intl.formatMessage({id: 'REQUEST.CONTRACT.ACTION'}))
        }
    },
    // eslint-disable-next-line
    [])

    const createOrTerminateContract = () => {
    if (lastActiveContract !== intl.formatMessage({id: 'REQUEST.CONTRACT.QUESTION'})) {
        navigate(`/contract/${lastActiveContract}/overview`)
    } else if (lastActiveContract === 'no_string') {
        navigate(`/contract/create/${contractId}`)
    }
    }

    return (
        <button type='submit' className='btn btn-sm btn-primary btn-active-light-primary ms-2' data-kt-menu-dismiss='true'
                  onClick={() => createOrTerminateContract()}
        >{extendContract}</button>
    )
}

export {ContractAction}