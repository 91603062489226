import React, {Dispatch, SetStateAction} from 'react'
import {Contract} from '../../../../shared/models/_models'
import moment from 'moment'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import {ContractHelper} from '../../../../shared/helper/ContractHelper'

type props = {
  contract: Contract
  setOpenContractModal: Dispatch<SetStateAction<boolean>>
  setUserContractInfo: Dispatch<SetStateAction<Contract>>
}

const ContractMobileElement: React.FC<props> = ({contract}) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const contract_undefined = useIntl().formatMessage({
    id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE.UNDEFINED',
  })
  const contract_defined = useIntl().formatMessage({
    id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE.DEFINED',
  })

  const imageCssClass = clsx('svg-icon-2x', {
    // eslint-disable-next-line
    ['svg-icon-danger']:
      contract.status === intl.formatMessage({id: 'CONTRACT.TABLE.STATUS.EXPIRE'}),
    // eslint-disable-next-line
    ['svg-icon-success']:
      contract.status === intl.formatMessage({id: 'CONTRACT.TABLE.STATUS.ACTIVE'}),
  })

  const goToContract = () => {
    navigate(`/contract/${{contract}.contract?.id}/overview`)
  }

  return (
    <div className='menu-item my-2'>
      {/*eslint-disable-next-line*/}
      <a
        className={clsx(
          'menu-link d-flex flex-stack bg-hover-light-primary rounded w-100 bg-gray-200'
        )}
        onClick={() => goToContract()}
      >
        <div className='align-items-center w-100'>
          <div className='mb-0 d-flex w-100'>
            <div className='d-flex w-20 align-items-center pe-6'>
            <div className='position-relative'>
                {contract?.signed_contract_file_url && <span className='position-absolute bottom-0 start-100 translate-middle'>
                    <KTSVG
                        path='/media/icons/duotune/communication/com008.svg'
                        className={'svg-icon-primary svg-icon-1'}
                    />
                </span>}
              <div className='symbol symbol-100px'>
                <OverlayTrigger
                  placement='top'
                  overlay={<Tooltip>{ContractHelper(contract)}</Tooltip>}
                >
                  <span>
                    <KTSVG
                      path='/media/icons/duotune/abstract/abs009.svg'
                      className={imageCssClass}
                    />
                  </span>
                </OverlayTrigger>
              </div>
              </div>
            </div>
            <div className='d-block w-100'>
              <div className='d-flex justify-content-start'>
                <span className='text-start text-muted fw-bolder fs-7 gs-0 text-gray-700 pe-1'>
                  {intl.formatMessage({id: 'CONTRACT.CODE'})}:
                </span>
                <div className='text-gray-900 fw-bold fs-7'>{contract?.code || ''}</div>
              </div>
              <div className='d-flex justify-content-start'>
                <span className='text-start text-muted fw-bolder fs-7 gs-0 text-gray-700 pe-1'>
                    {intl.formatMessage({id: 'CONTRACT.TYPE'})}:
                </span>
                <div className='text-gray-900 fw-bold fs-7'>{contract?.type === 'undefined' ? contract_undefined : contract_defined}</div>
              </div>
              <div className='d-flex justify-content-start'>
                <span className='text-start text-muted fw-bolder fs-7 gs-0 text-gray-700 pe-1'>
                    {intl.formatMessage({id: 'CONTRACT.START_DATE'})}:
                </span>
                <div className='text-gray-900 fw-bold fs-7'>{moment(contract?.start_date).format('DD.MM.YYYY') || 'N/A'}</div>
              </div>
              <div className='d-flex justify-content-start'>
                <span className='text-start text-muted fw-bolder fs-7 gs-0 text-gray-700 pe-1'>
                    {intl.formatMessage({id: 'CONTRACT.END_DATE'})}:
                </span>
                <div className='text-gray-900 fw-bold fs-7'>{contract?.end_date ? moment(contract?.end_date).format('DD.MM.YYYY') : ''}</div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

export {ContractMobileElement}
