import {FC} from 'react';
import {JobPosition} from "../../../../shared/models/job_position";
import {Link} from "react-router-dom";


type Props = {
    jobPosition: JobPosition
}

const JobPositionDepartmentCell: FC<Props> = ({jobPosition}) => (
    <div className='d-flex align-items-center'>
        <Link to={`/department/${jobPosition.department?.id}/overview`}
              className='text-dark text-hover-primary fs-6 fw-bold text-gray-800'>
            {jobPosition.department?.name || '-'}
        </Link>
    </div>
)

export {JobPositionDepartmentCell}
