/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {SimpleLoading} from '../../../../modules/apps/shared/list/components/loading/SimpleLoading'
import {getUsersStatistics} from '../../../../shared/services/department-service'
import './tableOfStatistics.css'

type Props = {
  className: string
}

const TableOfStatistics: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  const [statistics, setStatistics] = useState([] as any)
  const [loading, setLoading] = useState(false)

  const category_dir = statistics.reduce((accumulator: any, object: any) => {
    return accumulator + object?.category_dir
  }, 0)

  const category_id = statistics.reduce((accumulator: any, object: any) => {
    return accumulator + object?.category_id
  }, 0)

  const category_id_dir = statistics.reduce((accumulator: any, object: any) => {
    return accumulator + object?.category_id_dir
  }, 0)

  const category_oh = statistics.reduce((accumulator: any, object: any) => {
    return accumulator + object?.category_oh
  }, 0)

  const total = category_dir + category_id + category_id_dir + category_oh

  const asyncFetch = async () => {
    await getUsersStatistics().then((response: any) => setStatistics(response))
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    asyncFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='card card-xxl-stretch-50 mb-5 mb-xl-8 min-h-100'>
      <div className='card-header border-bottom border-2'>
        <h3 className='card-title d-flex align-items-center text-dark fw-bold'>
          {intl.formatMessage({id: 'DASHBOARD.HR.STATISTICS.TITLE'})}
        </h3>
      </div>
      <div className='card-body py-3'>
        {!loading ? (
          <>
            {statistics?.length > 0 ? (
              <div id='table-scroll' className='table-scroll'>
                <table
                  id='main-table'
                  className='main-table table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'
                >
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th></th>
                      <th className='justify-content-center'>
                        <span className='justify-content-center d-flex'>
                          {intl.formatMessage({id: 'DASHBOARD.HR.STATISTICS.DIR'})}
                        </span>
                      </th>
                      <th className='justify-content-center'>
                        <span className='justify-content-center d-flex'>
                          {intl.formatMessage({id: 'DASHBOARD.HR.STATISTICS.ID'})}
                        </span>
                      </th>
                      <th className='justify-content-center'>
                        <span className='justify-content-center d-flex'>
                          {intl.formatMessage({id: 'DASHBOARD.HR.STATISTICS.ID.DIR'})}
                        </span>
                      </th>
                      <th className='justify-content-center'>
                        <span className='justify-content-center d-flex'>
                          {intl.formatMessage({id: 'DASHBOARD.HR.STATISTICS.OH'})}
                        </span>
                      </th>
                      <th className='justify-content-center'>
                        <span className='justify-content-center d-flex'>
                          {intl.formatMessage({id: 'DASHBOARD.HR.STATISTICS.TOTAL'})}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {statistics?.map((value: any, key: any) => (
                      <tr key={key}>
                        <td className='d-flex'>
                          {value?.id ? <a
                              href={`/department/${value?.id}/overview`}
                              className='text-dark fw-bold text-hover-primary fs-7'>
                            {value?.name}
                          </a> : <span
                              className='text-dark fw-bold fs-7'>
                            {value?.name}
                          </span>}
                        </td>
                        <td>
                          <span className='fw-semibold d-block fs-7 justify-content-center d-flex text-gray-800'>
                            {value?.category_dir}
                          </span>
                        </td>
                        <td>
                          <span className='fw-semibold d-block fs-7 justify-content-center d-flex text-gray-800'>
                            {value?.category_id}
                          </span>
                        </td>
                        <td>
                          <span className='fw-semibold d-block fs-7 justify-content-center d-flex text-gray-800'>
                            {value?.category_id_dir}
                          </span>
                        </td>
                        <td>
                          <span className='fw-semibold d-block fs-7 justify-content-center d-flex text-gray-800'>
                            {value?.category_oh}
                          </span>
                        </td>
                        <td>
                          <span className='fw-bold d-block fs-7 justify-content-center d-flex'>
                            {value?.category_dir +
                              value?.category_id +
                              value?.category_id_dir +
                              value?.category_oh}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className='fw-bold text-muted text-uppercase me-0'>
                        {intl.formatMessage({id: 'DASHBOARD.HR.STATISTICS.TOTAL'})}
                      </td>
                      <td className='fw-bold text-muted'>
                        <span className='justify-content-center d-flex'>{category_dir}</span>
                      </td>
                      <td className='fw-bold text-muted'>
                        <span className='justify-content-center d-flex'>{category_id}</span>
                      </td>
                      <td className='fw-bold text-muted'>
                        <span className='justify-content-center d-flex'>{category_id_dir}</span>
                      </td>
                      <td className='fw-bold text-muted'>
                        <span className='justify-content-center d-flex'>{category_oh}</span>
                      </td>
                      <td className='fw-bold text-muted'>
                        <span className='justify-content-center d-flex'>{total}</span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            ) : (
              <div className='text-gray-600 fw-bold pt-10 d-flex text-center w-100 align-content-center justify-content-center'>
                {intl.formatMessage({id: 'TABLE.NOT_FOUND'})}
              </div>
            )}
          </>
        ) : (
          <div className='position-absolute top-50 start-50 translate-middle'>
            <SimpleLoading />
          </div>
        )}
      </div>
    </div>
  )
}

export {TableOfStatistics}
