import React from 'react'

type props = {
  vacation: any
}

const VacationFieldPreview: React.FC<props> = ({vacation}) => {
  const totalDaysOfVacation =
    vacation?.first_part + vacation?.second_part > 0
      ? vacation?.first_part + vacation?.second_part + vacation?.previously_used
      : 0

  return (
    <tr className='border-top'>
      <td className='align-middle text-center fs-6 py-5'>
        {vacation?.year ? vacation?.year : '-'}
      </td>
      <td className='align-middle text-center fs-6 py-5'>
        {vacation?.first_part + vacation?.second_part > 0
          ? vacation?.first_part +
            vacation?.second_part +
            vacation?.previously_used +
            ' (' +
            vacation?.first_part +
            '/' +
            vacation?.second_part +
            '/' +
            vacation?.previously_used +
            ')'
          : '-'}
      </td>
      <td className='align-middle text-center fs-6 py-5'>{vacation?.used}</td>
      <td className='align-middle text-center fs-6 py-5'>
        {totalDaysOfVacation - vacation?.used >= 0 ? totalDaysOfVacation - vacation?.used : '0'}
      </td>
    </tr>
  )
}

export {VacationFieldPreview}
