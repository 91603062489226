import {useIntl} from "react-intl"
import {Contract} from "../models/_models"
import {ContractProbationStatusHelper} from "./ContractProbationStatusHelper";
import React from "react";

const ProbationHelper = (contract: Contract) => {
    const intl = useIntl()
    let contractProbationStatus = ContractProbationStatusHelper.calculate(contract)
    if (contract.is_on_probation && contract.status !== 'expired') {
        return (
            <span>
                {contractProbationStatus.days <= 45 && contractProbationStatus.days > 0 &&
                    <span
                        className={`ms-lg-3 ms-sm-0 ms-md-0 ms-0  notice d-flex bg-light-${contractProbationStatus.status} rounded border-${contractProbationStatus.status} border border-dashed px-2 py-1`}>
                            <span
                                className={`fs-8 text-${contractProbationStatus.status}`}>{intl.formatMessage({id: 'CONTRACT.PROBATION.STATUS.EXPIRED_FOR'}) + contractProbationStatus?.days + ((contractProbationStatus?.days.toString().charAt(contractProbationStatus?.days.toString().length - 1) === '1' && contractProbationStatus?.days?.toString().charAt(contractProbationStatus?.days?.toString()?.length - 2) !== '1') ? intl.formatMessage({id: 'CONTRACT.STATUS.EXPIRED_DAY'}) : intl.formatMessage({id: 'CONTRACT.STATUS.EXPIRED_DAYS'}))}</span>
                        </span>}
                {contractProbationStatus.days <= 0 && <span
                    className={`ms-lg-3 ms-sm-0 ms-md-0 ms-0 notice d-flex bg-light-${contractProbationStatus.status} rounded border-${contractProbationStatus.status} border border-dashed px-2 py-1`}>
                            <span
                                className={`fs-8 text-${contractProbationStatus.status}`}>{intl.formatMessage({id: 'CONTRACT.PROBATION.STATUS.EXPIRED'})}</span>
                        </span>}

            </span>
        )
    }

}
export {ProbationHelper}
