/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react'
import {initialQueryState, KTSVG, useDebounce} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const ListSearchComponent = () => {
    const {updateState} = useQueryRequest()
    const [searchTerm, setSearchTerm] = useState<string>('')
    // Debounce search term so that it only gives us latest value ...
    // ... if searchTerm has not been updated within last 500ms.
    // The goal is to only have the API call fire when user stops typing ...
    // ... so that we aren't hitting our API rapidly.
    const debouncedSearchTerm = useDebounce(searchTerm, 500)
    // Effect for API call
    useEffect(
        () => {
            if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
                updateState({search: debouncedSearchTerm, ...initialQueryState})
            }
        },
        [debouncedSearchTerm] // Only call effect if debounced search term changes
        // More details about useDebounce: https://usehooks.com/useDebounce/
    )

    return (
        <div className='col-sm-12 col-md-12 col-lg-6 col-12'>
            <div className='d-flex align-items-center position-relative my-1'>
                <KTSVG
                    path='/media/icons/duotune/general/gen021.svg'
                    className='svg-icon-1 position-absolute ms-6'
                />
                <input
                    type='search'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid ps-14'
                    placeholder=''
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
        </div>
    )
}

export {ListSearchComponent}
