import {ID, Response} from '../../../_metronic/helpers'
import {
    Request,
    RequestQueryResponse,
    RequestTypeQueryResponse,
    RequestType,
    ActionQueryResponse
} from '../models/_models'
import {get, post, put, remove} from './api-client'
import {AxiosResponse} from 'axios'
import axiosInstance from './axiosInstance'

const ENDPOINT = `/request`
const ENDPOINT_REQUEST_TYPE = `/request-type`
const ENDPOINT_REQUEST_TYPE_LIST = `/request-type/list`
const ENDPOINT_REQUEST_TYPE_FILTER_LIST = `/request-type/filter-list`
const ENDPOINT_ACTIONS = `/actions`
const ENDPOINT_COMMENTS = `/request-note`

const getRequest = (query: string): Promise<RequestQueryResponse> => {
    return get(`${ENDPOINT}`, query)
    .then((response: AxiosResponse<RequestQueryResponse>) => response.data)
}

const getRequestTypes = (query: string): Promise<RequestTypeQueryResponse> => {
    return get(`${ENDPOINT_REQUEST_TYPE}?expand=profile,contracts`, query)
        .then((response: AxiosResponse<RequestTypeQueryResponse>) => response.data)
}

const updateRequestTypeForTechnicalSector = (id: ID): Promise<any | undefined> => {
    return put(`${ENDPOINT_REQUEST_TYPE}/is-active-technical/${id}`, '')
        .then((response: AxiosResponse<any>) => response.data)
}

const updateRequestTypeForCommercialSector = (id: ID): Promise<any | undefined> => {
    return put(`${ENDPOINT_REQUEST_TYPE}/is-active-commercial/${id}`, '')
        .then((response: AxiosResponse<any>) => response.data)
}

const getRequestTypesList = (query: string): Promise<RequestTypeQueryResponse> => {
    return get(`${ENDPOINT_REQUEST_TYPE_LIST}`, query)
        .then((response: AxiosResponse<RequestTypeQueryResponse>) => response.data)
}

const getRequestTypesFilterList = (query: string): Promise<RequestTypeQueryResponse> => {
    return get(`${ENDPOINT_REQUEST_TYPE_FILTER_LIST}`, query)
        .then((response: AxiosResponse<RequestTypeQueryResponse>) => response.data)
}

const getRequestTypeById = (id: ID): Promise<Request | undefined> => {
    return get(`${ENDPOINT_REQUEST_TYPE}/${id}`, '')
        .then((response: AxiosResponse<Response<RequestType>>) => response.data)
        .then((response: Response<RequestType>) => response.data)
}

const getRequestById = (id: ID): Promise<Request | undefined> => {
    return get(`${ENDPOINT}/${id}`, 'expand=user.immediate_superiors,user.profile.image_url,user.immediate_superiors_profile_image,form_data,request_notes.full_name,additional_files,initial_approval_file_url,signed_approval_file_url,approval_form_data,states,current_state,previous_action_json_schemas')
        .then((response: AxiosResponse<Response<Request>>) => response.data)
        .then((response: Response<Request>) => response.data)
}

const createRequest = (request: any): Promise<Request | undefined> => {
    return post(ENDPOINT, request)
        .then((response: AxiosResponse<Response<Request>>) => response.data)
        .then((response: Response<Request>) => response.data)
}

const updateRequest = (request: Request): Promise<Request | undefined> => {
    return put(`${ENDPOINT}/${request.id}`, request)
        .then((response: AxiosResponse<Response<Request>>) => response.data)
        .then((response: Response<Request>) => response.data)
}

const deleteRequest = (requestId: ID): Promise<void> => {
    return remove(`${ENDPOINT}/${requestId}`).then(() => {
    })
}

const getRequestActions = (requestId: ID): Promise<ActionQueryResponse> => {
    return get(`${ENDPOINT}/${requestId}${ENDPOINT_ACTIONS}`, '')
        .then((response: AxiosResponse<ActionQueryResponse>) => response.data)
}

const postRequestActions = (request: Request):  Promise<Request | undefined> => {
    return post(`${ENDPOINT}/${request.id}/do-action`, 
    {
        action_id:request?.action_id,
        form_data: request.form_data,
        signed_approval_file_id: request.signed_approval_file_id,
        request_note: request.request_note,
        approval_form_data: request.approval_form_data,
        ready_for_processing: request?.ready_for_processing,
        additional_file_ids_for_upload: request?.additional_file_ids_for_upload
    })
        .then((response: AxiosResponse<Response<Request>>) => response.data)
        .then((response: Response<Request>) => response as any)
}

const getRequestActionNotes = (requestId: ID): Promise<ActionQueryResponse> => {
    return axiosInstance.get(`${ENDPOINT}/${requestId}/completed-actions`)
        .then((response: AxiosResponse<ActionQueryResponse>) => response.data)
}

const getRequestComments = (requestId: ID): Promise<ActionQueryResponse> => {
    return axiosInstance.get(`${ENDPOINT_COMMENTS}/${requestId}/comments?expand=full_name`)
        .then((response: AxiosResponse<ActionQueryResponse>) => response.data)
}

const postRequestComments = (requestId: ID, note: string): Promise<ActionQueryResponse> => {
    return post(`${ENDPOINT_COMMENTS}`, {request_id: requestId, note: note})
        .then((response: AxiosResponse<ActionQueryResponse>) => response.data)
}

const deleteRequestComment = (commentId: ID): Promise<void> => {  
    return remove(`${ENDPOINT_COMMENTS}/${commentId}`).then(() => {})
}

const getRequestStatuses = (): Promise<any> => {
    return get(`${ENDPOINT}/statuses`, '')
        .then((response: AxiosResponse<any>) => response.data)
}

export {getRequest, deleteRequest, getRequestById, createRequest, updateRequest, getRequestTypes, getRequestTypeById, getRequestTypesList, getRequestTypesFilterList, getRequestActions, postRequestActions, postRequestComments, getRequestComments, deleteRequestComment, getRequestStatuses, getRequestActionNotes, updateRequestTypeForCommercialSector, updateRequestTypeForTechnicalSector}