import {FC, useEffect} from 'react'
import { AdditionalFilesAddModal } from './AdditionalFilesAddModal'
import { AdditionalModalHeader } from './AdditionalModalHeader'

type Props = {
    setOpenModal: any
    user: any
    asyncFetch: any
    setClosedWithAction: any
}

const AdditionalModalForm: FC<Props> = ({setOpenModal, user, asyncFetch, setClosedWithAction}) => {

    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <AdditionalModalHeader setOpenModal={setOpenModal}/>
                        <div className='modal-body scroll-y'>
                            <AdditionalFilesAddModal asyncFetch={asyncFetch} setOpenModal={setOpenModal} user={user} setClosedWithAction={setClosedWithAction}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {AdditionalModalForm}
