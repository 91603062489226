/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom';
import { StepperComponent } from '../../../../_metronic/assets/ts/components';
import { AccessHelper } from '../../../shared/helper/AccessHelper';
import {Request} from '../../../shared/models/_models';
import { ActionDropdown } from './ActionDropdown';
import { ContractAction } from './ContractAction';
import { SolutionDownload } from './SolutionDownload';
import {SimpleLoading} from "../../../modules/apps/shared/list/components/loading/SimpleLoading";
import React from 'react';

type Props = {
  request: Request
  stepperRef: any
  setOpenModal: any
  actions: any
  setRequestAction: any
  setIsDownloading: any
}
const OverviewStepper: FC<Props> = ({request, setOpenModal, setRequestAction, setIsDownloading, actions})  => {
  const intl = useIntl()
  const hasSolution = request?.initial_approval_file_url
  var workflowStateData = request?.states.sort((a: any, b: any) => Number(a.order) - Number(b.order));
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const currentUser = request?.current_state  
  const [isLoading, setIsLoading] = useState(true);
  const lastActiveContract = request?.last_active_contract_id
  
  if (request?.current_state?.order === workflowStateData.at(-1).order) {  
    workflowStateData = workflowStateData?.filter((item: any) => item.order !== workflowStateData.at(-2).order)
  }

  useEffect(() => {   
    setIsLoading(true) 
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    if (request?.current_state?.order === workflowStateData.at(-1)?.order) {
      stepper.current && stepper.current.goto(workflowStateData.at(-1).order)
      setIsLoading(false)   
    } else {
      stepper.current && stepper.current.goto(request?.current_state?.order)
      setIsLoading(false) 

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request])

  const checkUserSupervisor = (item: any, i: any) => {
    if (AccessHelper(item)) {
      return (
      <Link key={i} to={`/user/${item?.id ? item?.id : '-'}/details/business/view`} className='text-dark text-hover-primary'>
         <span className="me-2">{item?.first_name} {item?.last_name}</span> 
      </Link> )
    } else {
      return (
        <span key={i} className="me-2">{item?.first_name} {item?.last_name}</span> 
      )
    }
  }
 
  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column'
      id='kt_create_account_stepper'
    >
    <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
    <div className='card-body p-9 pt-2'>
    <div className='d-block d-sm-flex d-md-flex border-bottom pb-sm-7'>
      <h3 className='d-flex w-lg-100 w-sm-50 w-md-50 pt-5 fw-bolder m-0 text-start fs-5 text-uppercase align-items-center justify-content-center justify-content-sm-start justify-content-md-start justify-content-lg-start'>{intl.formatMessage({id: 'REQUEST.STATUS.STATE'})}</h3>
      <div className='d-flex w-sm-50 w-md-50 d-lg-none pt-sm-3 pt-md-3 my-4 my-sm-0 align-items-center justify-content-center justify-content-md-end justify-content-sm-end'>
       {actions?.length > 0 && <ActionDropdown setIsDownloading={setIsDownloading} setRequestAction={setRequestAction} setOpenModal={setOpenModal} request={request} actions={actions}/>}
       {hasSolution && <SolutionDownload request={request}/>}
       {lastActiveContract && <ContractAction request={request}/>}
      </div>
    </div>
      {isLoading && <SimpleLoading/>}
      <div className='stepper-nav pt-12'>
        {workflowStateData && workflowStateData.map((item: any, i: any) => (
          item?.name !== intl.formatMessage({id: 'REQUEST.STATUS.CANCEL'}) ?
          <React.Fragment key={item?.order}>
           {(item?.name === intl.formatMessage({id: 'REQUEST.STATUS.COMPLETE'}) && (currentUser.order === stepper?.current?.totatStepsNumber)) ?
           <div key={item?.order} className='stepper-item' data-kt-stepper-element='nav'>
           <div className='stepper-wrapper'>
             <div className='stepper-icon w-40px h-40px bg-success'>
               <i className='stepper-check fas fa-check text-white'></i>
               <span className='stepper-number fas fa-check'></span>
             </div>
             <div className='stepper-label'>
               <h3 className='stepper-title fs-5'>{item?.description}</h3>
               {(item?.order === currentUser.order) && <div className='stepper-desc fw-semibold fs-7 d-grid'>
                 {currentUser?.users?.map((item: any, i: any) => 
                   checkUserSupervisor(item, i)
                 )}                 
               </div>}
             </div>
           </div>
           {(i+1 !== workflowStateData.at(-2).order) && <div className='stepper-line h-40px'></div>}
         </div> : 
            <div key={item?.order} className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>{item?.order}</span>
                </div>
                <div className='stepper-label'>
                  <h3 className='stepper-title fs-5'>{item?.description}</h3>
                  {(item?.order === currentUser.order) && <div className='stepper-desc fw-semibold fs-7 d-grid'>
                    {currentUser?.users?.map((item: any, i: any) => 
                      checkUserSupervisor(item, i)
                    )}                 
                  </div>}
                </div>
              </div>
              {(i+1 !== workflowStateData.at(-2).order) && <div className='stepper-line h-40px'></div>}
            </div>
            }
          </React.Fragment>
           :
          <div key={item?.order}>
          {request.status ===  intl.formatMessage({id: 'REQUEST.STATUS.REJECT'}) && 
          <div key={request.status} className='stepper-item' data-kt-stepper-element='nav'>
            <div className='stepper-line h-40px'></div>
            <div className='stepper-wrapper'>
            <div className='stepper-icon w-40px h-40px bg-danger'>
              <i className='stepper-ban fas fa-ban text-light'></i>
            </div>
            <div className='stepper-label'>
              <h3 className='stepper-title fs-5'>{intl.formatMessage({id: 'REQUEST.DROPDOWN.STATUS.REJECT'})}</h3>
              <div className='stepper-desc fw-semibold'></div>
            </div>
            </div>
         </div>}
         </div>
        ))}
      </div>
    </div>
  </div>
  </div>
  )
}

export {OverviewStepper}
