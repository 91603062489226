import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {AccessHelper} from '../../shared/helper/AccessHelper'
import {Request} from '../../shared/models/_models'
import Avatar from 'react-avatar'
import {avatarColorConfig} from '../../shared/AvatarConfig'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import moment from "moment";

type Props = {
  request: Request
  typeUser: any
}

const UserProfile: FC<Props> = ({request, typeUser}) => {
  const intl = useIntl()
  var link = ''
  var userData = ''
  var userPosition = ''
  var userStartJobPositionDate = ''
  var userContractEnd = ''

  if (typeUser === intl.formatMessage({id: 'REQUEST.TABLE.SUBMITTER'})) {
    link = `/user/${request.user_id}/details/business/view`
    userData = request?.user?.first_name + ' ' + request.user?.last_name
    userPosition = request?.user?.job_position?.name || ''
    userStartJobPositionDate = request?.user?.profile?.date_of_employment || ''
    userContractEnd = request?.user?.profile?.end_of_contract || ''
  } else if (typeUser === intl.formatMessage({id: 'REQUEST.TABLE.MANAGER'})) {
    link = `/user/${
      request?.user?.immediate_superiors ? request?.user?.immediate_superiors[0]?.id : '-'
    }/details/business/view`
    userData = request?.user?.immediate_superiors
      ? request?.user?.immediate_superiors[0]?.first_name +
        ' ' +
        request?.user?.immediate_superiors[0]?.last_name
      : ''
    userPosition =
      (request?.user?.immediate_superiors &&
        request?.user?.immediate_superiors[0]?.job_position?.name) ||
      ''
  } else if (typeUser === intl.formatMessage({id: 'REQUEST.TABLE.SUBJECT'})) {
    link = `/user/${request?.created_by}/details/business/view`
    userData = request?.created_by_user || ''
    userPosition = request?.created_by_user_job_position || ''
  }

  const checkUserSupervisor = (item: any) => {
    if (item && AccessHelper(item)) {
      return (
        <Link to={link} className='text-dark text-hover-primary fs-6 fw-bold'>
          {userData}
        </Link>
      )
    } else {
      return <div className='text-dark fs-6 fw-bold'>{userData}</div>
    }
  }

  return (
    <div className='d-flex'>
      <div className='d-none d-sm-flex'>
        <div className='symbol h-100'>
          <div className='me-3 h-100'>
            <div className='d-flex justify-content-center align-items-center'>
              {typeUser === intl.formatMessage({id: 'REQUEST.TABLE.MANAGER'}) &&
                (request?.user?.immediate_superiors_profile_image ? (
                  <LazyLoadImage
                    src={request?.user?.immediate_superiors_profile_image}
                    width={'80px'}
                    height={'80px'}
                    style={{borderRadius: '10px'}}
                    effect='blur'
                  />
                ) : (
                  <Avatar
                    name={
                      request?.user?.immediate_superiors &&
                      request?.user?.immediate_superiors[0]?.first_name +
                        ' ' +
                        request?.user?.immediate_superiors[0]?.last_name
                    }
                    round={'10px'}
                    color={avatarColorConfig.color}
                    fgColor={avatarColorConfig.fgColor}
                    textSizeRatio={2 / 3}
                    size={'5rem'}
                    title={avatarColorConfig.title}
                    className={avatarColorConfig.style}
                  />
                ))}
              {typeUser === intl.formatMessage({id: 'REQUEST.TABLE.SUBJECT'}) &&
                (request?.created_by_user_profile_image ? (
                  <LazyLoadImage
                    src={request?.created_by_user_profile_image}
                    width={'80px'}
                    height={'80px'}
                    style={{borderRadius: '10px'}}
                    effect='blur'
                  />
                ) : (
                  <Avatar
                    name={request?.created_by_user}
                    round={'10px'}
                    color={avatarColorConfig.color}
                    fgColor={avatarColorConfig.fgColor}
                    textSizeRatio={2 / 3}
                    size={'5rem'}
                    title={avatarColorConfig.title}
                    className={avatarColorConfig.style}
                  />
                ))}
              {typeUser === intl.formatMessage({id: 'REQUEST.TABLE.SUBMITTER'}) &&
                (request?.user?.profile?.image_url ? (
                  <LazyLoadImage
                    src={request?.user?.profile?.image_url}
                    width={'80px'}
                    height={'80px'}
                    style={{borderRadius: '10px'}}
                    effect='blur'
                  />
                ) : (
                  <Avatar
                    name={request?.user?.first_name + ' ' + request?.user?.last_name}
                    round={'10px'}
                    color={avatarColorConfig.color}
                    fgColor={avatarColorConfig.fgColor}
                    textSizeRatio={2 / 3}
                    size={'5rem'}
                    title={avatarColorConfig.title}
                    className={avatarColorConfig.style}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className='justify-content-center align-items-center d-flex'>
        <div className='flex-column'>
          <div className='align-items-center'>
            <div className='text-gray-800 text-hover-primary fs-2 fw-bolder'>
              {typeUser === intl.formatMessage({id: 'REQUEST.TABLE.SUBMITTER'})
                ? checkUserSupervisor(request?.user)
                : checkUserSupervisor(
                    request?.user?.immediate_superiors ? request?.user?.immediate_superiors[0] : ''
                  )}
            </div>
          </div>
          <div className='fw-bold fs-6 text-gray-600'>
            <div className='text-wrap'>{userPosition}</div>
            {(typeUser === intl.formatMessage({id: 'REQUEST.TABLE.SUBMITTER'}) && request.request_type?.name === 'Zahtjev za promjenu elemenata ugovora o radu') && <div className='text-wrap fs-7'>{intl.formatMessage({id: 'REQUEST.TABLE.START_DATE'})}: {userStartJobPositionDate.length > 0 ? moment(userStartJobPositionDate).format('DD.MM.YYYY') : '-'}</div>}
            {(typeUser === intl.formatMessage({id: 'REQUEST.TABLE.SUBMITTER'}) && request.request_type?.name === 'Zahtjev za promjenu elemenata ugovora o radu') && <div className='text-wrap fs-7'>{intl.formatMessage({id: 'REQUEST.TABLE.CONTRACT_END'})}: {userContractEnd.length > 0 ? (moment(userContractEnd).format('DD.MM.YYYY')) : '-'}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export {UserProfile}
