import {Contract} from "../models/_models"
import moment from "moment";

export class ContractProbationStatusHelper {
    static calculate(contract: Contract) {
        var currentDate = moment(new Date());
        var contractProbationEndDate = moment(contract?.probation_to)
        var probationLeftDays = Math.ceil(contractProbationEndDate.diff(currentDate,'days', true))

        if (probationLeftDays > 45) {
            return {days: probationLeftDays, status: 'success'}
        } else if (probationLeftDays <= 45 && probationLeftDays > 0) {
            if (contract?.status === 'expired') {
                return {days: probationLeftDays, status: 'danger'}
            } else {
                return {days: probationLeftDays, status: 'warning'}
            }
        } else {
            return {days: probationLeftDays, status: 'danger'}
        }

    }
}
