import {FC} from 'react';
import {JobPosition} from "../../../../shared/models/job_position";

type Props = {
    jobPosition: JobPosition
    technical: string
    commercial: string
    global: string
}

const JobPositionSectorCell: FC<Props> = ({jobPosition, technical, commercial, global}) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            {jobPosition.sector === 'technical' ? technical : jobPosition.sector === 'commercial' ?
                commercial : jobPosition.sector === 'global' ? global : '-'
            }
        </div>
    </div>
)

export {JobPositionSectorCell}
