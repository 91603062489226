import React, {FC, useEffect} from 'react'
import {OverlayTrigger, Tooltip, TooltipProps} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {ID, KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../../shared/list/core/ListViewProvider'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {Link} from 'react-router-dom'

type Props = {
  id: ID
}

const ContractActionsCell: FC<Props> = ({id}) => {
  const intl = useIntl()
  const {setItemIdForDelete} = useListView()
  const currentUser = JSON.parse(localStorage.getItem('current_user') || '')
  const userPermissionHr =
    !!(currentUser?.job_position?.we_level === 'WE1') ||
    !!(currentUser?.roles?.filter((item: any) => (item === 'hr' || item === 'hr1' || item === 'hr2'))?.length > 0)
  const userPermissionAdmin =
    !!(currentUser?.roles?.filter((item: any) => item === 'admin')?.length > 0) ||
    !!(currentUser?.job_position?.we_level === 'WE1')

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openDeleteModal = () => {
    setItemIdForDelete(id)
  }

  const editTooltip = (
    props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>
  ) => <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.EDIT'})}</Tooltip>

  const deleteTooltip = (
    props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>
  ) => <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.DELETE'})}</Tooltip>

  const seeMoreTooltip = (
    props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>
  ) => <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.SEE'})}</Tooltip>

  return (
    (userPermissionHr || userPermissionAdmin) ?
    <>
        <OverlayTrigger placement='top' overlay={seeMoreTooltip}>
          <Link
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            to={`${id}/overview`}
          > 
            <i className='fa fa-eye'></i>
          </Link>
        </OverlayTrigger>
        <OverlayTrigger placement='top' overlay={editTooltip}>
          <Link
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            to={`${id}/edit`}
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </Link>
        </OverlayTrigger>
        <OverlayTrigger placement='top' overlay={deleteTooltip}>
          <button
            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
            onClick={openDeleteModal}
          >
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
          </button>
        </OverlayTrigger>
    </> : <></>
  )
}

export {ContractActionsCell}
