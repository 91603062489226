import React, {FC, useEffect} from 'react';
import {useListView} from "../../shared/list/core/ListViewProvider";
import {MenuComponent} from "../../../../../_metronic/assets/ts/components";
import {ID, KTSVG} from "../../../../../_metronic/helpers";
import {OverlayTrigger, Tooltip, TooltipProps} from "react-bootstrap";
import {useIntl} from "react-intl";
import {FamilyMember} from "../../../../shared/models/_models";

type Props = {
    id: ID
    familyMember: FamilyMember
}

const ChildrenActionCell: FC<Props> = ({id, familyMember}) => {
    const intl = useIntl();
    const {setItemIdForUpdate} = useListView();
    const {setItemIdForDelete} = useListView();

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openEditModal = () => {
        setItemIdForUpdate(familyMember.id)
    }

    const openDeleteModal = () => {
        setItemIdForDelete(id)
    }

    const editTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
        <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.EDIT'})}</Tooltip>
    );

    const deleteTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
        <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.DELETE'})}</Tooltip>
    );

    return (
        <>
            <OverlayTrigger placement="top" overlay={editTooltip}>
                <button
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    type='button'
                    onClick={openEditModal}
                >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                </button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={deleteTooltip}>
                <button
                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                    type='button'
                    onClick={openDeleteModal}
                >
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3'/>
                </button>
            </OverlayTrigger>
        </>
    )
}

export {ChildrenActionCell}
