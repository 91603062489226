/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ChartContractRequestStatistics} from './Statistics/ChartContractRequestStatistics'
import {TableOfStatistics} from './Statistics/TableOfStatistics'

const HRStatisticsDasboard: FC = () => {
    return (
        <>
            <div className='row'>
                <div className='col-xl-6 mb-10 mb-lg-0' style={{'minHeight': '200px'}}>
                    <TableOfStatistics className=''/>
                </div>
                <div className='col-xl-6' style={{'minHeight': '200px'}}>
                    <ChartContractRequestStatistics/>
                </div>
            </div>
        </>
    )
}

export {HRStatisticsDasboard}
