/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {NavLink, useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {User} from '../../../shared/models/_models'
import Avatar from 'react-avatar'
import {avatarColorConfig} from '../../../shared/AvatarConfig'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

type Props = {
  user: User
}

const UserLeftPanel: React.FC<Props> = ({user}) => {
  const intl = useIntl()
  const id = user.id

  let activeClassName = 'menu-item hover active bg-light rounded'
  const {pathname} = useLocation()
  const activePath = pathname.includes('view')
  const mailTo = 'mailto:' + user?.email

  return (
    <div className='card card-stretch card-xl-stretch mb-9' id='kt_profile_aside'>
      <div className='card-body'>
        <div className='d-flex align-items-center'>
          <div className='me-7'>
            <div className='symbol symbol-60px symbol-lg-100px symbol-fixed position-relative'>
              {user?.profile?.image_url ? (
                <LazyLoadImage
                  src={user?.profile?.image_url}
                  width={'100px'}
                  height={'100px'}
                  style={{borderRadius: '10px'}}
                  effect='blur'
                />
              ) : (
                <Avatar
                  name={user?.first_name + ' ' + user?.last_name}
                  round={'10px'}
                  color={avatarColorConfig.color}
                  fgColor={avatarColorConfig.fgColor}
                  textSizeRatio={2 / 3}
                  title={avatarColorConfig.title}
                  className={avatarColorConfig.style}
                />
              )}
            </div>
          </div>
          <div>
            <h5 className='font-weight-bolder text-dark-75'>
              {user?.first_name + ' ' + user?.last_name || 'N/A'}
            </h5>
            <div className='text-muted'>{user?.job_position?.name || 'N/A'}</div>
          </div>
        </div>
        <div className='py-9'>
          <div className='d-flex align-items-center justify-content-between mb-2'>
            <span className='fw-bold mr-2'>
              {intl.formatMessage({id: 'ACCOUNT.HEADER.EMAIL'})}:
            </span>
            <a href={mailTo} className='text-muted text-hover-primary cursor-pointer'>
              {user?.email || 'N/A'}
            </a>
          </div>
          <div className='d-flex align-items-center justify-content-between mb-2'>
            <span className='fw-bold mr-2'>
              {intl.formatMessage({id: 'ACCOUNT.HEADER.PHONE'})}:
            </span>
            <span className='text-muted'>
              {user?.profile?.business_number
                ? user?.profile?.business_number
                : user?.profile?.phone_number
                ? user?.profile?.phone_number
                : 'N/A'}
            </span>
          </div>
          <div className='d-flex align-items-center justify-content-between'>
            <span className='fw-bold mr-2'>
              {intl.formatMessage({id: 'ACCOUNT.HEADER.LOCATION'})}:
            </span>
            <span className='text-muted'>{user?.profile?.city || 'N/A'}</span>
          </div>
        </div>
        <div className='menu menu-column menu-title-gray-800 menu-rounded menu-state-title-primary menu-state-icon-primary'>
          <NavLink
            className={() => (activePath ? activeClassName : 'menu-item')}
            to={`/user/${id}/details/personal/view`}
          >
            <span className='menu-link'>
              <span className='menu-icon'>
                <KTSVG path={'/media/icons/duotune/general/gen010.svg'} className='svg-icon-2' />
              </span>
              <span className='menu-title'>
                {intl.formatMessage({id: 'ACCOUNT.HEADER.PROFILE_OVERVIEW'})}
              </span>
            </span>
          </NavLink>
          <NavLink
            className={({isActive}) => (isActive ? activeClassName : 'menu-item')}
            to={`/user/${id}/details/personal/edit`}
          >
            <span className='menu-link'>
              <span className='menu-icon'>
                <KTSVG
                  path={'/media/icons/duotune/communication/com006.svg'}
                  className='svg-icon-2'
                />
              </span>
              <span className='menu-title'>
                {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS'})}
              </span>
            </span>
          </NavLink>
          <NavLink
            className={({isActive}) => (isActive ? activeClassName : 'menu-item')}
            to={`/user/${id}/details/business/edit`}
          >
            <span className='menu-link'>
              <span className='menu-icon'>
                <KTSVG path={'/media/icons/duotune/finance/fin006.svg'} className='svg-icon-2' />
              </span>
              <span className='menu-title'>
                {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS'})}
              </span>
            </span>
          </NavLink>
          <NavLink
            className={({isActive}) => (isActive ? activeClassName : 'menu-item')}
            to={`/user/${id}/details/family/edit`}
          >
            <span className='menu-link'>
              <span className='menu-icon'>
                <KTSVG path={'/media/icons/duotune/general/gen001.svg'} className='svg-icon-2' />
              </span>
              <span className='menu-title'>
                {intl.formatMessage({id: 'ACCOUNT.FAMILY_DETAILS'})}
              </span>
            </span>
          </NavLink>
          <NavLink
            className={({isActive}) => (isActive ? activeClassName : 'menu-item')}
            to={`/user/${id}/details/user/edit`}
          >
            <span className='menu-link'>
              <span className='menu-icon'>
                <KTSVG path={'/media/icons/duotune/coding/cod001.svg'} className='svg-icon-2' />
              </span>
              <span className='menu-title'>{intl.formatMessage({id: 'ACCOUNT.USER_DETAILS'})}</span>
            </span>
          </NavLink>
          <NavLink
            className={({isActive}) => (isActive ? activeClassName : 'menu-item')}
            to={`/user/${id}/details/additional_documentation`}
          >
            <span className='menu-link'>
              <span className='menu-icon'>
                <KTSVG path={'/media/icons/duotune/files/fil019.svg'} className='svg-icon-2' />
              </span>
              <span className='menu-title'>
                {intl.formatMessage({id: 'ACCOUNT.ADDITIONAL_DOCUMENTATION'})}
              </span>
            </span>
          </NavLink>
          <NavLink
            className={({isActive}) => (isActive ? activeClassName : 'menu-item')}
            to={`/user/${id}/details/personal_requests`}
          >
            <span className='menu-link'>
              <span className='menu-icon'>
                <KTSVG path={'/media/icons/duotune/files/fil025.svg'} className='svg-icon-2' />
              </span>
              <span className='menu-title'>{intl.formatMessage({id: 'ACCOUNT.REQUEST'})}</span>
            </span>
          </NavLink>
          <NavLink
              className={({isActive}) => (isActive ? activeClassName : 'menu-item')}
              to={`/user/${id}/details/evaluations`}
          >
            <span className='menu-link'>
              <span className='menu-icon'>
                <KTSVG path={'/media/icons/duotune/graphs/gra004.svg'} className='svg-icon-2' />
              </span>
              <span className='menu-title'>{intl.formatMessage({id: 'ACCOUNT.EVALUATION'})}</span>
            </span>
          </NavLink>
          <NavLink
            className={({isActive}) => (isActive ? activeClassName : 'menu-item')}
            to={`/user/${id}/details/all_contracts`}
          >
            <span className='menu-link'>
              <span className='menu-icon'>
                <KTSVG path={'/media/icons/duotune/general/gen005.svg'} className='svg-icon-2' />
              </span>
              <span className='menu-title'>
                {intl.formatMessage({id: 'ACCOUNT.HEADER.CONTRACTS'})}
              </span>
            </span>
          </NavLink>
          <NavLink
            className={({isActive}) => (isActive ? activeClassName : 'menu-item')}
            to={`/user/${id}/details/vacations`}
          >
            <span className='menu-link'>
              <span className='menu-icon'>
                <KTSVG path={'/media/icons/duotune/general/gen060.svg'} className='svg-icon-2' />
              </span>
              <span className='menu-title'>
                {intl.formatMessage({id: 'ACCOUNT.HEADER.VACATION'})}
              </span>
            </span>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export {UserLeftPanel}
