import {ListViewProvider, useListView} from '../../modules/apps/shared/list/core/ListViewProvider'
import {QueryRequestProvider} from '../../modules/apps/shared/list/core/QueryRequestProvider'
import {UsersTable} from './UsersTable'
import {KTCard} from '../../../_metronic/helpers'
import React from 'react'
import {UsersListHeader} from '../../modules/apps/user-management/users-list/components/header/UsersListHeader'
import {DeleteUserModal} from "../../shared/components/DeleteUserModal";
import {UserCreateModal} from '../../modules/apps/user-management/users-list/user-edit-modal/UserCreateModal'
import {QueryResponseProviderUsers} from '../../modules/apps/user-management/users-list/core/QueryResponseProviderUsers'

const UsersList = () => {
    const {itemIdForUpdate} = useListView()
    const {itemIdForDelete} = useListView()
    return (
        <>
            <KTCard>
                <UsersListHeader/>
                <UsersTable/>
            </KTCard>
            {itemIdForUpdate !== undefined && <UserCreateModal/>}
            {itemIdForDelete !== undefined && <DeleteUserModal id={itemIdForDelete}/>}
        </>
    )
}

const UsersListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProviderUsers>
            <ListViewProvider>
                <UsersList/>
            </ListViewProvider>
        </QueryResponseProviderUsers>
    </QueryRequestProvider>
)

export {UsersListWrapper}
