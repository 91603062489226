/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext} from 'react';
import {Notification} from "../../../shared/models/notification";
import moment from "moment/moment";
import clsx from "clsx";
import NotificationService from "../../../shared/services/notification-service";
import {NotificationAlertContext} from "../../../shared/components/NotificationContext";
import {useNavigate} from 'react-router-dom';
import {useIntl} from "react-intl";
import {OverlayTrigger, Tooltip, TooltipProps} from 'react-bootstrap';
import {KTSVG} from '../../../../_metronic/helpers';

type props = {
    notification: Notification
    setLoading: any
}

const NotificationMobileItem: React.FC<props> = ({notification, setLoading}) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const navigateToNotification = (notification: any) => {
        if (notification?.model_name === 'request') {
            navigate(`/request/${notification?.model?.id}/overview`)
        } else if (notification?.model_name === 'contract') {
            navigate(`/contract/${notification?.model?.id}/overview`)
        }
    }

    function returnTime(alert: Notification) {
        return moment.unix(alert?.created_at || 0).format("DD.MM.YYYY HH:mm");
    }

    // eslint-disable-next-line
    const [numberOfUnread, setNumberOfUnread] = useContext(NotificationAlertContext);

    function notificationHandler() {
        setLoading(true);
        NotificationService.updateNotification(notification?.id).then(() => {
            NotificationService.getUnreadNotifications().then((response) => {
                if (response) {
                    setNumberOfUnread(response.data.filter((notification: Notification) => !notification?.is_read).length);
                }
            });
            navigateToNotification(notification)
        });
    }

    const markOneNotificaitonAsRead = (e: any) => {
        e.stopPropagation();
        setLoading(true);
        NotificationService.updateNotification(notification?.id).then(() => {
            NotificationService.getUnreadNotifications().then((response) => {
                if (response) {
                    setNumberOfUnread(response.data.filter((notification: Notification) => !notification?.is_read).length);
                }
            });
        });
    }

    const editTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
        <Tooltip {...props}>{intl.formatMessage({id: 'NOTIFICATIONS.MARK_AS_READ'})}</Tooltip>
    );

    const notificationState = notification?.message?.split('Komentar ')[0];
    const notificationComment = notification?.message?.split('Komentar ')[1];

    return (
        <div key={`alert${notification?.id}`} className='menu-item my-2'>
            <a onClick={() => notificationHandler()}
               className={clsx('menu-link d-flex flex-stack bg-hover-light-primary rounded w-100', notification?.is_read && 'bg-gray-200', !notification?.is_read && `bg-light-${notification?.type}`)}>
                <div className='align-items-center w-100'>
                    <div className='mb-0'>
                        <div className='d-flex w-100'>
                        <span className='d-flex fs-6 text-gray-800 fw-bolder align-items-center w-100'>
                            {notification?.title}
                        </span>
                            {!notification?.is_read && <OverlayTrigger placement="top" overlay={editTooltip}>
                                <button
                                    className='btn btn-icon btn-active-color-primary btn-sm align-items-end'
                                    onClick={(e) => markOneNotificaitonAsRead(e)}
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen037.svg' className='svg-icon-3x'/>
                                </button>
                            </OverlayTrigger>}
                        </div>
                        <div className='d-flex'>
                            <span
                                className='text-start text-muted fw-bolder fs-7 gs-0 text-gray-600 pe-1'>{intl.formatMessage({id: 'TABLE.NOTIFICATIONS.USER'})}:</span>
                            <div
                                className='text-gray-800 fw-bold fs-7'>{notification?.model?.user?.first_name + ' ' + notification?.model?.user?.last_name}</div>
                        </div>
                        <div className='text-gray-600 fw-bold fs-7'>{notificationState}</div>
                        {notificationComment ? <div
                            className='text-gray-800 fw-bold fs-7'>{intl.formatMessage({id: 'REQUEST.STATUS.STATE.COMMENT'}) + ' ' + notificationComment}</div> : ''}
                        <div className='d-flex w-100'>
                            <div className='w-50 justify-content-start'>
                                <span className='mt-2 fs-8 text-gray-700 fw-bold'>{returnTime(notification)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    )
}

export {NotificationMobileItem}