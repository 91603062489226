import {ListViewProvider, useListView} from '../../modules/apps/shared/list/core/ListViewProvider'
import {QueryRequestProvider} from '../../modules/apps/shared/list/core/QueryRequestProvider'
import {KTCard} from '../../../_metronic/helpers'
import {QueryResponseProviderRequests} from '../../modules/apps/request-menagement/core/QueryResponseProviderRequests';
import {RequestListHeader} from '../../modules/apps/request-menagement/components/header/RequestsListHeader';
import {RequestCreateModal} from '../../modules/apps/request-menagement/components/modal/RequestCreateModal';
import {RequestsTable} from './RequestsTable';
import {DeleteRequestModal} from '../../shared/components/DeleteRequestModal';
import { useState } from 'react';


const RequestsList = () => {
    const {itemIdForUpdate, itemIdForDelete} = useListView()
    // eslint-disable-next-line
    const [openCreateModal, setOpenCreateModal] = useState(false)

    return (
        <KTCard>
            <RequestListHeader/>
            <RequestsTable/>
            {itemIdForUpdate !== undefined && <RequestCreateModal setOpenCreateModal={setOpenCreateModal}/>}
            {itemIdForDelete !== undefined && <DeleteRequestModal id={itemIdForDelete}/>}
        </KTCard>
    )
}

const RequestsListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProviderRequests>
            <ListViewProvider>
                <RequestsList/>
            </ListViewProvider>
        </QueryResponseProviderRequests>
    </QueryRequestProvider>
)

export {RequestsListWrapper}
