/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import Dropdown from 'react-bootstrap/Dropdown';
import { Contract } from '../../shared/models/_models';

type Props = {
    contract: Contract
}

const ContractSolutionDownload: FC<Props> = ({contract}) => {
    const intl = useIntl()

    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" className='btn-active-light-success ms-2'>
                {intl.formatMessage({id: 'REQUEST.DOWNLOAD'})}
            </Dropdown.Toggle>
            <Dropdown.Menu className='px-4 py-4' align="end" id="dropdown-menu-align-end">
                {/* <Dropdown.Item className='px-3 py-3 text-hover-primary fs-6 rounded text-muted fw-bold'
                               onClick={() => window.open(`${contract?.initial_approval_file_url}`, `_blank`)}><i className='fa-solid fa-download text-primary fa-lg me-2'></i>{intl.formatMessage({id: 'REQUEST.DOWNLOAD.SOLUTION.UNSIGNED'})}</Dropdown.Item> */}
                <Dropdown.Item className='px-3 py-3 text-hover-primary fs-6 rounded text-muted fw-bold'
                               onClick={() => window.open(`${contract?.signed_contract_file_url}`, `_blank`)}><i
                    className="fa-solid text-primary fa-file-arrow-down fa-lg ms-1 me-3"></i>{intl.formatMessage({id: 'CONTRACT.DOWNLOAD.SOLUTION.SIGNED'})}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export {ContractSolutionDownload}
