type WELevel = 'WE1' | 'WE2' | 'WE3' | 'WE4' | 'WE5' | 'WE6';

const AccessHelper = (employed: any)  => {
    const currentUser = JSON.parse(localStorage.getItem('current_user') || '')
    const currentUserWeLevel = currentUser?.job_position?.we_level;
    const currentUserSecondaryWeLevel = currentUser?.secondary_job_position?.map((item: any) => item?.we_level);

    const employedWeLevel = employed?.job_position?.we_level;
    const userPermissionHr = !!(currentUser?.roles?.filter((item: any) => (item === 'hr'))?.length > 0);
    const userPermissionHr_1 = !!(currentUser?.roles?.filter((item: any) => (item === 'hr1'))?.length > 0);
    const userPermissionHr_2 = !!(currentUser?.roles?.filter((item: any) => (item === 'hr2'))?.length > 0);
    const userPermissionAdmin = !!(currentUser?.roles?.filter((item: any) => item === 'admin')?.length > 0);
    
    const hasPermission_third_AndWeLevel = userPermissionHr_2 && (employedWeLevel === 'WE6' || employedWeLevel === 'WE5');
    const hasPermission_second_AndWeLevel = userPermissionHr_1 && (employedWeLevel === 'WE6' || employedWeLevel === 'WE5' || employedWeLevel === 'WE4');
    if (isCurrentUserHasSuperiorLevel(currentUserWeLevel, currentUserSecondaryWeLevel, employedWeLevel) || (currentUser?.id === employed?.id) || userPermissionHr || userPermissionAdmin || hasPermission_third_AndWeLevel || hasPermission_second_AndWeLevel) {         
        return true
    } else {
        return false
    }
}

const isCurrentUserHasSuperiorLevel = (
    currentUserWeLevel?: WELevel,
    currentUserSecondaryWeLevels?: WELevel[],
    employedWeLevel?: WELevel
  ): boolean => {
    const weLevels: WELevel[] = ['WE6', 'WE5', 'WE4', 'WE3', 'WE2', 'WE1'];
    const getWeLevelIndex = (weLevel?: WELevel): number => weLevel ? weLevels.indexOf(weLevel) : -1;
    const isPrimarySuperior = getWeLevelIndex(currentUserWeLevel) > getWeLevelIndex(employedWeLevel);
    const isSecondarySuperior = currentUserSecondaryWeLevels ? currentUserSecondaryWeLevels.some(
      (weLevel) => getWeLevelIndex(weLevel) > getWeLevelIndex(employedWeLevel)
    ) : false;
  
    return isPrimarySuperior || isSecondarySuperior;
  };

export {AccessHelper}