import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {getUsers} from '../../../../shared/services/user-service'
import {useIntl} from 'react-intl'
import Pagination from 'react-js-pagination'
import {ListLoading} from '../../../../modules/apps/shared/list/components/loading/ListLoading'
import Avatar from 'react-avatar'
import {avatarColorConfig} from '../../../../shared/AvatarConfig'
import {ScrollTopComponent} from '../../../../../_metronic/assets/ts/components'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { RoutesHelper } from '../../../../shared/helper/RoutesHelper'

type Props = {
  departmentId: string | undefined
}

const DepartmentUsersTable: React.FC<Props> = ({departmentId}) => {
  const intl = useIntl()
  const [users, setUsers] = useState([] as any)
  const [pageNumber, setPageNumber] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  const direct = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.DIRECT'})
  const indirect = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.INDIRECT'})
  const indirect_direct = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.INDIRECT_DIRECT'})
  const overhead = intl.formatMessage({id: 'EMPLOYEE_CATEGORY.OVERHEAD'})
  const current_user = JSON.parse(localStorage.getItem('current_user') || '');
  const headOfDepartments = ['WE3', 'WE4']

  useEffect(
    () => {
      setIsLoading(true)
      getUsers(
        'expand=profile.image_url,contracts&schema_sort=true&department_user_id=' +
          departmentId +
          '&page=' +
          pageNumber +
          '&items_per_page=10'
      ).then((response) => {
        if (response) {
          setUsers(response)
          setIsLoading(false)
        }
      })
    },
    // eslint-disable-next-line
    [pageNumber, departmentId]
  )

  let totalItemsCount = users?._meta?.totalCount as number
  let currentPage = users?._meta?.currentPage as number
  let itemsCountPerPage = users?._meta?.perPage as number

  function printTotalInfo() {
    let startIndex = (currentPage - 1) * itemsCountPerPage + 1
    let endIndex = Math.min(startIndex + itemsCountPerPage - 1, totalItemsCount)

    return (
      <>
        {intl.formatMessage({id: 'PAGINATION.SHOW'})} {startIndex} - {endIndex}{' '}
        {intl.formatMessage({id: 'PAGINATION.FROM'})} {totalItemsCount}
      </>
    )
  }

  function handlePageChange(pageNumber: number) {
    setPageNumber(pageNumber)
    ScrollTopComponent.goTop()
  }

  return (
    <div className={`card`}>
      <div className='card-body p-0'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted align-middle'>
                <th className='w-50px align-middle'></th>
                <th className='min-w-170px text-uppercase align-middle'>
                  {intl.formatMessage({id: 'TABLE.USER.FULL_NAME'})}
                </th>
                <th className='min-w-150px text-uppercase align-middle'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.POSITION'})}
                </th>
                <th className='min-w-150px text-uppercase align-middle'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CATEGORY'})}
                </th>
              </tr>
            </thead>
            <tbody>
              {users?.data &&
                users?.data?.length > 0 &&
                users?.data?.map((user: any) => (
                  <tr key={user.id}>
                    <td>
                      <div className='symbol symbol-50px'>
                        {user?.profile?.image_url ? (
                          <LazyLoadImage
                            src={user?.profile?.image_url}
                            width={'60px'}
                            height={'60px'}
                            style={{borderRadius: '10px'}}
                            effect='blur'
                          />
                        ) : (
                          <Avatar
                            name={user?.first_name + ' ' + user?.last_name}
                            round={'10px'}
                            color={avatarColorConfig.color}
                            fgColor={avatarColorConfig.fgColor}
                            textSizeRatio={2 / 3}
                            size={'4rem'}
                            title={avatarColorConfig.title}
                            className={avatarColorConfig.style}
                          />
                        )}
                      </div>
                    </td>
                    <td className='min-w-170px align-middle'>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={(RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) || current_user?.id === user?.id) ? `/user/${user.id}/details/personal/view` : `/user/${user.id}/details/business/view`}
                            className={
                              headOfDepartments.includes(user?.job_position?.we_level)
                                ? 'text-dark fw-bolder text-hover-primary fs-6'
                                : 'text-dark text-hover-primary fs-6'
                            }
                          >
                            {user?.first_name} {user?.last_name}
                          </Link>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {user.email}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='min-w-150px align-middle'>
                      <label
                        className={
                          headOfDepartments.includes(user?.job_position?.we_level)
                            ? 'text-dark fw-bolder d-block fs-6'
                            : 'text-dark d-block fs-6'
                        }
                      >
                        {user?.job_position?.name}
                      </label>
                    </td>
                    <td className='min-w-150px align-middle'>
                      <label
                        className={
                          headOfDepartments.includes(user?.job_position?.we_level)
                            ? 'text-dark fw-bolder d-block fs-6'
                            : 'text-dark d-block fs-6'
                        }
                      >
                        {user?.job_position?.category === 'DIR'
                          ? direct
                          : user?.job_position?.category === 'ID'
                          ? indirect
                          : user?.job_position?.category === 'ID/DIR'
                          ? indirect_direct
                          : user?.job_position?.category === 'OH'
                          ? overhead
                          : 'N/A'}
                      </label>
                    </td>
                  </tr>
                ))}
              {users?.data && users?.data?.length === 0 && (
                <tr>
                  <td colSpan={4}>
                    <div className='text-dark text-center fw-bold d-block fs-6'>
                      {intl.formatMessage({id: 'TABLE.NOT_FOUND.EMPLOYEES'})}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-start'>
            <div className='mb-md-0 mb-2' id='kt_table_users_paginate'>
              {totalItemsCount > 10 && (
                <Pagination
                  activePage={currentPage}
                  innerClass='pagination'
                  activeClass='active'
                  activeLinkClass='page-link'
                  itemClass='page-item'
                  itemClassFirst='page-item'
                  itemClassPrev='page-item'
                  itemClassNext='page-item'
                  itemClassLast='page-item'
                  linkClass='page-link'
                  linkClassFirst='page-link bg-light'
                  linkClassPrev='page-link bg-light'
                  linkClassNext='page-link bg-light'
                  linkClassLast='page-link bg-light'
                  disabledClass='disabled'
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange.bind(this)}
                />
              )}
            </div>
          </div>
          <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-end fw-bold fs-6 text-gray-600'>
            {totalItemsCount > 0 && printTotalInfo()}
          </div>
        </div>
      </div>
      {isLoading && <ListLoading />}
    </div>
  )
}

export {DepartmentUsersTable}
