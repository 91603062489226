// @ts-ignore
import {commonEnvironment} from "./common_environment";

export const environment = {
    ...commonEnvironment,
    production: false,
    API_URL: 'https://api.hrportal-dev.singularity.is/v1',
    CLIENT_ID: 'testclient',
    CLIENT_SECRET: 'testpass',
};
