import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import Pagination from 'react-js-pagination'
import {useNavigate} from 'react-router-dom'
import {ScrollTopComponent} from '../../../../../_metronic/assets/ts/components'
import {getRequest} from '../../../../shared/services/request-service'
import {Request} from '../../../../shared/models/request'
import {SimpleLoading} from '../../../../modules/apps/shared/list/components/loading/SimpleLoading'
import {User} from '../../../../shared/models/_models'
import {InfoField} from './InfoField'

type Props = {
    user: User
}

const Evaluations: React.FC<Props> = ({user}) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const [evaluations, setEvaluations] = useState([] as Request[] | undefined)
    const [pagination, setPagination] = useState({} as any)
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)

    useEffect(
        () => {
            if (user?.job_position_id) {
                getRequest('user_id=' + user?.id + '&page=' + page + '&onlyEvaluations=true').then((response) => {
                    setEvaluations(response?.data)
                    setPagination(response._meta)
                    setLoading(false)
                })
            } else {
                setLoading(false)
            }
        },
        // eslint-disable-next-line
        [page]
    )

    function handlePageChange(pageNumber: number) {
        if (pagination?.currentPage !== pageNumber) {
            setLoading(true)
            setPage(pageNumber)
            ScrollTopComponent.goTop()
        }
    }

    function goToRequest(request: any) {
        if (request?.status === intl.formatMessage({id: 'REQUEST.STATUS.NEW'})) {
            navigate(`/request/${request?.id}/edit`)
        } else {
            navigate(`/request/${request?.id}/overview`)
        }
    }

    return (
        <>
            <div className='card mb-5 mb-xl-10 card-xl-stretch'>
                <div className='card-header border-1 w-100 border-bottom'>
                    <div className='card-title m-0 d-flex w-100'>
                        <h3 className='fw-bolder m-0 w-50 d-none d-sm-flex'>
                            {intl.formatMessage({id: 'ACCOUNT.EVALUATION'})}
                        </h3>
                        {!loading ? (
                            <h3 className='fw-bolder m-0 w-50 d-sm-none'>
                                {intl.formatMessage({id: 'ACCOUNT.EVALUATION'})}
                            </h3>
                        ) : (
                            <div className='d-sm-none w-100'>
                                <SimpleLoading />
                            </div>
                        )}
                    </div>
                </div>
                <div className='card-body pt-2'>
                    {!loading ? (
                        <>
                            <div className='d-none d-sm-flex py-5'>
                <span className='w-10 fw-bold text-muted text-center align-middle text-uppercase'>
                  {intl.formatMessage({id: 'CONTRACT.TABLE.STATUS'})}
                </span>
                                <span className='ps-5 fw-bold text-muted text-center align-middle text-uppercase'>
                  {intl.formatMessage({id: 'PROFILE.EVALUATION.SHOW'})}
                </span>
                                <span className='w-100 d-flex justify-content-end pe-4 fw-bold text-muted text-center align-middle text-uppercase'>
                  {intl.formatMessage({id: 'PROFILE.REQUEST.DATE'})}
                </span>
                            </div>
                            <div className='pb-12'>
                                {evaluations && evaluations?.length > 0 ? (
                                    evaluations?.map((evaluation) => (
                                        <div
                                            className='d-flex min-h-50px bg-gray-200 align-items-center mt-2 bg-hover-light-primary px-1 my-3 rounded'
                                            key={evaluation?.id}
                                            onClick={() => goToRequest(evaluation)}
                                            style={{paddingTop: '7px', paddingBottom: '7.5px'}}
                                        >
                                            <InfoField request={evaluation} />
                                        </div>
                                    ))
                                ) : (
                                    <div className='pt-5 d-flex justify-content-center'>
                    <span className='text-gray-800 fs-6'>
                      {intl.formatMessage({id: 'DASHBOARD.REQUEST.INFO.NOT_FOUND'})}
                    </span>
                                    </div>
                                )}{' '}
                            </div>
                            <div className='position-absolute bottom-0 start-50 translate-middle w-100'>
                                <div className='col-12 d-flex align-items-center justify-content-center'>
                                    <div className='mb-md-0 mb-2' id='kt_table_users_paginate'>
                                        {pagination?.totalCount > 10 && (
                                            <Pagination
                                                activePage={pagination?.currentPage}
                                                hideFirstLastPages={true}
                                                innerClass='pagination'
                                                activeClass='active'
                                                activeLinkClass='page-link'
                                                itemClass='page-item'
                                                itemClassFirst='page-item'
                                                itemClassPrev='page-item'
                                                itemClassNext='page-item'
                                                itemClassLast='page-item'
                                                linkClass='page-link'
                                                linkClassFirst='page-link bg-light'
                                                linkClassPrev='page-link bg-light'
                                                linkClassNext='page-link bg-light'
                                                linkClassLast='page-link bg-light'
                                                disabledClass='disabled'
                                                itemsCountPerPage={pagination?.perPage}
                                                totalItemsCount={pagination?.totalCount}
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange.bind(this)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className='d-none d-sm-flex position-absolute top-50 start-50 translate-middle'>
                            <SimpleLoading />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export {Evaluations}
