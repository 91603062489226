import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {DetailsHeader} from '../DetailsHeader'
import {Contract, User} from '../../../../../shared/models/_models'
import moment from 'moment/moment'
import {RoutesHelper} from '../../../../../shared/helper/RoutesHelper'
import {apuLevels} from '../../../../../shared/SelectLists'
import {getVacations} from "../../../../../shared/services/vacation-service";

type Props = {
  className: string
  user?: User
}

const BusinessDetails: React.FC<Props> = ({className, user}) => {
  const intl = useIntl()

  const currentDate = new Date()
  const startOfEmployment = new Date((user && user.profile && user.profile.date_of_employment) ? user.profile?.date_of_employment : '');

  const technical = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.TECHNICAL'})
  const commercial = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.COMMERCIAL'})
  const global = useIntl().formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR.GLOBAL'})

  const direct = useIntl().formatMessage({id: 'EMPLOYEE_CATEGORY.DIRECT'})
  const indirect = useIntl().formatMessage({id: 'EMPLOYEE_CATEGORY.INDIRECT'})
  const indirect_direct = useIntl().formatMessage({id: 'EMPLOYEE_CATEGORY.INDIRECT_DIRECT'})
  const overhead = useIntl().formatMessage({id: 'EMPLOYEE_CATEGORY.OVERHEAD'})

  const rr = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SHIFT.RR'})
  const sr = intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SHIFT.SR'})

  const [userLatestContract, setUserLatestContract] = useState({} as Contract)

  useEffect(() => {
    if (user?.contracts && user.contracts.length > 0)
      setUserLatestContract(
        user.contracts.sort((contract1, contract2) =>
          contract1.created_at && contract2.created_at
            ? contract1?.created_at > contract2?.created_at
              ? -1
              : 1
            : -1
        )[0]
      )
  }, [user?.contracts])

  const [currentVacation, setCurrentVacation] = useState({} as any)
  const [previousVacation, setPreviousVacation] = useState({} as any)

  useEffect(
      () => {
        if (RoutesHelper(['WE3']) && user?.job_position_id) {
          getVacations('user_id=' + user?.id + '&page=1').then((response: any) => {
            setCurrentVacation(response?.data.find((vacation: any) => vacation.year === currentDate.getFullYear()))
            setPreviousVacation(response?.data.find((vacation: any) => vacation.year === (currentDate.getFullYear() - 1)))
          })
        }
      },
      // eslint-disable-next-line
      []
  )

  function getDateDifference(startDate: any, endDate: any) {
    var start = new Date(startDate);
    var end = new Date(endDate);

    var yearDiff = end.getFullYear() - start.getFullYear();
    var monthDiff = end.getMonth() - start.getMonth();
    var dayDiff = end.getDate() - start.getDate();

    if (dayDiff < 0) {
      var daysInLastMonth = new Date(end.getFullYear(), end.getMonth(), 0).getDate();
      dayDiff = daysInLastMonth - start.getDate() + end.getDate();
      monthDiff--;
    }

    if (monthDiff < 0) {
      monthDiff += 12;
      yearDiff--;
    }

    if (isNaN(yearDiff) || isNaN(monthDiff) || isNaN(dayDiff)) {
      return null;
    }

    return {
      years: yearDiff,
      months: monthDiff,
      days: dayDiff
    };
  }

    const companyExperienceDiff = getDateDifference(startOfEmployment, currentDate);

  return (
    <>
      <div className={`card ${className}`} id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <DetailsHeader user={user} />
        </div>

        <div className='card-body mb-xl-4'>
          <div className='row'>
            <h3 className='col-12 mb-5 fw-semibold'>
              {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.DEPARTMENT.DATA'})}
            </h3>

            <label className='col-sm-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SECTOR'})}
            </label>
            <div className='col-sm-6 mb-5'>
              <span className='fs-6'>
                {user?.job_position?.sector === 'technical'
                  ? technical
                  : user?.job_position?.sector === 'commercial'
                  ? commercial
                  : user?.job_position?.sector === 'global'
                  ? global
                  : 'N/A'}
              </span>
            </div>

            <label className='col-sm-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'DEPARTMENT.PARENT_DEPARTMENT'})}
            </label>
            <div className='col-sm-6 mb-5'>
              <span className='fs-6'>
                {user?.job_position?.department?.parent
                  ? user?.job_position?.department?.parent?.name
                  : user?.job_position?.department
                  ? user?.job_position?.department?.name
                  : 'N/A'}
              </span>
            </div>

            <label className='col-sm-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'DEPARTMENT.CHILD_DEPARTMENT'})}
            </label>
            <div className='col-sm-6 mb-5'>
              <span className='fs-6'>
                {user?.job_position?.department?.parent
                  ? user?.job_position?.department?.name
                  : 'N/A'}
              </span>
            </div>

            <label className='col-sm-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'DEPARTMENT.WE_LEVEL'})}
            </label>
            <div className='col-sm-6 mb-5'>
              <span className='fs-6'>{user?.job_position?.we_level || 'N/A'}</span>
            </div>

            {RoutesHelper(['admin', 'hr', 'hr1', 'hr2']) && (
              <>
                <label className='col-sm-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'DEPARTMENT.COST_CENTER'})}
                </label>
                <div className='col-sm-6 mb-5'>
                  <span className='fs-6'>{user?.job_position?.department?.code || 'N/A'}</span>
                </div>
              </>
            )}

            <h3 className='col-12 my-5 fw-semibold'>
              {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.DEPARTMENT.EMPLOYEE'})}
            </h3>

            <label className='col-sm-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.CODE'})}
            </label>
            <div className='col-sm-6 mb-5'>
              <span className='fs-6'>{user?.profile?.code || 'N/A'}</span>
            </div>

            {RoutesHelper(['admin', 'hr', 'hr1', 'hr2']) && (
              <>
                <label className='col-sm-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.APU_LEVEL'})}
                </label>
                <div className='col-sm-6 mb-5'>
                  <span className='fs-6'>
                    {apuLevels.filter((apu_value) => apu_value.value === user?.profile?.apu)[0]
                      ?.label || 'N/A'}
                  </span>
                </div>
              </>
            )}

            <label className='col-sm-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.DATE_OF_EMPLOYMENT'})}
            </label>
            <div className='col-sm-6 mb-5'>
              <span className='fs-6'>
                {user?.profile?.date_of_employment
                  ? moment(user?.profile?.date_of_employment).format('DD.MM.YYYY')
                  : 'N/A'}
              </span>
            </div>

            <label className='col-sm-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.END_OF_EMPLOYMENT_DATE'})}
            </label>
            <div className='col-sm-6 mb-5'>
              <span className='fs-6'>
                {user?.profile?.end_of_employment_date
                  ? moment(user?.profile?.end_of_employment_date).format('DD.MM.YYYY')
                  : 'N/A'}
              </span>
            </div>

            <label className='col-sm-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.POSITION'})}
            </label>
            <div className='col-sm-6 mb-5'>
              <span className='fs-6'>{user?.job_position?.name || 'N/A'}</span>
            </div>
            <label className='col-sm-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SUPERIOR'})}
            </label>
            <div className='col-sm-6 mb-5'>
              <span className='fs-6'>
                {user?.immediate_superiors ? user?.immediate_superiors[0]?.first_name : '-'}{' '}
                {user?.immediate_superiors && user?.immediate_superiors[0]?.last_name}
                {' - '}{' '}
                {user?.immediate_superiors ? user?.immediate_superiors[0]?.job_position?.name : '-'}
              </span>
            </div>
            <label className='col-sm-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.BUSINESS_NUMBER'})}
            </label>
            <div className='col-sm-6 mb-5'>
              <span className='fs-6'>{user?.profile?.business_number || 'N/A'}</span>
            </div>
            <label className='col-sm-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SHIFT'})}
            </label>
            <div className='col-sm-6 mb-5'>
              <span className='fs-6'>
                {user?.profile?.shift
                  ? (user?.profile?.shift === 'RR' ? rr : sr) +
                    (user?.profile?.shift_from && user?.profile?.shift_to
                      ? ' (' +
                        user?.profile?.shift_from +
                        ' - ' +
                        user?.profile?.shift_to +
                        ' ' +
                        intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.SHIFT.HOURS'}) +
                        ')'
                      : '')
                  : 'N/A'}
              </span>
            </div>

            {RoutesHelper(['WE3']) && (
                <>
                  <label className='col-sm-6 fw-semibold text-muted'>
                    {intl.formatMessage({
                      id: 'ACCOUNT.BUSINESS_DETAILS.VACATION_FOR',
                    }) + currentDate.getFullYear() + '.'}
                  </label>
                  <div className='col-sm-6 mb-5'>
                      <span className='fs-6'>
                        {currentVacation ? intl.formatMessage({id: 'VACATION.LABEL.SUM'}) + (currentVacation.first_part + currentVacation.second_part + currentVacation.previously_used) + ', ' + intl.formatMessage({id: 'VACATION.LABEL.LEFT'}) + (currentVacation.first_part + currentVacation.second_part + currentVacation.previously_used - currentVacation.used): 'N/A'}
                      </span>
                  </div>
                  <label className='col-sm-6 fw-semibold text-muted'>
                    {intl.formatMessage({
                      id: 'ACCOUNT.BUSINESS_DETAILS.VACATION_FOR',
                    }) + (currentDate.getFullYear() - 1) + '.'}
                  </label>
                  <div className='col-sm-6 mb-5'>
                      <span className='fs-6'>
                        {previousVacation ? intl.formatMessage({id: 'VACATION.LABEL.SUM'}) + (previousVacation.first_part + previousVacation.second_part + previousVacation.previously_used) + ', ' + intl.formatMessage({id: 'VACATION.LABEL.LEFT'}) + (previousVacation.first_part + previousVacation.second_part + previousVacation.previously_used - previousVacation.used): 'N/A'}
                      </span>
                  </div>
                </>
            )}

            {RoutesHelper(['admin', 'hr', 'hr1', 'hr2']) && (
              <>
                <label className='col-sm-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.PU.POSITION'})}
                </label>
                <div className='col-sm-6 mb-5'>
                  {user?.secondary_job_position && user?.secondary_job_position?.length > 0
                    ? user?.secondary_job_position.map((puValue: any) => {
                        return (
                          <div key={puValue?.id} className='fs-6'>
                            <div className='d-flex align-items-center justify-content-between'>
                              <div className='d-inline'>
                                <label className='fs-6 pt-0 w-100 pb-0'>{puValue?.name}</label>
                                <label className='col-form-label fs-7 pt-0 text-gray-700'>
                                  {puValue?.department ? puValue?.department?.name : ''}
                                </label>
                              </div>
                            </div>
                            <span></span>
                          </div>
                        )
                      })
                    : 'N/A'}
                </div>
              </>
            )}
            {RoutesHelper(['WE1', 'WE2', 'hr1', 'hr', 'hr2']) && (
              <>
                <label className='col-sm-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CATEGORY'})}
                </label>
                <div className='col-sm-6 mb-5'>
                  <span className='fs-6'>
                    {user?.job_position?.category === 'DIR'
                      ? direct
                      : user?.job_position?.category === 'ID'
                      ? indirect
                      : user?.job_position?.category === 'ID/DIR'
                      ? indirect_direct
                      : user?.job_position?.category === 'OH'
                      ? overhead
                      : 'N/A'}
                  </span>
                </div>

                <label className='col-sm-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'CONTRACT.DATE'})}
                </label>
                <div className='col-sm-6 mb-5'>
                  <span className='fs-6'>
                    {userLatestContract.contract_date
                      ? moment(userLatestContract.contract_date).format('DD.MM.YYYY')
                      : 'N/A'}
                  </span>
                </div>
              </>
            )}

            {RoutesHelper(['WE1', 'WE2', 'WE3', 'WE4', 'hr1', 'hr', 'hr2']) && (
                <>
                {user?.profile?.contract_type === 'undefined' ? (
                  <>
                    <label className='col-sm-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE'})}
                    </label>
                    <div className='col-sm-6 mb-5'>
                      <span className='fs-6'>
                        {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE.UNDEFINED'})}
                      </span>
                    </div>
                  </>
                ) : user?.profile?.contract_type === 'defined' ? (
                  <>
                    <label className='col-sm-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE'})}
                    </label>
                    <div className='col-sm-6 mb-5'>
                      <span className='fs-6'>
                        {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE.DEFINED'})}
                      </span>
                    </div>
                  </>
                ) : (
                    <>
                      <label className='col-sm-6 fw-semibold text-muted'>
                        {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT_TYPE'})}
                      </label>
                      <div className='col-sm-6 mb-5'>
                      <span className='fs-6'>
                        {'N/A'}
                      </span>
                      </div>
                    </>
                )}
                </>
            )}

            {RoutesHelper(['WE1', 'WE2', 'hr1', 'hr', 'hr2']) && (
                <>
                <label className='col-sm-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.START_DATE'})}
                </label>
                <div className='col-sm-6 mb-5'>
                  <span className='fs-6'>
                    {userLatestContract.start_date
                      ? moment(userLatestContract.start_date).format('DD.MM.YYYY')
                      : 'N/A'}
                  </span>
                </div>
                </>
            )}

                {RoutesHelper(['WE1', 'WE2', 'WE3', 'WE4', 'admin', 'hr', 'hr1', 'hr2']) && (
                  <>
                    <label className='col-sm-6 fw-semibold text-muted'>
                      {intl.formatMessage({
                        id: 'ACCOUNT.BUSINESS_DETAILS.CONTRACT.END_OF_EMPLOYMENT',
                      })}
                    </label>
                    <div className='col-sm-6 mb-5'>
                      <span className='fs-6'>
                        {user?.profile?.end_of_contract
                          ? moment(user?.profile?.end_of_contract).format('DD.MM.YYYY')
                          : 'N/A'}
                      </span>
                    </div>

                {user?.profile?.is_on_probation ? (
                  <>
                    <label className='col-sm-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.PROBATION_PERIOD'})}
                    </label>
                    <div className='col-sm-6 mb-5'>
                      <span className='fs-6'>
                        {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.PROBATION_PERIOD.YES'})}(
                        {intl.formatMessage({id: 'UNTIL'})}
                        {user?.profile?.probation_to
                          ? moment(user?.profile?.probation_to).format('DD.MM.YYYY')
                          : 'N/A'}
                        )
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <label className='col-sm-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.PROBATION_PERIOD'})}
                    </label>
                    <div className='col-sm-6 mb-5'>
                      <span className='fs-6'>
                        {'N/A'}
                      </span>
                    </div>
                  </>
                )}
                </>
            )}

            {RoutesHelper(['WE1', 'WE2', 'hr1', 'hr', 'hr2']) && (
                <>
                <label className='col-sm-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.YEARS_OF_SERVICE'})}
                </label>
                <div className='col-sm-6 mb-5'>
                  <span className='fs-6'>
                    {(user?.profile?.experience_years && user?.profile?.experience_months && user?.profile?.experience_days)
                      ? user?.profile?.experience_years +
                        intl.formatMessage({id: 'CONTRACT.YEARS'}) + " " + user?.profile?.experience_months +
                        intl.formatMessage({id: 'CONTRACT.MONTHS'}) + " " + user?.profile?.experience_days +
                        intl.formatMessage({id: 'CONTRACT.DAYS'})
                      : 'N/A'}
                  </span>
                </div>

                <label className='col-sm-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.YEARS_OF_SERVICE_IN_COMPANY'})}
                </label>
                <div className='col-sm-6 mb-5'>
                  <span className='fs-6'>
                    {(companyExperienceDiff)
                        ? companyExperienceDiff.years +
                        intl.formatMessage({id: 'CONTRACT.YEARS'}) + " " + companyExperienceDiff.months +
                        intl.formatMessage({id: 'CONTRACT.MONTHS'}) + " " + companyExperienceDiff.days +
                        intl.formatMessage({id: 'CONTRACT.DAYS'})
                        : 'N/A'}
                  </span>
                </div>

                {/*{RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) && (*/}
                {/*  <>*/}
                {/*    <label className='col-sm-6 fw-semibold text-muted'>*/}
                {/*      {intl.formatMessage({*/}
                {/*        id: 'ACCOUNT.BUSINESS_DETAILS.YEARS_OF_SERVICE_IN_COMPANY',*/}
                {/*      })}*/}
                {/*    </label>*/}
                {/*    <div className='col-sm-6 mb-5'>*/}
                {/*      <span className='fs-6'>*/}
                {/*        {user?.profile?.working_years_in_company*/}
                {/*          ? user?.profile?.working_years_in_company +*/}
                {/*            intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.YEARS'})*/}
                {/*          : 'N/A'}*/}
                {/*      </span>*/}
                {/*    </div>*/}
                {/*  </>*/}
                {/*)}*/}

                {RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) && (
                  <label className='col-sm-6 fw-semibold text-muted'>
                    {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.GROSS_SALARY'})}
                  </label>
                )}
                {RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) && (
                  <div className='col-sm-6 mb-5'>
                    <span className='fs-6'>
                      {user?.profile?.gross_salary ?
                       (
                        user?.profile?.gross_salary ? user?.profile?.gross_salary +
                        intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.GROSS_SALARY.CURRENCY'}) : 'N/A'
                       ) :
                      (userLatestContract.gross_salary
                        ? userLatestContract.gross_salary +
                          intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.GROSS_SALARY.CURRENCY'})
                        : 'N/A')}
                    </span>
                  </div>
                )}

                {user?.profile?.is_full_time ? (
                  <>
                    <label className='col-sm-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.WORKING_HOURS'})}
                    </label>
                    <div className='col-sm-6 mb-5'>
                      <span className='fs-6'>
                        {intl.formatMessage({
                          id: 'ACCOUNT.BUSINESS_DETAILS.WORKING_HOURS.FULL_TIME',
                        })}
                      </span>
                    </div>
                  </>
                ) : (!user?.profile?.is_full_time && user?.profile?.weekly_hours) ? (
                  <>
                    <label className='col-sm-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.WORKING_HOURS'})}
                    </label>
                    <div className='col-sm-6 mb-5'>
                      <span className='fs-6'>
                        {intl.formatMessage({
                          id: 'ACCOUNT.BUSINESS_DETAILS.WORKING_HOURS.PART_TIME',
                        })}
                        ({user?.profile?.weekly_hours || 'N/A'}
                        {intl.formatMessage({
                          id: 'ACCOUNT.BUSINESS_DETAILS.WORKING_HOURS.PART_TIME.HOURS_PER_WEEK',
                        })}
                        )
                      </span>
                    </div>
                  </>
                ) : (
                    <>
                      <label className='col-sm-6 fw-semibold text-muted'>
                        {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.WORKING_HOURS'})}
                      </label>
                      <div className='col-sm-6 mb-5'>
                      <span className='fs-6'>
                        {'N/A'}
                      </span>
                      </div>
                    </>
                )}

                <label className='col-sm-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.END_DATE'})}
                </label>
                <div className='col-sm-6 mb-5'>
                  <span className='fs-6'>
                    {user?.profile?.end_of_employment_date
                      ? moment(user?.profile?.end_of_employment_date).format('DD.MM.YYYY')
                      : 'N/A'}
                  </span>
                </div>

                {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) && (
                  <>
                    <label className='col-sm-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.REASON_FOR_TERMINATION'})}
                    </label>
                    <div className='col-sm-6 mb-5'>
                      <span className='fs-6'>
                        {user?.profile?.reason_for_termination
                          ? user?.profile?.reason_for_termination
                          : 'N/A'}
                      </span>
                    </div>

                    <label className='col-sm-6 fw-semibold text-muted'>
                      {intl.formatMessage({id: 'ACCOUNT.BUSINESS_DETAILS.EMPLOYEE.NOTE'})}
                    </label>
                    <div className='col-sm-6 mb-5'>
                      <span className='fs-6'>
                        {user?.profile?.note ? user?.profile?.note : 'N/A'}
                      </span>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export {BusinessDetails}
