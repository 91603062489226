import React from 'react'
import {OverlayTrigger, Tooltip, TooltipProps} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'

type props = {
  vacation: any
  setModalOpen: any
  setVacationData: any
  user: any
  setDeleteModal: any
}

const VacationField: React.FC<props> = ({
  vacation,
  setModalOpen,
  setVacationData,
  setDeleteModal,
}) => {
  const intl = useIntl()

  const editTooltip = (
    props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>
  ) => <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.EDIT'})}</Tooltip>

  const deleteTooltip = (
    props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>
  ) => <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.DELETE'})}</Tooltip>

  const heandleOpenModal = () => {
    setModalOpen(true)
    setVacationData(vacation)
  }

  const deleteVacation = () => {
    setDeleteModal(true)
    setVacationData(vacation)
  }

  const totalDaysOfVacation =
    vacation?.first_part + vacation?.second_part > 0
      ? vacation?.first_part + vacation?.second_part + vacation?.previously_used
      : 0

  return (
    <tr className={'notification-row bg-gray-200'}>
      <td className='align-middle text-center fs-6'>{vacation?.year ? vacation?.year : '-'}</td>
      <td className='align-middle text-center fs-6'>
        {vacation?.first_part + vacation?.second_part > 0
          ? vacation?.first_part +
            vacation?.second_part +
            vacation?.previously_used +
            ' (' +
            vacation?.first_part +
            '/' +
            vacation?.second_part +
            '/' +
            vacation?.previously_used +
            ')'
          : '-'}
      </td>
      <td className='align-middle text-center fs-6'>{vacation?.used}</td>
      <td className='align-middle text-center fs-6'>
        {totalDaysOfVacation - vacation?.used >= 0 ? totalDaysOfVacation - vacation?.used : '0'}
      </td>
      <td className='align-middle text-end fs-6 pt-2 pb-2'>
        <OverlayTrigger placement='top' overlay={editTooltip}>
          <button
            className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-2'
            onClick={() => heandleOpenModal()}
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
          </button>
        </OverlayTrigger>
        <OverlayTrigger placement='top' overlay={deleteTooltip}>
          <button
            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-2'
            onClick={() => deleteVacation()}
          >
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
          </button>
        </OverlayTrigger>
      </td>
    </tr>
  )
}

export {VacationField}
