import {FC} from 'react'
import {Department} from '../../../../../shared/models/department'

type Props = {
    department: Department
}

const DepartmentCodeCell: FC<Props> = ({department}) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            {department.code || '-'}
        </div>
    </div>
)

export {DepartmentCodeCell}
