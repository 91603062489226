import React, {FC, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {getIn, useFormik} from 'formik';
import {isNotEmpty} from '../../../../../_metronic/helpers';
import {FamilyMember} from '../../../../shared/models/family_member';
import clsx from 'clsx';
import {useListView} from '../../shared/list/core/ListViewProvider';
import {useIntl} from "react-intl";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import {createFamilyMember, updateFamilyMember} from "../../../../shared/services/user-service";
import {useQueryResponse} from "../core/QueryResponseProviderChildren";
import {useParams} from "react-router-dom";
import {FlatpickrDateOptions} from "../../../../shared/helper/FlatpickrDateOptions";
import {NotificationMessage} from "../../../../shared/helper/Notification";

type Props = {
    familyMember: FamilyMember
    isChildrenLoading: boolean
    setIsChildrenLoading: React.Dispatch<React.SetStateAction<boolean>>
    asyncFetch?: any,
}

const ChildrenCreateModalForm: FC<Props> = ({familyMember, setIsChildrenLoading, asyncFetch}) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const {setItemIdForUpdate} = useListView();
    const {refetch} = useQueryResponse();
    const userId = useParams().id;

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch();
        }
        setItemIdForUpdate(undefined);
    }

    useEffect(() => {
        setIsChildrenLoading(false);
    });

    const editFamilyMemberSchema = Yup.object().shape({
        first_name: Yup.string()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .nullable(),
        last_name: Yup.string()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .nullable(),
        date_of_birth: Yup.date()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .nullable(),
        gender: Yup.string()
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .nullable(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: familyMember.id ? familyMember : {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            gender: ''
        },
        validationSchema: editFamilyMemberSchema,
        onSubmit: async (values) => {
            setLoading(true);

            values.user_id = userId;

            try {
                if (isNotEmpty(values.id)) {
                    await updateFamilyMember(values)
                    NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FAMILY_MEMBER_SUCCESSFULLY_UPDATED!'}), '', 'success')
                } else {
                    await createFamilyMember({...values})
                    NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FAMILY_MEMBER_SUCCESSFULLY_CREATED!'}), '', 'success')
                }
                asyncFetch();
            } catch (ex) {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.SAVE_FAILED!'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SAVING_DATA!_PLEASE_TRY_AGAIN.'}), 'danger')
            } finally {
                cancel(true);
            }
        },
    })

    return (
        <>
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                <div
                    className='row'
                    id='kt_modal_add_user_scroll'
                >
                    <div className='col-md-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2 required'>
                            {intl.formatMessage({id: 'CHILDREN.FIRST_NAME'})}
                        </label>
                        <input
                            placeholder={intl.formatMessage({id: 'CHILDREN.FIRST_NAME'})}
                            {...formik.getFieldProps('first_name')}
                            type='text'
                            name='first_name'
                            className={getIn(formik.errors, 'first_name') && getIn(formik.touched, 'first_name') ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger' : 'form-control form-control-solid mb-3 mb-lg-0'}
                            autoComplete='off'
                        />
                        {getIn(formik.errors, 'first_name') && getIn(formik.touched, 'first_name') && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{getIn(formik.errors, 'first_name')}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='col-md-6 mb-7'>
                        <label className='fw-bold fs-6 mb-2 required'>
                            {intl.formatMessage({id: 'CHILDREN.LAST_NAME'})}
                        </label>
                        <input
                            placeholder={intl.formatMessage({id: 'CHILDREN.LAST_NAME'})}
                            {...formik.getFieldProps('last_name')}
                            type='text'
                            name='last_name'
                            className={getIn(formik.errors, 'last_name') && getIn(formik.touched, 'last_name') ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger' : 'form-control form-control-solid mb-3 mb-lg-0'}
                            autoComplete='off'
                        />
                        {getIn(formik.errors, 'last_name') && getIn(formik.touched, 'last_name') && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{getIn(formik.errors, 'last_name')}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='col-md-6 mb-7'>
                        <label className='col-form-label fw-bold fs-6 required'>
                            {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.BIRTH_DATE'})}
                        </label>
                        <div
                            className={['input-group mb-3', formik.touched.date_of_birth && formik.errors.date_of_birth ? 'border rounded border-danger' : ''].join(' ')}>
                            <Flatpickr
                                options={FlatpickrDateOptions}
                                value={familyMember.date_of_birth ? moment(familyMember.date_of_birth).format('DD.MM.YYYY') : ''}
                                className={clsx('form-control form-control-solid mb-1 mb-lg-0')}
                                placeholder={intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.BIRTH_DATE'})}
                                name='date_of_birth'
                                onChange={(selectedDates) => {
                                    if (familyMember) {
                                        if (formik.values) {
                                            formik.values.date_of_birth = moment(selectedDates[0]).format('YYYY-MM-DD');
                                        }

                                        familyMember.date_of_birth = moment(selectedDates[0]).format('YYYY-MM-DD');
                                    }
                                }}
                            >
                            </Flatpickr>
                            <span
                                className="input-group-text border-0 ">
                                <i className='fa fa-calendar'></i>
                            </span>
                        </div>
                        {formik.touched.date_of_birth && formik.errors.date_of_birth &&
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{getIn(formik.errors, 'date_of_birth')}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='col-md-6 mb-7'>
                        <label className='col-form-label fw-bold fs-6 required'>
                            {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.GENDER'})}
                        </label>
                        <div className="radio-inline text-center mt-4">
                            <div className='d-inline-block col-6 mb-3'>
                                <label className='form-check-label'>
                                    <input className="form-check-input me-2" type="radio" value="male"
                                           name="gender"
                                           checked={formik.getFieldProps('gender').value === 'male'}
                                           onChange={() => {
                                               formik.setFieldValue(
                                                   'gender',
                                                   formik.getFieldProps('gender').value === 'male' ? 'female' : 'male'
                                               )
                                           }}
                                    />
                                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.GENDER.MALE'})}
                                </label>
                            </div>
                            <div className='d-inline-block col-6 mb-3'>
                                <label className='form-check-label'>
                                    <input className="form-check-input me-2" type="radio" value="female"
                                           name="gender"
                                           checked={formik.getFieldProps('gender').value === 'female'}
                                           onChange={() => {
                                               formik.setFieldValue(
                                                   'gender',
                                                   formik.getFieldProps('gender').value === 'female' ? 'male' : 'female'
                                               )
                                           }}
                                    />
                                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.GENDER.FEMALE'})}
                                </label>
                            </div>
                        </div>
                        {getIn(formik.errors, 'gender') && getIn(formik.touched, 'gender') && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{getIn(formik.errors, 'gender')}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='text-center pt-15'>
                    <button
                        type='submit'
                        className='btn btn-primary float-end'
                        data-kt-users-modal-action='submit'
                        disabled={loading}
                    >
                        {!loading && (familyMember.id === undefined ? intl.formatMessage({id: 'BUTTON.CREATE'}) : intl.formatMessage({id: 'BUTTON.SUBMIT'}))}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                    {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3 float-end'
                        data-kt-users-modal-action='cancel'
                        disabled={loading}
                    >
                        {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                    </button>
                </div>
            </form>
        </>
    )
}

export {ChildrenCreateModalForm}
