/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    useQueryResponseLoading,
} from '../../modules/apps/user-management/users-list/core/QueryResponseProviderUsers'
import {useQueryRequest} from '../../modules/apps/shared/list/core/QueryRequestProvider'
import Pagination from "react-js-pagination";
import React from "react";
import {useIntl} from "react-intl";
import {ScrollTopComponent} from "../../../_metronic/assets/ts/components";

type Props = {
    pagination: any
}

const ListPagination: React.FC<Props> = ({pagination}) => {
    const intl = useIntl();
    const isLoading = useQueryResponseLoading()
    const {updateState} = useQueryRequest()
    const updatePage = (page: number | null) => {
        if (!page || isLoading || pagination.page === page) {
            return
        }
        updateState({page, items_per_page: pagination.items_per_page})
    }

    let totalItemsCount = pagination.total_pages as number;
    let currentPage = pagination.page as number;
    let itemsCountPerPage = pagination.items_per_page as number;

    function handlePageChange(pageNumber: number) {
        updatePage(pageNumber);
        ScrollTopComponent.goTop();
    }

    function printTotalInfo() {
        let startIndex = (currentPage - 1) * itemsCountPerPage + 1;
        let endIndex = Math.min(startIndex + itemsCountPerPage - 1, totalItemsCount);

        return (
            <>
                {intl.formatMessage({id: 'PAGINATION.SHOW'})} {startIndex} - {endIndex} {intl.formatMessage({id: 'PAGINATION.FROM'})} {totalItemsCount}
            </>
        )
    }

    return (
        <div className='row my-4'>
            <div
                className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-start'>
                <div className='mb-md-0 mb-2' id='kt_table_users_paginate'>
                    {totalItemsCount > 10 && <Pagination
                        activePage={currentPage}
                        innerClass='pagination'
                        activeClass='active'
                        activeLinkClass='page-link'
                        itemClass='page-item'
                        itemClassFirst='page-item'
                        itemClassPrev='page-item'
                        itemClassNext='page-item'
                        itemClassLast='page-item'
                        linkClass='page-link'
                        linkClassFirst='page-link bg-light'
                        linkClassPrev='page-link bg-light'
                        linkClassNext='page-link bg-light'
                        linkClassLast='page-link bg-light'
                        disabledClass='disabled'
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={4}
                        onChange={handlePageChange.bind(this)}
                    />}
                </div>
            </div>
            <div
                className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-end fw-bold fs-6 text-gray-600'>
                {totalItemsCount > 0 && printTotalInfo()}
            </div>
        </div>
    )
}

export {ListPagination}
