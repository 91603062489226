import clsx from 'clsx'
import {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'

import {Request} from "../../../../shared/models/request";

type Props = {
    className?: string
    title?: string
    tableProps: PropsWithChildren<HeaderProps<Request>>
}
const RequestCustomHeader: FC<Props> = ({className, title, tableProps}) => {
    return (
        <th
            {...tableProps.column.getHeaderProps()}
            className={clsx(
                className,
            )}
        >
            {title}
        </th>
    )
}

export {RequestCustomHeader}
