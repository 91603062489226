import {FC} from 'react'
import {Contract} from "../../../../shared/models/contract";

type Props = {
    contract: Contract
    contract_undefined: string
    contract_defined: string
}

const ContractTypeCell: FC<Props> = ({contract, contract_undefined, contract_defined}) => (
    <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            {contract.type  === 'undefined' ? contract_undefined : contract.type  === 'defined' ?
                contract_defined : '-'
            }
        </div>
    </div>
)

export {ContractTypeCell}
