import {post} from './api-client'
import {environment} from '../../../environment/environment'

const ENDPOINT = `/oauth/token`
const CLIENT_ID = environment.CLIENT_ID
const CLIENT_SECRET = environment.CLIENT_SECRET
const GRANT_TYPE = environment.GRANT_TYPE

function login(username: string, password: string) {
  const body = {
    username,
    password,
    grant_type: GRANT_TYPE,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  }
  return post(ENDPOINT, body)
}

export {login}
