import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {AsideDefault} from './components/aside/AsideDefault';
import {HeaderWrapper} from './components/header/HeaderWrapper';
import {Content} from './components/Content';
import {PageDataProvider} from './core';
import {useLocation} from 'react-router-dom';
import {
    DrawerMessenger,
    ActivityDrawer,
    InviteUsers,
    UpgradePlan,
    ThemeModeProvider,
} from '../partials';
import {MenuComponent} from '../assets/ts/components';
import { RoutesHelper } from '../../app/shared/helper/RoutesHelper';

const MasterLayout = () => {
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [location.key])

    return (
        <PageDataProvider>
            <ThemeModeProvider>
                <div className='page d-flex flex-row flex-column-fluid'>
                    {(RoutesHelper(['WE1', 'WE2', 'WE3', 'WE4', 'admin', 'hr', 'hr1', 'hr2'])) && <AsideDefault/>}
                    <div className='wrapper d-flex flex-column flex-row-fluid pt-19' id='kt_wrapper'
                         style={{'paddingLeft': (RoutesHelper(['WE1', 'WE2', 'WE3', 'WE4', 'admin', 'hr', 'hr1', 'hr2'])) ? '': '0'}}
                    >
                        <HeaderWrapper/>

                        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                            <div className='post d-flex flex-column-fluid' id='kt_post'>
                                <Content>
                                    <Outlet/>
                                </Content>
                            </div>
                        </div>
                    </div>
                </div>

                {/* begin:: Drawers */}
                <ActivityDrawer/>
                <DrawerMessenger/>
                {/* end:: Drawers */}

                {/* begin:: Modals */}
                <InviteUsers/>
                <UpgradePlan/>
                {/* end:: Modals */}
                {/*<ScrollTop/>*/}
            </ThemeModeProvider>
        </PageDataProvider>
    )
}

export {MasterLayout}
