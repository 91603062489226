/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react';
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {KTSVG} from "../../../helpers";

const HeaderUserMenu: FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const current_user = JSON.parse(localStorage.getItem('current_user') || '');

    const logout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('current_user');
        window.location.reload();
    }

    const navigateToProfile = () => {
        navigate(`/user/${current_user?.id}/details/personal/view`);
    }

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-icon-muted menu-active-bg menu-state-primary menu-state-bg menu-state-primary fw-bold fs-6 w-200px'
            data-kt-menu='true'
        >
            <div className='menu-item p-3'>
                <a onClick={navigateToProfile} className='menu-link'>
                    <span className='menu-icon' data-kt-element='icon'>
                        <KTSVG path='/media/icons/duotune/communication/com006.svg' className='svg-icon svg-icon-1'/>
                    </span>
                    <span className='menu-title'>{intl.formatMessage({id: 'AUTH.PROFILE'})}</span>
                </a>
            </div>

            <div className='separator'></div>

            <div className='menu-item p-3'>
                <a onClick={logout} className='menu-link'>
                    <span className='menu-icon' data-kt-element='icon'>
                        <KTSVG path='/media/icons/duotune/arrows/arr024.svg' className='svg-icon svg-icon-1'/>
                    </span>
                    <span className='menu-title'>{intl.formatMessage({id: 'AUTH.LOGOUT.BUTTON'})}</span>
                </a>
            </div>
        </div>
    )
}

export {HeaderUserMenu}
