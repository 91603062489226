import { useIntl } from "react-intl"
import { Request } from "../models/_models"

const RequestStatusHelper = (request: Request)  => {
  const intl = useIntl()
  switch (request?.status) {
    case intl.formatMessage({id: 'REQUEST.STATUS.NEW'}):
      return intl.formatMessage({id: 'REQUEST.STATUS.NEW.DISPLAY'})
    case intl.formatMessage({id: 'REQUEST.STATUS.REJECT'}):
      return intl.formatMessage({id: 'REQUEST.STATUS.REJECT.DISPLAY'})
    case intl.formatMessage({id: 'REQUEST.STATUS.PROCESSING'}):
      return intl.formatMessage({id: 'REQUEST.STATUS.PROCESSING.DISPLAY'})
    case intl.formatMessage({id: 'REQUEST.STATUS.SUCCESS'}):
      return intl.formatMessage({id: 'REQUEST.STATUS.SUCCESS.DISPLAY'});
    case intl.formatMessage({id: 'REQUEST.STATUS.PRINT'}):
      return intl.formatMessage({id: 'REQUEST.DROPDOWN.STATUS.PRINT'});
    case intl.formatMessage({id: 'REQUEST.STATUS.FINISHED'}):
      return intl.formatMessage({id: 'REQUEST.STATUS.FINISHED.DISPLAY'});
    default:
      return intl.formatMessage({id: 'REQUEST.STATUS.NEW.DISPLAY'});
  }
}

export {RequestStatusHelper}