import {FC, Suspense} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {MasterLayout} from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper';
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils';
import {WithChildren} from '../../_metronic/helpers';
import UsersPage from '../pages/users/UsersPage';
import UserPage from '../pages/user/userPage/UserPage';
import {DepartmentsListWrapper} from '../pages/departments/DepartmentsList';
import DepartmentPage from "../pages/department/departmentPage/DepartmentPage";
import {ContractsListWrapper} from "../pages/contract/ContractsList";
import {ContractForm} from '../pages/contract/ContractForm';
import {RequestsListWrapper} from '../pages/request/RequestsList';
import {JobPositionsListWrapper} from "../pages/job_positions/JobPositionsList";
import {RequestPage} from '../pages/request/RequestPage';
import {NotificationTable} from "../pages/notification/NotificationTable";
import {ContractOverview} from "../pages/contract/ContractOverview";
import { RoutesHelper } from '../shared/helper/RoutesHelper';
import RequestSettings from '../pages/request/requestSettings/RequestSettings';

const PrivateRoutes = () => {
    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                <Route path='login/*' element={<Navigate to='/dashboard'/>}/>
                <Route path='dashboard' element={<DashboardWrapper/>}/>
                <Route
                    path='/*'
                    element={
                        <>
                            {RoutesHelper(['WE1', 'WE2', 'WE3', 'WE4', 'admin', 'hr', 'hr1', 'hr2']) ? <SuspensedView>
                                <UsersPage/>
                            </SuspensedView> : <Navigate to="/"/>}
                        </>
                    }
                />
                <Route
                    path='/user/:id/*'
                    element={
                        <>
                            <SuspensedView>
                                <UserPage/>
                            </SuspensedView>
                        </>
                    }
                />
                <Route
                    path='/department'
                    element={
                        <>
                            {RoutesHelper(['WE1', 'WE2', 'WE3', 'admin', 'hr', 'hr1', 'hr2']) ? <SuspensedView>
                                <DepartmentsListWrapper/>
                            </SuspensedView> : <Navigate to="/"/>}
                        </>
                    }
                />
                <Route
                    path='/department/:id/overview'
                    element={
                        <>
                            {RoutesHelper(['WE1', 'WE2', 'WE3', 'admin', 'hr', 'hr1', 'hr2']) ? <SuspensedView>
                                <DepartmentPage/>
                            </SuspensedView> : <Navigate to="/"/>}
                        </>
                    }
                />
                <Route
                    path='/department/:id/overview/child/:child_id'
                    element={
                        <>
                            {RoutesHelper(['WE1', 'WE2', 'WE3', 'admin', 'hr', 'hr1', 'hr2']) ? <SuspensedView>
                                <DepartmentPage/>
                            </SuspensedView> : <Navigate to="/"/>}
                        </>
                    }
                />
                <Route
                    path='/contract'
                    element={
                        <>
                            {RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) ? <SuspensedView>
                                <ContractsListWrapper/>
                            </SuspensedView> : <Navigate to="/"/>}
                        </>
                    }
                />
                <Route
                    path='/contract/create'
                    element={
                        <>
                            {RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) ?
                                <div className='row g-5 g-xl-8'>
                                    <div className='col-xl-12'>
                                        <ContractForm/>
                                    </div>
                                </div> : <Navigate to="/"/>}
                        </>
                    }
                />
                <Route
                    path='/contract/create/:user_id'
                    element={
                        <>
                            {RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) ?
                                <div className='row g-5 g-xl-8'>
                                    <div className='col-xl-12'>
                                        <ContractForm/>
                                    </div>
                                </div> : <Navigate to="/"/>}
                        </>
                    }
                />
                <Route
                    path='/contract/:id/edit'
                    element={
                        <>
                            {RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) ?
                                <div className='row g-5 g-xl-8'>
                                    <div className='col-xl-12'>
                                        <ContractForm/>
                                    </div>
                                </div> : <Navigate to="/"/>}
                        </>
                    }
                />
                <Route
                    path='/contract/:id/overview'
                    element={
                        <>
                            {RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) ? <ContractOverview/> :
                                <Navigate to="/"/>}
                        </>
                    }
                />
                <Route
                    path='/request'
                    element={
                        <>
                            {RoutesHelper(['WE1', 'WE2', 'WE3', 'WE4', 'admin', 'hr', 'hr1', 'hr2']) ? <SuspensedView>
                                <RequestsListWrapper/>
                            </SuspensedView> : <Navigate to="/"/>}
                        </>
                    }
                />
                <Route
                    path='/request/:id/*'
                    element={
                        <>
                            <SuspensedView>
                                <RequestPage/>
                            </SuspensedView>
                        </>
                    }
                />
                <Route
                    path='request/settings'
                    element={
                        <>
                            {RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) ? <SuspensedView>
                                <RequestSettings/>
                            </SuspensedView> : <Navigate to="/"/>}
                        </>
                    }
                />
                <Route
                    path='job_position'
                    element={
                        <>
                            {RoutesHelper(['WE1', 'WE2', 'admin', 'hr', 'hr1', 'hr2']) ? <SuspensedView>
                                <JobPositionsListWrapper/>
                            </SuspensedView> : <Navigate to="/"/>}
                        </>
                    }
                />
                <Route
                    path='/notification'
                    element={
                        <>
                            <SuspensedView>
                                <NotificationTable/>
                            </SuspensedView>
                        </>
                    }
                />
                <Route path='*' element={<Navigate to='/error/404'/>}/>
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--kt-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
