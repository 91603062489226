import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {getRequest} from "../../../shared/services/request-service";
import {Request} from "../../../shared/models/request";
import {useNavigate} from 'react-router-dom';
import Pagination from "react-js-pagination";
import {SimpleLoading} from "../../../modules/apps/shared/list/components/loading/SimpleLoading";
import {RequestInfoField} from './RequestInfoField';
import {ScrollTopComponent} from "../../../../_metronic/assets/ts/components";

const HRRequestDashboard: React.FC = () => {
    const [requests, setRequests] = useState([] as Request[] | undefined);
    const [tempRequests, setTempRequests] = useState([] as Request[] | undefined);
    const [numberOfRequests, setNumberOfRequests] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const intl = useIntl();
    const navigate = useNavigate();

    let pageSize = 10;
    let startIndex = (page - 1) * pageSize;
    let endIndex = startIndex + pageSize;

    useEffect(() => {
            getRequest('expand=allowed_actions&forAction=ready-for-prinitng,processing&list=true').then(response => {
                if (response && response.data) {
                    setTempRequests(response.data.filter((response: any) => response.allowed_actions.length > 0));
                    setNumberOfRequests(response.data.filter((response: any) => response.allowed_actions.length > 0).length);
                    setRequests(response.data.filter((response: any) => response.allowed_actions.length > 0)?.slice(startIndex, endIndex));
                    setLoading(false);
                }
            });
        },
        // eslint-disable-next-line
        []);

    function redirectToRequest(request: any) {
        if (request?.status === 'new') {
            navigate(`/request/${request?.id}/edit`);
        } else {
            navigate(`/request/${request?.id}/overview`);
        }
    }

    useEffect(() => {
            ScrollTopComponent.goTop();
            setRequests(tempRequests?.slice(startIndex, endIndex));
        },
        // eslint-disable-next-line
        [page]);

    function handlePageChange(pageNumber: number) {
        setPage(pageNumber);
    }

    function navigateToRequests() {
        navigate(`/request`)
    }

    return (
        <>
            <div className='card card-xxl-stretch-50 mb-5 mb-xl-8'>
                <div className='card-header border-bottom border-2'>
                    <h3 className='card-title d-flex align-items-center text-dark fw-bold'>
                        {intl.formatMessage({id: 'DASHBOARD.HR.REQUEST.HEADER'})}
                    </h3>
                    <span
                        className='fs-6 text-dark fw-bold opacity-75 d-flex align-items-center justify-content-end'>{numberOfRequests ? (numberOfRequests % 10 === 1 ? (numberOfRequests + intl.formatMessage({id: 'DASHBOARD.HR.REQUEST'})) : (numberOfRequests + intl.formatMessage({id: 'DASHBOARD.HR.REQUESTS'}))) : ''}</span>
                </div>
                <div className='card-body pt-2'>
                    {!loading ? (requests && requests?.length > 0 ? requests?.map((request) =>
                        <div className='d-flex align-items-center mt-2 bg-hover-light-primary rounded px-1'
                             key={request?.id} onClick={() => redirectToRequest(request)}
                             style={{paddingTop: '7px', paddingBottom: '7.5px'}}>
                            <RequestInfoField request={request}/>
                        </div>
                    ) : (
                        <div className='pt-5'>
                            <span className='text-gray-800 fw-bold fs-6'>
                                {intl.formatMessage({id: 'DASHBOARD.REQUEST.INFO.NOT_FOUND'})}
                            </span>
                        </div>
                    )) : <div className='py-3'><SimpleLoading/></div>}
                    <div className='row mt-4'>
                        <div
                            className='col-7 col-md-7 col-sm-7 col-lg-7 d-flex align-items-center '>
                            <div className='mb-md-0 mb-2' id='kt_table_users_paginate'>
                                {numberOfRequests > 10 && <Pagination
                                    activePage={page}
                                    hideFirstLastPages={true}
                                    innerClass='pagination'
                                    activeClass='active'
                                    activeLinkClass='page-link'
                                    itemClass='page-item'
                                    itemClassFirst='page-item'
                                    itemClassPrev='page-item'
                                    itemClassNext='page-item'
                                    itemClassLast='page-item'
                                    linkClass='page-link'
                                    linkClassFirst='page-link bg-light'
                                    linkClassPrev='page-link bg-light'
                                    linkClassNext='page-link bg-light'
                                    linkClassLast='page-link bg-light'
                                    disabledClass='disabled'
                                    itemsCountPerPage={pageSize}
                                    totalItemsCount={numberOfRequests}
                                    pageRangeDisplayed={3}
                                    onChange={handlePageChange.bind(this)}
                                />}
                            </div>
                        </div>
                        {requests && requests.length > 0 &&
                            <div className='col-5 col-md-5 col-sm-5 col-lg-5 mb-md-0 mb-2 '>
                                <button className='btn btn-primary btn-sm btn-active-light-primary float-end'
                                        onClick={navigateToRequests}>
                                    {intl.formatMessage({id: 'DASHBOARD.REQUEST.INFO.SEE_ALL'})}
                                    <i className="ms-1 fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export {HRRequestDashboard}
