import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import {getRequestActions, getRequestById} from '../../shared/services/request-service'
import {RequestFormSchema} from './RequestFormSchema'
import {ListLoading} from '../../modules/apps/shared/list/components/loading/ListLoading'
import {Request} from '../../shared/models/_models'
import {getVacations} from '../../shared/services/vacation-service'

const RequestForm: React.FC = () => {
  const [isDeliting, setIsDeliting] = useState(false)
  const [request, setRequest] = useState({} as Request)
  const [actions, setActions] = useState({} as any)
  const [isLoading, setIsLoading] = useState(true)
  const [vacations, setVacations] = useState()
  const currentUser = JSON.parse(localStorage.getItem('current_user') || '')
  const requestId = useParams().id

  useEffect(() => {
    setIsLoading(true)
    const asyncFetch = async () => {
      setRequest((await getRequestById(requestId)) as Request)
      setActions((await getRequestActions(requestId)) as any)
      setVacations((await getVacations('user_id=' + currentUser?.id)) as any)
      setIsLoading(false)
    }
    asyncFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeliting])

  return (
    <>
      {isLoading && <ListLoading />}
      {!isLoading && request && actions && (
        <RequestFormSchema
          vacations={vacations}
          request={request}
          actions={actions}
          setIsDeliting={setIsDeliting}
        />
      )}
    </>
  )
}

export {RequestForm}
