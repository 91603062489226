import { FC } from "react";
import {useIntl} from "react-intl";
import {KTSVG} from "../../../../../../_metronic/helpers";
import {useListView} from "../../../shared/list/core/ListViewProvider";

type Props = {
    setOpenCreateModal?: any
}

const RequestCreateModalHeader: FC<Props> = ({setOpenCreateModal}) => {
    const intl = useIntl()
    const {setItemIdForUpdate} = useListView()

    const closeModal = () => {
        setItemIdForUpdate(undefined)
        setOpenCreateModal(false)
    }

    return (
        <div className='modal-header'>
            <h2 className='fw-bolder'>{intl.formatMessage({id: 'REQUEST.NEW_REQUEST'})}</h2>
            <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => closeModal()}
                style={{cursor: 'pointer'}}
            >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
            </div>
        </div>
    )
}

export {RequestCreateModalHeader}
