import React, {useState} from 'react';

const NotificationAlertContext = React.createContext([] as any);
const NotificationUpdateContext = React.createContext([] as any);

type props = {
    children: JSX.Element[];
}

const NotificationContext: React.FC<props> = ({children}) => {
    const [numberOfUnreadNotifications, setNumberOfUnreadNotifications] = useState();
    const [updated, setUpdated] = useState();

    return (
        <NotificationAlertContext.Provider value={[numberOfUnreadNotifications, setNumberOfUnreadNotifications]}>
            <NotificationUpdateContext.Provider value={[updated, setUpdated]}>
                {children}
            </NotificationUpdateContext.Provider>
        </NotificationAlertContext.Provider>
    )
}

export {NotificationContext, NotificationAlertContext, NotificationUpdateContext}