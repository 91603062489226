import {KTSVG} from '../../../../../../../_metronic/helpers';
import {useListView} from '../../../../shared/list/core/ListViewProvider';
import {useIntl} from "react-intl";

const UsersListToolbar = () => {
    const intl = useIntl()
    const {setItemIdForUpdate} = useListView()
    const openAddUserModal = () => {
        setItemIdForUpdate(null)
    }

    return (
        <div data-kt-user-table-toolbar='base'>
            <button type='button' className='btn btn-primary ms-2 mb-1 px-4' onClick={openAddUserModal}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                {intl.formatMessage({id: 'USERS.ADD_USER'})}
            </button>
        </div>
    )
}

export {UsersListToolbar}
