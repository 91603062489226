import clsx from 'clsx';
import {FC, PropsWithChildren} from 'react';
import {HeaderProps} from 'react-table';
import {JobPosition} from "../../../../shared/models/job_position";

type Props = {
    className?: string
    title?: string
    tableProps: PropsWithChildren<HeaderProps<JobPosition>>
}
const JobPositionCustomHeader: FC<Props> = ({className, title, tableProps}) => {
    return (
        <th
            {...tableProps.column.getHeaderProps()}
            className={clsx(
                className,
            )}
            /*style={{cursor: 'pointer'}}*/

        >
            {title}
        </th>
    )
}

export {JobPositionCustomHeader}
