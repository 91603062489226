/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {NotificationMessage} from '../../../../shared/helper/Notification'

type Props = {
  setSelectedYear: any
}

const ChartFilter: React.FC<Props> = ({setSelectedYear}) => {
  const intl = useIntl()
  const date = new Date();
  const [filterSelectedYear, setFilterSelectedYear] = useState('' as any)

  function resetFilter() {
    setSelectedYear(date.getFullYear())
    setFilterSelectedYear('')
    NotificationMessage(
      intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FILTER_RESET_SUCCESSFULLY'}),
      '',
      'success'
    )
  }

  function setFilter() {
    if (filterSelectedYear === '') {
      NotificationMessage(
        intl.formatMessage({id: 'MESSAGE_NOTIFICATION.MUST_CHOOSE_FILTER_VALUE'}),
        '',
        'warning'
      )
    } else {
      setSelectedYear(filterSelectedYear)
      NotificationMessage(
        intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FILTER_SUCCESSFULLY_APPLIED'}),
        '',
        'success'
      )
    }
  }

  return (
    <Dropdown className={'year-select'}>
      <Dropdown.Toggle variant={'transparent'} className={'btn-active-light-primary ps-4 pe-2 mb-1'}>
        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2 text-primary' />
      </Dropdown.Toggle>
      <Dropdown.Menu
        className='menu menu-sub menu-sub-dropdown w-250px w-sm-300px'
        align='start'
        id='dropdown-menu-align-end'
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>
            {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER'})}
          </div>
        </div>

        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5'>
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>
              {intl.formatMessage({id: 'NOTIFICATIONS.FILTER.YEAR'})}:
            </label>
            <input
              placeholder={intl.formatMessage({id: 'NOTIFICATIONS.FILTER.INPUT_YEAR'})}
              type='text'
              name='year'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              onChange={(e) => setFilterSelectedYear(e.target.value)}
              value={filterSelectedYear}
            />
          </div>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-light btn-active-light-danger me-2'
              data-kt-menu-dismiss='true'
              onClick={() => resetFilter()}
            >
              {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.RESET'})}
            </button>
            <button
              type='submit'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => setFilter()}
            >
              {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.APPLY'})}
            </button>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export {ChartFilter}
