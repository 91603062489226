/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import {Request} from '../../../shared/models/_models';
import { UserProfile } from '../UserProfile'
import { ActionDropdown } from './ActionDropdown'
import { ContractAction } from './ContractAction';
import { SolutionDownload } from './SolutionDownload';
import {OverlayTrigger, Tooltip, TooltipProps} from "react-bootstrap";
import {KTSVG} from "../../../../_metronic/helpers";


type Props = {
  request: Request,
  setRequestAction: any,
  setOpenModal: any,
  actions: any,
  setIsDownloading: any
}
const OverviewHeader: FC<Props> = ({request, setRequestAction, setOpenModal, actions, setIsDownloading})  => {
  const intl = useIntl()
  const hasSolution = request?.initial_approval_file_url
  const lastActiveContract = request?.last_active_contract_id
  const requestCreatedBy = request?.created_by
  const userId = request?.user_id
  const dangerTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
      <Tooltip {...props}>{intl.formatMessage({id: 'TOOLTIP.REQUEST.LATE'})}</Tooltip>
  );

  return (
    <div className='card'>
      <div className='card-body pb-0 pt-2'>
        <div className='d-flex w-100 border-bottom pb-2 h-60px'>
          <h3 className='d-flex w-100 fw-bolder m-0  justify-content-center justify-content-sm-start justify-content-md-start justify-content-lg-start fs-5 text-uppercase align-items-center'>{request?.request_type?.name}
            <span className='text-muted fw-semibold d-flex align-items-center'>
                    {(request?.is_late && request?.status !== 'finished' && request?.status !== 'rejected') ? (<>
                      <OverlayTrigger placement="bottom" overlay={dangerTooltip}>
                        <span>
                        <KTSVG
                            path='/media/icons/duotune/general/gen013.svg'
                            className='svg-icon svg-icon-2 text-danger blink ms-1'
                        />
                        </span>
                      </OverlayTrigger></>) : ""}
                </span>
          </h3>
            <div className='d-none d-lg-flex w-50  my-4 align-items-center justify-content-end'>
              {actions?.length > 0 && <ActionDropdown setIsDownloading={setIsDownloading} setRequestAction={setRequestAction} setOpenModal={setOpenModal} request={request} actions={actions}/>}
              {hasSolution && <SolutionDownload request={request}/>}
              {lastActiveContract && <ContractAction request={request}/>}
            </div>
        </div>    
        <div className='d-flex flex-wrap flex-sm-nowrap mb-7 pt-10'>
          <div className='flex-grow-1 w-100'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2 w-30'>
              {requestCreatedBy === userId ? <div className='w-80'>
                <div className='text-start fw-bolder fs-7 text-uppercase gs-0'>{intl.formatMessage({id: 'REQUEST.TABLE.SUBMITTER'})}</div>
                <div className='mt-5'>
                  {request?.user && <UserProfile request={request} typeUser = {intl.formatMessage({id: 'REQUEST.TABLE.SUBMITTER'})}/>}
                </div>
              </div> :
              <>
                <div style={{width: '60%'}} className='pe-2'>
                  <div className='text-start fw-bolder fs-7 text-uppercase gs-0'>{intl.formatMessage({id: 'REQUEST.TABLE.SUBJECT'})}</div>
                  <div className='mt-5'>
                    {request?.user && <UserProfile request={request} typeUser = {intl.formatMessage({id: 'REQUEST.TABLE.SUBMITTER'})}/>}
                  </div>
                </div>
                <div style={{width: '40%'}}  className='ps-1'>
                  <div className='text-start fw-bolder fs-7 text-uppercase gs-0'>{intl.formatMessage({id: 'REQUEST.TABLE.SUBMITTER'})}</div>
                  <div className='mt-5'>
                    {request?.user && <UserProfile request={request} typeUser = {intl.formatMessage({id: 'REQUEST.TABLE.SUBJECT'})}/>}
                  </div>
                </div>
              </>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {OverviewHeader}
