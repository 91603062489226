import {useEffect} from 'react';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {Login} from './components/Login';
import {ActivateAccount} from "./components/ActivateAccount";
import {ForgotPassword} from "./components/ForgotPassword";
import {ResetPassword} from "./components/ResetPassword";

const AuthLayout = () => {
    useEffect(() => {
        document.body.classList.add('bg-body')
        return () => {
            document.body.classList.remove('bg-body')
        }
    }, []);

    return (
        <>
            <div className="d-none d-md-block d-flex align-items-center"
                 style={{
                     backgroundImage: 'url(/media/images/login_gradient.svg), url(/media/images/Veritas_building.webp)',
                     backgroundPosition: 'center, center',
                     backgroundSize: 'cover, cover',
                     backgroundRepeat: 'no-repeat, no-repeat',
                     height: '100vh'
                 }}>
                <div className="d-flex align-items-center"
                     style={{
                         backgroundImage: 'url(/media/images/logo_circles.svg)',
                         backgroundPosition: 'center',
                         backgroundSize: 'contain',
                         backgroundRepeat: 'no-repeat',
                         height: '100vh',
                         width: '100%'
                     }}>
                    <div className="mx-auto">
                        <div className='d-flex flex-center flex-column flex-column-fluid'>
                            <div className='bg-body rounded-4'>
                                <div className='d-flex justify-content-center'>
                                    <img className="my-20 mb-15" height='70px' src='/media/images/logo_hr_impulse.svg'
                                         alt="HR_Impulse_Veritas_logo"/>
                                </div>
                                <div className='w-400px mb-20 px-10 px-lg-10 mx-auto'>
                                    <Outlet/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-block d-md-none d-flex align-items-center"
                 style={{
                     backgroundImage: 'url(/media/images/logo_circles_diagonal.svg)',
                     backgroundPosition: 'center',
                     backgroundSize: 'auto',
                     backgroundRepeat: 'no-repeat',
                     height: '100vh',
                     width: '100%'
                 }}>
                <div className="mx-auto">
                    <div className='d-flex flex-center flex-column flex-column-fluid'>
                        <div className=''>
                            <div className='d-flex justify-content-center'>
                                <img className="my-20 mb-20" height='50px' src='/media/images/logo_hr_impulse.svg'
                                     alt="HR_Impulse_Veritas_logo"/>
                            </div>
                            <div className='w-400px mb-20 px-10 px-lg-10 mx-auto'>
                                <Outlet/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout/>}>
            <Route path='login' element={<Login/>}/>
            <Route path='access_request_code' element={<Login/>}/>
            {/*<Route path='registration' element={<Registration/>}/>*/}
            <Route path='forgot-password' element={<ForgotPassword/>}/>
            <Route path='reset-password/*' element={<ResetPassword/>}/>
            <Route path='activation/*' element={<ActivateAccount/>}/>
            <Route path='*' element={<Navigate to='/login'/>}/>
        </Route>
    </Routes>
)

export {AuthPage}
