import React, {FC, useContext, useEffect, useState} from 'react'
import {KTCard, KTCardBody} from "../../../_metronic/helpers";
import {useIntl} from "react-intl";
import {NotificationListFilter} from "./components/NotificationListFilter";
import NotificationService from "../../shared/services/notification-service";
import {Notification} from "../../shared/models/notification";
import {NotificationItem} from "./components/NotificationItem";
import {ListLoading} from "../../modules/apps/shared/list/components/loading/ListLoading";
import {NotificationMessage} from "../../shared/helper/Notification";
import Pagination from "react-js-pagination";
import {NotificationUpdateContext} from "../../shared/components/NotificationContext";
import {ScrollTopComponent} from "../../../_metronic/assets/ts/components";
import { NotificationMobileItem } from './components/NotificationMobileItem';

const NotificationTable: FC = () => {
    const intl = useIntl();
    const [notifications, setNotifications] = useState([] as Notification[] | undefined);
    const [pagination, setPagination] = useState({} as any);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [isFilterd, setIsFiltered] = useState(false);
    const [filterParamsQuery, setFilterParamsQuery] = useState();
    // eslint-disable-next-line
    const [updated, setUpdated] = useContext(NotificationUpdateContext);

    useEffect(() => {
            if (isFilterd) {
                setPage(1);
            }

            NotificationService.getNotifications("page=" + page + (filterParamsQuery !== undefined ? filterParamsQuery : '')).then((response) => {
                setNotifications(response.data);
                setPagination((response._meta));
                setLoading(false);

                if (isFilterd) {
                    setIsFiltered(false);
                }

                if (filterParamsQuery && !loading) {
                    NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.NOTIFICATION_SUCCESSFULLY_FILTERED'}), '', 'success')
                }
            });
        },
        // eslint-disable-next-line
        [loading, filterParamsQuery, page]);

    useEffect(() => {
            if (isFilterd) {
                setPage(1);
            }

            NotificationService.getNotifications("page=" + page + (filterParamsQuery !== undefined ? filterParamsQuery : '')).then((response) => {
                setNotifications(response.data);
                setPagination((response._meta));
                setLoading(false);

                if (isFilterd) {
                    setIsFiltered(false);
                }
            });
        },
        // eslint-disable-next-line
        [updated]);

    function handlePageChange(pageNumber: number) {
        setLoading(true);
        setPage(pageNumber);
        ScrollTopComponent.goTop();
    }

    function printTotalInfo() {
        let startIndex = (pagination?.currentPage - 1) * pagination?.perPage + 1;
        let endIndex = Math.min(startIndex + pagination?.perPage - 1, pagination?.totalCount);

        return (
            <>
                {intl.formatMessage({id: 'PAGINATION.SHOW'})} {startIndex} - {endIndex} {intl.formatMessage({id: 'PAGINATION.FROM'})} {pagination?.totalCount}
            </>
        )
    }

    return (
        <KTCard>
            <div className='card-header row border-0 pt-6'>
                <div className='d-flex align-items-center'>
                    <h1 className='d-flex align-items-sm-start align-items-center flex-column fs-2qx'>
                        {intl.formatMessage({id: 'NOTIFICATIONS'})}
                    </h1>
                    <div className='ms-auto'>
                        <NotificationListFilter setFilterParamsQuery={setFilterParamsQuery} setLoading={setLoading}
                                                setIsFiltered={setIsFiltered}/>
                    </div>
                </div>
            </div>
            <KTCardBody className='py-4'>
                <div className='table-responsive'>
                    <div className='d-none d-lg-flex'>
                    <table
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer notification-table'>
                        <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th className='w-40px'></th>
                            <th>
                                {intl.formatMessage({id: 'TABLE.NOTIFICATIONS.REQUEST'})}
                            </th>
                            <th>
                                {intl.formatMessage({id: 'TABLE.NOTIFICATIONS.USER'})}
                            </th>
                            <th className='w-lg-550px w-300px'>
                                {intl.formatMessage({id: 'TABLE.NOTIFICATIONS.MESSAGE'})}
                            </th>
                            <th className='w-55px'></th>
                        </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold'>
                        {notifications && notifications.length > 0 ? notifications.map((notification) => (
                            <NotificationItem key={notification?.id} notification={notification}
                                              setLoading={setLoading}/>
                        )) : (
                            <tr>
                                <td colSpan={4}>
                                    <div className='d-flex flex-column text-center align-content-center'>
                                        {intl.formatMessage({id: 'TABLE.NOTIFICATIONS.NOT_FOUND'})}
                                    </div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table> 
                    </div>
                    <div className='d-block d-lg-none'>
                    {notifications && notifications.length > 0 ? notifications.map((notification) => (
                            <NotificationMobileItem key={notification?.id} notification={notification}
                                              setLoading={setLoading}/>
                        )) : (
                            <div className='d-flex flex-column text-center align-content-center'>
                                {intl.formatMessage({id: 'TABLE.NOTIFICATIONS.NOT_FOUND'})}
                            </div>
                    )}
                    </div>
                </div>
                <div className='row my-4'>
                    <div
                        className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-start'>
                        <div className='mb-md-0 mb-2' id='kt_table_users_paginate'>
                            {pagination?.totalCount > 10 && <Pagination
                                activePage={pagination?.currentPage}
                                innerClass='pagination'
                                activeClass='active'
                                activeLinkClass='page-link'
                                itemClass='page-item'
                                itemClassFirst='page-item'
                                itemClassPrev='page-item'
                                itemClassNext='page-item'
                                itemClassLast='page-item'
                                linkClass='page-link'
                                linkClassFirst='page-link bg-light'
                                linkClassPrev='page-link bg-light'
                                linkClassNext='page-link bg-light'
                                linkClassLast='page-link bg-light'
                                disabledClass='disabled'
                                itemsCountPerPage={pagination?.perPage}
                                totalItemsCount={pagination?.totalCount}
                                pageRangeDisplayed={4}
                                onChange={handlePageChange.bind(this)}
                            />}
                        </div>
                    </div>
                    <div
                        className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-end fw-bold fs-6 text-gray-600'>
                        {pagination?.totalCount > 0 && printTotalInfo()}
                    </div>
                </div>
            </KTCardBody>
            {loading && <ListLoading/>}
        </KTCard>
    )
}

export {NotificationTable}
