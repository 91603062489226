import {get, post, remove} from './api-client'
import {AxiosResponse} from 'axios'
import { ID } from '../../../_metronic/helpers'

const ENDPOINT = 'file'
const ADDITIONAL_FILE_REQUEST_ENDPOINT = 'additional-request-files'
const USER_DOCUMENTATION = 'user-documentation'

const uploadFile = (file: any):  Promise<any> => {  
    let formData = new FormData();
    formData.append('files[]',file)
    return post(`${ENDPOINT}/upload`, formData,'',{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
    .then((response: AxiosResponse<File>) => response.data)
}

const uploadFiles = (file: any):  Promise<any> => {    
  let formData = new FormData();
  for (var i = 0; i < file.length; i++) {
    formData.append('files[]', file[i]);
  }
  return post(`${ENDPOINT}/upload`, formData,'',{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  })
  .then((response: AxiosResponse<File>) => response.data)
}

const uploadAdditionalRequstFiles = (request_id: any, file_id: Array<number>):  Promise<File> => {  
  return post(`${ADDITIONAL_FILE_REQUEST_ENDPOINT}`, {request_id, file_id: file_id})
  .then((response: AxiosResponse<File>) => response.data)
}

const deleteAdditionalRequstFiles = (request_id: any, file_id: any):  Promise<any> => {  
  return remove(`${ADDITIONAL_FILE_REQUEST_ENDPOINT}/${request_id}/${file_id}`)
  .then((response: AxiosResponse<File>) => response.data)
}

const postUserDocumentation = (user_id: any, comment: any, additional_file_ids_for_upload: any):  Promise<any | undefined> => {
  return post(`${USER_DOCUMENTATION}`, 
  {
      user_id: user_id,
      comment: comment,
      file_id: additional_file_ids_for_upload
  })
  .then((response: AxiosResponse<any>) => response.data)
}

const getUserDocumentationByUser = (query: string): Promise<any> => {  
  return get(`${USER_DOCUMENTATION}`, query)
    .then((response: AxiosResponse<any>) => response.data)
}

const deleteAttachmentFile = (id: ID): Promise<void> => {  
  return remove(`${USER_DOCUMENTATION}/${id}`).then(() => {})
}

export {uploadFiles, uploadAdditionalRequstFiles, deleteAdditionalRequstFiles, uploadFile, postUserDocumentation, getUserDocumentationByUser, deleteAttachmentFile}