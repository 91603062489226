import React, {useState} from 'react'
import {getIn, useFormik} from 'formik'
import {Link, useNavigate} from 'react-router-dom'
import {getCurrentUser, updateUserPersonalData} from '../../../shared/services/user-service'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import 'react-datepicker/dist/react-datepicker.css'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import * as Yup from 'yup'
import {listLanguagesOptions, listDrivingLicenceOptions} from '../../../shared/SelectLists'
import Select, {OnChangeValue} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {FlatpickrDateOptions} from '../../../shared/helper/FlatpickrDateOptions'
import {User} from '../../../shared/models/user'
import {NotificationMessage} from '../../../shared/helper/Notification'
import {SimpleLoading} from '../../../modules/apps/shared/list/components/loading/SimpleLoading'

type Props = {
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
}

let foreignLanguages: string | undefined
let drivingLicence: string | undefined

const EditPersonalDetails: React.FC<Props> = ({user, setUser}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  let languagesArray = user.profile?.foreign_languages?.split(' ').slice(0, -1)
  const selectedLanguagesArray = languagesArray?.map(function (i) {
    return {value: i, label: i}
  })

  let drivingLicenceArray = user.profile?.driving_licence?.split(' ').slice(0, -1)
  const selectedDrivingLicenceArray = drivingLicenceArray?.map(function (i) {
    return {value: i, label: i}
  })

  const PersonalDetailsValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
      .nullable(),
    last_name: Yup.string()
      .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
      .nullable(),
    profile: Yup.object().shape({
      parent_name: Yup.string()
        .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
        .nullable(),
      address: Yup.string()
        .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
        .nullable(),
      city: Yup.string()
        .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
        .nullable(),
      municipality: Yup.string()
        .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
        .nullable(),
      date_of_birth: Yup.date()
        .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
        .nullable(),
      place_of_birth: Yup.string()
        .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
        .nullable(),
      jmbg: Yup.string()
        .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
        .max(13, intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.SERIAL_NUMBER_LENGTH'}))
        .nullable(),
      gender: Yup.string()
        .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
        .nullable(),
    }),
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: JSON.parse(JSON.stringify(user).replace(/:null/gi, ':""')),
    validationSchema: PersonalDetailsValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)

      if (values.profile && foreignLanguages !== undefined) {
        values.profile.foreign_languages = foreignLanguages
      }

      if (values.profile && drivingLicence !== undefined) {
        values.profile.driving_licence = drivingLicence
      }

      updateUserPersonalData(values).then((response) => {
        if (response) {
          getCurrentUser('expand=profile').then((response: any) => {
            localStorage.setItem('current_user', JSON.stringify(response.data as string))
            setUser(values)
            NotificationMessage(
              intl.formatMessage({
                id: 'MESSAGE_NOTIFICATION.PERSONAL_INFORMATION_HAS_BEEN_SUCCESSFULLY_UPDATED!',
              }),
              '',
              'success'
            )
            navigate(`/user/${user?.id}/details/personal/view/`)
          })
        } else {
          NotificationMessage(
            intl.formatMessage({id: 'MESSAGE_NOTIFICATION.UPDATE_FAILED!'}),
            intl.formatMessage({
              id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_UPDATING_PERSONAL_INFORMATION!_PLEASE_TRY_AGAIN.',
            }),
            'danger'
          )
          setLoading(false)
        }
      })
    },
  })

  return (
    <div className='card mb-xl-8 card-xl-stretch'>
      <div className='card-header border-0'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS'})}</h3>
        </div>
      </div>
      {!loading ? (
        <div id='kt_account_personal_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <div className='row'>
                <div className='col-sm-6 col-md-3 mb-3'>
                  <label className='col-form-label fw-bold fs-6 required'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.FIRST_NAME'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      placeholder={intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.FIRST_NAME'})}
                      {...(formik.getFieldProps('first_name') || '')}
                      type='text'
                      name='first_name'
                      className={
                        getIn(formik.errors, 'first_name') && getIn(formik.touched, 'first_name')
                          ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                          : 'form-control form-control-solid mb-3 mb-lg-0'
                      }
                      autoComplete='off'
                    />
                  )}
                  {getIn(formik.errors, 'first_name') && getIn(formik.touched, 'first_name') && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{getIn(formik.errors, 'first_name')}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-sm-6 col-md-3 mb-3'>
                  <label className='col-form-label fw-bold fs-6 required'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.FATHER_NAME'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      placeholder={intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.FATHER_NAME'})}
                      {...(formik.getFieldProps('profile.parent_name') || '')}
                      type='text'
                      name='profile.parent_name'
                      className={
                        getIn(formik.errors, 'profile.parent_name') &&
                        getIn(formik.touched, 'profile.parent_name')
                          ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                          : 'form-control form-control-solid mb-3 mb-lg-0'
                      }
                      autoComplete='off'
                    />
                  )}
                  {getIn(formik.errors, 'profile.parent_name') &&
                    getIn(formik.touched, 'profile.parent_name') && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{getIn(formik.errors, 'profile.parent_name')}</span>
                        </div>
                      </div>
                    )}
                </div>
                <div className='col-sm-12 col-md-6 mb-3'>
                  <label className='col-form-label fw-bold fs-6 required'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.LAST_NAME'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      placeholder={intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.LAST_NAME'})}
                      {...(formik.getFieldProps('last_name') || '')}
                      type='text'
                      name='last_name'
                      className={
                        getIn(formik.errors, 'last_name') && getIn(formik.touched, 'last_name')
                          ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                          : 'form-control form-control-solid mb-3 mb-lg-0'
                      }
                      autoComplete='off'
                    />
                  )}
                  {getIn(formik.errors, 'last_name') && getIn(formik.touched, 'last_name') && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{getIn(formik.errors, 'last_name')}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-sm-8 col-md-4 col-lg-8 col-xxl-4 mb-3'>
                  <label className='col-form-label fw-bold fs-6 required'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.STREET_NUMBER'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      placeholder={intl.formatMessage({
                        id: 'ACCOUNT.PERSONAL_DETAILS.STREET_NUMBER',
                      })}
                      {...(formik.getFieldProps('profile.address') || '')}
                      type='text'
                      name='profile.address'
                      className={
                        getIn(formik.errors, 'profile.address') &&
                        getIn(formik.touched, 'profile.address')
                          ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                          : 'form-control form-control-solid mb-3 mb-lg-0'
                      }
                      autoComplete='off'
                    />
                  )}{' '}
                  {getIn(formik.errors, 'profile.address') &&
                    getIn(formik.touched, 'profile.address') && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{getIn(formik.errors, 'profile.address')}</span>
                        </div>
                      </div>
                    )}
                </div>
                <div className='col-sm-4 col-md-2 col-lg-4 col-xxl-2 mb-3'>
                  <label className='col-form-label fw-bold fs-6'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.ZIP_CODE'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      placeholder={intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.ZIP_CODE'})}
                      {...(formik.getFieldProps('profile.postal_number') || '')}
                      type='text'
                      name='profile.postal_number'
                      className={
                        getIn(formik.errors, 'profile.postal_number') &&
                        getIn(formik.touched, 'profile.postal_number')
                          ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                          : 'form-control form-control-solid mb-3 mb-lg-0'
                      }
                      autoComplete='off'
                    />
                  )}
                  {getIn(formik.errors, 'profile.postal_number') &&
                    getIn(formik.touched, 'postal_number') && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{getIn(formik.errors, 'profile.postal_number')}</span>
                        </div>
                      </div>
                    )}
                </div>
                <div className='col-sm-6 col-md-3 col-lg-6 col-xxl-3 mb-3'>
                  <label className='col-form-label fw-bold fs-6 required'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.MUNICIPALITY'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      placeholder={intl.formatMessage({
                        id: 'ACCOUNT.PERSONAL_DETAILS.MUNICIPALITY',
                      })}
                      {...(formik.getFieldProps('profile.municipality') || '')}
                      type='text'
                      name='profile.municipality'
                      className={
                        getIn(formik.errors, 'profile.municipality') &&
                        getIn(formik.touched, 'profile.municipality')
                          ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                          : 'form-control form-control-solid mb-3 mb-lg-0'
                      }
                      autoComplete='off'
                    />
                  )}
                  {getIn(formik.errors, 'profile.municipality') &&
                    getIn(formik.touched, 'profile.municipality') && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{getIn(formik.errors, 'profile.municipality')}</span>
                        </div>
                      </div>
                    )}
                </div>
                <div className='col-sm-6 col-md-3 col-lg-6 col-xxl-3 mb-3'>
                  <label className='col-form-label fw-bold fs-6 required'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.CITY'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      placeholder={intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.CITY'})}
                      {...(formik.getFieldProps('profile.city') || '')}
                      type='text'
                      name='profile.city'
                      className={
                        getIn(formik.errors, 'profile.city') &&
                        getIn(formik.touched, 'profile.city')
                          ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                          : 'form-control form-control-solid mb-3 mb-lg-0'
                      }
                      autoComplete='off'
                    />
                  )}
                  {getIn(formik.errors, 'profile.city') && getIn(formik.touched, 'profile.city') && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{getIn(formik.errors, 'profile.city')}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-sm-4 col-md-3 col-lg-4 col-xxl-3 mb-3'>
                  <label className='col-form-label fw-bold fs-6 required'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.BIRTH_DATE'})}
                  </label>
                  <div
                    className={[
                      'input-group',
                      getIn(formik.errors, 'profile.date_of_birth') &&
                      getIn(formik.touched, 'profile.date_of_birth')
                        ? 'border rounded border-danger'
                        : '',
                    ].join(' ')}
                  >
                    {formik?.values?.profile && (
                      <Flatpickr
                        options={FlatpickrDateOptions}
                        value={
                          user.profile?.date_of_birth
                            ? moment(user?.profile?.date_of_birth).format('DD.MM.YYYY')
                            : ''
                        }
                        className={clsx('form-control form-control-solid mb-1 mb-lg-0')}
                        placeholder={intl.formatMessage({
                          id: 'ACCOUNT.PERSONAL_DETAILS.BIRTH_DATE',
                        })}
                        name='profile.date_of_birth'
                        onChange={(selectedDates) => {
                          if (user?.profile) {
                            if (formik.values.profile) {
                              formik.values.profile.date_of_birth = moment(selectedDates[0]).format(
                                'YYYY-MM-DD'
                              )
                            }
                          }
                        }}
                      ></Flatpickr>
                    )}
                    <span className='input-group-text border-0 '>
                      <i className='fa fa-calendar'></i>
                    </span>
                  </div>
                  {getIn(formik.errors, 'profile.date_of_birth') &&
                    getIn(formik.touched, 'profile.date_of_birth') && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{getIn(formik.errors, 'profile.date_of_birth')}</span>
                        </div>
                      </div>
                    )}
                </div>
                <div className='col-sm-4 col-md-3 col-lg-4 col-xxl-3 mb-3'>
                  <label className='col-form-label fw-bold fs-6 required'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.BIRTH_PLACE'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      placeholder={intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.BIRTH_PLACE'})}
                      {...(formik.getFieldProps('profile.place_of_birth') || '')}
                      type='text'
                      name='profile.place_of_birth'
                      className={
                        getIn(formik.errors, 'profile.place_of_birth') &&
                        getIn(formik.touched, 'profile.place_of_birth')
                          ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                          : 'form-control form-control-solid mb-3 mb-lg-0'
                      }
                      autoComplete='off'
                    />
                  )}
                  {getIn(formik.errors, 'profile.place_of_birth') &&
                    getIn(formik.touched, 'profile.place_of_birth') && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{getIn(formik.errors, 'profile.place_of_birth')}</span>
                        </div>
                      </div>
                    )}
                </div>
                <div className='col-sm-4 col-md-3 col-lg-4 col-xxl-3 mb-3'>
                  <label className='col-form-label fw-bold fs-6 required'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.SERIAL_NUMBER'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      placeholder={intl.formatMessage({
                        id: 'ACCOUNT.PERSONAL_DETAILS.SERIAL_NUMBER',
                      })}
                      {...(formik.getFieldProps('profile.jmbg') || '')}
                      type='text'
                      name='profile.jmbg'
                      className={
                        getIn(formik.errors, 'profile.jmbg') &&
                        getIn(formik.touched, 'profile.jmbg')
                          ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                          : 'form-control form-control-solid mb-3 mb-lg-0'
                      }
                      autoComplete='off'
                    />
                  )}
                  {getIn(formik.errors, 'profile.jmbg') && getIn(formik.touched, 'profile.jmbg') && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{getIn(formik.errors, 'profile.jmbg')}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-sm-4 col-md-3 col-lg-4 col-xxl-3 mb-3'>
                  <label className='col-form-label fw-bold fs-6 mb-3 required'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.GENDER'})}
                  </label>
                  <div className='radio-inline'>
                    <div className='d-inline-block col-6 mb-3'>
                      <label className='form-check-label'>
                        {formik?.values?.profile && (
                          <input
                            className='form-check-input me-2'
                            type='radio'
                            value='male'
                            name='profile.gender'
                            checked={formik.getFieldProps('profile.gender').value === 'male'}
                            onChange={() => {
                              formik.setFieldValue(
                                'profile.gender',
                                formik.getFieldProps('profile.gender').value === 'male'
                                  ? 'female'
                                  : 'male'
                              )
                            }}
                          />
                        )}
                        {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.GENDER.MALE'})}
                      </label>
                    </div>
                    <div className='d-inline-block col-6 mb-3'>
                      <label className='form-check-label'>
                        {formik?.values?.profile && (
                          <input
                            className='form-check-input me-2'
                            type='radio'
                            value='female'
                            name='profile.gender'
                            checked={formik.getFieldProps('profile.gender').value === 'female'}
                            onChange={() => {
                              formik.setFieldValue(
                                'profile.gender',
                                formik.getFieldProps('profile.gender').value === 'female'
                                  ? 'male'
                                  : 'female'
                              )
                            }}
                          />
                        )}
                        {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.GENDER.FEMALE'})}
                      </label>
                    </div>
                  </div>
                  {getIn(formik.errors, 'profile.gender') &&
                    getIn(formik.touched, 'profile.gender') && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{getIn(formik.errors, 'profile.gender')}</span>
                        </div>
                      </div>
                    )}
                </div>
                <div className='col-sm-4 col-md-4 col-lg-4 col-xxl-4 mb-3'>
                  <label className='col-form-label fw-bold fs-6'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.IDCARD_NUMBER'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      placeholder={intl.formatMessage({
                        id: 'ACCOUNT.PERSONAL_DETAILS.IDCARD_NUMBER',
                      })}
                      {...(formik.getFieldProps('profile.id_number') || '')}
                      type='text'
                      name='profile.id_number'
                      className={clsx('form-control form-control-solid mb-1 mb-lg-0')}
                      autoComplete='off'
                    />
                  )}
                </div>
                <div className='col-sm-4 col-md-4 col-lg-4 col-xxl-4 mb-3'>
                  <label className='col-form-label fw-bold fs-6'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.PHONE_NUMBER'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      placeholder={intl.formatMessage({
                        id: 'ACCOUNT.PERSONAL_DETAILS.PHONE_NUMBER',
                      })}
                      {...(formik.getFieldProps('profile.phone_number') || '')}
                      type='text'
                      name='profile.phone_number'
                      className={clsx('form-control form-control-solid mb-1 mb-lg-0')}
                      autoComplete='off'
                    />
                  )}
                </div>
                <div className='col-sm-6 col-md-4 col-lg-6 col-xxl-4 mb-3'>
                  <label className='col-form-label fw-bold fs-6'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.SCHOOL'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      placeholder={intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.SCHOOL'})}
                      {...(formik.getFieldProps('profile.education') || '')}
                      type='text'
                      name='profile.education'
                      className={clsx('form-control form-control-solid mb-1 mb-lg-0')}
                      autoComplete='off'
                    />
                  )}
                </div>
                <div className='col-sm-6 col-md-4 col-lg-6 col-xxl-4 mb-3'>
                  <label className='col-form-label fw-bold fs-6'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.PROFESSION'})}
                  </label>
                  {formik?.values?.profile && (
                    <input
                      placeholder={intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.PROFESSION'})}
                      {...(formik.getFieldProps('profile.profession') || '')}
                      type='text'
                      name='profile.profession'
                      className={clsx('form-control form-control-solid mb-1 mb-lg-0')}
                      autoComplete='off'
                    />
                  )}
                </div>
                <div className='col-sm-6 col-md-4 col-lg-6 col-xxl-4 mb-3'>
                  <label className='col-form-label fw-bold fs-6'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.DRIVING_LICENCE'})}
                  </label>
                  {formik?.values?.profile && (
                    <Select
                      placeholder='Izaberite dozvole'
                      isMulti
                      menuPlacement={'top'}
                      name='profile.driving_licence'
                      defaultValue={selectedDrivingLicenceArray}
                      options={listDrivingLicenceOptions}
                      className={clsx('mb-1 mb-lg-0')}
                      classNamePrefix='select'
                      noOptionsMessage={() => intl.formatMessage({id: 'NO_DATA'})}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          neutral50:
                            localStorage.getItem('kt_theme_mode_menu') === 'dark'
                              ? '#495674'
                              : '#a1a5b7',
                        },
                      })}
                      onChange={(newValue: OnChangeValue<any, true>) => {
                        drivingLicence = ''
                        newValue.map((item) => (drivingLicence += item.value + ' '))
                      }}
                    />
                  )}
                </div>
                <div className='col-sm-6 col-md-4 col-lg-6 col-xxl-4 mb-3'>
                  <label className='col-form-label fw-bold fs-6'>
                    {intl.formatMessage({id: 'ACCOUNT.PERSONAL_DETAILS.LANGUAGES'})}
                  </label>
                  {formik?.values?.profile && (
                    <CreatableSelect
                      className={clsx('mb-1 mb-lg-0')}
                      name='profile.foreign_languages'
                      placeholder='Izaberite jezike'
                      isMulti
                      defaultValue={selectedLanguagesArray}
                      options={listLanguagesOptions}
                      noOptionsMessage={() => intl.formatMessage({id: 'NO_DATA'})}
                      formatCreateLabel={(userInput) => `Dodaj "${userInput}"`}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          neutral50:
                            localStorage.getItem('kt_theme_mode_menu') === 'dark'
                              ? '#495674'
                              : '#a1a5b7',
                        },
                      })}
                      onChange={(newValue: OnChangeValue<any, true>) => {
                        foreignLanguages = ''
                        newValue.map((item) => (foreignLanguages += item.value + ' '))
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <Link to={`/user/${user.id}/details/personal/view`}>
                <button type='reset' className='btn btn-light me-3' disabled={loading}>
                  {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                </button>
              </Link>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && intl.formatMessage({id: 'BUTTON.SUBMIT'})}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className='position-absolute top-50 start-50 translate-middle'>
          <SimpleLoading />
        </div>
      )}
    </div>
  )
}

export {EditPersonalDetails}
