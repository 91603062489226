import {Options} from "flatpickr/dist/types/options";

const FilterDateOption: Partial<Options> = {
    dateFormat: "d.m.Y",
    enableTime: false,
    locale: "sr",
    allowInput: true,
    static: true,
    position: "below left"
}
const FilterDateOptionRight:Partial<Options> ={
    dateFormat: "d.m.Y",
    enableTime: false,
    locale: "sr",
    allowInput: true,
    position: "above right"
}
export {FilterDateOption,FilterDateOptionRight}

