import {FC, useEffect, useState} from 'react'
import {useMutation} from 'react-query'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {NotificationMessage} from '../../../../shared/helper/Notification'
import {deleteVacation} from '../../../../shared/services/vacation-service'

type Props = {
  vacationData: any
  setDeleteModal: any
  user: any
  asyncFetch: any
}

const DeleteVacationModal: FC<Props> = ({vacationData, setDeleteModal, asyncFetch}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  const deleteItem = useMutation(() => deleteVacation(vacationData?.id), {
    onSuccess: () => {
      try {
        NotificationMessage(
          intl.formatMessage({id: 'MESSAGE_NOTIFICATION.VACATION_SUCCESSFULLY_DELETED'}),
          '',
          'success'
        )
        setDeleteModal(false)
        asyncFetch()
      } catch (ex) {
        NotificationMessage(
          intl.formatMessage({id: 'MESSAGE_NOTIFICATION.DELETE_FAILED'}),
          '',
          'danger'
        )
        setDeleteModal(false)
        asyncFetch()
      } finally {
        setDeleteModal(false)
        asyncFetch()
      }
    },
  })

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_delete'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-400px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h3 className='modal-title'>{intl.formatMessage({id: 'MODAL.DELETE_VACATION'})}</h3>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => setDeleteModal(false)}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body text-center'>
              <h5>{intl.formatMessage({id: 'MODAL.DELETE_VACATION.QUESTION'})}</h5>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                disabled={loading}
                onClick={()=> {setDeleteModal(false)}}
              >
                {intl.formatMessage({id: 'MODAL.CANCEL'})}
              </button>
              <button
                type='button'
                className='btn btn-danger'
                disabled={loading}
                onClick={async () => {
                  setLoading(true)
                  await deleteItem.mutateAsync()
                }}
              >
                {!loading && intl.formatMessage({id: 'MODAL.DELETE'})}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {DeleteVacationModal}
