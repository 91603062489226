import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import Pagination from 'react-js-pagination'
import {ScrollTopComponent} from '../../../../../_metronic/assets/ts/components'
import {SimpleLoading} from '../../../../modules/apps/shared/list/components/loading/SimpleLoading'
import {User} from '../../../../shared/models/_models'
import {getVacations} from '../../../../shared/services/vacation-service'
import {DetailsHeader} from '../userDetails/DetailsHeader'
import {VacationFieldPreview} from './VacationFieldPreview'

type Props = {
  user: User
}

const VacationPreview: React.FC<Props> = ({user}) => {
  const intl = useIntl()
  const [vacations, setVacations] = useState([] as any)
  const [pagination, setPagination] = useState({} as any)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)

  useEffect(
    () => {
      if (user?.job_position_id) {
        getVacations('user_id=' + user?.id + '&page=' + page).then((response: any) => {
          setVacations(response?.data)
          setPagination(response._meta)
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    },
    // eslint-disable-next-line
    [page]
  )

  function handlePageChange(pageNumber: number) {
    if (pagination?.currentPage !== pageNumber) {
      setLoading(true)
      setPage(pageNumber)
      ScrollTopComponent.goTop()
    }
  }

  return (
    <>
      <div
        className='card mb-5 mb-xl-10 card-xl-stretch overflow-auto'
        style={{minHeight: '300px'}}
      >
        <div className='card-header cursor-pointer'>
          <DetailsHeader user={user} />
        </div>
        <div className='card-body pt-2'>
          {!loading ? (
            <>
              <table className='table align-middle'>
                <thead className='border-bottom'>
                  <tr className='fw-bold text-muted'>
                    <th className='fw-bolder text-center align-middle text-uppercase py-5 fs-7'>
                      {intl.formatMessage({id: 'ACCOUNT.VACATION.YEAR'})}
                    </th>
                    <th
                      className='fw-bolder text-uppercase text-center align-middle fs-7'
                      style={{minWidth: '95px'}}
                    >
                      <div className='d-sm-flex justify-content-center'>
                        <div className='pe-1'>{intl.formatMessage({id: 'ACCOUNT.VACATION.TOTAL'})}</div>
                        <div>
                          ({intl.formatMessage({id: 'ACCOUNT.VACATION.DAYS'})}/
                          {intl.formatMessage({id: 'ACCOUNT.VACATION.ADDITIONA_DAYS'})}/
                          {intl.formatMessage({id: 'ACCOUNT.VACATION.PREVIOUSLY_DAYS'})})
                        </div>
                      </div>
                    </th>
                    <th className='fw-bolder text-uppercase text-center align-middle fs-7'>
                      {intl.formatMessage({id: 'ACCOUNT.VACATION.USED'})}
                    </th>
                    <th className='fw-bolder text-uppercase text-center align-middle fs-7'>
                      {intl.formatMessage({id: 'ACCOUNT.VACATION.NOT.USED'})}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {vacations && vacations?.length > 0 ? (
                    vacations.map((vacation: any) => (
                      <VacationFieldPreview key={vacation?.id} vacation={vacation} />
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={5}
                        className='text-center pt-5 justify-content-center text-center'
                      >
                        <span className='text-gray-800 fs-6'>
                          {intl.formatMessage({id: 'ACCOUNT.VACATION.INFO.NOT_FOUND'})}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className='position-absolute bottom-0 start-50 translate-middle w-100'>
                <div className='col-12 d-flex align-items-center justify-content-center'>
                  <div className='mb-md-0 mb-2' id='kt_table_users_paginate'>
                    {pagination?.totalCount > 10 && (
                      <Pagination
                        activePage={pagination?.currentPage}
                        hideFirstLastPages={true}
                        innerClass='pagination'
                        activeClass='active'
                        activeLinkClass='page-link'
                        itemClass='page-item'
                        itemClassFirst='page-item'
                        itemClassPrev='page-item'
                        itemClassNext='page-item'
                        itemClassLast='page-item'
                        linkClass='page-link'
                        linkClassFirst='page-link bg-light'
                        linkClassPrev='page-link bg-light'
                        linkClassNext='page-link bg-light'
                        linkClassLast='page-link bg-light'
                        disabledClass='disabled'
                        itemsCountPerPage={pagination?.perPage}
                        totalItemsCount={pagination?.totalCount}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange.bind(this)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className='d-none d-sm-flex position-absolute top-50 start-50 translate-middle'>
              <SimpleLoading />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export {VacationPreview}
