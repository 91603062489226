import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../../shared/list/core/ListViewProvider'
import {ChildrenCreateModalForm} from "./ChildrenCreateModalForm";
import {getFamilyMember} from "../../../../shared/services/user-service";
import React, {useEffect, useState} from "react";

type props = {
    isLoadingModal: boolean,
    setIsLoadingModal: React.Dispatch<React.SetStateAction<boolean>>,
    asyncFetch?: Function

}
const ChildrenCreateModalFormWrapper: React.FC<props> = ({
                                                             isLoadingModal,
                                                             setIsLoadingModal,
                                                             asyncFetch,
                                                         }) => {
    const {itemIdForUpdate, setItemIdForUpdate} = useListView();
    const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);
    const [isChildrenLoading, setIsChildrenLoading] = useState(isLoadingModal);

    const {
        isLoading,
        data: familyMember,
        error,
    } = useQuery(
        `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
        () => {
            return getFamilyMember(itemIdForUpdate)
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {
                setItemIdForUpdate(undefined)
                console.error(err)
            },
        }
    )

    useEffect(() => {
        setIsLoadingModal(isChildrenLoading);
    });

    if (!itemIdForUpdate) {
        return (
            <ChildrenCreateModalForm familyMember={{id: undefined}} isChildrenLoading={isChildrenLoading}
                                     setIsChildrenLoading={setIsChildrenLoading} asyncFetch={asyncFetch} />
        )
    }

    if (!isLoading && !error && familyMember) {
        return <ChildrenCreateModalForm familyMember={familyMember} isChildrenLoading={isChildrenLoading}
                                        setIsChildrenLoading={setIsChildrenLoading} asyncFetch={asyncFetch}/>
    }

    return null
}

export {ChildrenCreateModalFormWrapper}
