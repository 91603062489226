import React from 'react';
import {DepartmentUsersTable} from "../overview/tables/DepartmentUsersTable";

type props = {
    departmentId: any
}

const DepartmentList: React.FC<props> = ({departmentId}) => {
    return (
        <div className='card-body'>
            <div className='row'>
                <DepartmentUsersTable departmentId={departmentId}/>
            </div>
        </div>
    )
}

export {DepartmentList}
