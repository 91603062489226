import {FC} from 'react'
import {Request} from "../../../../shared/models/_models";
import {Link} from "react-router-dom";
import { AccessHelper } from '../../../../shared/helper/AccessHelper';


type Props = {
    request: Request
}

const RequestManagerCell: FC<Props> = ({request}) => {
    const jobPosition = (request && request?.user && request?.user?.immediate_superiors && request?.user?.immediate_superiors[0]?.job_position && request?.user?.immediate_superiors[0]?.job_position?.name);
    
    const checkUserSupervisor = (item: any) => {        
        if (item?.immediate_superiors?.length > 0 && AccessHelper(item?.immediate_superiors[0])) {            
          return (
            <Link
                to={`/user/${item.immediate_superiors ? item?.immediate_superiors[0]?.id : '-'}/details/business/view`}
                className='text-dark text-hover-primary fs-6 fw-bold text-gray-800'>
                {item?.immediate_superiors ? item?.immediate_superiors[0]?.first_name : '-'} {item?.immediate_superiors && item?.immediate_superiors[0]?.last_name}
            </Link>
        )
        } else {
          return (
            <div className='text-dark fs-5 fw-bold text-gray-800'>
                {item?.immediate_superiors ? item?.immediate_superiors[0]?.first_name : '-'} {item?.immediate_superiors && item?.immediate_superiors[0]?.last_name}
            </div>
          )
        }
      }
    
    return (
        <div className='d-flex flex-column justify-content-center'>
            <span className='text-dark fs-6 fw-bold'>
                {checkUserSupervisor(request?.user)}
            </span>
            {jobPosition && <span
                className='text-muted fw-semibold text-sm-left'>{jobPosition}</span>}
        </div>
    )
}
export {RequestManagerCell}
