import { FC } from 'react'
import {useQuery} from 'react-query'
import {getRequestTypesList} from '../../../../../shared/services/request-service'
import { RequestCreateModalForm } from './RequestCreateModalForm'

type Props = {
  setOpenCreateModal?: any
}

const RequestCreateModalFormWrapper: FC<Props> = ({setOpenCreateModal}) => {    
    const {
        data: types,
    } = useQuery(
      `request_types`,
      () => {
          return getRequestTypesList('')
      },
      {
        cacheTime: 0,
        onError: (err) => {
          console.error(err)
        },
      }
    )
  
    return (
      <RequestCreateModalForm request={{id: undefined}} types={types?.data} setOpenCreateModal={setOpenCreateModal}/>
    )
}

export {RequestCreateModalFormWrapper}
