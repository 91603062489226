import {FC, useEffect, useState} from 'react';
import {useMutation} from "react-query";
import {KTSVG} from "../../../_metronic/helpers";
import {useIntl} from "react-intl";
import {Request} from "../../shared/models/_models";
import {postRequestActions} from '../../shared/services/request-service';
import {useNavigate} from 'react-router-dom';
import {NotificationMessage} from "../../shared/helper/Notification";
import {uploadFiles} from '../../shared/services/file-service';

type Props = {
    request: Request
    setOpenModal: any
    formData: any
    arrayOfFileForUpload: any
    actions: any
    arrayOfIdsForUpload: any
}

const ConfirmationModal: FC<Props> = ({
                                          request,
                                          setOpenModal,
                                          formData,
                                          arrayOfFileForUpload,
                                          actions,
                                          arrayOfIdsForUpload
                                      }) => {
    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        setOpenModal(false)
    }

    const sendData = () => {
        if (arrayOfFileForUpload.length > 0) {
            uploadFiles(arrayOfFileForUpload)
                .then(result => {
                    const fileIds = [] as number[]
                    for (var i = 0; i < result?.data.length; i++) {
                        fileIds.push(result?.data[i]?.id)
                    }
                    var difference = arrayOfIdsForUpload.filter((x: any) => fileIds.indexOf(x) === -1);
                    (difference.length > 0) ? sendRequestData.mutateAsync(difference) : sendRequestData.mutateAsync(fileIds);
                })
        } else {
            sendRequestData.mutateAsync([]);
        }
    }

    const sendRequestData = useMutation((fileId: any) => postRequestActions(
        {
            ...request,
            form_data: formData,
            ready_for_processing: true,
            additional_file_ids_for_upload: fileId,
            action_id: actions?.data[0].id
        }
    ), {
        onSuccess: () => {
            try {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.REQUEST_SEND_FOR_PROCESSING'}), '', 'success')
                navigate(`/request/${request.id}/overview`);

            } catch (ex) {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.SEND_FAILED'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SENDING_REQUEST_FOR_PROCESSING!_PLEASE_TRY_AGAIN.'}), 'danger')
            } finally {
                closeModal()
            }
        },
    })

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_delete'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered mw-400px'>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{request?.request_type?.name?.includes('ocjenu') ? intl.formatMessage({id: 'MODAL.CONFIRMATION.GRADING'}) : intl.formatMessage({id: 'MODAL.CONFIRMATION'})}</h3>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => closeModal()}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body text-center">
                        <span><h5>{request?.request_type?.name?.includes('ocjenu') ? intl.formatMessage({id: 'MODAL.CONFIRMATION.QUESTION.GRADING'}) : intl.formatMessage({id: 'MODAL.CONFIRMATION.QUESTION'})}</h5>
                        </span>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => closeModal()}
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    disabled={loading}
                            >
                                {intl.formatMessage({id: 'MODAL.CANCEL'})}
                            </button>
                            <button type="button" className="btn btn-success"
                                    disabled={loading}
                                    onClick={async () => {
                                        setLoading(true);
                                        sendData()
                                    }}>
                                {!loading && intl.formatMessage({id: 'MODAL.SEND_CONFIRMATION'})}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                        {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {ConfirmationModal}
