/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Dropdown} from 'react-bootstrap';
import {useIntl} from 'react-intl';
import Select from "react-select";
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers';
import {NotificationMessage} from '../../../../../shared/helper/Notification';
import {getDepartmentList} from '../../../../../shared/services/department-service';
import {useQueryRequest} from '../../../shared/list/core/QueryRequestProvider';
import {listRiskTypes, listSectors} from "../../../../../shared/SelectLists";

const JobPositionFilter: FC = () => {
    const intl = useIntl()
    const [departments, setDepartments] = useState({} as any)
    const [sectorValue, setSectorValue] = useState({} as any)
    const [buttonColor, setButtonColor] = useState('primary')
    const [buttonColorActive, setButtonColorActive] = useState('btn-active-light-primary mb-1 me-2')
    const [selectDepartmentValue, setSelectDepartmentValue] = useState({} as any)
    const [selectRiskValue, setSelectRiskValue] = useState({} as any)
    const {updateState} = useQueryRequest();

    useEffect(() => {
        getDepartmentList().then(result => {
            setDepartments(result)
        });
    }, [])

    var departmentList = Object.entries(departments).map(function (item: any) {
        return {
            value: item[1]?.id,
            label: item[1]?.name
        };
    });

    function filter(obj: any) {
        for (var key in obj) {
            if (obj[key] === undefined) {
                delete obj[key];
                continue;
            }
            if (obj[key] && typeof obj[key] === "object") {
                filter(obj[key]);
                if (!Object.keys(obj[key]).length) {
                    delete obj[key];
                }
            }
        }
        return obj;
    }

    const setFilter = async () => {
        const defaultFilterValues = {
            sector: {value: sectorValue?.value},
            department_id: {value: selectDepartmentValue?.value},
            is_risk: {value: selectRiskValue?.value}
        }


        const filteredObjectValues = filter(defaultFilterValues)

        if (Object.keys(filteredObjectValues).length !== 0) {
            Object.assign(filteredObjectValues, {...initialQueryState});

            await updateState(filteredObjectValues)
            setButtonColor('success')
            setButtonColorActive('btn-active-light-success mb-1 me-2')

            NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FILTER_SUCCESSFULLY_APPLIED'}), '', 'success')
        } else {
            setButtonColor('primary')
            setButtonColorActive('btn-active-light-success mb-1 me-2')
            NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.MUST_CHOOSE_FILTER_VALUE'}), '', 'warning')
        }
    }

    const resetFilter = async () => {
        setButtonColor('primary')
        setButtonColorActive('btn-active-light-primary me-2 mb-1')
        setSectorValue({})
        setSelectDepartmentValue({})
        setSelectRiskValue({})
        await updateState({
            sector: {value: ''},
            department_id: {value: ''},
            is_risk: {value: ''}, ...initialQueryState
        })
        NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FILTER_RESET_SUCCESSFULLY'}), '', 'success')
    }

    return (
        <Dropdown>
            <Dropdown.Toggle variant={buttonColor} className={buttonColorActive}>
                <KTSVG
                    path='/media/icons/duotune/general/gen031.svg'
                    className='svg-icon-5 svg-icon-gray-500 me-2'
                />
                {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER'})}
            </Dropdown.Toggle>
            <Dropdown.Menu className='px-4 py-4menu menu-sub menu-sub-dropdown w-250px w-sm-300px' align="start"
                           id="dropdown-menu-align-end">
                <div className='px-2 py-5'>
                    <div
                        className='fs-5 text-dark fw-bolder'>{intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.OPTION'})}</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className='px-2 py-5'>
                    <div className='mb-3'>
                        <label className='form-label fw-bold'>{intl.formatMessage({id: 'JOB_POSITION.SECTOR'})}:</label>
                        <Select
                            options={listSectors}
                            value={{
                                value: sectorValue ? sectorValue?.id : '',
                                label: sectorValue?.label ? sectorValue?.label : intl.formatMessage({id: 'SELECT_SECTOR'})
                            }}
                            onChange={(value: any) => setSectorValue(value)}
                            isSearchable={false}
                            classNamePrefix="select"
                            className={'mb-3 mb-lg-0'}
                        />
                    </div>
                    <div className='mb-3'>
                        <label
                            className='form-label fw-bold'>{`${intl.formatMessage({id: 'JOB_POSITION.DEPARTMENT'})}`}:</label>
                        <Select
                            options={departmentList}
                            value={{
                                value: selectDepartmentValue ? selectDepartmentValue?.value : '',
                                label: selectDepartmentValue?.label ? selectDepartmentValue?.label : intl.formatMessage({id: 'SELECT_DEPARTMENT'})
                            }}
                            onChange={(value: any) => setSelectDepartmentValue(value)}
                            isSearchable={true}
                            classNamePrefix="select"
                            className={'mb-3 mb-lg-0'}
                        />
                    </div>
                    <div className='mb-10'>
                        <label className='form-label fw-bold'>Uvećan rizik rada:</label>
                        <Select
                            options={listRiskTypes}
                            value={{
                                value: selectRiskValue ? selectRiskValue?.value : '',
                                label: selectRiskValue?.label ? selectRiskValue?.label : intl.formatMessage({id: 'SELECT_RISK'})
                            }}
                            onChange={(value: any) => setSelectRiskValue(value)}
                            isSearchable={true}
                            classNamePrefix="select"
                            className={'mb-3 mb-lg-0'}
                        />
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button
                            type='reset'
                            className='btn btn-sm btn-light btn-active-light-danger me-2'
                            data-kt-menu-dismiss='true'
                            onClick={() => resetFilter()}
                        >
                            {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.RESET'})}
                        </button>
                        <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'
                                onClick={() => setFilter()}
                        >
                            {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.APPLY'})}
                        </button>
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export {JobPositionFilter}
