import {FC, useEffect} from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { Contract } from '../../shared/models/_models'
import { ContractModalForm } from './ContractModalForm'

type Props = {
    setOpenModal: any
    action: any
    contract: Contract
}

const ContractModal: FC<Props> = ({setOpenModal, action, contract}) => {

    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='fw-bolder'>{action.name}</h2>
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => setOpenModal(false)}
                                style={{cursor: 'pointer'}}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                            </div>
                        </div>
                        <div className='modal-body scroll-y'>
                            <ContractModalForm setOpenModal={setOpenModal} action={action} contract={contract}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {ContractModal}
