import React, {useContext} from 'react';
import {Notification} from "../../../shared/models/notification";
import moment from "moment/moment";
import clsx from "clsx";
import {KTSVG} from "../../../../_metronic/helpers";
import NotificationService from "../../../shared/services/notification-service";
import {NotificationAlertContext} from "../../../shared/components/NotificationContext";
import {useNavigate} from 'react-router-dom';
import {OverlayTrigger, Tooltip, TooltipProps} from "react-bootstrap";
import {useIntl} from "react-intl";

type props = {
    notification: Notification
    setLoading: any
}

const NotificationItem: React.FC<props> = ({notification, setLoading}) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const navigateToNotification = (notification: any) => {
        if (notification?.model_name === 'request') {
            navigate(`/request/${notification?.model?.id}/overview`)
        } else if (notification?.model_name === 'contract') {
            navigate(`/contract/${notification?.model?.id}/overview`)
        }
    }

    // eslint-disable-next-line
    const [numberOfUnread, setNumberOfUnread] = useContext(NotificationAlertContext);

    function notificationHandler() {
        setLoading(true);
        NotificationService.updateNotification(notification?.id).then(() => {
            NotificationService.getUnreadNotifications().then((response) => {
                if (response) {
                    setNumberOfUnread(response.data.filter((notification: Notification) => !notification?.is_read).length);
                }
            });
            navigateToNotification(notification)
        });
    }

    const markOneNotificaitonAsRead = (e: any) => {
        e.stopPropagation();
        setLoading(true);
        NotificationService.updateNotification(notification?.id).then(() => {
            NotificationService.getUnreadNotifications().then((response) => {
                if (response) {
                    setNumberOfUnread(response.data.filter((notification: Notification) => !notification?.is_read).length);
                }
            });
        });
    }

    function checkIcon() {
        if (notification?.model_name === 'contract') {
            return `/media/icons/duotune/finance/fin006.svg`;
        } else {
            if (notification?.type === 'success') {
                return `/media/icons/duotune/general/gen043.svg`;
            } else if (notification?.type === 'danger') {
                return `/media/icons/duotune/general/gen042.svg`;
            } else if (notification?.type === 'primary') {
                return `/media/icons/duotune/general/gen045.svg`;
            } else {
                return `/media/icons/duotune/general/gen045.svg`;
            }
        }
    }

    const editTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
        <Tooltip {...props}>{intl.formatMessage({id: 'NOTIFICATIONS.MARK_AS_READ'})}</Tooltip>
    );

    const notificationState = notification?.message?.split('Komentar ')[0];
    const notificationComment = notification?.message?.split('Komentar ')[1];

    return (
        <tr onClick={() => notificationHandler()}
            className={clsx('border-0 bg-hover-light-primary notification-row ', !notification?.is_read ?`bg-light-${notification?.type}` : 'bg-gray-200')}>
            <td className={'pb-1'}>
                <div className='ms-3 symbol symbol-40px d-flex ps-2'>
                    <div className='h-50px pe-3'>
                        <span className='fs-8 fw-bolder text-gray-600'> {moment.unix(notification?.created_at  || 0).format("DD.MM.YY")}</span>
                        <span className='fs-8 fw-bolder text-gray-600 justify-content-end d-flex'> {moment.unix(notification?.created_at  || 0).format("H:mm")}</span>
                    </div>
                    <span className={clsx('symbol-label', `bg-light-${notification?.type}`)}>
                        <KTSVG
                            path={checkIcon()}
                            className={`svg-icon-2x svg-icon-${notification?.type}`}
                        />
                    </span>
                </div>
            </td>
            <td>
                <span className='text-dark fs-6 fw-bold text-gray-700'>{notification?.title}<br/></span>
            </td>
            <td>
                <div className='d-flex flex-column text-left align-content-center text-gray-700'>
                    {notification?.model?.user?.first_name + ' ' + notification?.model?.user?.last_name}
                </div>
            </td>
            <td>
                <div className='d-flex flex-column text-left align-content-center me-3 fs-7'>
                    {notificationState}<br/>
                    {notificationComment ? <span
                        className='text-gray-800 fs-7'>{intl.formatMessage({id: 'REQUEST.STATUS.STATE.COMMENT'})} {notificationComment}</span> : ''}
                </div>
            </td>
            <td>
                {!notification?.is_read && <OverlayTrigger placement="top" overlay={editTooltip}>
                    <button
                        className='btn btn-icon btn-active-color-primary btn-sm me-1'
                        onClick={(e) => markOneNotificaitonAsRead(e)}
                    >
                        <KTSVG path='/media/icons/duotune/general/gen037.svg' className='svg-icon-3x'/>
                    </button>
                </OverlayTrigger>}
            </td>
        </tr>
    )
}

export {NotificationItem}