import {FC, useEffect, useState} from 'react';
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';
import {PrivateRoutes} from './PrivateRoutes';
import {ErrorsPage} from '../modules/errors/ErrorsPage';
import {Logout, AuthPage} from '../modules/auth';
import {App} from '../App';
import {AccessRequestCode} from "../modules/auth/AccessRequestCode";

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
    const [token, setToken] = useState('')

    useEffect(() => {
        setToken(localStorage.getItem('access_token') || '');
    }, [])

    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App/>}>
                    <Route path='error/*' element={<ErrorsPage/>}/>
                    <Route path='logout' element={<Logout/>}/>
                    {/*<Route path='access_token_code' element={<AccessTokenCode setToken={setToken}/>}/>*/}

                    {token ? (
                        <>
                            <Route path='access_request_code' element={<AccessRequestCode/>}/>
                            <Route path='/*' element={<PrivateRoutes/>}/>
                            <Route index element={<Navigate to='/dashboard'/>}/>
                        </>
                    ) : (
                        <>
                            <Route path='*' element={<AuthPage/>}/>
                            <Route path='*' element={<Navigate to='/login'/>}/>
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}
