/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Request} from '../../../shared/models/_models'
import {postRequestActions} from '../../../shared/services/request-service'
import clsx from 'clsx'
import {Dropdown} from 'react-bootstrap'

type Props = {
  setOpenModal: any
  request: Request
  actions: any
  setRequestAction: any
  setIsDownloading: any
}

const ActionDropdown: FC<Props> = ({
  setOpenModal,
  actions,
  setRequestAction,
  request,
  setIsDownloading,
}) => {
  const intl = useIntl()

  const setActionData = async (action: any) => {
    if (action?.name === intl.formatMessage({id: 'REQUEST.ACTIONS.DENY'})) {
      await postRequestActions({...request, action_id: action.id})
    } else {
      setOpenModal(true)
      setRequestAction(action)
    }
  }

  const optionsLists = actions?.map((item: any) => {
    switch (item?.name) {
      case intl.formatMessage({id: 'REQUEST.ACTIONS.APPROVE'}):
        if (item?.full_name === intl.formatMessage({id: 'REQUEST.ACTIONS.DIRECTOR.DISPLAY'})) {
          return {
            id: item?.id,
            name: intl.formatMessage({id: 'REQUEST.ACTIONS.DIRECTOR.DISPLAY'}),
            icon: 'fa-solid fa-forward',
            json_schema: item?.json_schema,
            ui_schema: item?.ui_schema,
          }
        } else if (
          item?.full_name === intl.formatMessage({id: 'REQUEST.ACTIONS.RATE_THE_REQUEST'})
        ) {
          return {
            id: item?.id,
            name: intl.formatMessage({id: 'REQUEST.ACTIONS.RATE_THE_REQUEST.DISPLAY'}),
            icon: 'fa-solid fa-list-check',
            json_schema: item?.json_schema,
            ui_schema: item?.ui_schema,
          }
        } else if (
          item?.full_name === intl.formatMessage({id: 'REQUEST.ACTIONS.INFO_ABOUT_GRADE'})
        ) {
          return {
            id: item?.id,
            name: intl.formatMessage({id: 'REQUEST.ACTIONS.INFO_ABOUT_GRADE.DISPLAY'}),
            icon: 'fa-solid fa-circle-info',
            json_schema: item?.json_schema,
            ui_schema: item?.ui_schema,
          }
        } else if (
          item?.full_name === intl.formatMessage({id: 'REQUEST.ACTIONS.INFO_ABOUT_CONVERSATION'})
        ) {
          return {
            id: item?.id,
            name: intl.formatMessage({id: 'REQUEST.ACTIONS.INFO_ABOUT_CONVERSATION.DISPLAY'}),
            icon: 'fa-solid fa-user-group',
            json_schema: item?.json_schema,
            ui_schema: item?.ui_schema,
          }
        } else {
          return {
            id: item?.id,
            name: intl.formatMessage({id: 'REQUEST.ACTIONS.APPROVE.DISPLAY'}),
            icon: 'fa-solid fa-check',
            json_schema: item?.json_schema,
            ui_schema: item?.ui_schema,
          }
        }
      case intl.formatMessage({id: 'REQUEST.ACTIONS.CANCEL'}):
        return {
          id: item?.id,
          name: intl.formatMessage({id: 'REQUEST.ACTIONS.CANCEL.DISPLAY'}),
          icon: 'fa-solid fa-ban',
        }
      case intl.formatMessage({id: 'REQUEST.ACTIONS.UPLOAD'}):
        return {
          id: item?.id,
          name: intl.formatMessage({id: 'REQUEST.ACTIONS.UPLOAD.DISPLAY'}),
          icon: 'fa-solid fa-upload',
        }
      case intl.formatMessage({id: 'REQUEST.ACTIONS.DENY'}):
        return {
          id: item?.id,
          name: intl.formatMessage({id: 'REQUEST.ACTIONS.DENY.DISPLAY'}),
          icon: 'fa-solid fa-backward',
        }
      default:
        return {
          id: item?.id,
          name: item?.json_schema
            ? intl.formatMessage({id: 'REQUEST.DROPDOWN.STATUS.PRINT'})
            : intl.formatMessage({id: 'REQUEST.DROPDOWN.STATUS.PRINT.ONLY'}),
          icon: 'fa-solid fa-print',
          json_schema: item?.json_schema,
          ui_schema: item?.ui_schema,
        }
    }
  })

  return (
    <Dropdown>
      <Dropdown.Toggle variant='primary' className='btn-active-light-primary'>
        {intl.formatMessage({id: 'REQUEST.ACTIONS'})}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-250px'
        align='end'
        id='dropdown-menu-align-end'
      >
        <div className='menu-item px-3'>
          <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
            {intl.formatMessage({id: 'REQUEST.DROPDOWN'})}
          </div>
        </div>
        <div className='separator mb-3 opacity-75'></div>
        {optionsLists?.length > 0 ? (
          optionsLists?.map((action: any) => (
            <div className='menu-item px-3' key={action?.id}>
              <div onClick={() => setActionData(action)} className='menu-link px-3'>
                <i className={clsx(action.icon, 'me-2 text-primary fa-lg')}></i>
                {action.name}
              </div>
            </div>
          ))
        ) : (
          <div className='menu-item px-3'>
            <div className='menu-link disabled px-3' style={{cursor: 'not-allowed'}}>
              {intl.formatMessage({id: 'REQUEST.DROPDOWN.STATUS.REJECT.DISPLAY'})}
            </div>
          </div>
        )}
        <div className='separator mt-3 opacity-75 border-0'></div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export {ActionDropdown}
