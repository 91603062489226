import {FC, useEffect} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import Form from "react-jsonschema-form";
import {JSONSchema6} from "json-schema";
import './requestPreviewCss.css';
import { KTCardBody } from '../../../../_metronic/helpers';
import { ObjectFieldTemplate } from '../jsonSchemaAccessories/ObjectFieldTemplate';
import {Request} from "../../../shared/models/_models";
import { jsonDefaultSchema } from '../jsonSchemaAccessories/jsonDefaultSchema';
import { useIntl } from 'react-intl';

type Props = {
    request: Request
    openModal: any
}

const RequestPreview: FC<Props> = ({request, openModal}) => {
    const schema: JSONSchema6 = request?.request_type?.json_schema ? JSON.parse(request?.request_type?.json_schema) : jsonDefaultSchema
    const intl = useIntl()
    const attachmentFiles = request?.additional_files && request?.additional_files
    

    useEffect(() => {
        !openModal && Array.from(document.querySelectorAll('input[type=text]')).forEach(function(item: any) {
            var text = document.createElement('label');
            text.setAttribute('class', "textLabel");
            text.innerText = item.value
            item.parentNode.replaceChild(text, item);
         })
        
         !openModal && Array.from(document.querySelectorAll('span')).forEach(function(item: any) {
            if (item?.textContent.includes(intl.formatMessage({id: 'NOTIFICATION'}))) {
                item?.parentElement?.classList.add('d-none')
            }
             if (item?.textContent.includes(intl.formatMessage({id: 'REQUEST.REMARK'}))) {
                 item?.parentElement?.classList.add('d-none')
             }
             if (item?.textContent.includes(intl.formatMessage({id: 'REQUEST.DROPZONE.NAME'}))) {
                item?.parentElement?.classList.add('d-none')
            }
         });

         document.querySelector('.form-group.field.field-null')?.parentElement?.parentElement?.classList.add('d-none')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema])

    return (
        <KTCardBody>
            <div className='card'>
                <div className='card-body pt-0'>
                    <div className='text-start fw-bolder fs-7 text-uppercase gs-0'>{intl.formatMessage({id: 'REQUEST.PREVIEW'})}</div>
                    <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
                        <div className='card-body p-0'>
                            {schema && <Form
                                id="json-schema-preview"
                                autocomplete="off"
                                schema={schema}
                                ObjectFieldTemplate={ObjectFieldTemplate}
                                formData={request?.form_data}
                                disabled
                            >
                            <div className='d-none'>
                            <button></button>
                            </div>
                            {(attachmentFiles?.length !== 0) && <div className='d-flex w-100 ps-2'>
                                <div className='col-7 fw-bold fs-6 text-gray-700 text-uppercase'>{intl.formatMessage({id: 'REQUEST.DROPZONE.NAME'})}</div>
                                <div className='col-5 ps-8'>
                                {attachmentFiles?.map((item: any) => (
                                    <a key={item.id} href={item.url} className='d-flex py-2 text-break'>{item.original_name}</a>
                                ))
                                }
                                </div>
                            </div>}
                            </Form>
                            }
                        </div>
                    </div>
                    </div>
            </div>
        </KTCardBody>
    )
}

export {RequestPreview}
