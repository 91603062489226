import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {NotificationMessage} from '../../../shared/helper/Notification'
import {
  updateRequestTypeForCommercialSector,
  updateRequestTypeForTechnicalSector,
} from '../../../shared/services/request-service'

type props = {
  item: any
  setLoading: any
}

const SettingsItem: React.FC<props> = ({item, setLoading}) => {  
  const [technicalIsActive, setTechnicalIsActive] = useState(item?.is_active_technical)
  const [commercialIsActive, setCommercialIsActive] = useState(item?.is_active_commercial)
  const intl = useIntl()

  const updateTechnicalSector = () => {
    setLoading(true)
    updateRequestTypeForTechnicalSector(item?.id).then(() => {
      setTechnicalIsActive(!technicalIsActive)
      if (technicalIsActive) {
        NotificationMessage(
          intl.formatMessage({id: 'MESSAGE_NOTIFICATION.REQUEST.SETTINGS.COMMERCIAL.TURN_OFF'}),
          '',
          'success'
        )
      } else {
        NotificationMessage(
          intl.formatMessage({id: 'MESSAGE_NOTIFICATION.REQUEST.SETTINGS.COMMERCIAL.TURN_ON'}),
          '',
          'success'
        )
      }
      setLoading(false)
    })
  }

  const updateCommercialSector = () => {
    setLoading(true)
    updateRequestTypeForCommercialSector(item?.id).then(() => {
      setCommercialIsActive(!commercialIsActive)
      if (commercialIsActive) {
        NotificationMessage(
          intl.formatMessage({id: 'MESSAGE_NOTIFICATION.REQUEST.SETTINGS.COMMERCIAL.TURN_OFF'}),
          '',
          'success'
        )
      } else {
        NotificationMessage(
          intl.formatMessage({id: 'MESSAGE_NOTIFICATION.REQUEST.SETTINGS.COMMERCIAL.TURN_ON'}),
          '',
          'success'
        )
      }
      setLoading(false)
    })
  }

  return (
    <tr className={'border-bottom'}>
      <td>
        <span className='fs-6 fw-bold text-gray-800'>{item?.name}</span>
      </td>
      <td>
        <div className='d-flex justify-content-center'>
          <span className='text-dark fs-6 fw-bold text-gray-600'>{item?.max_we_level}</span>
        </div>
      </td>
      <td>
        <div className='d-flex justify-content-center'>
          <span className='text-dark fs-6 fw-bold text-gray-600'>{item?.we_level}</span>
        </div>
      </td>
      <td>
        <div className='d-flex text-left align-content-center  justify-content-center'>
          <div className='d-flex align-items-center form-check form-check-solid form-switch'>
            <input
              style={{cursor: 'pointer'}}
              className='form-check-input form-check-solid form-switch w-45px h-30px'
              type='checkbox'
              name='technical'
              checked={technicalIsActive}
              onChange={() => {
                updateTechnicalSector()
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div className='d-flex text-left align-content-center  justify-content-center'>
          <div className='d-flex align-items-center form-check form-check-solid form-switch'>
            <input
              style={{cursor: 'pointer'}}
              className='form-check-input form-check-solid form-switch w-45px h-30px'
              type='checkbox'
              name='commercial'
              checked={commercialIsActive}
              onChange={() => {
                updateCommercialSector()
              }}
            />
          </div>
        </div>
      </td>
    </tr>
  )
}

export {SettingsItem}