/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { NotificationMessage } from '../../../shared/helper/Notification';
import {Request} from '../../../shared/models/_models';
import './requestPreviewCss.css';
import { deleteRequestComment, getRequestComments, postRequestComments } from '../../../shared/services/request-service';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {SimpleLoading} from "../../../modules/apps/shared/list/components/loading/SimpleLoading";

type Props = {
    request: Request
}

const Comments: FC<Props> = ({request})  => {
  const intl = useIntl()
  const [commentString, setCommentString] = useState('')
  const [messages, setMessages] = useState([] as any)
  const [querySent, setQuerySent] = useState(false)
  const requestId = useParams().id;
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const asyncFetch = async () => {
    await getRequestComments(requestId).then(response => setMessages(response))
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    asyncFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySent, request])

  const updateComments = (message: any) => {
    const current_user = JSON.parse(localStorage.getItem('current_user') || '')

    if (current_user?.id === message?.user_id)
    {
      setIsLoading(true)
      deleteComment.mutateAsync(message?.id)
    } else {
        NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.ERROR_DELETING_COMMENT'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.NO_PERMISSION_DELETING_COMMENT'}), 'danger')
    }
  }

  const addComments = () => {
    commentString ? setIsMessageSent(true) : NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.MUST_ADD_COMMENT'}), '', 'warning')
    commentString && sentComment.mutateAsync()
  }

  const sentComment = useMutation(() => postRequestComments(
    requestId,
    commentString
    ), {
    onSuccess: () => {
        try {
            setCommentString('')
            NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.COMMENT_SUCCESSFULLY_CREATED'}), '', 'success')
            setQuerySent(!querySent)
            setIsMessageSent(false)
        } catch (ex) {
            NotificationMessage(intl.formatMessage({id: 'ERROR_ADDING_COMMENT'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_DELETING_COMMENT!_PLEASE_TRY_AGAIN.'}), 'danger')
        } finally {
        }
    },
  })

  const deleteComment = useMutation((messageId: any) => deleteRequestComment(
    messageId
    ), {
    onSuccess: () => {
        try {
            setCommentString('')
            NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.COMMENT_SUCCESSFULLY_DELETED'}), '', 'success')
            setQuerySent(!querySent)
            setIsLoading(false)
        } catch (ex) {
            NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.ERROR_ADDING_COMMENT'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_DELETING_COMMENT!_PLEASE_TRY_AGAIN.'}), 'danger')
        } finally {
        }
    },
  })

  return (
    <>
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9 mb-xl-0 mb-10 mt-10'>
        <div className='card-body p-9 mb-0 pb-0'>
        {isLoading ? <div className='border-bottom pb-8'><SimpleLoading/></div> :
        <h3 className='fw-bolder m-0 d-flex justify-content-center justify-content-sm-start justify-content-md-start justify-content-lg-start fs-5 text-uppercase border-bottom pb-8'>{intl.formatMessage({id: 'REQUEST.STATUS.STATE.COMMENTS'})}</h3>}
        </div>
        <div
          className='scroll-y ps-9 me-n5 pe-9 py-1 pt-3 pb-3'
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-max-height='380px'
          data-kt-scroll-dependencies={
            '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
          }
          data-kt-scroll-wrappers={
            '#kt_drawer_chat_messenger_body'
          }
          data-kt-scroll-offset={'0px'}
        >
          {messages?.data?.length > 0 ? messages?.data.slice(0).reverse().map((message: any, index: any) => {
            return (
              <div
                key={`message${index}`}
                className='d-flex justify-content-start text-break w-100'
              >
                  <div className='d-flex align-items-center mb-1 w-100 pe-1'>
                      <div className='me-3 d-grid w-100 border-bottom pb-2'>
                          <div className='me-3 d-flex w-100 pt-1'>
                              <span className='fs-7 fw-bolder text-gray-900 w-50 align-items-center d-flex'>{message?.full_name}</span>
                              <span className='fs-8 fw-bolder text-gray-700 w-50 align-items-center justify-content-end d-flex'>
                                  <span><i className="fa fa-calendar"></i><span className='ps-1'>{moment.unix(message?.created_at || 0).format("DD.MM.YYYY H:mm")}</span></span>
                                      {[intl.formatMessage({id: 'REQUEST.STATUS.FINISHED'}), intl.formatMessage({id: 'REQUEST.STATUS.REJECT'})].includes(request?.status || '') ?
                                      <div></div>
                                      : <OverlayTrigger placement="top" overlay={<Tooltip>{intl.formatMessage({id: 'TOOLTIP.COMMENT.DELETE'})}</Tooltip>}>
                                          <span onClick={() => updateComments(message)} className='text-muted fs-6 ms-2 bi bi-x-circle text-hover-danger cursor-pointer'></span>
                                      </OverlayTrigger>}

                              </span>
                          </div>
                          <span className='text-muted fs-7 mb-1 text-justify'>{message?.note}</span>
                      </div>
                  </div>
              </div>
            )
          }) :
          <span className='fs-7 fw-bolder text-gray-700 d-flex justify-content-center pe-5'>{intl.formatMessage({id: 'REQUEST.STATUS.COMMENTS.MESSAGE'})}</span>
          }
        </div>
        {![intl.formatMessage({id: 'REQUEST.STATUS.FINISHED'}), intl.formatMessage({id: 'REQUEST.STATUS.REJECT'})].includes(request?.status || '') &&
        <div
          className='card-body pt-4 d-flex'
          id='kt_drawer_chat_messenger_footer'
        >
          <textarea
            className='form-control form-control-solid'
            rows={1}
            data-kt-element='input'
            placeholder={intl.formatMessage({id: 'REQUEST.STATUS.COMMENTS.PLACEHOLDER'})}
            value={commentString}
            onChange={(e) => setCommentString(e.target.value)}
          ></textarea>

          <div className='d-flex flex-stack ps-2'>
            <button
              className='btn btn-primary'
              type='button'
              data-kt-element='send'
              disabled={isMessageSent}
              onClick={() => addComments()}
            >
              {!isMessageSent && intl.formatMessage({id: 'REQUEST.STATUS.COMMENTS.SEND'})}
              {isMessageSent && (
                <span className='indicator-progress' style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                    <span className='spinner-border align-middle mx-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>}
      </div>
    </>
  )
}

export {Comments}
