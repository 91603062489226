import {FC, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {getIn, useFormik} from 'formik';
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {useListView} from '../../../shared/list/core/ListViewProvider';
import {useQueryResponse} from '../../core/QueryResponseProviderRequests';
import {Request, RequestType} from '../../../../../shared/models/_models';
import {isNotEmpty} from '../../../../../../_metronic/helpers';
import {createRequest, updateRequest} from '../../../../../shared/services/request-service';
import {SelectField} from "../../../../../shared/helper/Select";
import {NotificationMessage} from "../../../../../shared/helper/Notification";
import {getUsers} from '../../../../../shared/services/user-service';

type Props = {
    request: Request
    types: RequestType[] | undefined
    setOpenCreateModal?: any
}

const RequestCreateModalForm: FC<Props> = ({request, types, setOpenCreateModal}) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const {setItemIdForUpdate} = useListView();
    const [requestApplicant, setRequestApplicant] = useState(request?.user)
    const currentUser = JSON.parse(localStorage.getItem('current_user') || '')
    const [requestType, setRequestType] = useState(request?.request_type)
    const [requestData, setRequestData] = useState<any | null>(null)
    const {refetch} = useQueryResponse();
    const navigate = useNavigate();
    const [userData, setUserData] = useState<any | null>(null)


    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setOpenCreateModal(false)
        setItemIdForUpdate(undefined)
    }

    useEffect(() => {
            getUserData()
        },
        // eslint-disable-next-line
        [requestData])

    function getUserData() {
        requestData && setLoading(true);
        requestData && getUsers(`list=true&request_type_id=${requestType?.id}`)
            .then(result => {
                if (result) {
                    setUserData(result?.data)
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            });
    }

    const usersList = userData?.map((item: any) => (
        {
            value: item.id, label: item?.first_name + ' ' + item?.last_name
        }
    ))

    const typeList = types?.map(item => (
        {
            value: item.id, label: item?.name
        }
    ))

    

    const editRequestSchema = () => {
        if (userData?.length > 0 && (currentUser?.id !== userData[0]?.id)) {
            return Yup.object().shape({
                user_id: Yup.string()
                    .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
                    .nullable()
            })
        } else {
            return Yup.object().shape({
                request_type_id: Yup.string()
                    .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
                    .nullable(),
            })
        }
    }    

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            user_id: (userData?.length === 1 && (currentUser?.id === userData[0]?.id)) ? currentUser?.id : '',
            request_type_id: requestData?.id,
            created_by: currentUser?.id
        },
        validationSchema: editRequestSchema,
        onSubmit: async (values: Request, {setSubmitting}) => {
            let newRequest: Request | undefined;
            if (userData?.length === 0) {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.DONT_HAVE_USERS'}), '', 'warning')
            } else {            
                setLoading(true);
                setSubmitting(true)
                try {
                    if (isNotEmpty(values.id)) {
                        await updateRequest(values)
                        NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.REQUEST_SUCCESSFULLY_UPDATED'}), '', 'success')
                    } else {
                        await createRequest(values).then((res) => newRequest = res)
                        if (newRequest) {
                            NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.REQUEST_SUCCESSFULLY_CREATED'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.REQUEST_SUCCESSFULLY_CREATED.INFO'}), 'success')
                            navigate(`/request/${newRequest.id}/edit`);
                        }
                    }
                } catch (ex) {
                    NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.SAVE_FAILED!'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_SAVING_DATA!_PLEASE_TRY_AGAIN.'}), 'danger')
                } finally {
                    setSubmitting(true)
                    cancel(true)
                }
            }
        },
    })

    return (
        <>
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                <div className='col-12 mb-3'>
                    <label className='col-form-label fw-bold fs-6 required'>
                        {intl.formatMessage({id: 'REQUEST.REQUEST_TYPE'})}
                    </label>
                    <SelectField
                        name={'request_type_id'}
                        className_condition={formik.touched.request_type_id && formik.errors.request_type_id}
                        placeholder={intl.formatMessage({id: 'REQUEST.SELECT_REQUEST_TYPE'})}
                        value={{
                            value: requestType ? requestType.id : '',
                            label: requestType ? requestType.name : intl.formatMessage({id: 'REQUEST.SELECT.REQUEST_TYPE'})
                        }}
                        options={typeList}
                        onChange={(
                            newValue: any,
                        ) => {
                            formik.setFieldValue('request_type_id', newValue?.value)
                            setRequestType(types?.filter(type => type.id?.toString() === newValue.value.toString())[0])
                            setRequestData(types?.filter(type => type.id?.toString() === newValue.value.toString())[0])
                            setRequestApplicant(request?.user)
                        }}
                        isSearchable={true}
                        formatOptionLabel={{}}
                    />
                    {getIn(formik.errors, 'request_type_id') && getIn(formik.touched, 'request_type_id') &&
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{getIn(formik.errors, 'user_id')}</span>
                            </div>
                        </div>
                    }
                </div>
                {requestType && (userData?.length > 0 && (currentUser?.id !== userData[0]?.id)) && <div className='col-12 mb-3'>
                    <label className='col-form-label fw-bold fs-6 required'>
                        {intl.formatMessage({id: 'REQUEST.APPLICANT'})}
                    </label>
                    <SelectField
                        name={'user_id'}
                        className_condition={formik.touched.user_id && formik.errors.user_id}
                        placeholder={intl.formatMessage({id: 'REQUEST.SELECT_APPLICANT'})}
                        value={{
                            value: requestApplicant ? requestApplicant.id : '',
                            label: requestApplicant?.first_name ? (requestApplicant.first_name + ' ' + requestApplicant.last_name) : intl.formatMessage({id: 'REQUEST.APPLICANT'})
                        }}
                        options={usersList}
                        onChange={(
                            newValue: any,
                        ) => {
                            formik.setFieldValue('user_id', newValue?.value)
                            setRequestApplicant(userData?.filter((user: any) => user?.id?.toString() === newValue.value.toString())[0])
                        }}
                        isSearchable={true}
                        formatOptionLabel={{}}
                    />
                    {getIn(formik.errors, 'user_id') && getIn(formik.touched, 'user_id') &&
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{getIn(formik.errors, 'user_id')}</span>
                            </div>
                        </div>
                    }
                </div>}
                <div className='text-center pt-15'>
                    <button
                        type='submit'
                        className='btn btn-primary float-end'
                        data-kt-users-modal-action='submit'
                        disabled={loading}
                    >
                        {!loading && (request.id === undefined ? intl.formatMessage({id: 'BUTTON.CREATE'}) : intl.formatMessage({id: 'BUTTON.SUBMIT'}))}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                                    {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3 float-end'
                        data-kt-users-modal-action='cancel'
                        disabled={loading}
                    >
                        {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                    </button>
                </div>
            </form>
        </>
    )
}

export {RequestCreateModalForm}

