import React, {FC, useEffect, useState} from 'react';
import {useMutation, useQueryClient} from "react-query";
import {ID, KTSVG, QUERIES} from "../../../_metronic/helpers";
import {useListView} from "../../modules/apps/shared/list/core/ListViewProvider";
import {useIntl} from "react-intl";
import {deleteContract} from "../services/contract-service";
import {useQueryResponse} from "../../modules/apps/contract-menagement/core/QueryResponseProviderContracts";
import {NotificationMessage} from "../helper/Notification";

type Props = {
    id: ID
}

const DeleteContractModal: FC<Props> = ({id}) => {
    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    const intl = useIntl();
    const {query} = useQueryResponse();
    const queryClient = useQueryClient();
    const {setItemIdForDelete} = useListView();
    const {refetch} = useQueryResponse();
    const [loading, setLoading] = useState(false);

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForDelete(undefined)
    }

    const deleteItem = useMutation(() => deleteContract(id), {
        onSuccess: () => {
            try {
                queryClient.invalidateQueries([`${QUERIES.CONTRACTS_LIST}-${query}`]);
                cancel();
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.CONTRACT_SUCCESSFULLY_DELETED'}), '', 'success')
            } catch (ex) {
                NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.DELETE_FAILED'}), intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED_WHILE_DELETING_DEPARTMENT!_PLEASE_TRY_AGAIN.'}), 'danger')
            } finally {
                cancel();
            }
        },
    })

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_delete'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-dialog-centered mw-400px'>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{intl.formatMessage({id: 'MODAL.DELETE_CONTRACT'})}</h3>
                            <div onClick={() => setItemIdForDelete(undefined)}
                                 className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                 data-bs-dismiss="modal"
                                 aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body text-center">
                            <h5>{intl.formatMessage({id: 'MODAL.DELETE_CONTRACT.QUESTION'})}</h5>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => cancel()}
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    disabled={loading}
                            >
                                {intl.formatMessage({id: 'MODAL.CANCEL'})}
                            </button>
                            <button type="button" className="btn btn-danger"
                                    disabled={loading}
                                    onClick={async () => {
                                        setLoading(true);
                                        await deleteItem.mutateAsync();
                                    }}>
                                {!loading && intl.formatMessage({id: 'MODAL.DELETE'})}
                                {loading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                    {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export {DeleteContractModal}
