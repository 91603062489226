const QUERIES = {
  USERS_LIST: 'users-list',
  DEPARTMENTS_LIST: 'departments-list',
  CONTRACTS_LIST: 'contracts-list',
  CHILDREN_LIST: 'children-list',
  JOB_POSITIONS_LIST: 'job-positions-list',
  REQUEST_LIST: 'request-list'
}

export {QUERIES}
