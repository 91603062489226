import {FC} from "react";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../../../_metronic/helpers";

type Props = {
    setOpenModal: any
}

const AdditionalModalHeader: FC<Props> = ({setOpenModal}) => {
    const intl = useIntl()

    return (
        <div className='modal-header'>
            <h2 className='fw-bolder'> {intl.formatMessage({id: 'PROFILE.ADD.DOCUMENT'})}</h2>
            <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => setOpenModal(false)}
                style={{cursor: 'pointer'}}
            >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
            </div>
        </div>
    )
}

export {AdditionalModalHeader}
