import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {DetailsHeader} from '../DetailsHeader'
import {User} from '../../../../../shared/models/_models'
import moment from 'moment/moment'
import {getIn, useFormik} from 'formik'
import * as Yup from 'yup'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {newPassword} from '../../../../../shared/services/user-service'
import {NotificationMessage} from '../../../../../shared/helper/Notification'
import {AddProfileImageModal} from './AddProfileImageModal'
import { RoutesHelper } from '../../../../../shared/helper/RoutesHelper'

type Props = {
  className: string
  user?: User
  asyncFetch?: any
}

const UserDetails: React.FC<Props> = ({className, user, asyncFetch}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const currentUser = JSON.parse(localStorage.getItem('current_user') || '')
  let dateString = moment.unix(user?.created_at || 0).format('DD.MM.YYYY')
  const [openModal, setOpenModal] = useState(false)

  const UserDetailsValidationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.INVALID_PASSWORD'})
      )
      .nullable(),
    password_repeat: Yup.string()
      .nullable()
      .when('password', {
        is: (password: string) => isNotEmpty(password),
        then: (password_repeat) =>
          password_repeat
            .required(intl.formatMessage({id: 'REQUIRED_FIELD'}))
            .oneOf(
              [Yup.ref('password'), null],
              intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.EQUAL_PASSWORD'})
            ),
      }),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {password: '', password_repeat: ''},
    validationSchema: UserDetailsValidationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      setSubmitting(true)

      try {
        await newPassword({id: user?.id, password: values.password})
        NotificationMessage(
          intl.formatMessage({id: 'MESSAGE_NOTIFICATION.NEW_PASSWORD_SET'}),
          '',
          'success'
        )
        formik.values.password = ''
        formik.values.password_repeat = ''
        navigate(`/user/${user?.id}/details/personal/view`)
      } catch (ex) {
        NotificationMessage(
          intl.formatMessage({id: 'MESSAGE_NOTIFICATION.AN_ERROR_OCCURRED'}),
          intl.formatMessage({id: 'MESSAGE_NOTIFICATION.ERROR_PASSWORD_CHANGE'}),
          'danger'
        )
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <div className={`card ${className}`} id='kt_user_details_view'>
        <div className='card-header cursor-pointer'>
          <DetailsHeader user={user} />
        </div>
        <div className='card-body p-9'>
        {(RoutesHelper(['WE1', 'admin', 'hr', 'hr1', 'hr2']) || currentUser?.id === user?.id) && (<div className='row'>
              <label className='col-sm-6 fw-semibold text-muted pb-2'>
                {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.PROFILE.PHOTO'})}
              </label>
              <div className='col-sm-6 mb-5'>
                 <button type='submit' className='btn btn-primary' onClick={() => setOpenModal(true)}>{intl.formatMessage({id: 'PROFILE.IMAGE.ADD_FILE'})}</button>
              </div>
        </div>)}
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row'>
              <label className='col-sm-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.USERNAME'})}
              </label>
              <div className='col-sm-6 mb-5'>
                <span className='fs-6'>{user?.username}</span>
              </div>

              <label className='col-sm-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.EMAIL'})}
              </label>
              <div className='col-sm-6 mb-5'>
                <span className='fs-6'>{user?.email}</span>
              </div>

              <label className='col-sm-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.ACTIVATED'})}
              </label>
              <div className='col-sm-6 mb-5'>
                <span className='fs-6'>
                  {user?.is_active
                    ? intl.formatMessage({id: 'YES'})
                    : intl.formatMessage({id: 'NO'})}
                </span>
              </div>

              <label className='col-sm-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.CREATED_AT'})}
              </label>
              <div className='col-sm-6 mb-5'>
                <span className='fs-6'>{dateString}</span>
              </div>

              {user?.id === currentUser?.id && (
                <>
                  <h3 className='col-12 mt-10 fw-semibold'>
                    {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.CHANGE_PASSWORD'})}
                  </h3>

                  <div className='col-12 col-sm-6 mb-3'>
                    <label className='col-form-label fw-bold fs-6'>
                      {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.NEW_PASSWORD'})}
                    </label>
                    <input
                      {...formik.getFieldProps('password')}
                      placeholder={intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.NEW_PASSWORD'})}
                      type='password'
                      name='password'
                      className={
                        getIn(formik.errors, 'password') && getIn(formik.touched, 'password')
                          ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                          : 'form-control form-control-solid mb-3 mb-lg-0'
                      }
                      autoComplete='off'
                    />
                    {getIn(formik.errors, 'password') && getIn(formik.touched, 'password') && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{getIn(formik.errors, 'password')}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-12 col-sm-6 mb-3'>
                    <label className='col-form-label fw-bold fs-6'>
                      {intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.PASSWORD_REPEAT'})}
                    </label>
                    <input
                      {...formik.getFieldProps('password_repeat')}
                      placeholder={intl.formatMessage({id: 'ACCOUNT.USER_DETAILS.PASSWORD_REPEAT'})}
                      type='password'
                      name='password_repeat'
                      className={
                        getIn(formik.errors, 'password_repeat') &&
                        getIn(formik.touched, 'password_repeat')
                          ? 'form-control form-control-solid mb-3 mb-lg-0 border border-danger'
                          : 'form-control form-control-solid mb-3 mb-lg-0'
                      }
                      autoComplete='off'
                    />
                    {getIn(formik.errors, 'password_repeat') &&
                      getIn(formik.touched, 'password_repeat') && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{getIn(formik.errors, 'password_repeat')}</span>
                          </div>
                        </div>
                      )}
                  </div>
                </>
              )}
            </div>
          {formik.dirty && (
            <div className='card-footer d-flex justify-content-end py-6'>
              <Link to={`/user/${user?.id}/details/personal/view`}>
                <button type='reset' className='btn btn-light me-3'>
                  {intl.formatMessage({id: 'BUTTON.DISCARD'})}
                </button>
              </Link>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && intl.formatMessage({id: 'BUTTON.SUBMIT'})}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'BUTTON.WAIT_LOADING'})}...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          )}
        </form>
        </div>
        {openModal && <AddProfileImageModal setOpenModal={setOpenModal} user={user} asyncFetch={asyncFetch}/>}
      </div>
    </>
  )
}

export {UserDetails}
