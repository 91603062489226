/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import Flatpickr from "react-flatpickr";
import { Dropdown } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Select from "react-select";
import { initialQueryState, KTSVG } from '../../../../../../_metronic/helpers';
import { NotificationMessage } from '../../../../../shared/helper/Notification';
import { useQueryRequest } from '../../../shared/list/core/QueryRequestProvider';
import moment from 'moment';
import { FilterDateOption , FilterDateOptionRight } from '../../../../../shared/helper/FilterDateOption';

const ContractsFilter: FC = () => {
    const intl = useIntl()
    const [contractStateValue, setContractStateValue] = useState({} as any)
    const [buttonColor, setButtonColor] = useState('primary')
    const [buttonColorActive, setButtonColorActive] = useState('btn-active-light-primary me-2 mb-1')
    const [selectcontractTypeFilteredValue, setSelectcontractTypeFilteredValue] = useState({} as any)
    const {updateState} = useQueryRequest()
    const contractTypeList =  [{value: "defined", label: intl.formatMessage({id: 'CONTRACT.TYPE.DEFINED'})}, {value: "undefined", label:  intl.formatMessage({id: 'CONTRACT.TYPE.UNDEFINED'})}]
    const contractStatusList =  [{value: "active", label:intl.formatMessage({id: 'CONTRACT.STATUS.ACTIVE'})}, {value: "expired", label: intl.formatMessage({id: 'CONTRACT.STATUS.EXPIRED'})}]
    const [dateFrom, setDateFrom] = useState('');    
    const [dateTo, setDateTo] = useState('');

    function filter(obj: any) {
        for (var key in obj) {
          if (obj[key] === undefined) {
            delete obj[key];
            continue;
          }
          if (obj[key] && typeof obj[key] === "object") {
            filter(obj[key]);
            if (!Object.keys(obj[key]).length) {
              delete obj[key];
            }
          }
        }
        return obj;
      }
      

    const setFilter = async () =>  {           
        const defaultFilterValues = {
            status: {value: contractStateValue?.value},
            type: {value: selectcontractTypeFilteredValue?.value},
            start_date: {value: dateFrom || undefined},
            end_date: {value: dateTo || undefined},
        }
        const filteredObjectValues = filter(defaultFilterValues)

        if(Object.keys(filteredObjectValues).length !== 0) {
            Object.assign(filteredObjectValues, {...initialQueryState});

            await updateState(filteredObjectValues)
            setButtonColor('success')
            setButtonColorActive('btn-active-light-success me-2 mb-1')
        
            NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FILTER_SUCCESSFULLY_APPLIED'}), '', 'success')
        } else {
            setButtonColor('primary')
            setButtonColorActive('btn-active-light-success me-2 mb-1')
            NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.MUST_CHOOSE_FILTER_VALUE'}), '', 'warning')
        }
    }

    const resetFilter = async () => {
        setButtonColor('primary')
        setButtonColorActive('btn-active-light-primary me-2 mb-1')
        setContractStateValue({})
        setSelectcontractTypeFilteredValue({})
        setDateFrom('')
        setDateTo('')

        const defaultFilterValues = {
            type: {value: ''},
            status: {value: ''},
            start_date: {value: ''},
            end_date: {value: ''},
        }
        const filteredObjectValues = filter(defaultFilterValues)
        await updateState(filteredObjectValues)
        NotificationMessage(intl.formatMessage({id: 'MESSAGE_NOTIFICATION.FILTER_RESET_SUCCESSFULLY'}), '', 'success')
      }

    return (
        <Dropdown>
            <Dropdown.Toggle variant={buttonColor} className={buttonColorActive}>
                <KTSVG
                    path='/media/icons/duotune/general/gen031.svg'
                    className='svg-icon-5 svg-icon-gray-500 me-2'
                />
                {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER'})}
            </Dropdown.Toggle>
            <Dropdown.Menu className='px-4 py-4menu menu-sub menu-sub-dropdown w-250px w-sm-300px' align="start" id="dropdown-menu-align-end" data-provide="datepicker">
                <div className='px-2 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>{intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.OPTION'})}</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className='px-2 py-5'>
                    <div className='mb-3'>
                        <label className='form-label fw-bold'>{`${intl.formatMessage({id: 'CONTRACT.TYPE'})}`}:</label>
                        <Select
                            options={contractTypeList}
                            value={{
                                value: selectcontractTypeFilteredValue ? selectcontractTypeFilteredValue?.value : '',
                                label: selectcontractTypeFilteredValue?.label ? selectcontractTypeFilteredValue?.label : intl.formatMessage({id: 'CONTRACT.TYPE.SELECT'})
                            }}
                            onChange={(value: any) => setSelectcontractTypeFilteredValue(value)}
                            isSearchable={true}
                            classNamePrefix="select"
                            className={'mb-3 mb-lg-0'}
                        />
                    </div>
                     <div className='mb-3'>
                        <label className='form-label fw-bold'>{intl.formatMessage({id: 'CONTRACT.STATUS'})}:</label>
                        <Select
                            options={contractStatusList}
                            value={{
                                value: contractStateValue ? contractStateValue?.id : '',
                                label: contractStateValue?.label ? contractStateValue?.label : intl.formatMessage({id: 'CONTRACT.STATUS.SELECT'})
                            }}
                            onChange={(value: any) => setContractStateValue(value)}
                            isSearchable={false}
                            classNamePrefix="select"
                            className={'mb-3 mb-lg-0'}
                        />
                    </div>
                    <label className='form-label fs-6 fw-bold mt-2 mb-3'>{intl.formatMessage({id: 'NOTIFICATIONS.FILTER.DATE_RANGE'})}:</label>
                    <div className='d-flex'>
                        <div className='mb-3 pe-2'>
                            <label className='form-label fs-6 fw-bold'>{intl.formatMessage({id: 'CONTRACT.TOOLBAR.FILTER.DATE.FROM'})}:</label>
                            <Flatpickr
                                options={FilterDateOption}
                                className={'form-control form-control-solid mb-1 mb-lg-0'}
                                placeholder={intl.formatMessage({id: 'CONTRACT.TOOLBAR.FILTER.DATE.FROM'})}
                                name='profile.date_of_birth'
                                onChange={(newDates) => {
                                    setDateFrom(moment(newDates[0]).format('YYYY-MM-DD'));
                                }}
                                value={dateFrom ? moment(dateFrom).format('DD.MM.YYYY') : ''}
                            >
                            </Flatpickr>
                        </div>
                        <div className='mb-10'>
                            <label className='form-label fs-6 fw-bold'>{intl.formatMessage({id: 'CONTRACT.TOOLBAR.FILTER.DATE.TO'})}:</label>
                            <Flatpickr
                                options={FilterDateOptionRight}
                                className={'form-control form-control-solid mb-1 mb-lg-0'}
                                placeholder={intl.formatMessage({id: 'CONTRACT.TOOLBAR.FILTER.DATE.TO'})}
                                name='profile.date_of_birth'
                                onChange={(newDates) => {
                                    setDateTo(moment(newDates[0]).format('YYYY-MM-DD'));
                                }}
                                value={dateTo ? moment(dateTo).format('DD.MM.YYYY') : ''}


                            >
                            </Flatpickr>
                        </div>
                    </div>
                <div className='d-flex justify-content-end'>
                <button
                    type='reset'
                    className='btn btn-sm btn-light btn-active-light-danger me-2'
                    data-kt-menu-dismiss='true'
                    onClick={() => resetFilter()}
                >
                    {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.RESET'})}
                </button>
                <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'
                  onClick={() => setFilter()}
                >
                    {intl.formatMessage({id: 'REQUEST.TOOLBAR.FILTER.APPLY'})}
                </button>
                </div>
            </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export {ContractsFilter}
